'use strict';

angular
  .module('insights')
  .factory('PremiumPeople', function(
    $q,
    PremiumPeopleConfig,
    $injector,
    $http,
    $document,
    $log,
    commonWebsite,
    store
  ) {
    var y;
    var x;
    var xAxis;
    var barHeight;
    var Premium;
    var prevChartType;

    /**
     * Gets the currently showed data type. E.g. UA, Impressions or Clicks.
     * @returns {string} - Type of data shown
     */
    function getTypeOfData() {
      var data = angular
        .element($document[0].querySelector('.switch.active'))
        .attr('value');

      if (data === 'UA') {
        data = 'ua';
      } else if (data === 'Impressions') {
        data = 'impressions';
      } else if (data === 'Clicks') {
        data = 'clicks';
      }

      return data;
    }

    /**
     * Sort data
     * @param {string} sortable
     */
    function sortData(sortable, sortOrder) {
      var chartId = PremiumPeopleConfig.chartId();
      var chart = d3.select(chartId);
      chart
        .selectAll('.bar')
        .sort(function(a, b) {
          if (sortOrder) {
            return d3.ascending(a[sortable], b[sortable]);
          }
          return d3.descending(a[sortable], b[sortable]);
        })
        .transition()
        .delay(function(d, i) {
          return i * 25;
        })
        .duration(750)
        .attr('transform', function(d, i) {
          return 'translate(0,' + y(i) + ')';
        });
    }

    /**
     * Draw Graph
     * @param {Array} sortedData
     */
    function createChart(sortedData) {
      var typeOfData = getTypeOfData();
      var width = PremiumPeopleConfig.width();
      var premiumPeopleYLabelOffset = PremiumPeopleConfig.premiumPeopleYLabelOffset();
      var chartId = PremiumPeopleConfig.chartId();
      var margin = PremiumPeopleConfig.margin;
      var measuredWidth = PremiumPeopleConfig.measuredWidth();
      var height;
      var widthPercent = PremiumPeopleConfig.widthPercent();
      var spacing = PremiumPeopleConfig.spacing;
      barHeight = PremiumPeopleConfig.barHeight();

      if (!Premium) {
        Premium = $injector.get('Premium');
      }

      // Get the max so our table doesn't go outside the bounds
      var max = Math.max.apply(
        Math,
        sortedData.map(function(o) {
          return o[typeOfData];
        })
      );

      if (measuredWidth < 400) {
        x = d3.scale
          .linear()
          .range([0, width])
          .domain([0, max]);
      } else {
        x = d3.scale
          .linear()
          .range([0, width - premiumPeopleYLabelOffset])
          .domain([0, max]);
      }

      y = d3.scale.ordinal();

      xAxis = d3.svg
        .axis()
        .ticks(4)
        .tickFormat(function(d) {
          var prefix = d3.formatPrefix(d);
          return prefix.scale(d) + prefix.symbol;
        })
        .scale(x);

      d3.svg
        .axis()
        .scale(y)
        .tickFormat('');

      // Create the chart
      var chart = d3
        .select(chartId)
        .append('svg')
        .style('width', width + margin.left + margin.right + 'px')
        .append('g')
        .attr('transform', 'translate(' + [premiumPeopleYLabelOffset, 0] + ')')
        .attr('class', 'outer');

      if (measuredWidth < 400) {
        chart.attr('transform', 'translate(' + [150, 0] + ')');
      }

      // set y domain
      y.domain(d3.range(sortedData.length)).rangeBands([
        0,
        sortedData.length * barHeight
      ]);

      // set height based on data
      height = y.rangeExtent()[1];
      d3.select(chart.node().parentNode).style(
        'height',
        height + margin.top + margin.bottom + 'px'
      );

      chart
        .append('g')
        .attr('class', 'x axis bottom')
        .attr('transform', 'translate(0,' + height + ')')
        .call(xAxis.orient('bottom'));

      var bars = chart
        .selectAll('.bar')
        .data(sortedData)
        .enter()
        .append('g')
        .attr('class', 'bar')
        .attr('transform', function(d, i) {
          return 'translate(0,' + y(i) + ')';
        });

      bars
        .append('rect')
        .attr('class', function(d) {
          return 'percent _' + d.className;
        })
        .attr('height', y.rangeBand())
        .attr('width', function(d) {
          return (d[typeOfData] / max) * widthPercent + '%';
        });

      // Hide Labels
      if (measuredWidth < 400) {
        angular
          .element($document[0].querySelector('#premium-people-charts'))
          .addClass('small-chart');
        bars
          .append('text')
          .text(function(d) {
            return d.name;
          })
          .attr('class', 'name')
          .attr('y', y.rangeBand() - 5)
          .attr('x', spacing)
          .attr('transform', 'translate(' + [-150, -5] + ')');

        bars
          .append('text')
          .text(function(d) {
            return d3.format(',d')(d[typeOfData]);
          })
          .attr('class', 'number')
          .attr('y', y.rangeBand() - 5)
          .attr('x', spacing)
          .attr('transform', 'translate(' + [5, -5] + ')');
      } else {
        angular
          .element($document[0].querySelector('#premium-people-charts'))
          .removeClass('small-chart');
        bars
          .append('text')
          .text(function(d) {
            return d.name;
          })
          .attr('class', 'name')
          .attr('y', y.rangeBand() - 5)
          .attr('x', spacing)
          .attr('tooltip-placement', 'right')
          .attr('tooltip', 'on the right')
          .attr('transform', 'translate(' + [-180, -5] + ')');

        bars
          .append('text')
          .text(function(d) {
            return d3.format(',d')(d[typeOfData]);
          })
          .attr('class', 'number')
          .attr('y', y.rangeBand() - 5)
          .attr('x', spacing)
          .attr('transform', 'translate(' + [5, -5] + ')');
      }

      function changePremiumPeople() {
        var t0;
        if (!Premium) {
          Premium = $injector.get('Premium');
        }
        var previousTypeOfData = typeOfData;
        typeOfData = getTypeOfData();
        if (previousTypeOfData === typeOfData) {
          return;
        }
        var data = chart.selectAll('.bar').data();
        var peopleCaret = $document[0].getElementById('premium-people-caret');
        var helixCaret = $document[0].getElementById('premium-caret');
        var indexCaret = $document[0].getElementById('premium-index-caret');
        var measuredWidth = PremiumPeopleConfig.measuredWidth();

        if (typeOfData === 'impressions') {
          d3.selectAll('.premium-people-header').text('Impressions');
          d3.selectAll('#toggle-premium-people').append('i');
          d3.selectAll('#toggle-premium-people i').attr(
            'id',
            'premium-people-caret'
          );
          peopleCaret = $document[0].getElementById('premium-people-caret');
          helixCaret.className = 'fa';
          indexCaret.className = 'fa';
          peopleCaret.className = 'fa';

          var maxImpressions = Math.max.apply(
            Math,
            data.map(function(o) {
              return o.impressions;
            })
          );

          if (maxImpressions > 0) {
            t0 = d3
              .select('#premium-people-chart svg')
              .transition()
              .duration(750);
            if (maxImpressions > 0) {
              t0.selectAll('rect.percent')
                .attr('height', y.rangeBand())
                .attr('width', function(d) {
                  return (d.impressions / maxImpressions) * widthPercent + '%';
                });
            } else {
              t0.selectAll('rect.percent')
                .attr('height', y.rangeBand())
                .attr('width', '0%');
            }

            t0.selectAll('.number')
              .text(function(d) {
                return d3.format(',d')(d.impressions);
              })
              .attr('class', 'number')
              .attr('y', y.rangeBand() - 5)
              .attr('x', 0)
              .attr('transform', 'translate(' + [10, -5] + ')');

            if (measuredWidth < 400) {
              x = d3.scale
                .linear()
                .range([0, width])
                .domain([0, maxImpressions]);
            } else {
              x = d3.scale
                .linear()
                .range([0, width - premiumPeopleYLabelOffset])
                .domain([0, maxImpressions]);
            }

            y = d3.scale.ordinal();
            y.domain(d3.range(data.length)).rangeBands([
              0,
              data.length * barHeight
            ]);
            xAxis = d3.svg
              .axis()
              .ticks(4)
              .tickFormat(function(d) {
                var prefix = d3.formatPrefix(d);
                return prefix.scale(d) + prefix.symbol;
              })
              .scale(x);

            t0.selectAll('.x.axis.bottom').call(xAxis.orient('bottom'));
            Premium.sortData(typeOfData);
          }
        } else if (typeOfData === 'clicks') {
          d3.selectAll('.premium-people-header').text('Clicks');
          d3.selectAll('#toggle-people').append('i');
          d3.selectAll('#toggle-people i').attr('id', 'premium-people-caret');
          peopleCaret = $document[0].getElementById('premium-people-caret');
          helixCaret.className = 'fa';
          indexCaret.className = 'fa';
          peopleCaret.className = 'fa';

          var maxClicks = Math.max.apply(
            Math,
            data.map(function(o) {
              return o.clicks;
            })
          );

          if (maxClicks > 0) {
            t0 = d3
              .select('#premium-people-chart svg')
              .transition()
              .duration(750);
            if (maxClicks > 0) {
              t0.selectAll('rect.percent')
                .attr('height', y.rangeBand())
                .attr('width', function(d) {
                  return (d.clicks / maxClicks) * widthPercent + '%';
                });
            } else {
              t0.selectAll('rect.percent')
                .attr('height', y.rangeBand())
                .attr('width', '0%');
            }

            t0.selectAll('.number')
              .text(function(d) {
                return d3.format(',d')(d.clicks);
              })
              .attr('class', 'number')
              .attr('y', y.rangeBand() - 5)
              .attr('x', 0)
              .attr('transform', 'translate(' + [10, -5] + ')');

            if (measuredWidth < 400) {
              x = d3.scale
                .linear()
                .range([0, width])
                .domain([0, maxClicks]);
            } else {
              x = d3.scale
                .linear()
                .range([0, width - premiumPeopleYLabelOffset])
                .domain([0, maxClicks]);
            }

            y = d3.scale.ordinal();
            y.domain(d3.range(data.length)).rangeBands([
              0,
              data.length * barHeight
            ]);
            xAxis = d3.svg
              .axis()
              .ticks(4)
              .tickFormat(function(d) {
                var prefix = d3.formatPrefix(d);
                return prefix.scale(d) + prefix.symbol;
              })
              .scale(x);
            t0.selectAll('.x.axis.bottom').call(xAxis.orient('bottom'));
            Premium.sortData(typeOfData);
          }
        } else if (typeOfData === 'ua') {
          d3.selectAll('.premium-people-header').text('Unique Audience');
          d3.selectAll('#toggle-people').append('i');
          d3.selectAll('#toggle-people i').attr('id', 'premium-people-caret');
          peopleCaret = $document[0].getElementById('premium-people-caret');
          helixCaret.className = 'fa';
          indexCaret.className = 'fa';
          peopleCaret.className = 'fa';

          var maxUA = Math.max.apply(
            Math,
            data.map(function(o) {
              return o.ua;
            })
          );

          if (maxUA > 0) {
            t0 = d3
              .select('#premium-people-chart svg')
              .transition()
              .duration(750);
            if (maxUA > 0) {
              t0.selectAll('rect.percent')
                .attr('height', y.rangeBand())
                .attr('width', function(d) {
                  return (d.ua / maxUA) * widthPercent + '%';
                });
            } else {
              t0.selectAll('rect.percent')
                .attr('height', y.rangeBand())
                .attr('width', '1%');
            }

            t0.selectAll('.number')
              .text(function(d) {
                return d3.format(',d')(d.ua);
              })
              .attr('class', 'number')
              .attr('y', y.rangeBand() - 5)
              .attr('x', 0)
              .attr('transform', 'translate(' + [10, -5] + ')');

            if (measuredWidth < 400) {
              x = d3.scale
                .linear()
                .range([0, width])
                .domain([0, maxUA]);
            } else {
              x = d3.scale
                .linear()
                .range([0, width - premiumPeopleYLabelOffset])
                .domain([0, maxUA]);
            }

            y = d3.scale.ordinal();
            y.domain(d3.range(data.length)).rangeBands([
              0,
              data.length * barHeight
            ]);
            xAxis = d3.svg
              .axis()
              .ticks(4)
              .tickFormat(function(d) {
                var prefix = d3.formatPrefix(d);
                return prefix.scale(d) + prefix.symbol;
              })
              .scale(x);

            t0.selectAll('.x.axis.bottom').call(xAxis.orient('bottom'));
            Premium.sortData(typeOfData);
          }
        }
      }

      var metricSwitch = $document[0].getElementsByClassName('switch');

      for (var i = 0; i < metricSwitch.length; i++) {
        metricSwitch[i].addEventListener('click', changePremiumPeople);
      }

      var togglePeople = $document[0].getElementById('toggle-premium-people');
      togglePeople.addEventListener('click', Premium.peopleOnClick, false);

      var togglePersona = $document[0].getElementById('toggle-premium');
      togglePersona.addEventListener('click', Premium.personaOnClick, false);
      commonWebsite.hideSpinner('premium-people-chart', 'hideNoDataMsg');
    }

    function resize() {
      var chartId = PremiumPeopleConfig.chartId();
      var premiumPeopleYLabelOffset = PremiumPeopleConfig.premiumPeopleYLabelOffset();
      var margin = PremiumPeopleConfig.margin;
      var chart = d3.select(chartId);
      var typeOfData = getTypeOfData();
      var data = chart.selectAll('.bar').data();
      var width = PremiumPeopleConfig.width();
      var measuredWidth = PremiumPeopleConfig.measuredWidth();
      var widthPercent = PremiumPeopleConfig.widthPercent();

      // Get the max so our table doesn't go outside the bounds
      var max = Math.max.apply(
        Math,
        data.map(function(o) {
          return o[typeOfData];
        })
      );

      var x;
      if (measuredWidth < 400) {
        x = d3.scale
          .linear()
          .range([0, width])
          .domain([0, max]);
      } else {
        x = d3.scale
          .linear()
          .range([0, width - premiumPeopleYLabelOffset])
          .domain([0, max]);
      }

      var y = d3.scale.ordinal();

      var xAxis = d3.svg
        .axis()
        .ticks(5)
        .tickFormat(function(d) {
          var prefix = d3.formatPrefix(d);
          return prefix.scale(d) + prefix.symbol;
        })
        .scale(x);

      y.domain(d3.range(data.length)).rangeBands([0, data.length * barHeight]);
      var height = y.rangeExtent()[1];

      // Hide Labels
      if (measuredWidth < 400) {
        angular
          .element($document[0].querySelector('#premium-people-charts'))
          .addClass('small-chart');
        chart
          .selectAll('.bar text.name')
          .text(function(d) {
            return d.name;
          })
          .attr('transform', 'translate(' + [-150, -5] + ')');

        chart
          .selectAll('svg')
          .style('width', width + margin.left + margin.right + 'px');

        chart
          .selectAll('.outer')
          .attr('transform', 'translate(' + [150, 0] + ')');

        chart
          .selectAll('.x.axis.bottom')
          .attr('width', width - 50)
          .attr('transform', 'translate(' + [0, height] + ')')
          .call(xAxis.orient('bottom'));

        chart
          .selectAll('rect')
          .attr('class', 'percent')
          .attr('class', function(d) {
            return 'percent _' + d.className;
          })
          .attr('height', y.rangeBand())
          .attr('width', function(d) {
            return (d[typeOfData] / max) * widthPercent + '%';
          });
      } else {
        angular
          .element($document[0].querySelector('#premium-people-charts'))
          .removeClass('small-chart');

        chart
          .selectAll('text.name')
          .text(function(d) {
            return d.name;
          })
          .attr('transform', 'translate(' + [-180, -5] + ')');

        chart
          .selectAll('svg')
          .style('width', width + margin.left + margin.right + 'px');

        chart
          .selectAll('.outer')
          .attr(
            'transform',
            'translate(' + [premiumPeopleYLabelOffset, 0] + ')'
          );

        chart
          .selectAll('.x.axis.bottom')
          .attr('width', width - premiumPeopleYLabelOffset)
          .attr('transform', 'translate(' + [0, height] + ')')
          .call(xAxis.orient('bottom'));

        chart
          .selectAll('rect')
          .attr('class', 'percent')
          .attr('class', function(d) {
            return 'percent _' + d.className;
          })
          .attr('height', y.rangeBand())
          .attr('width', function(d) {
            return (d[typeOfData] / max) * widthPercent + '%';
          });
      }
    }

    function changeDate(data) {
      var chartId = PremiumPeopleConfig.chartId();
      var t0;
      var widthPercent = PremiumPeopleConfig.widthPercent();
      var typeOfData = getTypeOfData();
      var measuredWidth = PremiumPeopleConfig.measuredWidth();
      var chart = d3.select(chartId);

      if (measuredWidth < 400) {
        angular
          .element($document[0].querySelector('#premium-people-charts'))
          .addClass('small-chart');
      } else {
        angular
          .element($document[0].querySelector('#premium-people-charts'))
          .removeClass('small-chart');
      }

      // Get Old Data.
      var oldData = chart.selectAll('.bar').data();

      // Put New Data in Same order as Old Data.
      var newData = [];
      oldData.forEach(function(key) {
        var found = false;
        data = data.filter(function(item) {
          if (!found && item.name === key.name) {
            newData.push(item);
            found = true;
            return false;
          }
          return true;
        });
      });

      data = newData;

      // Add new data to chart
      chart.selectAll('.bar').data(data);
      chart.selectAll('rect.percent').data(data);
      chart.selectAll('.number').data(data);

      var width = PremiumPeopleConfig.width();
      var premiumPeopleYLabelOffset = PremiumPeopleConfig.premiumPeopleYLabelOffset();

      var max = Math.max.apply(
        Math,
        data.map(function(o) {
          return o[typeOfData];
        })
      );

      // First transition the line & label.
      t0 = chart
        .select('svg')
        .transition()
        .duration(750);
      if (max > 0) {
        t0.selectAll('rect.percent')
          .attr('height', y.rangeBand())
          .attr('width', function(d) {
            return (d[typeOfData] / max) * widthPercent + '%';
          });
      } else {
        t0.selectAll('rect.percent')
          .attr('height', y.rangeBand())
          .attr('width', '0%');
      }

      t0.selectAll('.number')
        .text(function(d) {
          return d3.format(',d')(d[typeOfData]);
        })
        .attr('class', 'number')
        .attr('y', y.rangeBand() - 5)
        .attr('x', 0)
        .attr('transform', 'translate(' + [10, -5] + ')');
      x = d3.scale
        .linear()
        .range([0, width - premiumPeopleYLabelOffset])
        .domain([0, max]);
      y = d3.scale.ordinal();
      y.domain(d3.range(data.length)).rangeBands([0, data.length * barHeight]);
      xAxis = d3.svg
        .axis()
        .ticks(4)
        .tickFormat(function(d) {
          var prefix = d3.formatPrefix(d);
          return prefix.scale(d) + prefix.symbol;
        })
        .scale(x);

      t0.selectAll('.x.axis.bottom').call(xAxis.orient('bottom'));

      chart
        .selectAll('.bar')
        .sort(function(a, b) {
          return d3.descending(a[typeOfData], b[typeOfData]);
        })
        .transition()
        .delay(function(d, i) {
          return i * 25;
        })
        .duration(750)
        .attr('transform', function(d, i) {
          return 'translate(5,' + y(i) + ')';
        });
    }

    /**
     * Check if graph has been created
     * @returns {boolean}
     */
    function graphInitialised() {
      var chart = $document[0].getElementById('premium-people-chart');
      var svg = chart.getElementsByTagName('svg');
      return svg.length > 0;
    }

    /**
     * Remove chart
     * - Used when transitioning between chart types.
     */
    function removeChart() {
      var chart = d3.select('#premium-people-chart');
      chart.selectAll('svg').remove();
    }

    /**
     * Create chart or change date
     * @param {Array} jsonData
     */
    function createOrChange(jsonData) {
      removeChart();

      if (!graphInitialised()) {
        return createChart(jsonData);
      }

      return changeDate(jsonData);
    }

    return {
      createOrChange: createOrChange,
      resize: resize,
      changeDate: changeDate,
      sortData: sortData
    };
  });
