(function() {
  'use strict';

  angular.module('campaigns').factory('commonCampaign', commonCampaign);

  commonCampaign.$inject = ['usSpinnerService'];

  function commonCampaign(usSpinnerService) {
    return {
      noData: noData,
      todayDate: todayDate,
      getLastDate: getLastDate,
      formatEndDate: formatEndDate,
      isSameDay: isSameDay,
      hideSpinner: hideSpinner,
      showSpinner: showSpinner,
      hideNoData: hideNoData,
      getUID: getUID
    };

    function getUID() {
      var currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
      var companyUID = currentCompany.randomId;

      return companyUID;
    }

    /**
     * Display if no data
     * @param {string} chartId - Id of chart
     */
    function noData(chartId) {
      var noInsights = '<div class="no-insights">Data Unavailable</div>';

      var wrapper = document.getElementById(chartId);
      var spinner = wrapper.getElementsByTagName('img');
      var overlay = wrapper.getElementsByClassName('overlay');
      if (overlay) {
        angular.element(overlay).remove();
      }

      angular.element(spinner).remove();

      if (wrapper.getElementsByClassName('no-insights').length < 1) {
        angular.element(wrapper).append(noInsights);
      }

      if (wrapper.getElementsByTagName('svg').length > 0) {
        angular.element(wrapper.getElementsByTagName('svg')).remove();
      }

      angular.element(wrapper.getElementsByClassName('area')).remove();
    }

    /**
     * Remove noData message
     * @param {string} chartId - Id of chart
     */
    function hideNoData(chartId) {
      var wrapper = document.getElementById(chartId);
      var noInsights = wrapper.getElementsByClassName('no-insights');

      if (noInsights) {
        angular.element(noInsights).remove();
      }
    }

    /**
     * Calculate Today's Date
     * @returns {string} Formatted date
     */
    function todayDate() {
      var timeStamp = new Date();
      return moment(timeStamp).format('YYYY-MM-DD');
    }

    /**
     * Get the last n Days/Weeks/Months
     * @param {string|Date} date - Date to get values from, if not provided then from today.
     * @param {number} number - Number of Days, Weeks, Months
     * @param {string} type - day, week, month
     * @returns {{start: string, end: string}}
     */
    function getLastDate(date, number, type) {
      var startDate;
      var endDate;
      var startType = type;

      // Start the Week on Sunday
      if (startType === 'week' || startType === 'weeks') {
        startType = 'isoWeek';
      }

      if (date) {
        if (type === 'months') {
          startDate = moment(date)
            .subtract(number, type)
            .startOf('month')
            .toDate();
        } else {
          startDate = moment(date)
            .subtract(number, type)
            .toDate();
        }

        endDate = moment(date)
          .endOf(startType)
          .toDate();
      } else {
        startDate = moment()
          .subtract(number, type)
          .toDate();
        endDate = moment()
          .endOf(startType)
          .toDate();
      }

      startDate = moment(startDate).format('YYYY-MM-DD');
      endDate = moment(endDate).format('YYYY-MM-DD');
      return {
        start: startDate,
        end: endDate
      };
    }

    /**
     * Format End Date to YYYY-MM-DDD if not present return yesterdays date formatted.
     * @param {Date} [endDate] Optional end date
     * @returns {string} date formatted in YYYY-MM-DD
     */
    function formatEndDate(endDate) {
      var newDate;
      if (endDate) {
        newDate = moment(endDate).format('YYYY-MM-DD');
      } else {
        newDate = moment()
          .subtract(1, 'day')
          .format('YYYY-MM-DD');
      }

      return newDate;
    }

    /**
     * Checks if 2 dates are the same day
     * @param {Date|Moment} oldDate
     * @param {Date|Moment} newDate
     * @returns {boolean}
     */
    function isSameDay(oldDate, newDate) {
      return moment(newDate).isSame(oldDate, 'day');
    }

    /**
     * Hide spinner
     * @param {string} chartId - Id of chart
     * @param {string} hideSpinnerWithMsg === "showNoDataMsg" (optional) then show the "data unavailable" message
     * @param {string} hideSpinnerWithMsg === "hideNoDataMsg" (optional) then hide the "data unavailable" message
     */
    function hideSpinner(chartId, hideSpinnerWithMsg) {
      usSpinnerService.stop('spinner-' + chartId);
      var wrapper = document.getElementById(chartId);
      if (hideSpinnerWithMsg === 'showNoDataMsg') {
        var noInsights = '<div class="no-insights">Data Unavailable</div>';
        if (wrapper.getElementsByClassName('no-insights').length < 1) {
          angular.element(wrapper).append(noInsights);
        }
      }
      if (hideSpinnerWithMsg === 'hideNoDataMsg') {
        if (wrapper.getElementsByClassName('no-insights').length > 0) {
          angular
            .element(wrapper.getElementsByClassName('no-insights'))
            .remove();
        }
      }
    }

    /**
     * Show spinner or hide the spinner and show the message
     *
     * @param {string} chartId
     * @param {string} hideChart === "hideChart" (optional) then hide the chart or if chart is not there then look for data unavailable message
     */
    function showSpinner(chartId, hideChart) {
      if (hideChart === 'hideChart') {
        var wrapper = document.getElementById(chartId);
        // we have to show spinners only, so check both for chart and data unavailable
        if (wrapper.getElementsByTagName('svg').length > 0) {
          angular.element(wrapper.getElementsByTagName('svg')).remove();
        }
        if (wrapper.getElementsByClassName('no-insights').length > 0) {
          angular
            .element(wrapper.getElementsByClassName('no-insights'))
            .remove();
        }
      }
      usSpinnerService.spin('spinner-' + chartId);
    }
  }
})();
