'use strict';

/* eslint angular/di: [2,"array"] */
// Setting up route
angular.module('campaigns').config([
  '$stateProvider',
  function($stateProvider) {
    // Campaigns state routing
    $stateProvider
      .state('listCampaigns', {
        title: 'Ad Campaign',
        url: '/campaigns',
        templateUrl: 'modules/campaigns/views/list-campaigns.client.view.html'
      })
      .state('createCampaign', {
        title: 'Ad Campaign',
        url: '/campaigns/create',
        templateUrl: 'modules/campaigns/views/create-campaign.client.view.html'
      })
      .state('campaignTeams', {
        title: 'Assign Teams',
        url: '/campaigns/teams',
        templateUrl: 'modules/campaigns/views/campaigns-teams.view.html'
      })
      .state('viewCampaign', {
        title: 'Ad Campaign',
        url: '/campaigns/:campaignId',
        templateUrl: 'modules/campaigns/views/view-campaign.client.view.html'
      })
      .state('editCampaign', {
        title: 'Ad Campaign',
        url: '/campaigns/:campaignId/edit',
        templateUrl: 'modules/campaigns/views/edit-campaign.client.view.html'
      })
      .state('campaignInsight', {
        title: 'Ad Campaign',
        url: '/campaigns/:campaignId/insights',
        templateUrl: 'modules/campaigns/views/campaign-insight.client.view.html'
      })
      .state('campaignPixels', {
        title: 'Ad Campaign',
        url: '/campaigns/:campaignId/pixels',
        templateUrl: 'modules/campaigns/views/campaign-pixels.client.view.html'
      });
  }
]);
