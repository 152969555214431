(function() {
  'use strict';

  angular
    .module('websites')
    .factory(
      'localVsInternationalChartWebsite',
      localVsInternationalChartWebsite
    );

  localVsInternationalChartWebsite.$inject = [
    'commonWebsite',
    '$http',
    'InsightsLocalVsInternational',
    '$log',
    '$q',
    'downloads'
  ];

  function localVsInternationalChartWebsite(
    commonWebsite,
    $http,
    InsightsLocalVsInternational,
    $log,
    $q,
    downloads
  ) {
    return {
      chart: chart,
      resize: resize,
      getData: getData
    };

    /**
     * Parse which Local vs International Chart
     * @param {string} period
     * @param {string} websiteId
     * @param {Object} startEnd - Insight start and end dates
     * @param {Object} [variable]
     */
    function chart(period, websiteId, startEnd, variable) {
      var companyUID = commonWebsite.getUID();
      timeChart(
        websiteId,
        companyUID,
        startEnd.start,
        startEnd.end,
        period,
        variable
      );
    }

    /**
     * Local vs International Chart for a Time Period
     * @param {string} websiteId
     * @param {string} startDate - Campaign Start Date YYYY-MM-DD
     * @param {?string} endDate - Campaign End Date YYYY-MM-DD
     * @param {Object} variable
     * @param {string} period
     */
    function timeChart(
      websiteId,
      companyUID,
      startDate,
      endDate,
      period,
      variable
    ) {
      var yesterday = commonWebsite.formatEndDate();
      var adjustedStart;
      if (period === 'month') {
        adjustedStart = moment(startDate)
          .startOf('month')
          .format('YYYY-MM-DD');
      } else if (period === 'week') {
        adjustedStart = moment(startDate)
          .startOf('isoWeek')
          .format('YYYY-MM-DD');
      }

      $http
        .post('/api/insights/website', {
          campaign: websiteId,
          companyUID: companyUID,
          startDate: adjustedStart || startDate,
          endDate: endDate || yesterday,
          chart: 'local',
          period: 'time',
          type: 'website',
          variable: variable
        })
        .then(
          function(result) {
            if (result.data.insights.length > 0) {
              InsightsLocalVsInternational.checkService(
                angular.copy(result.data.insights)
              );
            } else {
              commonWebsite.hideSpinner(
                'local-vs-international-chart',
                'showNoDataMsg'
              );
            }
          },
          function(reason) {
            commonWebsite.hideSpinner(
              'local-vs-international-chart',
              'showNoDataMsg'
            );
            $log.error('Error: website-local-time');
            $log.error(reason);
          }
        );
    }

    /**
     * Resize chart
     */
    function resize() {
      InsightsLocalVsInternational.resize();
    }

    /**
     * Get data for download.
     *
     * @param {string} websiteId
     * @param {Object} startEnd - Insight start and end dates
     * @param {Object} [variable]
     */
    function getData(websiteId, startEnd, variable) {
      var companyUID = commonWebsite.getUID();
      var yesterday = commonWebsite.formatEndDate();

      return $q(function(resolve, reject) {
        $http
          .post('/api/insights/website', {
            campaign: websiteId,
            companyUID: companyUID,
            startDate: startEnd.start,
            endDate: startEnd.end || yesterday,
            chart: 'local',
            period: 'time',
            type: 'website',
            variable: variable
          })
          .then(
            function(result) {
              var data = result.data.insights;
              data = downloads.addHeading(
                data,
                'Date',
                startEnd.end || yesterday
              );
              data = downloads.renameHeading(
                data,
                'impressions_local',
                'AU_Impressions'
              );
              data = downloads.renameHeading(
                data,
                'impressions_int',
                'International_Impressions'
              );
              data = downloads.orderFields(data, [
                'Date',
                'AU_Impressions',
                'International_Impressions'
              ]);

              resolve(data);
            },
            function(reason) {
              $log.error('Error: website-local-download');
              $log.error(reason);
              reject();
            }
          );
      });
    }
  }
})();
