(function() {
  'use strict';

  angular
    .module('campaigns')
    .factory('deviceChartCampaign', deviceChartCampaign);

  deviceChartCampaign.$inject = [
    '$http',
    'commonCampaign',
    'InsightsDevice',
    '$q',
    '$log',
    'downloads'
  ];

  function deviceChartCampaign(
    $http,
    commonCampaign,
    InsightsDevice,
    $q,
    $log,
    downloads
  ) {
    return {
      chart: chart,
      resize: resize,
      getData: getData
    };

    /**
     * StartEnd type
     * @typedef {Object} StartEnd
     * @property {string} start Campaign start date
     * @property {?string} end Campaign end date or selected date.
     */

    /**
     * Device Chart based on date period
     * @param {string} period
     * @param {string} campaignId
     * @param {StartEnd} startEnd - Insight start and end dates.
     * @param {Object} variable
     */
    function chart(period, campaignId, startEnd, variable) {
      var companyUID = commonCampaign.getUID();
      if (period === 'all') {
        allChart(campaignId, companyUID, startEnd.end, variable);
      } else if (period === 'month') {
        monthChart(campaignId, companyUID, startEnd.end, variable);
      } else if (period === 'week') {
        weekChart(campaignId, companyUID, startEnd.end, variable);
      } else if (period === 'day') {
        dayChart(campaignId, companyUID, startEnd.end, variable);
      }
    }

    /**
     * Device Chart for All Time
     * @param {string} campaignId
     * @param {?string} endDate - Campaign End Date YYYY-MM-DD
     * @param {Object} variable
     */
    function allChart(campaignId, companyUID, endDate, variable) {
      var yesterday = commonCampaign.formatEndDate();
      $http
        .post('/api/insights/campaign', {
          campaign: campaignId,
          companyUID: companyUID,
          endDate: endDate || yesterday,
          chart: 'device',
          period: 'all',
          type: 'campaign',
          variable: variable
        })
        .then(
          function(result) {
            if (result.data.insights.length > 0) {
              InsightsDevice.createChart(angular.copy(result.data.insights));
            } else {
              commonCampaign.hideSpinner('device-chart', 'showNoDataMsg');
            }
          },
          function(reason) {
            commonCampaign.hideSpinner('device-chart', 'showNoDataMsg');
            $log.error('Error: campaign-device-all');
            $log.error(reason);
          }
        );
    }

    /**
     * Month Device Chart
     * @param {string} campaignId
     * @param {?string} endDate - Campaign End Date YYYY-MM-DD
     * @param {Object} variable
     */
    function monthChart(campaignId, companyUID, endDate, variable) {
      var startOfMonth = moment(endDate || undefined)
        .startOf('month')
        .format('YYYY-MM-DD');

      $http
        .post('/api/insights/campaign', {
          campaign: campaignId,
          companyUID: companyUID,
          endDate: startOfMonth,
          chart: 'device',
          period: 'month',
          type: 'campaign',
          variable: variable
        })
        .then(
          function(result) {
            if (result.data.insights.length > 0) {
              InsightsDevice.createChart(angular.copy(result.data.insights));
            } else {
              commonCampaign.hideSpinner('device-chart', 'showNoDataMsg');
            }
          },
          function(reason) {
            commonCampaign.hideSpinner('device-chart', 'showNoDataMsg');
            $log.error('Error: campaign-device-month');
            $log.error(reason);
          }
        );
    }

    /**
     * Weekly Device Chart
     * @param {string} campaignId
     * @param {?string} endDate - Campaign End Date YYYY-MM-DD
     * @param {Object} variable
     */
    function weekChart(campaignId, companyUID, endDate, variable) {
      var startOfWeek = moment(endDate || undefined)
        .startOf('isoWeek')
        .format('YYYY-MM-DD');

      $http
        .post('/api/insights/campaign', {
          campaign: campaignId,
          companyUID: companyUID,
          endDate: startOfWeek,
          chart: 'device',
          period: 'week',
          type: 'campaign',
          variable: variable
        })
        .then(
          function(result) {
            if (result.data.insights.length > 0) {
              InsightsDevice.createChart(angular.copy(result.data.insights));
            } else {
              commonCampaign.hideSpinner('device-chart', 'showNoDataMsg');
            }
          },
          function(reason) {
            commonCampaign.hideSpinner('device-chart', 'showNoDataMsg');
            $log.error('Error: campaign-device-week');
            $log.error(reason);
          }
        );
    }

    /**
     * Day Device Chart
     * @param {string} campaignId
     * @param {?string} endDate - Campaign End Date YYYY-MM-DD
     * @param {Object} variable
     */
    function dayChart(campaignId, companyUID, endDate, variable) {
      var yesterday = commonCampaign.formatEndDate();

      $http
        .post('/api/insights/campaign', {
          campaign: campaignId,
          companyUID: companyUID,
          endDate: endDate || yesterday,
          chart: 'device',
          period: 'day',
          type: 'campaign',
          variable: variable
        })
        .then(
          function(result) {
            if (result.data.insights.length > 0) {
              InsightsDevice.createChart(angular.copy(result.data.insights));
            } else {
              commonCampaign.hideSpinner('device-chart', 'showNoDataMsg');
            }
          },
          function(reason) {
            commonCampaign.hideSpinner('device-chart', 'showNoDataMsg');
            $log.error('Error: campaign-device-day');
            $log.error(reason);
          }
        );
    }

    /**
     * Resize chart
     */
    function resize() {
      InsightsDevice.resize();
    }

    /**
     * Which data to download
     * @param {string} campaignId
     * @param {string} type
     * @param {StartEnd} startEnd - Insight start and end dates
     * @param {Object} variable
     * @return {Promise<Array<Object>>}
     */
    function getData(campaignId, type, startEnd, variable) {
      var companyUID = commonCampaign.getUID();
      if (type === 'day') {
        return getDataDay(campaignId, companyUID, startEnd.end, variable);
      }
      if (type === 'week') {
        return getDataWeek(campaignId, companyUID, startEnd.end, variable);
      }
      if (type === 'all') {
        return getDataAll(campaignId, companyUID, startEnd.end, variable);
      }
      return getDataMonth(campaignId, companyUID, startEnd.end, variable);
    }

    /**
     * Download all day data
     * @param {string} campaignId
     * @param {?string} endDate - Campaign End Date YYYY-MM-DD
     * @param {Object} variable
     * @return {Promise<Array<Object>>}
     */
    function getDataDay(campaignId, companyUID, endDate, variable) {
      var yesterday = commonCampaign.formatEndDate();
      return $q(function(resolve, reject) {
        $http
          .post('/api/insights/campaign', {
            campaign: campaignId,
            companyUID: companyUID,
            endDate: endDate || yesterday,
            chart: 'device',
            period: 'day',
            type: 'campaign',
            variable: variable
          })
          .then(
            function(result) {
              var finalResult;
              if (result.data.insights.length) {
                finalResult = formatDownload(
                  result.data.insights,
                  'Date',
                  endDate || yesterday
                );
              }
              resolve(finalResult);
            },
            function(reason) {
              $log.error('Error: campaign-device-data-day');
              $log.error(reason);
              reject();
            }
          );
      });
    }

    /**
     * Download all day data
     * @param {string} campaignId
     * @param {?string} endDate - Campaign End Date YYYY-MM-DD
     * @param {Object} variable
     * @return {Promise<Array<Object>>}
     */
    function getDataAll(campaignId, companyUID, endDate, variable) {
      var yesterday = commonCampaign.formatEndDate();
      return $q(function(resolve, reject) {
        $http
          .post('/api/insights/campaign', {
            campaign: campaignId,
            companyUID: companyUID,
            endDate: endDate || yesterday,
            chart: 'device',
            period: 'all',
            type: 'campaign',
            variable: variable
          })
          .then(
            function(result) {
              var finalResult;
              if (result.data.insights.length) {
                finalResult = formatDownload(
                  result.data.insights,
                  'Period_End_Date',
                  endDate || yesterday
                );
              }
              resolve(finalResult);
            },
            function(reason) {
              $log.error('Error: campaign-device-data-day');
              $log.error(reason);
              reject();
            }
          );
      });
    }

    /**
     * Download all week data
     * @param {string} campaignId
     * @param {?string} endDate - Campaign End Date YYYY-MM-DD
     * @param {Object} variable
     * @return {Promise<Array<Object>>}
     */
    function getDataWeek(campaignId, companyUID, endDate, variable) {
      var startOfWeek = moment(endDate || undefined)
        .startOf('isoWeek')
        .format('YYYY-MM-DD');
      return $q(function(resolve, reject) {
        $http
          .post('/api/insights/campaign', {
            campaign: campaignId,
            companyUID: companyUID,
            endDate: startOfWeek,
            chart: 'device',
            period: 'week',
            type: 'campaign',
            variable: variable
          })
          .then(
            function(result) {
              var finalResult;
              if (result.data.insights.length) {
                finalResult = formatDownload(
                  result.data.insights,
                  'Start_Date_of_Week',
                  startOfWeek
                );
              }
              resolve(finalResult);
            },
            function(reason) {
              $log.error('Error: campaign-device-data-week');
              $log.error(reason);
              reject();
            }
          );
      });
    }

    /**
     * Download all month data
     * @param {string} campaignId
     * @param {?string} endDate - Campaign End Date YYYY-MM-DD
     * @param {Object} variable
     * @return {Promise<Array<Object>>}
     */
    function getDataMonth(campaignId, companyUID, endDate, variable) {
      var startOfMonth = moment(endDate || undefined)
        .startOf('month')
        .format('YYYY-MM-DD');
      return $q(function(resolve, reject) {
        $http
          .post('/api/insights/campaign', {
            campaign: campaignId,
            companyUID: companyUID,
            endDate: startOfMonth,
            chart: 'device',
            period: 'month',
            type: 'campaign',
            variable: variable
          })
          .then(
            function(result) {
              var finalResult;
              if (result.data.insights.length) {
                finalResult = formatDownload(
                  result.data.insights,
                  'Start_Date_of_Month',
                  startOfMonth
                );
              }
              resolve(finalResult);
            },
            function(reason) {
              $log.error('Error: campaign-device-data-month');
              $log.error(reason);
              reject();
            }
          );
      });
    }

    function formatDownload(rawData, dateHeading, date) {
      var data = addVennsSingleValues(rawData);
      data = downloads.addHeading(data, dateHeading, date);
      data = downloads.renameHeading(data, 'ua_d', 'Desktop_UA');
      data = downloads.renameHeading(data, 'ua_m', 'Mobile_UA');
      data = downloads.renameHeading(data, 'ua_t', 'Tablet_UA');
      data = downloads.renameHeading(data, 'ua_dm', 'Desktop_Mobile_UA');
      data = downloads.renameHeading(data, 'ua_mt', 'Mobile_Tablet_UA');
      data = downloads.renameHeading(data, 'ua_dt', 'Desktop_Tablet_UA');
      data = downloads.renameHeading(
        data,
        'ua_dmt',
        'Desktop_Mobile_Tablet_UA'
      );
      data = downloads.renameHeading(data, 'ua_d_only', 'Desktop_Only_UA');
      data = downloads.renameHeading(data, 'ua_m_only', 'Mobile_Only_UA');
      data = downloads.renameHeading(data, 'ua_t_only', 'Tablet_Only_UA');
      data = downloads.renameHeading(data, 'imp_d', 'Desktop_Impressions');
      data = downloads.renameHeading(data, 'imp_m', 'Mobile_Impressions');
      data = downloads.renameHeading(data, 'imp_t', 'Tablet_Impressions');
      data = downloads.renameHeading(data, 'clicks_d', 'Desktop_Clicks');
      data = downloads.renameHeading(data, 'clicks_m', 'Mobile_Clicks');
      data = downloads.renameHeading(data, 'clicks_t', 'Tablet_Clicks');
      return data;
    }

    /**
     * calculate Desktop/Mobile/Tablet only data
     * @param {Array<Object>} dataValues
     */
    function addVennsSingleValues(dataValues) {
      // add charts "only" values
      angular.forEach(dataValues, function(value) {
        if (value && (value.ua_d > 0 || value.ua_m > 0 || value.ua_t > 0)) {
          value.ua_d_only =
            parseInt(value.ua_d) -
            parseInt(value.ua_dt) -
            parseInt(value.ua_dm) +
            parseInt(value.ua_dmt);
          value.ua_m_only =
            parseInt(value.ua_m) -
            parseInt(value.ua_mt) -
            parseInt(value.ua_dm) +
            parseInt(value.ua_dmt);
          value.ua_t_only =
            parseInt(value.ua_t) -
            parseInt(value.ua_dt) -
            parseInt(value.ua_mt) +
            parseInt(value.ua_dmt);
        }
      });
      return dataValues;
    }
  }
})();
