(function() {
  'use strict';

  // Campaigns service used to communicate Campaigns REST endpoints
  angular.module('campaigns').factory('Campaigns', Campaigns);

  Campaigns.$inject = ['AudiencesApi'];

  function Campaigns(audiencesApi) {
    return audiencesApi.resource(
      '/campaigns/:id',
      {
        id: '@id'
      },
      {
        update: {
          method: 'PUT'
        },
        allTeams: {
          method: 'GET',
          isArray: true,
          params: { allTeams: true }
        }
      }
    );
  }
})();
