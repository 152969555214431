'use strict';

// Setting up route
angular.module('core').config([
  '$stateProvider',
  '$urlRouterProvider',
  function($stateProvider, $urlRouterProvider) {
    // Redirect to home view when route not found
    $urlRouterProvider.when('', '/');

    // Home state routing
    $stateProvider
      .state('home', {
        url: '/',
        templateUrl: 'modules/core/views/home.client.view.html'
      })

      .state('403', {
        url: '/no-access',
        templateUrl: 'modules/core/views/noaccess.client.view.html'
      })

      .state('no-access', {
        url: '/no-access',
        templateUrl: 'modules/core/views/noaccess.client.view.html'
      })

      .state('verification', {
        url: '/verification',
        templateUrl: 'modules/core/views/verification.client.view.html'
      });
  }
]);
