(function() {
  'use strict';

  angular.module('settings').controller('AdminController', AdminController);

  AdminController.$inject = [
    '$rootScope',
    'Authorization',
    'AdminSettings',
    'Notification',
    'Settings',
    '$log'
  ];
  function AdminController(
    $rootScope,
    Authorization,
    AdminSettings,
    Notification,
    Settings,
    $log
  ) {
    $rootScope.title = 'Admin Settings';
    var vm = this;
    Authorization.authorize().then(function(result) {
      vm.user = result;
    });

    Settings.checkNotification();

    AdminSettings.get().then(function(result) {
      vm.dashboardNotificationOn = result.dashboardNotificationOn;
      var lastUpdatedRaw = result.dateUpdated;
      var day = moment.tz(lastUpdatedRaw, 'UTC');
      vm.lastUpdated = moment
        .tz(day, moment.tz.guess())
        .format('dddd, MMMM Do YYYY, h:mm:ss a');
    });

    vm.changeNotificationStatus = changeNotificationStatus;

    function changeNotificationStatus() {
      vm.dashboardNotificationOn = !vm.dashboardNotificationOn;
      AdminSettings.update({
        dashboardNotificationOn: vm.dashboardNotificationOn
      }).then(
        function(result) {
          if (result) {
            Notification.success({
              message: 'Setting updated',
              positionX: 'center',
              positionY: 'top',
              delay: 2500
            });
          } else {
            Notification.error({
              message: 'Error: Setting not updated',
              positionX: 'center',
              positionY: 'top'
            });
          }
        },
        function(reason) {
          $log.error('ERROR: ', reason);
          Notification.error({
            message: 'Error: Setting not updated',
            positionX: 'center',
            positionY: 'top'
          });
        }
      );
    }
  }
})();
