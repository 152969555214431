'use strict';

angular
  .module('insights')
  .factory('InsightsAge', function(InsightsAgeConfig, commonWebsite) {
    var typeOfData;
    var globalData = [];
    var completeData = [];

    /**
     * Returns today's date in YYYY-MM-DD format
     */
    function todayDate() {
      return moment().format('YYYY-MM-DD');
    }

    /**
     * Gets the currently showed data type. E.g. UA, Impressions or Clicks.
     * @returns {string} - Type of data shown
     */
    function getTypeOfData() {
      var data = angular
        .element(document.querySelector('.switch.active'))
        .attr('value');
      if (data === 'UA') {
        data = 'ua';
      } else if (data === 'Impressions') {
        data = 'impressions';
      } else if (data === 'Clicks') {
        data = 'clicks';
      }

      return data;
    }

    /**
     * Remove null data
     * @param {Array} data
     * @returns {Array}
     */
    function removeNullData(data) {
      var tempArray = [];
      if (data.length > 0) {
        for (var i = 0; i < data.length; i++) {
          if (data[i].gender !== null) {
            var found = false;
            // check if the gender is male and then filter accross data that if the same data is there for FEMALE
            if (data[i].gender === 'Male') {
              data.filter(function(item) {
                if (item.age === data[i].age && item.gender === 'Female') {
                  found = true;
                }
                return true;
              });
            } else {
              data.filter(function(item) {
                if (item.age === data[i].age && item.gender === 'Male') {
                  found = true;
                }
                return true;
              });
            }
            // if the other gender not have that group data then insert null values.
            if (!found) {
              tempArray.push({
                v: '0',
                ua: '0',
                impressions: '0',
                gender: data[i].gender === 'Male' ? 'Female' : 'Male',
                age: data[i].age
              });
            }
            tempArray.push(data[i]);
          }
        }
      }

      return tempArray;
    }

    /**
     * Gets last date from data.
     * @param {Array} data
     * @returns {Array}
     */
    function parseData(data) {
      data = removeNullData(data);

      // Proceed only if there is data.
      if (data.length < 1) {
        return data;
      }

      var lastDate;
      var relevantData;
      if (data[0].Period_End) {
        lastDate = data[0].Period_End;
        relevantData = [];
        for (var j = 0; j < data.length; j++) {
          if (data[j].Period_End === lastDate) {
            relevantData.push(data[j]);
          } else {
            break;
          }
        }
      } else {
        lastDate = data[0].Period_Start;
        relevantData = [];
        for (var n = 0; n < data.length; n++) {
          if (data[n].Period_Start === lastDate) {
            relevantData.push(data[n]);
          } else {
            break;
          }
        }
      }

      relevantData.forEach(function(data) {
        data.impressions = parseInt(Number(data.impressions));
        data.ua = parseInt(Number(data.ua));
      });

      return relevantData;
    }

    /**
     * Check if Data is null
     * @param {Array} data
     * @returns {boolean}
     */
    function checkIfData(data) {
      if (data.length < 1) {
        return false;
      }

      var hasData = true;
      if (data[0].age === null && data[0].gender === null) {
        hasData = false;
      }

      return hasData;
    }

    function readData(data) {
      typeOfData = getTypeOfData();
      globalData = parseData(data);
      var containsData = checkIfData(globalData);
      if (!containsData) {
        commonWebsite.hideSpinner('age-chart', 'showNoDataMsg');
        commonWebsite.hideSpinner('age-chart', 'showNoDataMsg');

        return;
      }

      var nestArray = function(flatArray) {
        var nestArr = [];
        var maleObj = {};
        var femaleObj = {};
        maleObj.gender = 'Male';
        maleObj.data = [];
        femaleObj.gender = 'Female';
        femaleObj.data = [];
        for (var i = 0; i < flatArray.length; i++) {
          if (flatArray[i].gender === 'Male') {
            maleObj.data.push(flatArray[i]);
          } else if (flatArray[i].gender === 'Female') {
            femaleObj.data.push(flatArray[i]);
          } else {
            console.error('ERROR');
            console.error(flatArray[i].gender);
          }
        }
        nestArr.push(maleObj);
        nestArr.push(femaleObj);
        return nestArr;
      };

      globalData = nestArray(globalData);

      var series = globalData.map(function(d) {
        return d.gender;
      });

      var parentIndex;
      globalData = globalData.map(function(d, index) {
        parentIndex = index;
        return d.data.map(function(o, i) {
          // Structure it so that your numeric
          // axis (the stacked amount) is y
          var oppositeIndex = parentIndex === 0 ? 1 : 0;
          return {
            y: parseInt(Number(o[typeOfData])),
            x: o.age,
            ua: parseInt(Number(o.ua)),
            impressions: parseInt(Number(o.impressions)),
            age: o.age,
            g: o.gender,
            o_ua: parseInt(Number(globalData[oppositeIndex].data[i].ua)),
            o_impressions: parseInt(
              Number(globalData[oppositeIndex].data[i].impressions)
            )
          };
        });
      });

      var stack = d3.layout.stack();

      stack(globalData);

      globalData = globalData.map(function(group) {
        return group.map(function(d) {
          // Invert the x and y values, and y0 becomes x0
          // noinspection JSSuspiciousNameCombination
          return {
            x: d[typeOfData],
            y: d.x,
            x0: d.y0,
            g: d.g,
            o_ua: d.o_ua,
            o_impressions: d.o_impressions,
            ua: d.ua,
            impressions: d.impressions,
            age: d.age
          };
        });
      });

      var svg = d3
        .select(InsightsAgeConfig.chartId)
        .append('svg')
        .attr(
          'width',
          InsightsAgeConfig.width() +
            InsightsAgeConfig.margins.left +
            InsightsAgeConfig.margins.right
        )
        .attr(
          'height',
          InsightsAgeConfig.height() +
            InsightsAgeConfig.margins.top +
            InsightsAgeConfig.margins.bottom +
            InsightsAgeConfig.legendPanel.height
        )
        .append('g')
        .attr(
          'transform',
          'translate(' +
            InsightsAgeConfig.margins.left +
            ',' +
            InsightsAgeConfig.margins.top +
            ')'
        );

      var xMax = d3.max(globalData, function(group) {
        return d3.max(group, function(d) {
          return d.x + d.x0;
        });
      });

      var xScale = d3.scale
        .linear()
        .domain([0, xMax])
        .range([0, InsightsAgeConfig.width()]);

      var ages = globalData[0].map(function(d) {
        return d.y;
      });

      var yScale = d3.scale
        .ordinal()
        .domain(ages)
        .rangeRoundBands([0, InsightsAgeConfig.height()], 0.4);

      var xAxis = d3.svg
        .axis()
        .ticks(5)
        .tickFormat(function(d) {
          var prefix = d3.formatPrefix(d);
          return prefix.scale(d) + prefix.symbol;
        })
        .scale(xScale)
        .orient('bottom');

      var yAxis = d3.svg
        .axis()
        .scale(yScale)
        .orient('left');

      var groups = svg
        .selectAll('g')
        .data(globalData)
        .enter()
        .append('g')
        .attr('class', function(d, i) {
          return d[0].g.toLowerCase() + ' groups';
        });

      // Bars
      groups
        .selectAll('rect')
        .data(function(d) {
          return d;
        })
        .enter()
        .append('rect')
        .attr('x', function() {
          return 0;
        })
        .attr('y', function(d) {
          return yScale(d.y);
        })
        .attr('height', function() {
          return yScale.rangeBand();
        })
        .attr('width', function() {
          return 0;
        })
        .on('mouseover', function(d) {
          var xPos =
            parseFloat(d3.select(this).attr('x')) / 2 +
            InsightsAgeConfig.width() / 2;
          var yPos =
            parseFloat(d3.select(this).attr('y')) +
            (yScale.rangeBand() + InsightsAgeConfig.margins.top) / 2;
          var selectedGender = d.g;
          var femaleValue;
          var maleValue;
          if (selectedGender === 'Female') {
            femaleValue = d[typeOfData];
            maleValue = d['o_' + typeOfData];
          } else if (selectedGender === 'Male') {
            maleValue = d[typeOfData];
            femaleValue = d['o_' + typeOfData];
          } else {
            femaleValue = 0;
            maleValue = 0;
          }

          d3.select('#age-tooltip')
            .style('left', xPos + 'px')
            .style('top', yPos + 'px');

          d3.select('#male-value').text(function() {
            return d3.format(',d')(maleValue);
          });

          d3.select('#female-value').text(function() {
            return d3.format(',d')(femaleValue);
          });

          d3.select('#total-value').text(function() {
            return d3.format(',d')(femaleValue + maleValue);
          });

          d3.select('#age-tooltip').classed('hidden', false);
        })
        .on('mouseout', function() {
          d3.select('#age-tooltip').classed('hidden', true);
        });

      groups
        .selectAll('rect')
        .transition()
        .attr('x', function(d) {
          return xScale(d.x0);
        })
        .attr('width', function(d) {
          return xScale(d.x);
        })
        .duration(500);

      svg
        .append('g')
        .attr('class', 'x axis bottom')
        .attr('stroke', '#000')
        .attr('fill', 'none')
        .attr('transform', 'translate(0,' + InsightsAgeConfig.height() + ')')
        .call(xAxis);

      svg
        .append('g')
        .attr('class', 'axis')
        .attr('stroke', '#000')
        .attr('fill', 'none')
        .call(yAxis);

      // Legend
      series.forEach(function(s, i) {
        svg
          .append('rect')
          .attr('class', s.toLowerCase() + ' legend box')
          .attr('width', 20)
          .attr('height', 20)
          .attr('x', InsightsAgeConfig.width() / 5 + 100 * i)
          .attr('y', InsightsAgeConfig.height() + 28);
        svg
          .append('text')
          .attr('fill', 'black')
          .attr('class', 'legend text')
          .attr('x', InsightsAgeConfig.width() / 5 + 30 + 100 * i)
          .attr('y', InsightsAgeConfig.height() + 44)
          .text(s);
      });

      function changeAge() {
        typeOfData = getTypeOfData();
        var overlay = document.querySelector(
          InsightsAgeConfig.chartId + ' .overlay'
        );
        var variable = document
          .querySelector('.variable-dropdown .ng-scope')
          .innerHTML.trim();

        // check if the variable is not state as if it is state we are showing the age chart but not for other categories
        if (variable.length > 0 && variable != 'STATE') {
          return;
        }

        // check if the type is clicks then return
        if (typeOfData === 'clicks') {
          angular.element(overlay).addClass('active');
          return true;
        }
        angular.element(overlay).removeClass('active');

        var parentIndex;

        globalData = globalData.map(function(d, index) {
          parentIndex = index;
          return d.map(function(o, i) {
            // Structure it so that your numeric
            // axis (the stacked amount) is y
            var oppositeIndex = parentIndex === 0 ? 1 : 0;
            return {
              y: parseInt(Number(o[typeOfData])),
              x: o.age,
              ua: parseInt(Number(o.ua)),
              impressions: parseInt(Number(o.impressions)),
              age: o.age,
              g: o.g,
              o_ua: parseInt(Number(globalData[oppositeIndex][i].ua)),
              o_impressions: parseInt(
                Number(globalData[oppositeIndex][i].impressions)
              )
            };
          });
        });

        var stack = d3.layout.stack();

        stack(globalData);

        globalData = globalData.map(function(group) {
          return group.map(function(d) {
            // Invert the x and y values, and y0 becomes x0
            return {
              x: d[typeOfData],
              y: d.x,
              x0: d.y0,
              g: d.g,
              ua: d.ua,
              impressions: d.impressions,
              age: d.age,
              o_ua: d.o_ua,
              o_impressions: d.o_impressions
            };
          });
        });

        var chart = d3.select(InsightsAgeConfig.chartId);

        // Get the max so our table doesn't go outside the bounds
        var xMax = d3.max(globalData, function(group) {
          return d3.max(group, function(d) {
            return d.x + d.x0;
          });
        });

        var xScale = d3.scale
          .linear()
          .domain([0, xMax])
          .range([0, InsightsAgeConfig.width()]);

        var yScale = d3.scale
          .ordinal()
          .domain(ages)
          .rangeRoundBands([0, InsightsAgeConfig.height()], 0.4);

        var xAxis = d3.svg
          .axis()
          .ticks(5)
          .tickFormat(function(d) {
            var prefix = d3.formatPrefix(d);
            return prefix.scale(d) + prefix.symbol;
          })
          .scale(xScale)
          .orient('bottom');

        var groups = chart.selectAll('.groups').data(globalData);

        groups.selectAll('rect').data(function(d) {
          return d;
        });

        if (xMax > 0) {
          groups
            .selectAll('rect')
            .transition()
            .duration(750)
            .attr('x', function(d) {
              return xScale(d.x0);
            })
            .attr('y', function(d) {
              return yScale(d.y);
            })
            .attr('height', function() {
              return yScale.rangeBand();
            })
            .attr('width', function(d) {
              return xScale(d.x);
            });
        } else {
          groups
            .selectAll('rect')
            .transition()
            .duration(750)
            .attr('x', '0')
            .attr('y', function(d) {
              return yScale(d.y);
            })
            .attr('height', function() {
              return yScale.rangeBand();
            })
            .attr('width', '0%');
        }

        var t0 = d3
          .select(InsightsAgeConfig.chartId + ' svg')
          .transition()
          .duration(750);

        t0.selectAll('.x.axis.bottom').call(xAxis);
      }

      commonWebsite.hideSpinner('age-chart');
      d3.selectAll('.switch').on('click.age', changeAge);
    }

    function resize() {
      var chartId = InsightsAgeConfig.chartId;
      var chart = d3.select(chartId);

      if (globalData.length === 0) {
        return;
      }

      // Get the max so our table doesn't go outside the bounds
      var xMax = d3.max(globalData, function(group) {
        return d3.max(group, function(d) {
          return d.x + d.x0;
        });
      });

      var xScale = d3.scale
        .linear()
        .domain([0, xMax])
        .range([0, InsightsAgeConfig.width()]);

      var ages = globalData[0].map(function(d) {
        return d.y;
      });

      // yScale
      d3.scale
        .ordinal()
        .domain(ages)
        .rangeRoundBands([0, InsightsAgeConfig.height()], 0.4);

      var xAxis = d3.svg
        .axis()
        .ticks(5)
        .tickFormat(function(d) {
          var prefix = d3.formatPrefix(d);
          return prefix.scale(d) + prefix.symbol;
        })
        .scale(xScale)
        .orient('bottom');

      chart
        .selectAll('svg')
        .attr(
          'width',
          InsightsAgeConfig.width() +
            InsightsAgeConfig.margins.left +
            InsightsAgeConfig.margins.right
        );

      chart
        .selectAll('.x.axis.bottom')
        .attr('width', InsightsAgeConfig.width())
        .attr('transform', 'translate(0,' + InsightsAgeConfig.height() + ')')
        .call(xAxis);

      chart.selectAll('.legend.text').attr('x', function(d, i) {
        return InsightsAgeConfig.width() / 5 + 30 + 100 * i;
      });

      chart.selectAll('.legend.box').attr('x', function(d, i) {
        return InsightsAgeConfig.width() / 5 + 100 * i;
      });

      chart
        .selectAll('.groups')
        .selectAll('rect')
        .attr('x', function(d) {
          return xScale(d.x0);
        })
        .attr('width', function(d) {
          return xScale(d.x);
        });
    }

    function roundFinalData(dataToRound) {
      var flattened = [].concat.apply([], dataToRound);

      // If data from today is included remove it.
      var currentIndex;
      for (var i = dataToRound.length - 1; i >= 0; i--) {
        if (new Date(dataToRound[i].Period_End) < new Date(todayDate())) {
          currentIndex = i;
          break;
        }
      }

      if (currentIndex) {
        if (currentIndex <= dataToRound.length) {
          dataToRound.splice(
            currentIndex + 1,
            dataToRound.length - currentIndex + 1
          );
        }
      }

      flattened = flattened.filter(function(d) {
        return !!('ua' in d && !isNaN(d.ua) && d.ua !== null);
      });

      flattened = flattened.map(function(d) {
        return {
          Period_Start: d.Period_Start,
          Period_End: d.Period_End,
          gender: d.gender,
          age: d.age,
          ua: Math.round(d.ua),
          impressions: Math.round(d.impressions)
        };
      });

      return flattened;
    }

    function resetGraph() {
      var chartId = InsightsAgeConfig.chartId;
      var chart = d3.select(chartId);
      var svg = chart.selectAll('svg');
      if (svg[0].length > 0) {
        svg.remove();
      }
    }

    /**
     * Check if Data is using Period_Start or Period_End
     * @param {Array} data
     * @returns {string}
     */
    function getDatePeriodType(data) {
      if (data[0].Period_Start) {
        return 'Period_Start';
      }
      return 'Period_End';
    }

    return {
      roundData: roundFinalData,
      checkService: function(jsonData) {
        jsonData = removeNullData(jsonData);
        completeData = angular.copy(jsonData);
        resetGraph();
        return readData(jsonData);
      },

      resize: function() {
        return resize();
      }
    };
  });
