(function() {
  'use strict';

  angular.module('insights').factory('convertToCSV', convertToCSV);

  function convertToCSV() {
    return {
      convert: convert
    };

    /**
     * Convert object to CSV
     * @param {string} objArray - JSON string of object or array
     * @returns {string}
     */
    function convert(objArray) {
      var array =
        typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
      var str = '';
      var line = '';
      if (array.length > 0) {
        line = '';
        for (var index in array[0]) {
          if (index !== 'v') {
            if (line !== '') line += ',';

            line += index;
          }
        }

        str += line + '\r\n';
      }

      for (var i = 0; i < array.length; i++) {
        line = '';
        for (var j in array[i]) {
          if (j !== 'v') {
            if (line !== '') line += ',';
            if (array[i].hasOwnProperty(j)) {
              if (array[i][j] === 0 || array[i][j]) {
                line += array[i][j].toString().replace(/,/gi, '');
              }
            }
          }
        }

        str += line + '\r\n';
      }

      return str;
    }
  }
})();
