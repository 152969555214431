(function() {
  'use strict';

  angular.module('insights').factory('getAusPopulation', getAusPopulation);

  getAusPopulation.$inject = ['$http', '$q', '$log'];

  function getAusPopulation($http, $q, $log) {
    return {
      get: get
    };

    /**
     * get the Australia population from BQ
     * @returns {Promise<object>}
     */
    function get() {
      return $q(function(resolve, reject) {
        $http
          .get('/api/insights/getAusPopulation')
          .then(function(result) {
            resolve(result.data);
          })
          .catch(function(reason) {
            $log.error('Error: ', reason);
            reject(reason);
          });
      });
    }
  }
})();
