(function() {
  'use strict';

  angular.module('websites').factory('ageChartWebsite', ageChartWebsite);

  ageChartWebsite.$inject = [
    '$http',
    'commonWebsite',
    'InsightsAge',
    '$q',
    '$log',
    'downloads'
  ];

  function ageChartWebsite(
    $http,
    commonWebsite,
    InsightsAge,
    $q,
    $log,
    downloads
  ) {
    return {
      chart: chart,
      resize: resize,
      changeDate: changeDate,
      getData: getData
    };

    /**
     * Age Chart based on date period
     * @param {string} period
     * @param {string} websiteId
     * @param {Object} startEnd - Insight start and end dates
     * @param {Object} variable
     */
    function chart(period, websiteId, startEnd, variable) {
      var companyUID = commonWebsite.getUID();
      if (period === 'month') {
        monthChart(websiteId, companyUID, startEnd.end, variable);
      } else if (period === 'week') {
        weekChart(websiteId, companyUID, startEnd.end, variable);
      } else if (period === 'day') {
        dayChart(websiteId, companyUID, startEnd.end, variable);
      }
    }

    /**
     * Month Age Chart
     * @param {string} websiteId
     * @param {?string} endDate - Campaign End Date YYYY-MM-DD
     * @param {Object} variable
     */
    function monthChart(websiteId, companyUID, endDate, variable) {
      var startOfMonth = moment(endDate || undefined)
        .startOf('month')
        .format('YYYY-MM-DD');

      $http
        .post('/api/insights/website', {
          campaign: websiteId,
          companyUID: companyUID,
          endDate: startOfMonth,
          chart: 'age',
          period: 'month',
          type: 'website',
          variable: variable
        })
        .then(
          function(result) {
            if (result.data.insights.length > 0) {
              var insights = result.data.insights;
              InsightsAge.checkService(insights);
            } else {
              commonWebsite.hideSpinner('age-chart', 'showNoDataMsg');
            }
          },
          function(reason) {
            commonWebsite.hideSpinner('age-chart', 'showNoDataMsg');
            $log.error('Error: website-age-month');
            $log.error(reason);
          }
        );
    }

    /**
     * Week Age Chart
     * @param {string} websiteId
     * @param {?string} endDate - Campaign End Date YYYY-MM-DD
     * @param {Object} variable
     */
    function weekChart(websiteId, companyUID, endDate, variable) {
      var startOfWeek = moment(endDate || undefined)
        .startOf('isoWeek')
        .format('YYYY-MM-DD');
      $http
        .post('/api/insights/website', {
          campaign: websiteId,
          companyUID: companyUID,
          endDate: startOfWeek,
          chart: 'age',
          period: 'week',
          type: 'website',
          variable: variable
        })
        .then(
          function(result) {
            if (result.data.insights.length > 0) {
              var insights = result.data.insights;
              InsightsAge.checkService(insights);
            } else {
              commonWebsite.hideSpinner('age-chart', 'showNoDataMsg');
            }
          },
          function(reason) {
            commonWebsite.hideSpinner('age-chart', 'showNoDataMsg');
            $log.error('Error: website-age-week');
            $log.error(reason);
          }
        );
    }

    /**
     * Day Age Chart
     * @param {string} websiteId
     * @param {?string} endDate - Campaign End Date YYYY-MM-DD
     * @param {Object} variable
     */
    function dayChart(websiteId, companyUID, endDate, variable) {
      var yesterday = commonWebsite.formatEndDate();
      $http
        .post('/api/insights/website', {
          campaign: websiteId,
          companyUID: companyUID,
          endDate: endDate || yesterday,
          chart: 'age',
          period: 'day',
          type: 'website',
          variable: variable
        })
        .then(
          function(result) {
            if (result.data.insights.length > 0) {
              var insights = result.data.insights;
              InsightsAge.checkService(insights);
            } else {
              commonWebsite.hideSpinner('age-chart', 'showNoDataMsg');
            }
          },
          function(reason) {
            commonWebsite.hideSpinner('age-chart', 'showNoDataMsg');
            $log.error('Error: website-age-day');
            $log.error(reason);
          }
        );
    }

    /**
     * Resize chart
     */
    function resize() {
      InsightsAge.resize();
    }

    /**
     * Change Date
     * @param {string} websiteId
     * @param {Object} startEnd - Insight start and end dates.
     * @param {string} period - Date period
     * @param {Object} variable
     */
    function changeDate(websiteId, startEnd, period, variable) {
      var companyUID = commonWebsite.getUID();
      if (period === 'month') {
        monthChart(websiteId, companyUID, startEnd.end, variable);
      } else if (period === 'week') {
        weekChart(websiteId, companyUID, startEnd.end, variable);
      } else if (period === 'day') {
        dayChart(websiteId, companyUID, startEnd.end, variable);
      }
    }

    /**
     * Which data to download
     * @param {string} websiteId
     * @param {string} type
     * @param {Object} startEnd - Insight start and end dates.
     * @param {Object} variable
     * @returns {Promise} Promise
     */
    function getData(websiteId, type, startEnd, variable) {
      var companyUID = commonWebsite.getUID();
      if (type === 'day') {
        return getDataDay(websiteId, companyUID, startEnd.end, variable);
      }
      if (type === 'week') {
        return getDataWeek(websiteId, companyUID, startEnd.end, variable);
      }
      return getDataMonth(websiteId, companyUID, startEnd.end, variable);
    }

    /**
     * Get Day Data for Download
     * @param {string} websiteId
     * @param {?string} endDate - Campaign End Date YYYY-MM-DD
     * @param {Object} variable
     * @returns {Promise<Array<Object>>}
     */
    function getDataDay(websiteId, companyUID, endDate, variable) {
      var yesterday = commonWebsite.formatEndDate();
      return $q(function(resolve, reject) {
        $http
          .post('/api/insights/website', {
            campaign: websiteId,
            companyUID: companyUID,
            endDate: endDate || yesterday,
            chart: 'age',
            period: 'day',
            type: 'website',
            variable: variable
          })
          .then(
            function(result) {
              var data = formatDownloads(
                result.data.insights,
                'Date',
                endDate || yesterday
              );

              resolve(data);
            },
            function(reason) {
              $log.error('Error: website-age-data-day');
              $log.error(reason);
              reject();
            }
          );
      });
    }

    /**
     * Get Week Data for Download
     * @param {string} websiteId
     * @param {?string} endDate - Campaign End Date YYYY-MM-DD
     * @param {Object} variable
     * @returns {Promise<Array<Object>>}
     */
    function getDataWeek(websiteId, companyUID, endDate, variable) {
      var startOfWeek = moment(endDate || undefined)
        .startOf('isoWeek')
        .format('YYYY-MM-DD');

      return $q(function(resolve, reject) {
        $http
          .post('/api/insights/website', {
            campaign: websiteId,
            companyUID: companyUID,
            endDate: startOfWeek,
            chart: 'age',
            period: 'week',
            type: 'website',
            variable: variable
          })
          .then(
            function(result) {
              var data = formatDownloads(
                result.data.insights,
                'Start_Date_of_Week',
                startOfWeek
              );

              resolve(data);
            },
            function(reason) {
              $log.error('Error: website-age-data-week');
              $log.error(reason);
              reject();
            }
          );
      });
    }

    /**
     * Get Month Data for Download
     * @param {string} websiteId
     * @param {?string} endDate - Campaign End Date YYYY-MM-DD
     * @param {Object} variable
     * @returns {Promise<Array<Object>>}
     */
    function getDataMonth(websiteId, companyUID, endDate, variable) {
      var startOfMonth = moment(endDate || undefined)
        .startOf('month')
        .format('YYYY-MM-DD');
      return $q(function(resolve, reject) {
        $http
          .post('/api/insights/website', {
            campaign: websiteId,
            companyUID: companyUID,
            endDate: startOfMonth,
            chart: 'age',
            period: 'month',
            type: 'website',
            variable: variable
          })
          .then(
            function(result) {
              var data = formatDownloads(
                result.data.insights,
                'Start_Date_of_Month',
                startOfMonth
              );

              resolve(data);
            },
            function(reason) {
              $log.error('Error: website-age-data-month');
              $log.error(reason);
              reject();
            }
          );
      });
    }

    function formatDownloads(rawData, dateHeading, date) {
      var data = InsightsAge.roundData(rawData);
      data = downloads.addHeading(data, dateHeading, date);
      data = downloads.removeHeading(data, 'Period_End');
      data = downloads.removeHeading(data, 'Period_Start');
      data = downloads.renameHeading(data, 'gender', 'Gender');
      data = downloads.renameHeading(data, 'age', 'Age');
      data = downloads.renameHeading(data, 'ua', 'UA');
      data = downloads.renameHeading(data, 'impressions', 'Impressions');

      return data;
    }
  }
})();
