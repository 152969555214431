(function() {
  'use strict';

  angular.module('insights').factory('Utility', Utility);

  Utility.$inject = ['$document'];

  function Utility($document) {
    return {
      getTypeOfData: getTypeOfData,
      getUID: getUID
    };

    function getUID() {
      var currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
      var companyUID = currentCompany.randomId;

      return companyUID;
    }

    /**
     * Get currently displayed data type
     *
     * @return {string} ua/impressions/clicks
     */
    function getTypeOfData() {
      var data = angular
        .element($document[0].querySelector('.switch.active'))
        .attr('value');
      if (data === 'UA') {
        data = 'ua';
      } else if (data === 'Impressions') {
        data = 'impressions';
      } else if (data === 'Clicks') {
        data = 'clicks';
      }

      return data;
    }
  }
})();
