'use strict';

// Setting up route
angular.module('upload').config([
  '$stateProvider',
  function($stateProvider) {
    // Upload state routing

    $stateProvider.state('upload', {
      title: 'Upload',
      url: '/upload',
      templateUrl: 'modules/upload/views/upload-documents.client.view.html'
    });
  }
]);
