(function() {
  'use strict';

  angular
    .module('websites')
    .controller('WebsitesInsightsController', WebsitesInsightsController);

  WebsitesInsightsController.$inject = [
    'Websites',
    '$stateParams',
    '$rootScope',
    'InsightsConfig',
    'Authorization',
    'commonWebsite',
    'uniqueChartWebsite',
    'deviceChartWebsite',
    'localVsInternationalChartWebsite',
    'browserChartWebsite',
    'helixChartsWebsite',
    'premiumChartsWebsite',
    'areaChartWebsite',
    'genderChartWebsite',
    'ageChartWebsite',
    'Settings',
    '$http',
    '$analytics',
    'Teams',
    '$window',
    '$log',
    '$document',
    'HelixPersonas',
    '$timeout',
    'insightExclusionIds',
    'getLastDate',
    'getAusPopulation',
    'getAusStatePopulation',
    'store',
    'downloads',
    'convertToCSV',
    '$q',
    'ageGenderDisclaimer'
  ];

  function WebsitesInsightsController(
    Websites,
    $stateParams,
    $rootScope,
    InsightsConfig,
    Authorization,
    commonWebsite,
    uniqueChartWebsite,
    deviceChartWebsite,
    localVsInternationalChartWebsite,
    browserChartWebsite,
    helixChartsWebsite,
    premiumChartsWebsite,
    areaChartWebsite,
    genderChartWebsite,
    ageChartWebsite,
    Settings,
    $http,
    $analytics,
    Teams,
    $window,
    $log,
    $document,
    HelixPersonas,
    $timeout,
    insightExclusionIds,
    getLastDate,
    getAusPopulation,
    getAusStatePopulation,
    store,
    downloads,
    convertToCSV,
    $q,
    ageGenderDisclaimer
  ) {
    /**
     * StartEnd type
     * @typedef {Object} StartEnd
     * @property {string} start Campaign start date
     * @property {?string} end Campaign end date or selected date.
     */

    /**
     * Variable type
     * @typedef {Object} Variable
     * @property {string} variable Variable name
     * @property {string} file Variable filename
     */

    var vm = this;
    vm.allowHelixChartSwitch = false; // ability to switch between Helix version has been removed (but keep code just in case) - https://roymorgan.atlassian.net/browse/RD-5439
    vm.currentDateToLog = null;
    vm.selectedVariable = null;
    vm.selectedWebsite = null;
    vm.activeVariable = null;
    vm.premiumAccess = Authorization.hasPermission(['Premium']);
    vm.changeVariable = changeVariable;
    vm.changeVariableCategory = changeVariableCategory;
    vm.categorySelected = false;
    vm.activeAreaChart = 'area'; // default area chart type
    vm.areaCharts = ['area', 'state', 'postcode'];
    vm.changeAreaChart = changeAreaChart;
    vm.changeFocus = changeFocus;
    vm.activePersonasChart = 'Personas'; // default helix chart type
    vm.personaCharts = ['Personas', 'Communities'];
    vm.changePersonasChart = changePersonasChart;
    vm.showAreaChart = null;
    var variableFiles;
    var selectedVariableFile;
    var filtersWithIdx;
    // var websiteSections;
    var exclusionCheck = false;
    vm.showAgeDisclaimer = false;

    // year selected
    if (store.get('helixVersion')) {
      vm.yearSelected = store.get('helixVersion');
    } else {
      store.set('helixVersion', 2016);
      vm.yearSelected = 2016;
    }

    vm.changeYear = changeYear;

    vm.spinnerDesign = {
      radius: 25,
      width: 2,
      length: 30,
      color: '#00aec7'
    };
    /**
     * Set UA button label
     * - UA for window width less then 1500px
     * - Unique Audience greater than 1500px
     */

    vm.uaLabel = $window.innerWidth >= 1024 ? 'Unique Audience' : 'UA';
    vm.downloadLabel = $window.innerWidth >= 1500 ? 'Download Data' : '';
    vm.downloadText = $window.innerWidth >= 1200 ? 'Download' : '';
    vm.impressionLabel = $window.innerWidth > 1024 ? 'Impressions' : 'Imp';
    Authorization.authorize().then(function(result) {
      vm.user = result;
    });

    vm.website = Websites.get(
      {
        id: $stateParams.websiteId
      },
      function() {
        /**
         * check if website id is allowed to show age/gender chart
         */
        var websiteId = $stateParams.websiteId;
        insightExclusionIds.hasId(websiteId).then(function(result) {
          exclusionCheck = result;
          initialCharts(vm.datePeriod, website);
        });
      }
    );

    var collections = Authorization.getCollections('Websites');

    // Get Variable Categories
    $http
      .post('/api/insights/variable-categories', {
        allowedVariables: collections
      })
      .then(
        function(result) {
          variableFiles = result.data;
          // add state filter
          variableFiles.push({
            name: 'STATE'
          });
          vm.variableCategories = variableFiles.map(function(file) {
            return file.name;
          });
        },
        function(reason) {
          $log.error('Error: ', reason.data);
        }
      );

    Settings.checkNotification();

    vm.radioModel = 'UA';
    vm.toggleWebsiteCaret = toggleWebsiteCaret;

    /**
     * Only return active websites for search box
     */
    Websites.query({}, function(result) {
      vm.websites = result.filter(function(r) {
        if (r.status === 'Active') {
          return r;
        }
        return undefined;
      });
    });

    $rootScope.title = 'Website Evaluation';
    vm.dateRangeClick = dateRangeClick;
    var website = $stateParams.websiteId;
    clearTotals();
    vm.downloadAsCSV = downloadAsCSV;

    // Default selected period
    vm.datePeriod = 'day';
    vm.changePeriod = changePeriod;
    datePicker(vm.datePeriod);
    vm.disableButtons = true;

    /**
     * Display Initial Charts
     * @param {string} datePeriod - Currently selected date period e.g. week, day, month.
     * @param {string} websiteId
     */
    function initialCharts(datePeriod, websiteId) {
      // Display Unique Chart Heading
      uniqueChartHeadings(datePeriod);

      // Calculate Totals
      parseTotals(datePeriod, websiteId);

      // Display Charts
      var dateRange = getActiveDateRange();
      var startDate = moment(vm.website.dateCreated).format('YYYY-MM-DD');
      var endDate = moment().format('YYYY-MM-DD');

      var startEnd = {
        start: startDate,
        end: null
      };

      getLastDate
        .get(websiteId, datePeriod, startDate, endDate, 'website')
        .then(function(lastDateObj) {
          startEnd.end = lastDateObj.Period_End
            ? lastDateObj.Period_End
            : lastDateObj.Period_Start;

          vm.currentDateToLog = startEnd.end;

          uniqueChartWebsite.chart(datePeriod, websiteId, dateRange, startEnd);
          helixChartsWebsite.chart(
            datePeriod,
            websiteId,
            startEnd,
            null,
            vm.activePersonasChart,
            vm.yearSelected
          );
          premiumChartsWebsite.chart(datePeriod, websiteId, startEnd, null);
          // check if the id is in array then dont show gender/age chart
          if (exclusionCheck) {
            commonWebsite.hideSpinner('gender-chart', 'showNoDataMsg');
            commonWebsite.hideSpinner('age-chart', 'showNoDataMsg');
          } else {
            genderChartWebsite.chart(datePeriod, websiteId, startEnd);
            ageChartWebsite.chart(datePeriod, websiteId, startEnd);
            ageGenderDisclaimer.check(websiteId).then(function(result) {
              vm.showAgeDisclaimer = result;
            });
          }
          areaChartWebsite.chart(
            datePeriod,
            websiteId,
            startEnd,
            vm.activeAreaChart,
            vm.radioModel.toLowerCase()
          );
          // check if data exists or not, then call dependent charts as they are
          // returning null when not checking data and hence making chart to broke
          uniqueChartWebsite
            .checkIfDataExists(websiteId, datePeriod, startEnd)
            .then(function(result) {
              if (result) {
                localVsInternationalChartWebsite.chart(datePeriod, websiteId, {
                  start: startEnd.end,
                  end: startEnd.end
                });
                deviceChartWebsite.chart(datePeriod, websiteId, startEnd);
                browserChartWebsite.chart(datePeriod, websiteId, {
                  start: startEnd.end,
                  end: startEnd.end
                });
              } else {
                commonWebsite.hideSpinner(
                  'local-vs-international-chart',
                  'showNoDataMsg'
                );
                commonWebsite.hideSpinner('device-chart', 'showNoDataMsg');
                commonWebsite.hideSpinner('browser-chart', 'showNoDataMsg');
              }
              vm.disableButtons = false;
            });
        });
    }

    /**
     * Change date using date picker
     * @param {string} datePeriod - day, week, month or all
     */
    function datePicker(datePeriod) {
      vm.dateOpen = false;
      var formats = ['dd MMM yyyy', 'MMMM yyyy'];
      var dateRanges = InsightsConfig.dateRanges;

      /**
       * Open date picker
       */
      vm.openDate = function() {
        vm.dateOpen = true;
      };

      vm.dateOptions = {
        startingDay: 1
      };

      /**
       * Highlight current week
       * @param {Date} date
       * @param {string} selectedDatePeriod
       * @returns {string}
       */
      vm.highlightWeek = function(date, selectedDatePeriod) {
        if (selectedDatePeriod === 'week') {
          var endOfWeek = moment(vm.datePicker).endOf('isoWeek');
          if (
            moment(date).isSame(moment(vm.datePicker), 'day') ||
            moment(date).isBetween(moment(vm.datePicker), endOfWeek, 'day') ||
            moment(date).isSame(endOfWeek, 'day')
          ) {
            return 'selected';
          }
        }
        return '';
      };

      // Remove date picker if all is selected
      vm.datePickerActive = datePeriod !== 'all';
      vm.datePickerWeekActive = datePeriod === 'week';
      vm.dateRangeVisible = datePeriod !== 'all';

      if (datePeriod === 'week') {
        vm.dateRanges = dateRanges.week;
        vm.activeDateRange = vm.dateRanges[1];
        vm.dateMode = 'day';

        // Disable all but Mondays
        vm.dateDisabled = function(date, mode) {
          return mode === 'day' && date.getDay() !== 1;
        };

        vm.format = formats[0];
        vm.minMode = 'day';
      } else if (datePeriod === 'month') {
        vm.dateRanges = dateRanges.month;
        vm.activeDateRange = vm.dateRanges[1];
        vm.dateDisabled = function() {
          return false;
        };

        vm.dateMode = 'month';
        vm.format = formats[1];
        vm.minMode = 'month';
      } else {
        if (datePeriod === 'all') {
          vm.dateRanges = dateRanges.all;
          vm.activeDateRange = vm.dateRanges[0];
        } else {
          vm.dateRanges = dateRanges.day;
          vm.activeDateRange = vm.dateRanges[1];
        }

        vm.dateDisabled = function() {
          return false;
        };

        vm.dateMode = 'day';
        vm.format = formats[0];
        vm.minMode = 'day';
      }

      /**
       * Change charts based on selected date
       * @param {Date} date
       */
      vm.changeDatePicker = function(date) {
        // show spinners
        commonWebsite.showSpinner('unique-area-chart', 'hideChart');
        commonWebsite.showSpinner('helix-people-chart', 'hideChart');
        commonWebsite.showSpinner('helix-index-chart', 'hideChart');
        commonWebsite.hideNoData('premium-charts');
        commonWebsite.showSpinner('premium-people-chart', 'hideChart');
        commonWebsite.showSpinner('premium-index-chart', 'hideChart');
        commonWebsite.showSpinner('gender-chart', 'hideChart');
        commonWebsite.showSpinner('age-chart', 'hideChart');
        commonWebsite.showSpinner('area-chart', 'hideChart');
        commonWebsite.showSpinner('local-vs-international-chart', 'hideChart');
        commonWebsite.showSpinner('device-chart', 'hideChart');
        commonWebsite.showSpinner('browser-chart', 'hideChart');

        vm.disableButtons = true;
        var dateRange = getDateRange();
        var variable = {
          variable: vm.activeVariable,
          file: selectedVariableFile,
          category: vm.selectedCategory
        };
        var startEnd = {
          start: moment(vm.website.dateCreated).format('YYYY-MM-DD'),
          end: moment(date).format('YYYY-MM-DD')
        };

        parseTotals(datePeriod, website, date);
        weekDatePicker(date, datePeriod);

        // get the current date to event log
        vm.currentDateToLog = date;

        //  logging dashboard data
        logEvents('Audiences dashboard render');

        uniqueChartWebsite.changeDate(dateRange, datePeriod, date);
        helixChartsWebsite.changeDate(
          website,
          datePeriod,
          startEnd,
          variable,
          vm.activePersonasChart,
          vm.yearSelected
        );

        premiumChartsWebsite.changeDate(
          website,
          datePeriod,
          startEnd,
          variable
        );

        // update the charts if the category is ONLY selected to be as STATE
        // and if no variables are there
        if (!variable.variable || vm.selectedCategory === 'STATE') {
          // check if the id is in array then dont show gender/age chart
          if (exclusionCheck) {
            commonWebsite.hideSpinner('gender-chart', 'showNoDataMsg');
            commonWebsite.hideSpinner('age-chart', 'showNoDataMsg');
          } else {
            genderChartWebsite.changeDate(
              website,
              startEnd,
              datePeriod,
              variable
            );
            ageChartWebsite.changeDate(website, startEnd, datePeriod, variable);
          }

          var localOverlay = $document[0].querySelector(
            '#local-vs-international-chart .overlay'
          );
          var browserOverlay = $document[0].querySelector(
            '#browser-chart .overlay'
          );

          // Show the local & browser chart
          if (vm.radioModel === 'Impressions') {
            angular.element(localOverlay).removeClass('active');
            angular.element(browserOverlay).removeClass('active');
          } else {
            angular.element(localOverlay).addClass('active');
            angular.element(browserOverlay).addClass('active');
          }

          // update the dates based on date picker
          startEnd = getStartEndDateOfSelectedPeriod(datePeriod);

          deviceChartWebsite.chart(datePeriod, website, startEnd, variable);
          localVsInternationalChartWebsite.chart(
            datePeriod,
            website,
            startEnd,
            variable
          );
          browserChartWebsite.chart(datePeriod, website, startEnd, variable);
        }

        areaChartWebsite.changeDate(
          website,
          datePeriod,
          startEnd,
          vm.activeAreaChart,
          vm.radioModel.toLowerCase(),
          variable
        );

        vm.disableButtons = false;
      };
    }

    /**
     * Change displayed date range
     * @param {Event} event
     */
    function dateRangeClick(event) {
      // @ts-ignore
      vm.activeDateRange = event.target.text;
      var date = vm.datePicker;
      if (vm.datePeriod === 'month') {
        date = moment(date).toDate();
      } else if (vm.datePeriod === 'day') {
        date = moment(date).toDate();
      } else if (vm.datePeriod === 'week') {
        date = moment(date).toDate();
      }

      // @ts-ignore
      uniqueChartWebsite.changeDate(event.target.text, vm.datePeriod, date);
    }

    /**
     * Get the active date range if set.
     * @returns {string|boolean}
     */
    function getActiveDateRange() {
      if (vm.activeDateRange) {
        return vm.activeDateRange;
      }
      return false;
    }

    /**
     * Window resize
     */
    d3.select(window).on('resize', function() {
      vm.uaLabel = $window.innerWidth >= 1024 ? 'Unique Audience' : 'UA';
      vm.impressionLabel = $window.innerWidth >= 1024 ? 'Impressions' : 'Imp';
      vm.downloadLabel = $window.innerWidth >= 1500 ? 'Download Data' : '';
      vm.downloadText = $window.innerWidth >= 1200 ? 'Download' : '';

      var navBar = angular.element(
        $document[0].querySelector('.navbar-collapse')
      );
      var sliding = navBar.hasClass('collapsing');

      if (sliding) {
        $timeout(resizeGraphs, 500);
      } else {
        resizeGraphs();
      }
    });

    /**
     * Resize graphs
     */
    function resizeGraphs() {
      var selectedRange = getDateRange();

      // check if the chart is loaded then resize
      if (
        checkIfChartIsloaded('helix-people-chart') &&
        checkIfChartIsloaded('helix-index-chart')
      ) {
        helixChartsWebsite.resize();
      }
      if (checkIfChartIsloaded('unique-area-chart')) {
        uniqueChartWebsite.resize(selectedRange, vm.datePeriod, vm.datePicker);
      }
      if (checkIfChartIsloaded('area-chart')) {
        areaChartWebsite.resize();
      }
      if (checkIfChartIsloaded('browser-chart')) {
        browserChartWebsite.resize();
      }
      if (checkIfChartIsloaded('device-chart')) {
        deviceChartWebsite.resize();
      }
      if (checkIfChartIsloaded('local-vs-international-chart')) {
        localVsInternationalChartWebsite.resize();
      }
      if (
        checkIfChartIsloaded('premium-people-chart') &&
        checkIfChartIsloaded('premium-index-chart')
      ) {
        premiumChartsWebsite.resize();
      }

      // check if the id is in array then dont show gender/age chart
      if (exclusionCheck) {
        commonWebsite.hideSpinner('gender-chart', 'showNoDataMsg');
        commonWebsite.hideSpinner('age-chart', 'showNoDataMsg');
      } else {
        if (checkIfChartIsloaded('gender-chart')) {
          genderChartWebsite.resize();
        }
        if (checkIfChartIsloaded('age-chart')) {
          ageChartWebsite.resize();
        }
      }
    }

    /**
     * Get currently selected date range
     * @returns {?string}
     */
    function getDateRange() {
      var activeDateRange = $document[0]
        .getElementById('date-range-picker')
        .getElementsByClassName('selected');
      if (activeDateRange) {
        return activeDateRange[0].innerHTML;
      }
      return null;
    }

    /**
     * Download all data as zipped CSV's
     */
    function downloadAsCSV() {
      if (vm.downloading) {
        return;
      }

      $analytics.eventTrack('Click', {
        category: 'Websites',
        label: 'Download'
      });

      var variable = {
        variable: vm.activeVariable,
        file: selectedVariableFile,
        category: vm.selectedCategory
      };

      //  logging download data
      logEvents('Audiences dashboard download');

      if (vm.datePeriod === 'month') {
        downloadAsCSVMonth(variable);
      } else if (vm.datePeriod === 'week') {
        downloadAsCSVWeek(variable);
      } else if (vm.datePeriod === 'day') {
        downloadAsCSVDay(variable);
      }
    }

    /**
     * Convert object to CSV
     * @param {object} variable - JSON object
     * @return {void}
     */
    function downloadAsCSVMonth(variable) {
      var spinner = $document[0].getElementById('download-icon');
      spinner.classList.add('fa-spinner');
      spinner.classList.add('fa-spin');
      vm.downloading = true;

      // @ts-ignore
      var zip = new JSZip();
      var uniqueDataMonth;
      var helixDataMonthPersonas;
      var helixDataMonthCommunities;
      var helixDataMonthPersonas2014;
      var helixDataMonthCommunities2014;
      var ageDataMonth;
      var deviceDataMonth;
      var areaDataMonth;
      var stateDataMonth;
      var postcodeDataMonthUA;
      var postcodeDataMonthImp;
      var browserData;
      var statePopMonth;
      var postcodePopMonth;
      var premiumData;

      // find the first date and the last date of month
      var startEnd = {
        start: moment(vm.datePicker)
          .startOf('month')
          .format('YYYY-MM-DD'),
        end: vm.datePicker
          ? moment(vm.datePicker)
              .endOf('month')
              .format('YYYY-MM-DD')
          : null
      };

      $q.all([
        uniqueChartWebsite.getData(
          website,
          'month',
          {
            start: downloads.downloadsStartDate(
              startEnd.end,
              vm.activeDateRange,
              moment(vm.website.dateCreated).format('YYYY-MM-DD')
            ),
            end: startEnd.end
          },
          variable
        ),
        !vm.premiumAccess
          ? helixChartsWebsite.getData(
              website,
              'month',
              startEnd,
              variable,
              'Personas',
              '2014'
            )
          : null,
        !vm.premiumAccess
          ? helixChartsWebsite.getData(
              website,
              'month',
              startEnd,
              variable,
              'Personas',
              '2016'
            )
          : null,

        (!variable.variable || variable.file === 'STATE') && !exclusionCheck
          ? ageChartWebsite.getData(website, 'month', startEnd, variable)
          : null,
        areaChartWebsite.getData(website, 'month', startEnd, 'area', variable),
        areaChartWebsite.getData(website, 'month', startEnd, 'state', variable),
        !variable.variable || variable.file === 'STATE'
          ? deviceChartWebsite.getData(website, 'month', startEnd, variable)
          : null,
        !variable.variable || variable.file === 'STATE'
          ? browserChartWebsite.getData(website, startEnd, variable, 'month')
          : null,
        !vm.premiumAccess
          ? helixChartsWebsite.getData(
              website,
              'month',
              startEnd,
              variable,
              'Communities',
              '2014'
            )
          : null,
        !vm.premiumAccess
          ? helixChartsWebsite.getData(
              website,
              'month',
              startEnd,
              variable,
              'Communities',
              '2016'
            )
          : null,
        areaChartWebsite.getData(
          website,
          'month',
          startEnd,
          'postcode',
          variable,
          'ua'
        ),
        areaChartWebsite.getData(
          website,
          'month',
          startEnd,
          'postcode',
          variable,
          'impressions'
        ),
        areaChartWebsite.getData(
          website,
          'month',
          startEnd,
          'state',
          variable,
          'population'
        ),
        areaChartWebsite.getData(
          website,
          'month',
          startEnd,
          'postcode',
          variable,
          'population'
        ),
        vm.premiumAccess && (!variable.variable || variable.file === 'STATE')
          ? premiumChartsWebsite.getData(website, 'month', startEnd, variable)
          : null
      ]).then(function(result) {
        uniqueDataMonth = result[0];
        helixDataMonthPersonas2014 = result[1];
        helixDataMonthPersonas = result[2];
        ageDataMonth = result[3];
        areaDataMonth = result[4];
        stateDataMonth = result[5];
        deviceDataMonth = result[6];
        browserData = result[7];
        helixDataMonthCommunities2014 = result[8];
        helixDataMonthCommunities = result[9];
        postcodeDataMonthUA = result[10];
        postcodeDataMonthImp = result[11];
        statePopMonth = result[12];
        postcodePopMonth = result[13];
        premiumData = result[14];

        if (uniqueDataMonth && uniqueDataMonth.length) {
          zip.file(
            'Audience_Monthly.csv',
            convertToCSV.convert(angular.toJson(uniqueDataMonth))
          );
        }
        if (helixDataMonthPersonas2014 && helixDataMonthPersonas2014.length) {
          zip.file(
            'HelixPersonas2014_Monthly.csv',
            convertToCSV.convert(angular.toJson(helixDataMonthPersonas2014))
          );
        }
        if (helixDataMonthPersonas && helixDataMonthPersonas.length) {
          zip.file(
            'HelixPersonas_Monthly.csv',
            convertToCSV.convert(angular.toJson(helixDataMonthPersonas))
          );
        }
        if (ageDataMonth && ageDataMonth.length && !exclusionCheck) {
          zip.file(
            'AgeGender_Monthly.csv',
            convertToCSV.convert(angular.toJson(ageDataMonth))
          );
        }
        if (areaDataMonth && areaDataMonth.length) {
          zip.file(
            'Area_Monthly.csv',
            convertToCSV.convert(angular.toJson(areaDataMonth))
          );
        }
        if (stateDataMonth && stateDataMonth.length) {
          zip.file(
            'State_Monthly.csv',
            convertToCSV.convert(angular.toJson(stateDataMonth))
          );
        }
        if (deviceDataMonth && deviceDataMonth.length) {
          zip.file(
            'Device_Audience_Monthly.csv',
            convertToCSV.convert(angular.toJson(deviceDataMonth))
          );
        }
        if (browserData && browserData.length) {
          zip.file(
            'Browser_Monthly.csv',
            convertToCSV.convert(angular.toJson(browserData))
          );
        }
        if (
          helixDataMonthCommunities2014 &&
          helixDataMonthCommunities2014.length
        ) {
          zip.file(
            'HelixCommunity2014_Monthly.csv',
            convertToCSV.convert(angular.toJson(helixDataMonthCommunities2014))
          );
        }
        if (helixDataMonthCommunities && helixDataMonthCommunities.length) {
          zip.file(
            'HelixCommunity_Monthly.csv',
            convertToCSV.convert(angular.toJson(helixDataMonthCommunities))
          );
        }
        if (postcodeDataMonthUA && postcodeDataMonthUA.length) {
          zip.file(
            'Postcode_By_UA_Monthly.csv',
            convertToCSV.convert(angular.toJson(postcodeDataMonthUA))
          );
        }
        if (postcodeDataMonthImp && postcodeDataMonthImp.length) {
          zip.file(
            'Postcode_By_Impressions_Monthly.csv',
            convertToCSV.convert(angular.toJson(postcodeDataMonthImp))
          );
        }
        if (statePopMonth && statePopMonth.length) {
          zip.file(
            'State_By_Population_Monthly.csv',
            convertToCSV.convert(angular.toJson(statePopMonth))
          );
        }
        if (postcodePopMonth && postcodePopMonth.length) {
          zip.file(
            'Postcode_by_Population_Share_Monthly.csv',
            convertToCSV.convert(angular.toJson(postcodePopMonth))
          );
        }
        if (premiumData && premiumData.length) {
          zip.file(
            'Premium_Audiences_Monthly.csv',
            convertToCSV.convert(angular.toJson(premiumData))
          );
        }

        var content = zip.generate({
          type: 'blob'
        });
        // @ts-ignore
        saveAs(
          content,
          downloads.filename(
            vm.website.name,
            variable ? variable.variable : null,
            vm.activeDateRange,
            startEnd.end ||
              moment()
                .subtract(1, 'day')
                .format('YYYY-MM-DD')
          )
        );
        spinner.classList.remove('fa-spinner');
        spinner.classList.remove('fa-spin');
        vm.downloading = false;
      });
    }

    /**
     * Convert object to CSV
     * @param {object} variable - JSON object
     * @return {void}
     */
    function downloadAsCSVWeek(variable) {
      var spinner = $document[0].getElementById('download-icon');
      spinner.classList.add('fa-spinner');
      spinner.classList.add('fa-spin');
      vm.downloading = true;

      // @ts-ignore
      var zip = new JSZip();
      var uniqueDataWeek;
      var helixDataWeekPersonas;
      var helixDataWeekCommunities;
      var helixDataWeekPersonas2014;
      var helixDataWeekCommunities2014;
      var ageDataWeek;
      var deviceDataWeek;
      var areaDataWeek;
      var stateDataWeek;
      var postcodeDataWeekUA;
      var postcodeDataWeekImp;
      var browserData;
      var statePopWeek;
      var postcodePopWeek;
      var premiumData;

      // find the first date and the last date of week
      var startEnd = {
        start: moment(vm.datePicker)
          .startOf('isoWeek')
          .format('YYYY-MM-DD'),
        end: vm.datePicker
          ? moment(vm.datePicker)
              .endOf('isoWeek')
              .format('YYYY-MM-DD')
          : null
      };

      $q.all([
        uniqueChartWebsite.getData(
          website,
          'week',
          {
            start: downloads.downloadsStartDate(
              startEnd.end,
              vm.activeDateRange,
              moment(vm.website.dateCreated).format('YYYY-MM-DD')
            ),
            end: startEnd.end
          },
          variable
        ),
        !vm.premiumAccess
          ? helixChartsWebsite.getData(
              website,
              'week',
              startEnd,
              variable,
              'Personas',
              '2014'
            )
          : null,
        !vm.premiumAccess
          ? helixChartsWebsite.getData(
              website,
              'week',
              startEnd,
              variable,
              'Personas',
              '2016'
            )
          : null,
        (!variable.variable || variable.file === 'STATE') && !exclusionCheck
          ? ageChartWebsite.getData(website, 'week', startEnd, variable)
          : null,
        areaChartWebsite.getData(website, 'week', startEnd, 'area', variable),
        areaChartWebsite.getData(website, 'week', startEnd, 'state', variable),
        !variable.variable || variable.file === 'STATE'
          ? deviceChartWebsite.getData(website, 'week', startEnd, variable)
          : null,
        !variable.variable || variable.file === 'STATE'
          ? browserChartWebsite.getData(website, startEnd, variable, 'week')
          : null,
        !vm.premiumAccess
          ? helixChartsWebsite.getData(
              website,
              'week',
              startEnd,
              variable,
              'Communities',
              '2014'
            )
          : null,
        !vm.premiumAccess
          ? helixChartsWebsite.getData(
              website,
              'week',
              startEnd,
              variable,
              'Communities',
              '2016'
            )
          : null,
        areaChartWebsite.getData(
          website,
          'week',
          startEnd,
          'postcode',
          variable,
          'ua'
        ),
        areaChartWebsite.getData(
          website,
          'week',
          startEnd,
          'postcode',
          variable,
          'impressions'
        ),
        areaChartWebsite.getData(
          website,
          'week',
          startEnd,
          'state',
          variable,
          'population'
        ),
        areaChartWebsite.getData(
          website,
          'week',
          startEnd,
          'postcode',
          variable,
          'population'
        ),
        vm.premiumAccess && (!variable.variable || variable.file === 'STATE')
          ? premiumChartsWebsite.getData(website, 'week', startEnd, variable)
          : null
      ]).then(function(result) {
        uniqueDataWeek = result[0];
        helixDataWeekPersonas2014 = result[1];
        helixDataWeekPersonas = result[2];
        ageDataWeek = result[3];
        areaDataWeek = result[4];
        stateDataWeek = result[5];
        deviceDataWeek = result[6];
        browserData = result[7];
        helixDataWeekCommunities2014 = result[8];
        helixDataWeekCommunities = result[9];
        postcodeDataWeekUA = result[10];
        postcodeDataWeekImp = result[11];
        statePopWeek = result[12];
        postcodePopWeek = result[13];
        premiumData = result[14];

        if (uniqueDataWeek && uniqueDataWeek.length) {
          zip.file(
            'Audience_Weekly.csv',
            convertToCSV.convert(angular.toJson(uniqueDataWeek))
          );
        }
        if (helixDataWeekPersonas2014 && helixDataWeekPersonas2014.length) {
          zip.file(
            'HelixPersonas2014_Weekly.csv',
            convertToCSV.convert(angular.toJson(helixDataWeekPersonas2014))
          );
        }
        if (helixDataWeekPersonas && helixDataWeekPersonas.length) {
          zip.file(
            'HelixPersonas_Weekly.csv',
            convertToCSV.convert(angular.toJson(helixDataWeekPersonas))
          );
        }
        if (ageDataWeek && ageDataWeek.length && !exclusionCheck) {
          zip.file(
            'AgeGender_Weekly.csv',
            convertToCSV.convert(angular.toJson(ageDataWeek))
          );
        }
        if (areaDataWeek && areaDataWeek.length) {
          zip.file(
            'Area_Weekly.csv',
            convertToCSV.convert(angular.toJson(areaDataWeek))
          );
        }
        if (stateDataWeek && stateDataWeek.length) {
          zip.file(
            'State_Weekly.csv',
            convertToCSV.convert(angular.toJson(stateDataWeek))
          );
        }
        if (deviceDataWeek && deviceDataWeek.length) {
          zip.file(
            'Device_Audience_Weekly.csv',
            convertToCSV.convert(angular.toJson(deviceDataWeek))
          );
        }
        if (browserData && browserData.length) {
          zip.file(
            'Browser_Weekly.csv',
            convertToCSV.convert(angular.toJson(browserData))
          );
        }
        if (
          helixDataWeekCommunities2014 &&
          helixDataWeekCommunities2014.length
        ) {
          zip.file(
            'HelixCommunity2014_Weekly.csv',
            convertToCSV.convert(angular.toJson(helixDataWeekCommunities2014))
          );
        }
        if (helixDataWeekCommunities && helixDataWeekCommunities.length) {
          zip.file(
            'HelixCommunity_Weekly.csv',
            convertToCSV.convert(angular.toJson(helixDataWeekCommunities))
          );
        }
        if (postcodeDataWeekUA && postcodeDataWeekUA.length) {
          zip.file(
            'Postcode_By_UA_Weekly.csv',
            convertToCSV.convert(angular.toJson(postcodeDataWeekUA))
          );
        }
        if (postcodeDataWeekImp && postcodeDataWeekImp.length) {
          zip.file(
            'Postcode_By_Impressions_Weekly.csv',
            convertToCSV.convert(angular.toJson(postcodeDataWeekImp))
          );
        }
        if (statePopWeek && statePopWeek.length) {
          zip.file(
            'State_By_Population_Weekly.csv',
            convertToCSV.convert(angular.toJson(statePopWeek))
          );
        }
        if (postcodePopWeek && postcodePopWeek.length) {
          zip.file(
            'Postcode_by_Population_Share_Weekly.csv',
            convertToCSV.convert(angular.toJson(postcodePopWeek))
          );
        }
        if (premiumData && premiumData.length) {
          zip.file(
            'Premium_Audiences_Weekly.csv',
            convertToCSV.convert(angular.toJson(premiumData))
          );
        }

        var content = zip.generate({
          type: 'blob'
        });
        // @ts-ignore
        saveAs(
          content,
          downloads.filename(
            vm.website.name,
            variable ? variable.variable : null,
            vm.activeDateRange,
            startEnd.end ||
              moment()
                .subtract(1, 'day')
                .format('YYYY-MM-DD')
          )
        );
        spinner.classList.remove('fa-spinner');
        spinner.classList.remove('fa-spin');
        vm.downloading = false;
      });
    }

    /**
     * Convert object to CSV
     * @param {object} variable - JSON object
     * @return {void}
     */
    function downloadAsCSVDay(variable) {
      var spinner = $document[0].getElementById('download-icon');
      spinner.classList.add('fa-spinner');
      spinner.classList.add('fa-spin');
      vm.downloading = true;

      // @ts-ignore
      var zip = new JSZip();
      var uniqueDataDay;
      var helixDataDayPersonas;
      var helixDataDayCommunities;
      var helixDataDayPersonas2014;
      var helixDataDayCommunities2014;
      var ageDataDay;
      var deviceDataDay;
      var areaDataDay;
      var stateDataDay;
      var postcodeDataDayUA;
      var postcodeDataDayImp;
      var browserData;
      var localIntData;
      var statePopDay;
      var postcodePopDay;
      var premiumData;

      // find the first date and the last date
      var startEnd = {
        start: moment(vm.datePicker).format('YYYY-MM-DD'),
        end: vm.datePicker ? moment(vm.datePicker).format('YYYY-MM-DD') : null
      };

      $q.all([
        uniqueChartWebsite.getData(
          website,
          'day',
          {
            start: downloads.downloadsStartDate(
              startEnd.end,
              vm.activeDateRange,
              moment(vm.website.dateCreated).format('YYYY-MM-DD')
            ),
            end: startEnd.end
          },
          variable
        ),
        !vm.premiumAccess
          ? helixChartsWebsite.getData(
              website,
              'day',
              startEnd,
              variable,
              'Personas',
              '2014'
            )
          : null,
        !vm.premiumAccess
          ? helixChartsWebsite.getData(
              website,
              'day',
              startEnd,
              variable,
              'Personas',
              '2016'
            )
          : null,
        (!variable.variable || variable.file === 'STATE') && !exclusionCheck
          ? ageChartWebsite.getData(website, 'day', startEnd, variable)
          : null,
        areaChartWebsite.getData(website, 'day', startEnd, 'area', variable),
        areaChartWebsite.getData(website, 'day', startEnd, 'state', variable),
        !variable.variable || variable.file === 'STATE'
          ? deviceChartWebsite.getData(website, 'day', startEnd, variable)
          : null,
        !variable.variable || variable.file === 'STATE'
          ? browserChartWebsite.getData(website, startEnd, variable, 'day')
          : null,
        localVsInternationalChartWebsite.getData(website, startEnd, variable),
        !vm.premiumAccess
          ? helixChartsWebsite.getData(
              website,
              'day',
              startEnd,
              variable,
              'Communities',
              '2014'
            )
          : null,
        !vm.premiumAccess
          ? helixChartsWebsite.getData(
              website,
              'day',
              startEnd,
              variable,
              'Communities',
              '2016'
            )
          : null,
        areaChartWebsite.getData(
          website,
          'day',
          startEnd,
          'postcode',
          variable,
          'ua'
        ),
        areaChartWebsite.getData(
          website,
          'day',
          startEnd,
          'postcode',
          variable,
          'impressions'
        ),
        areaChartWebsite.getData(
          website,
          'day',
          startEnd,
          'state',
          variable,
          'population'
        ),
        areaChartWebsite.getData(
          website,
          'day',
          startEnd,
          'postcode',
          variable,
          'population'
        ),
        vm.premiumAccess && (!variable.variable || variable.file === 'STATE')
          ? premiumChartsWebsite.getData(website, 'day', startEnd, variable)
          : null
      ]).then(function(result) {
        uniqueDataDay = result[0];
        helixDataDayPersonas2014 = result[1];
        helixDataDayPersonas = result[2];
        ageDataDay = result[3];
        areaDataDay = result[4];
        stateDataDay = result[5];
        deviceDataDay = result[6];
        browserData = result[7];
        localIntData = result[8];
        helixDataDayCommunities2014 = result[9];
        helixDataDayCommunities = result[10];
        postcodeDataDayUA = result[11];
        postcodeDataDayImp = result[12];
        statePopDay = result[13];
        postcodePopDay = result[14];
        premiumData = result[15];

        if (uniqueDataDay && uniqueDataDay.length > 0) {
          zip.file(
            'Audience_Daily.csv',
            convertToCSV.convert(angular.toJson(uniqueDataDay))
          );
        }
        if (helixDataDayPersonas2014 && helixDataDayPersonas2014.length > 0) {
          zip.file(
            'HelixPersonas2014_Daily.csv',
            convertToCSV.convert(angular.toJson(helixDataDayPersonas2014))
          );
        }
        if (helixDataDayPersonas && helixDataDayPersonas.length > 0) {
          zip.file(
            'HelixPersonas_Daily.csv',
            convertToCSV.convert(angular.toJson(helixDataDayPersonas))
          );
        }
        if (ageDataDay && ageDataDay.length > 0 && !exclusionCheck) {
          zip.file(
            'AgeGender_Daily.csv',
            convertToCSV.convert(angular.toJson(ageDataDay))
          );
        }
        if (areaDataDay && areaDataDay.length > 0) {
          zip.file(
            'Area_Daily.csv',
            convertToCSV.convert(angular.toJson(areaDataDay))
          );
        }
        if (stateDataDay && stateDataDay.length > 0) {
          zip.file(
            'State_Daily.csv',
            convertToCSV.convert(angular.toJson(stateDataDay))
          );
        }
        if (deviceDataDay && deviceDataDay.length > 0) {
          // when a variable is set these will be null
          zip.file(
            'Device_Audience_Daily.csv',
            convertToCSV.convert(angular.toJson(deviceDataDay))
          );
        }
        // when a variable is set these will be null
        if (browserData && browserData.length > 0) {
          zip.file(
            'Browser_Daily.csv',
            convertToCSV.convert(angular.toJson(browserData))
          );
        }
        if (localIntData && localIntData.length > 0) {
          zip.file(
            'International_Traffic_Daily.csv',
            convertToCSV.convert(angular.toJson(localIntData))
          );
        }
        if (
          helixDataDayCommunities2014 &&
          helixDataDayCommunities2014.length > 0
        ) {
          zip.file(
            'HelixCommunity2014_Daily.csv',
            convertToCSV.convert(angular.toJson(helixDataDayCommunities2014))
          );
        }
        if (helixDataDayCommunities && helixDataDayCommunities.length > 0) {
          zip.file(
            'HelixCommunity_Daily.csv',
            convertToCSV.convert(angular.toJson(helixDataDayCommunities))
          );
        }
        if (postcodeDataDayUA && postcodeDataDayUA.length > 0) {
          zip.file(
            'Postcode_By_UA_Daily.csv',
            convertToCSV.convert(angular.toJson(postcodeDataDayUA))
          );
        }
        if (postcodeDataDayImp && postcodeDataDayImp.length > 0) {
          zip.file(
            'Postcode_By_Impressions_Daily.csv',
            convertToCSV.convert(angular.toJson(postcodeDataDayImp))
          );
        }
        if (statePopDay && statePopDay.length > 0) {
          zip.file(
            'State_By_Population_Daily.csv',
            convertToCSV.convert(angular.toJson(statePopDay))
          );
        }
        if (postcodePopDay && postcodePopDay.length > 0) {
          zip.file(
            'Postcode_by_Population_Share_Daily.csv',
            convertToCSV.convert(angular.toJson(postcodePopDay))
          );
        }
        if (premiumData && premiumData.length) {
          zip.file(
            'Premium_Audiences_Daily.csv',
            convertToCSV.convert(angular.toJson(premiumData))
          );
        }

        var content = zip.generate({
          type: 'blob'
        });
        // @ts-ignore
        saveAs(
          content,
          downloads.filename(
            vm.website.name,
            variable ? variable.variable : null,
            vm.activeDateRange,
            startEnd.end ||
              moment()
                .subtract(1, 'day')
                .format('YYYY-MM-DD')
          )
        );
        spinner.classList.remove('fa-spinner');
        spinner.classList.remove('fa-spin');
        vm.downloading = false;
      });
    }

    /**
     * Toggle search box caret
     */
    function toggleWebsiteCaret() {
      var myEl = angular.element($document[0].querySelector('#website-caret'));
      if (myEl.hasClass('fa-caret-down')) {
        myEl.removeClass('fa-caret-down');
        myEl.addClass('fa-caret-up');
      } else {
        myEl.addClass('fa-caret-down');
        myEl.removeClass('fa-caret-up');
      }
    }

    /**
     * Split string off numbers into 111,000 from 111000
     * @param {number} x - Number to split.
     * @param {string} [sep] - Separator.
     * @param {number} [grp] - Number to group by, must be an integer.
     * @return {string}
     */
    function localeString(x, sep, grp) {
      var sx = ('' + x).split('.');
      var s = '';
      var i;
      var j;

      sep = sep || (sep = ','); // default separator
      // @ts-ignore
      grp = grp || grp === 0 || (grp = 3); // default grouping
      i = sx[0].length;
      while (i > grp) {
        j = i - grp;
        s = sep + sx[0].slice(j, i) + s;
        i = j;
      }

      s = sx[0].slice(0, i) + s;
      sx[0] = s;
      return sx.join('.');
    }

    /**
     * Change Date Period
     * @param {string} period
     */
    function changePeriod(period) {
      vm.disableButtons = true;
      $analytics.eventTrack('Websites', {
        category: 'Reporting Timeframe Click',
        label: period
      });
      // show spinners
      commonWebsite.showSpinner('unique-area-chart', 'hideChart');
      commonWebsite.showSpinner('helix-people-chart', 'hideChart');
      commonWebsite.showSpinner('helix-index-chart', 'hideChart');
      commonWebsite.hideNoData('premium-charts');
      commonWebsite.showSpinner('premium-people-chart', 'hideChart');
      commonWebsite.showSpinner('premium-index-chart', 'hideChart');
      commonWebsite.showSpinner('gender-chart', 'hideChart');
      commonWebsite.showSpinner('age-chart', 'hideChart');
      commonWebsite.showSpinner('area-chart', 'hideChart');
      commonWebsite.showSpinner('local-vs-international-chart', 'hideChart');
      commonWebsite.showSpinner('device-chart', 'hideChart');
      commonWebsite.showSpinner('browser-chart', 'hideChart');

      var variable = {
        variable: vm.activeVariable,
        file: selectedVariableFile,
        category: vm.selectedCategory
      };
      var startEnd = {
        start: moment(vm.website.dateCreated).format('YYYY-MM-DD'),
        end: null
      };
      if (period === 'month') {
        startEnd.start = moment(startEnd.start)
          .startOf('month')
          .format('YYYY-MM-DD');
      } else if (period === 'week') {
        startEnd.start = moment(startEnd.start)
          .startOf('isoWeek')
          .format('YYYY-MM-DD');
      }
      uniqueChartHeadings(period);
      datePicker(period);
      parseTotals(period, website);
      getLastDate
        .get(
          website,
          period,
          startEnd.start,
          startEnd.end ? startEnd.end : moment().format('YYYY-MM-DD'),
          'website'
        )
        .then(function(lastDateObj) {
          var lastDate;
          if (lastDateObj) {
            lastDate = lastDateObj.Period_End
              ? lastDateObj.Period_End
              : lastDateObj.Period_Start;
          } else {
            lastDate = startEnd.end;
          }
          startEnd.end = lastDate;

          vm.currentDateToLog = startEnd.end;

          uniqueChartWebsite.chart(
            period,
            website,
            vm.activeDateRange,
            startEnd,
            variable
          );
          //  logging dashboard data
          logEvents('Audiences dashboard render');
          helixChartsWebsite.chart(
            period,
            website,
            startEnd,
            variable,
            vm.activePersonasChart,
            vm.yearSelected
          );
          premiumChartsWebsite.chart(period, website, startEnd, variable);
          // update the charts if the category is ONLY selected to be as STATE and if no variables are there
          if (!variable.variable || vm.selectedCategory === 'STATE') {
            // check if the id is in array then dont show gender/age chart
            if (exclusionCheck) {
              commonWebsite.hideSpinner('gender-chart', 'showNoDataMsg');
              commonWebsite.hideSpinner('age-chart', 'showNoDataMsg');
            } else {
              genderChartWebsite.chart(period, website, startEnd, variable);
              ageChartWebsite.chart(period, website, startEnd, variable);
            }
          }
          areaChartWebsite.chart(
            period,
            website,
            startEnd,
            vm.activeAreaChart,
            vm.radioModel.toLowerCase(),
            variable
          );

          uniqueChartWebsite
            .checkIfDataExists(website, period, startEnd)
            .then(function(result) {
              if (result) {
                if (!variable.variable || vm.selectedCategory === 'STATE') {
                  startEnd = getStartEndDateOfSelectedPeriod(period);
                  localVsInternationalChartWebsite.chart(
                    period,
                    website,
                    startEnd,
                    variable
                  );
                  deviceChartWebsite.chart(period, website, startEnd, variable);
                  browserChartWebsite.chart(
                    period,
                    website,
                    startEnd,
                    variable
                  );
                }

                vm.disableDropdowns = false;
              } else {
                commonWebsite.hideSpinner(
                  'local-vs-international-chart',
                  'showNoDataMsg'
                );
                commonWebsite.hideSpinner('device-chart', 'showNoDataMsg');
                commonWebsite.hideSpinner('browser-chart', 'showNoDataMsg');
                vm.disableDropdowns = true;
              }

              vm.disableButtons = false;
            });
        });
    }

    /**
     * Calculate Totals for Information Panel
     * @param {string} period - The date period
     * @param {string} websiteId
     * @param {Date} [date] - Optional Date
     */
    function parseTotals(period, websiteId, date) {
      clearTotals();
      switch (period) {
        case 'month':
          monthlyTotals(websiteId, date);
          break;
        case 'week':
          weeklyTotals(websiteId, date);
          break;
        case 'day':
          dailyTotals(websiteId, date);
          break;
        default:
          $log.error('Website parseTotals default case');
      }
    }

    /**
     * Change unique chart headings based on selected period.
     * @param {string} period - The date period
     */
    function uniqueChartHeadings(period) {
      switch (period) {
        case 'month':
          vm.uniqueChartPeriodHeading = 'Monthly Count';
          break;
        case 'week':
          vm.uniqueChartPeriodHeading = 'Weekly Count';
          break;
        case 'day':
          vm.uniqueChartPeriodHeading = 'Daily Count';
          break;
        default:
          $log.error('Website uniqueChartHeadings default case');
      }
    }

    /**
     * Clear Information Panel Totals
     */
    function clearTotals() {
      var clearString = '-------------';
      vm.ua = clearString;
      vm.impressions = clearString;
      vm.reach = clearString;
      vm.frequency = clearString;
      vm.uaTotalPercent = '';
    }

    /**
     * Calculate Totals for Information Panel
     * @param {Array} totals
     */
    function calculateTotals(totals) {
      var ua = Math.floor(Number(totals[totals.length - 1].UA));
      if (isNaN(ua)) {
        ua = 0;
      }
      var variable = {
        variable: vm.activeVariable,
        file: selectedVariableFile,
        category: vm.selectedCategory
      };
      vm.ua = localeString(ua);
      var impressions = Math.floor(
        Number(totals[totals.length - 1].impressions)
      );
      if (isNaN(impressions)) {
        impressions = 0;
      }
      vm.impressions = localeString(impressions);
      if (variable.file == 'STATE') {
        /**
         * get australia's state population from BQ
         */
        getAusStatePopulation.get(variable.variable).then(function(result) {
          var ausPopulation = result.ABS_Pop_0;
          var reach = ua / ausPopulation;
          vm.reach = String((reach * 100).toFixed(2)) + '%';
        });
      } else {
        /**
         * get australia's population from BQ
         */
        getAusPopulation.get().then(function(result) {
          var ausPopulation = result.ABS_Pop_0;
          var reach = ua / ausPopulation;
          vm.reach = String((reach * 100).toFixed(2)) + '%';
        });
      }

      var frequency = impressions / ua;
      if (isNaN(frequency)) {
        frequency = 0;
      }
      vm.frequency = String(frequency.toFixed(2));
    }

    /**
     * Calculate percentage for filtered results.
     * - calculateTotals must have been run on filtered totals first.
     * @param {Array} totals
     * @param {Object} variable
     */
    function calculateFilteredTotals(totals, variable) {
      var idxObjects = filtersWithIdx.filter(function(f) {
        if (f.name === variable.variable) {
          return f.idx;
        }
        return undefined;
      });
      var idxValue = idxObjects[0].idx;
      var ua = Math.floor(Number(totals[totals.length - 1].UA));

      if (ua > 0) {
        vm.uaTotalPercent = ' (';
        var rawUAPcnt = Number(vm.ua.split(',').join('')) / ua;
        rawUAPcnt *= 100;
        vm.uaTotalPercent += Math.floor(rawUAPcnt).toString() + '%, ';
        var ix = rawUAPcnt / idxValue;
        vm.uaTotalPercent += Math.floor(ix * 100).toString();
        vm.uaTotalPercent += 'ix)';
      }
    }

    /**
     * Website Daily Totals
     * @param {string} websiteId
     * @param {Date} [date] - Optional Date
     */
    function dailyTotals(websiteId, date) {
      var variable = {
        variable: vm.activeVariable,
        file: selectedVariableFile,
        category: vm.selectedCategory
      };
      var startDate = moment(vm.website.dateCreated).format('YYYY-MM-DD');
      var endDate = moment(date || undefined).format('YYYY-MM-DD');

      uniqueChartWebsite
        .dailyTotals(websiteId, startDate, endDate, variable)
        .then(
          function(result) {
            // enable buttons after getting data
            vm.disableButtons = false;

            if (result.length > 0) {
              // Calculate Max and Min if not changing date
              if (!date) {
                calculateMaxMinDate(result);
              }
              calculateTotals(result);
              if (variable.variable && variable.file !== 'STATE') {
                uniqueChartWebsite
                  .dailyTotals(websiteId, startDate, endDate)
                  .then(function(res) {
                    calculateFilteredTotals(res, variable);
                  });
              }
            }
          },
          function(reason) {
            $log.error(reason);
          }
        );
    }

    /**
     * Website Weekly Totals
     * @param {string} websiteId
     * @param {Date} [date] - Optional Date
     */
    function weeklyTotals(websiteId, date) {
      var variable = {
        variable: vm.activeVariable,
        file: selectedVariableFile,
        category: vm.selectedCategory
      };
      var startDate = moment(vm.website.dateCreated).format('YYYY-MM-DD');
      var endDate = moment(date || undefined).format('YYYY-MM-DD');
      uniqueChartWebsite
        .weeklyTotals(websiteId, startDate, endDate, variable)
        .then(
          function(result) {
            if (result.length > 0) {
              // Calculate Max and Min if not changing date
              if (!date) {
                calculateMaxMinDate(result);
              }
              calculateTotals(result);
              if (variable.variable && variable.file !== 'STATE') {
                uniqueChartWebsite
                  .weeklyTotals(websiteId, startDate, endDate, variable)
                  .then(function(res) {
                    calculateFilteredTotals(res, variable);
                  });
              }
            }
          },
          function(reason) {
            $log.error(reason);
          }
        );
    }

    /**
     * Website Monthly Totals
     * @param {string} websiteId
     * @param {Date} [date] - Optional Date
     */
    function monthlyTotals(websiteId, date) {
      var variable = {
        variable: vm.activeVariable,
        file: selectedVariableFile,
        category: vm.selectedCategory
      };
      var startDate = moment(vm.website.dateCreated).format('YYYY-MM-DD');
      var endDate = moment(date || undefined).format('YYYY-MM-DD');

      uniqueChartWebsite
        .monthlyTotals(websiteId, startDate, endDate, variable)
        .then(
          function(result) {
            // enable buttons after getting data
            vm.disableButtons = false;
            if (result.length > 0) {
              // Calculate Max and Min if not changing date
              if (!date) {
                calculateMaxMinDate(result);
              }
              calculateTotals(result);
              if (variable.variable && variable.file !== 'STATE') {
                uniqueChartWebsite
                  .monthlyTotals(websiteId, startDate, endDate, variable)
                  .then(function(res) {
                    calculateFilteredTotals(res, variable);
                  });
              }
            }
          },
          function(reason) {
            vm.disableButtons = true;
            $log.error(reason);
          }
        );
    }

    /**
     * Calculate Max and Min Date for Date Picker
     * @param {Object[]} data
     * @param {string} [data[].Period_End]
     * @param {string} [data[].Period_Start]
     */
    function calculateMaxMinDate(data) {
      if (data[0].Period_End) {
        vm.maxDate = data[data.length - 1].Period_End;
        vm.minDate = data[0].Period_End;
      } else {
        vm.maxDate = data[data.length - 1].Period_Start;
        vm.minDate = data[0].Period_Start;
      }

      vm.datePicker = vm.maxDate;
      weekDatePicker(vm.maxDate, vm.datePeriod);
    }

    /**
     * Calculate value for week date picker
     * @param {string|Date} value - Current date
     * @param {string} datePeriod
     */
    function weekDatePicker(value, datePeriod) {
      if (datePeriod === 'week') {
        vm.datePickerWeek =
          moment(value)
            .startOf('isoWeek')
            .format('DD MMM YYYY') +
          ' - ' +
          moment(value)
            .endOf('isoWeek')
            .format('DD MMM YYYY');
      }
    }

    /**
     * Change selected variable.
     * @param {string} variable - Selected variable
     */
    function changeVariable(variable) {
      // show spinners
      commonWebsite.showSpinner('unique-area-chart', 'hideChart');
      commonWebsite.showSpinner('helix-people-chart', 'hideChart');
      commonWebsite.showSpinner('helix-index-chart', 'hideChart');
      commonWebsite.hideNoData('premium-charts');
      commonWebsite.showSpinner('premium-people-chart', 'hideChart');
      commonWebsite.showSpinner('premium-index-chart', 'hideChart');
      commonWebsite.showSpinner('gender-chart', 'hideChart');
      commonWebsite.showSpinner('age-chart', 'hideChart');
      commonWebsite.showSpinner('area-chart', 'hideChart');
      commonWebsite.showSpinner('local-vs-international-chart', 'hideChart');
      commonWebsite.showSpinner('device-chart', 'hideChart');
      commonWebsite.showSpinner('browser-chart', 'hideChart');

      vm.disableButtons = true;
      $analytics.eventTrack('Audience Filter Subcategory', {
        category: 'Websites',
        label: variable
      });
      vm.processing = true;
      vm.selectedVariable = variable;

      var variableObj = {
        variable: variable,
        file: selectedVariableFile,
        category: vm.selectedCategory
      };

      var dateRange = getActiveDateRange();
      var subCatWrapper = angular.element(
        $document[0].querySelector('.sub-cat-wrapper .btn')
      );

      if (!subCatWrapper.hasClass('active')) {
        subCatWrapper.toggleClass('active');
      }
      var endDate = vm.datePicker;

      var startEnd = {
        start: moment(vm.website.dateCreated).format('YYYY-MM-DD'),
        end: endDate ? moment(endDate).format('YYYY-MM-DD') : null
      };

      vm.activeVariable = variable;
      parseTotals(vm.datePeriod, website, vm.datePicker);

      //  logging dashboard data
      logEvents('Audiences dashboard render');

      uniqueChartWebsite.chart(
        vm.datePeriod,
        website,
        dateRange,
        startEnd,
        variableObj
      );
      helixChartsWebsite.chart(
        vm.datePeriod,
        website,
        startEnd,
        variableObj,
        vm.activePersonasChart,
        vm.yearSelected
      );
      areaChartWebsite.chart(
        vm.datePeriod,
        website,
        startEnd,
        vm.activeAreaChart,
        vm.radioModel.toLowerCase(),
        variableObj
      );
      premiumChartsWebsite.chart(vm.datePeriod, website, startEnd, variableObj);

      // Show charts for state category and hide from others
      if (vm.selectedCategory === 'STATE') {
        // check if the id is in array then dont show gender/age chart
        if (exclusionCheck) {
          commonWebsite.hideSpinner('gender-chart', 'showNoDataMsg');
          commonWebsite.hideSpinner('age-chart', 'showNoDataMsg');
        } else {
          genderChartWebsite.chart(
            vm.datePeriod,
            website,
            startEnd,
            variableObj
          );
          ageChartWebsite.chart(vm.datePeriod, website, startEnd, variableObj);
        }
        var localOverlay = $document[0].querySelector(
          '#local-vs-international-chart .overlay'
        );
        var browserOverlay = $document[0].querySelector(
          '#browser-chart .overlay'
        );
        // Show the local and browser chart
        if (vm.radioModel === 'Impressions') {
          angular.element(localOverlay).removeClass('active');
          angular.element(browserOverlay).removeClass('active');
        } else {
          angular.element(localOverlay).addClass('active');
          angular.element(browserOverlay).addClass('active');
        }

        localVsInternationalChartWebsite.chart(
          vm.datePeriod,
          website,
          startEnd,
          variableObj
        );
        deviceChartWebsite.chart(vm.datePeriod, website, startEnd, variableObj);
        browserChartWebsite.chart(
          vm.datePeriod,
          website,
          startEnd,
          variableObj
        );
      } else {
        commonWebsite.hideSpinner('gender-chart', 'showNoDataMsg');
        commonWebsite.hideSpinner('age-chart', 'showNoDataMsg');
        commonWebsite.hideSpinner(
          'local-vs-international-chart',
          'showNoDataMsg'
        );
        commonWebsite.hideSpinner('device-chart', 'showNoDataMsg');
        commonWebsite.hideSpinner('browser-chart', 'showNoDataMsg');
      }

      $timeout(clearSpinner, 2500);
      vm.disableButtons = false;
    }

    function clearSpinner() {
      vm.processing = false;
    }

    /**
     * Change Variable Category
     * @param {string} category - Selected category
     */
    function changeVariableCategory(category) {
      //  check if the selected category is state then disable state chart
      if (vm.selectedCategory === 'STATE') {
        vm.showAreaChart = ['area', 'postcode'];
        // if in area chart already state chart is displayed then show the area chart
        if (vm.activeAreaChart === 'state') {
          changeAreaChart('area');
        }
      } else {
        vm.showAreaChart = null;
      }

      vm.disableButtons = true;
      $analytics.eventTrack('Audience Filter', {
        category: 'Websites',
        label: category
      });

      vm.selectedVariable = null;

      var catWrapper = angular.element(
        $document[0].querySelector('.cat-wrapper .btn')
      );
      var subCatWrapper = angular.element(
        $document[0].querySelector('.sub-cat-wrapper .btn')
      );

      if (vm.variableCategories[0] !== 'Total Audience') {
        vm.variableCategories.unshift('Total Audience');
      }

      if (category === 'Total Audience') {
        // show spinners
        commonWebsite.showSpinner('unique-area-chart', 'hideChart');
        commonWebsite.showSpinner('helix-people-chart', 'hideChart');
        commonWebsite.showSpinner('helix-index-chart', 'hideChart');
        commonWebsite.hideNoData('premium-charts');
        commonWebsite.showSpinner('premium-people-chart', 'hideChart');
        commonWebsite.showSpinner('premium-index-chart', 'hideChart');
        commonWebsite.showSpinner('gender-chart', 'hideChart');
        commonWebsite.showSpinner('age-chart', 'hideChart');
        commonWebsite.showSpinner('area-chart', 'hideChart');
        commonWebsite.showSpinner('local-vs-international-chart', 'hideChart');
        commonWebsite.showSpinner('device-chart', 'hideChart');
        commonWebsite.showSpinner('browser-chart', 'hideChart');

        category = '';
        selectedVariableFile = null;
        vm.variableCategories.shift();
        vm.categorySelected = false;
        vm.selectedCategory = null;
        vm.variables = null;
        vm.activeVariable = null;
        var dateRange = getActiveDateRange();

        if (catWrapper.hasClass('active')) {
          catWrapper.toggleClass('active');
        }

        if (subCatWrapper.hasClass('active')) {
          subCatWrapper.toggleClass('active');
        }

        parseTotals(vm.datePeriod, website, vm.datePicker);
        var endDate = vm.datePicker;

        var startEnd = {
          start: moment(vm.website.dateCreated).format('YYYY-MM-DD'),
          end: endDate ? moment(endDate).format('YYYY-MM-DD') : null
        };
        uniqueChartWebsite.chart(
          vm.datePeriod,
          website,
          dateRange,
          startEnd,
          vm.activeVariable
        );
        helixChartsWebsite.chart(
          vm.datePeriod,
          website,
          startEnd,
          vm.activeVariable,
          vm.activePersonasChart,
          vm.yearSelected
        );
        premiumChartsWebsite.chart(
          vm.datePeriod,
          website,
          startEnd,
          vm.activeVariable
        );
        // check if the id is in array then dont show gender/age chart
        if (exclusionCheck) {
          commonWebsite.hideSpinner('gender-chart', 'showNoDataMsg');
          commonWebsite.hideSpinner('age-chart', 'showNoDataMsg');
        } else {
          genderChartWebsite.chart(vm.datePeriod, website, startEnd);
          ageChartWebsite.chart(vm.datePeriod, website, startEnd);
        }

        areaChartWebsite.chart(
          vm.datePeriod,
          website,
          startEnd,
          vm.activeAreaChart,
          vm.radioModel.toLowerCase(),
          vm.activeVariable
        );

        var localOverlay = $document[0].querySelector(
          '#local-vs-international-chart .overlay'
        );
        var browserOverlay = $document[0].querySelector(
          '#browser-chart .overlay'
        );

        // Show the local & browser chart
        if (vm.radioModel === 'Impressions') {
          angular.element(localOverlay).removeClass('active');
          angular.element(browserOverlay).removeClass('active');
        } else {
          angular.element(localOverlay).addClass('active');
          angular.element(browserOverlay).addClass('active');
        }

        localVsInternationalChartWebsite.chart(
          vm.datePeriod,
          website,
          startEnd
        );
        deviceChartWebsite.chart(vm.datePeriod, website, startEnd);
        browserChartWebsite.chart(vm.datePeriod, website, startEnd);

        vm.disableButtons = false;
      } else if (category === 'STATE') {
        vm.selectedCategory = category;
        // get states as categories
        vm.variables = ['VIC', 'NSW', 'QLD', 'WA', 'SA', 'TAS', 'NT', 'ACT'];

        selectedVariableFile = 'STATE';

        if (!catWrapper.hasClass('active')) {
          catWrapper.toggleClass('active');
        }

        vm.categorySelected = true;
        vm.disableButtons = false;
      } else {
        if (!catWrapper.hasClass('active')) {
          catWrapper.toggleClass('active');
        }

        variableFiles.forEach(function(file) {
          if (file.name === category) {
            selectedVariableFile = file.refName;
          }
        });

        vm.selectedCategory = category;
        $http
          .post('/api/insights/variables', {
            category: category,
            fileName: selectedVariableFile
          })
          .then(
            function(result) {
              filtersWithIdx = result.data;
              vm.variables = result.data.map(function(v) {
                return v.name;
              });
              vm.categorySelected = true;
              vm.disableButtons = false;
            },
            function(reason) {
              $log.error(reason.data);
            }
          );
      }
    }

    /**
     * Toggle between area charts.
     * @param {string} chart
     */
    function changeAreaChart(chart) {
      // show spinner on switching to different mode i.e area/state/postcode
      commonWebsite.showSpinner('area-chart', 'hideChart');

      vm.activeAreaChart = chart.toLowerCase();

      var startEnd = {
        start: moment(vm.website.dateCreated).format('YYYY-MM-DD'),
        end: vm.datePicker ? vm.datePicker : null
      };

      var variable = {
        variable: vm.activeVariable,
        file: selectedVariableFile
      };
      if (vm.selectedWebsite === null) {
        areaChartWebsite.chart(
          vm.datePeriod,
          vm.website.id.toString(),
          startEnd,
          vm.activeAreaChart,
          vm.radioModel.toLowerCase(),
          variable
        );
      } else {
        areaChartWebsite.chart(
          vm.datePeriod,
          vm.website.id.toString(),
          startEnd,
          vm.activeAreaChart,
          vm.radioModel.toLowerCase(),
          variable,
          vm.selectedWebsite
        );
      }
    }

    function changeFocus() {
      //  logging dashboard data
      logEvents('Audiences dashboard render');

      if (vm.activeAreaChart === 'postcode') {
        changeAreaChart(vm.activeAreaChart);
      }
    }

    /**
     * Toggle between personas and communities on the Helix Personas chart.
     * @param {string} chart
     */
    function changePersonasChart(chart) {
      // showing spinner on getting data from server
      commonWebsite.showSpinner('helix-people-chart', 'hideChart');
      commonWebsite.showSpinner('helix-index-chart', 'hideChart');

      vm.activePersonasChart = chart;

      var startEnd = {
        start: moment(vm.website.dateCreated).format('YYYY-MM-DD'),
        end: vm.datePicker ? vm.datePicker : null
      };

      var variable = {
        variable: vm.activeVariable,
        file: selectedVariableFile,
        category: vm.selectedCategory
      };

      if (vm.selectedWebsite === null) {
        helixChartsWebsite.chart(
          vm.datePeriod,
          vm.website.id.toString(),
          startEnd,
          variable,
          chart,
          vm.yearSelected
        );
      } else {
        helixChartsWebsite.chart(
          vm.datePeriod,
          vm.website.id.toString(),
          startEnd,
          variable,
          chart,
          vm.selectedWebsite,
          vm.yearSelected
        );
      }

      premiumChartsWebsite.chart(
        vm.datePeriod,
        vm.website.id.toString(),
        startEnd,
        variable
      );
    }

    /**
     * Search website URL and Name for search text.
     * @param {Object} website
     * @return {boolean}
     */
    function searchWebsites(website) {
      return commonWebsite.searchWebsites(website, vm.searchText);
    }

    vm.searchWebsites = searchWebsites;

    /**
     * check if the chart is present or not
     * @param {string} wrapperName - chart Id
     */
    function checkIfChartIsloaded(wrapperName) {
      var wrapper = document.getElementById(wrapperName);
      return wrapper.getElementsByTagName('svg').length >= 1;
    }

    /**
     * get the dates for tables by period name
     * @param {string} period - month/week/day etc
     */
    function getStartEndDateOfSelectedPeriod(period) {
      var startEnd;
      // find the first date and the last date of month
      if (period === 'month') {
        startEnd = {
          start: moment(vm.datePicker)
            .startOf('month')
            .format('YYYY-MM-DD'),
          end: vm.datePicker
            ? moment(vm.datePicker)
                .endOf('month')
                .format('YYYY-MM-DD')
            : null
        };
      } else if (period === 'week') {
        startEnd = {
          start: moment(vm.datePicker)
            .startOf('isoWeek')
            .format('YYYY-MM-DD'),
          end: vm.datePicker
            ? moment(vm.datePicker)
                .endOf('isoWeek')
                .format('YYYY-MM-DD')
            : null
        };
      } else if (period === 'day') {
        startEnd = {
          start: moment(vm.datePicker).format('YYYY-MM-DD'),
          end: vm.datePicker ? moment(vm.datePicker).format('YYYY-MM-DD') : null
        };
      }

      return startEnd;
    }

    /**
     * Change Year to see data
     * - 2014 -> 2016
     * @param {string} year
     */
    function changeYear(year) {
      store.set('helixVersion', year);
      vm.yearSelected = year;

      // show spinners
      commonWebsite.showSpinner('helix-people-chart', 'hideChart');
      commonWebsite.showSpinner('helix-index-chart', 'hideChart');

      $analytics.eventTrack('Campaigns', {
        category: 'Year changed',
        label: year
      });
      var variableObj = {
        variable: vm.activeVariable,
        file: selectedVariableFile,
        category: vm.selectedCategory
      };

      var endDate = vm.datePicker;
      var startEnd = {
        start: moment(vm.website.dateCreated).format('YYYY-MM-DD'),
        end: endDate ? moment(endDate).format('YYYY-MM-DD') : null
      };

      parseTotals(vm.datePeriod, website, vm.datePicker);

      helixChartsWebsite.chart(
        vm.datePeriod,
        vm.website.id.toString(),
        startEnd,
        variableObj,
        vm.activePersonasChart,
        vm.yearSelected
      );

      //  logging dashboard data
      logEvents('Audiences dashboard render');
    }

    /**
     * Log action and events
     * @PARAM {string} action
     */
    function logEvents(action) {
      //  logging  data
      var timePeriod =
        vm.datePeriod === 'all' ? 'total' : vm.datePeriod.toLowerCase();
      var currentDate = null;
      if (timePeriod === 'month') {
        currentDate = moment(vm.currentDateToLog).format('MM-YYYY');
      } else if (timePeriod === 'day' || timePeriod === 'week') {
        currentDate = moment(vm.currentDateToLog).format('DD-MM-YYYY');
      }

      var activeCategory =
        vm.selectedCategory &&
        vm.selectedCategory.toLowerCase() !== 'total audience'
          ? vm.selectedCategory
          : 'null';
      var activeSubVariable = vm.selectedVariable
        ? vm.selectedVariable
        : 'null';

      var dataString =
        vm.website.id +
        '/period=' +
        timePeriod +
        '/period-value=' +
        currentDate +
        '/reporting-measure=' +
        vm.radioModel.toLowerCase() +
        '/variable-category=' +
        activeCategory.toLowerCase() +
        '/variable-subcategory=' +
        activeSubVariable.toLowerCase() +
        '/persona-type=' +
        store.get('helixVersion');

      $http.post(
        '/api/pixels/logevents',
        JSON.stringify({
          action: action,
          dataString: dataString
        })
      );
    }
  }
})();
