(function() {
  'use strict';

  angular
    .module('campaigns')
    .factory('listingTotalsCampaign', listingTotalsCampaign);

  listingTotalsCampaign.$inject = ['$http', '$q', '$log', 'commonCampaign'];

  function listingTotalsCampaign($http, $q, $log, commonCampaign) {
    return {
      total: total
    };

    function total(campaignId, startDate, endDate) {
      var yesterday = commonCampaign.formatEndDate();
      var companyUID = commonCampaign.getUID();

      return $q(function(resolve, reject) {
        $http
          .post('/api/insights/campaign/totals', {
            campaign: campaignId.toString(),
            companyUID: companyUID,
            endDate: endDate || yesterday,
            startDate: startDate
          })
          .then(function(result) {
            resolve(result.data);
          })
          .catch(function(reason) {
            $log.error(reason);
            reject(reason);
          });
      });
    }
  }
})();
