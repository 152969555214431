'use strict';

angular.module('resources').factory('Resources', [
  function() {
    // Resources service logic
    // ...

    // Public API
    return {
      someMethod: function() {
        return true;
      }
    };
  }
]);
