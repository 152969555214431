'use strict';

// Setting up route
angular.module('websites').config([
  '$stateProvider',
  function($stateProvider) {
    // Websites state routing
    $stateProvider
      .state('website-pixels', {
        url: '/website-pixels',
        templateUrl: 'modules/websites/views/website-pixels.client.view.html'
      })
      .state('websites', {
        title: 'Website Overview',
        url: '/websites',
        templateUrl: 'modules/websites/views/websites.client.view.html'
      })
      .state('websiteTeams', {
        title: 'Assign Teams',
        url: '/websites/teams',
        templateUrl: 'modules/websites/views/websites-teams.view.html'
      })
      .state('viewWebsite', {
        title: 'Website Overview',
        url: '/websites/:websiteId',
        templateUrl: 'modules/websites/views/view-website.client.view.html'
      })
      .state('editWebsite', {
        title: 'Website Overview',
        url: '/websites/:websiteId/edit',
        templateUrl: 'modules/websites/views/edit-website.client.view.html'
      })
      .state('websiteInsight', {
        title: 'Website Overview',
        url: '/websites/:websiteId/insights',
        templateUrl: 'modules/websites/views/website-insight.client.view.html'
      })
      .state('websitePixels', {
        title: 'Website Overview',
        url: '/websites/:websiteId/pixels',
        templateUrl: 'modules/websites/views/website-pixels.client.view.html'
      });
  }
]);
