(function() {
  'use strict';

  angular
    .module('insights')
    .factory('InsightsTopWebsites', InsightsTopWebsites);

  InsightsTopWebsites.$inject = ['InsightsTopWebsitesConfig', 'commonCampaign'];

  function InsightsTopWebsites(InsightsTopWebsitesConfig, commonCampaign) {
    function compare(a, b) {
      if (a.ua > b.ua) return -1;
      if (a.ua < b.ua) return 1;
      return 0;
    }

    function onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    }

    function getUniqWebsites(data) {
      var websites = data.map(function(datum) {
        return datum.website;
      });
      var uniqueWebsites = websites.filter(onlyUnique);
      return uniqueWebsites;
    }

    function getTypeOfData() {
      var data = angular
        .element(document.querySelector('.switch.active'))
        .attr('value');
      if (data === 'UA') {
        data = 'ua';
      } else if (data === 'Impressions') {
        data = 'impressions';
      } else if (data === 'Clicks') {
        data = 'clicks';
      }

      return data;
    }

    function sortData(sortable) {
      // Sort Data
      sortOrder = !sortOrder;
      var chartId = InsightsTopWebsitesConfig.chartId;
      var chart = d3.select(chartId);
      chart
        .selectAll('.bar')
        .sort(function(a, b) {
          if (sortOrder) {
            return d3.ascending(a[sortable], b[sortable]);
          }
          return d3.descending(a[sortable], b[sortable]);
        })
        .transition()
        .delay(function(d, i) {
          return i * 10;
        })
        .duration(750)
        .attr('transform', function(d, i) {
          return 'translate(0,' + y(i) + ')';
        });
    }

    var blacklistedSites = InsightsTopWebsitesConfig.blacklistedSites;
    var x;
    var y;
    var xAxis = [];
    var finalData = [];
    var splicedFinal = [];
    var barHeight = InsightsTopWebsitesConfig.barHeight;
    var sortOrder = false;
    var typeOfData;

    function readData(data) {
      var chartId = InsightsTopWebsitesConfig.chartId;
      typeOfData = getTypeOfData();
      var margin = InsightsTopWebsitesConfig.margin;
      var width = InsightsTopWebsitesConfig.width();
      var widthPercent = InsightsTopWebsitesConfig.widthPercent();
      var spacing = InsightsTopWebsitesConfig.spacing;
      var height;

      var websites = getUniqWebsites(data);
      finalData = [];
      angular.forEach(websites, function(value) {
        var impressions = 0;
        var clicks = 0;
        var ua = 0;
        if (InsightsTopWebsitesConfig.blacklistedSites.indexOf(value) === -1) {
          for (var i = 0; i < data.length; i++) {
            if (data[i].website === value) {
              ua = parseInt(Number(data[i].UA), 10);
              impressions = parseInt(Number(data[i].impressions), 10);
              clicks = parseInt(Number(data[i].clicks), 10);
            }
          }

          finalData.push({
            Website: value,
            ua: ua,
            impressions: impressions,
            clicks: clicks
          });
        }
      });

      var myMax = Math.max.apply(
        Math,
        finalData.map(function(o) {
          if (isNaN(o[typeOfData])) return 0;
          return o[typeOfData];
        })
      );

      splicedFinal = finalData.sort(compare).splice(0, 100);

      x = d3.scale
        .linear()
        .range([0, width - 210])
        .domain([0, myMax]);

      y = d3.scale.ordinal();

      xAxis = d3.svg
        .axis()
        .ticks(3)
        .tickFormat(function(d) {
          var prefix = d3.formatPrefix(d);
          return prefix.scale(d) + prefix.symbol;
        })
        .scale(x);

      d3.svg
        .axis()
        .scale(y)
        .tickFormat('');

      // create the chart
      var chart = d3
        .select(chartId)
        .append('svg')
        .style('width', width + margin.left + margin.right + 'px')
        .append('g')
        .attr('transform', 'translate(' + [210, margin.top] + ')');

      // set y domain
      y
        .domain(d3.range(splicedFinal.length))
        .rangeBands([0, splicedFinal.length * barHeight]);

      // set height based on data
      height = y.rangeExtent()[1];
      d3
        .select(chart.node().parentNode)
        .style('height', height + margin.top + margin.bottom + 'px');

      // render the chart
      // add top and bottom axes
      chart
        .append('g')
        .attr('class', 'x axis bottom')
        .attr('stroke', '#000')
        .attr('fill', 'none')
        .attr('transform', 'translate(0,' + height + ')')
        .call(xAxis.orient('bottom'));

      var bars = chart
        .selectAll('.bar')
        .data(splicedFinal)
        .enter()
        .append('g')
        .attr('class', 'bar')
        .attr('transform', function(d, i) {
          return 'translate(0,' + y(i) + ')';
        });

      bars
        .append('rect')
        .attr('class', 'percent')
        .attr('height', y.rangeBand())
        .attr('width', function(d) {
          return d[typeOfData] / myMax * widthPercent + '%';
        });

      bars
        .append('text')
        .text(function(d) {
          return d.Website;
        })
        .attr('class', 'name')
        .attr('y', y.rangeBand() - 10)
        .attr('x', spacing)
        .style('text-anchor', 'end')
        .attr('transform', 'translate(' + [-10, 0] + ')');

      bars
        .append('text')
        .text(function(d) {
          return d3.format(',d')(d[typeOfData]);
        })
        .attr('class', 'number')
        .attr('y', y.rangeBand() - 5)
        .attr('x', spacing)
        .style('text-anchor', 'start')
        .attr('transform', 'translate(' + [10, -5] + ')');

      function changewebsite() {
        var t0;
        typeOfData = getTypeOfData();
        sortOrder = true;
        var widthPercent = InsightsTopWebsitesConfig.widthPercent();
        var width = InsightsTopWebsitesConfig.width();
        d3.selectAll('#toggle-websites').append('i');
        d3.selectAll('#toggle-websites i').attr('id', 'websites-caret');
        websiteCaret = document.getElementById('websites-caret');
        websiteCaret.className = 'fa fa-caret-down';

        // Get the max so our table doesn't go outside the bounds
        var max = Math.max.apply(
          Math,
          splicedFinal.map(function(o) {
            return o[typeOfData];
          })
        );

        // First transition the line & label to the new city.
        t0 = d3
          .select('#website-chart svg')
          .transition()
          .duration(750);
        if (max > 0) {
          t0
            .selectAll('rect.percent')
            .attr('height', y.rangeBand())
            .attr('width', function(d) {
              return d[typeOfData] / max * widthPercent + '%';
            });
        } else {
          t0
            .selectAll('rect.percent')
            .attr('height', y.rangeBand())
            .attr('width', '0%');
        }

        t0
          .selectAll('.number')
          .text(function(d) {
            return d3.format(',d')(d[typeOfData]);
          })
          .attr('class', 'number')
          .attr('y', y.rangeBand() - 5)
          .attr('x', 0)
          .attr('transform', 'translate(' + [10, -5] + ')');
        x = d3.scale
          .linear()
          .range([0, width - 210])
          .domain([0, max]);

        y = d3.scale.ordinal();
        y
          .domain(d3.range(splicedFinal.length))
          .rangeBands([0, splicedFinal.length * barHeight]);
        xAxis = d3.svg
          .axis()
          .ticks(3)
          .tickFormat(function(d) {
            var prefix = d3.formatPrefix(d);
            return prefix.scale(d) + prefix.symbol;
          })
          .scale(x);

        t0.selectAll('.x.axis.bottom').call(xAxis.orient('bottom'));
        sortData(typeOfData);
      }

      var websiteCaret = document.getElementById('websites-caret');

      commonCampaign.hideSpinner('website-chart');

      d3.selectAll('.switch').on('click.website', changewebsite);
      var toggleWebsites = document.getElementById('toggle-websites');
      toggleWebsites.addEventListener(
        'click',
        function() {
          sortData(typeOfData);
          if (sortOrder) {
            websiteCaret.className = 'fa fa-caret-up';
          } else {
            websiteCaret.className = 'fa fa-caret-down';
          }
        },
        false
      );
    }

    function resize() {
      var chartId = InsightsTopWebsitesConfig.chartId;
      var margin = InsightsTopWebsitesConfig.margin;
      var chart = d3.select(chartId);
      typeOfData = getTypeOfData();
      var widthPercent = InsightsTopWebsitesConfig.widthPercent();

      // update width
      var width = InsightsTopWebsitesConfig.width();

      // Get the max so our table doesn't go outside the bounds
      var max = Math.max.apply(
        Math,
        splicedFinal.map(function(o) {
          return o[typeOfData];
        })
      );

      var x = d3.scale
        .linear()
        .range([0, width - 210])
        .domain([0, max]);

      var y = d3.scale.ordinal();

      y
        .domain(d3.range(splicedFinal.length))
        .rangeBands([0, splicedFinal.length * barHeight]);
      var height = y.rangeExtent()[1];

      var xAxis = d3.svg
        .axis()
        .ticks(3)
        .tickFormat(function(d) {
          var prefix = d3.formatPrefix(d);
          return prefix.scale(d) + prefix.symbol;
        })
        .scale(x);

      chart
        .selectAll('svg')
        .style('width', width + margin.left + margin.right + 'px');

      chart
        .selectAll('.x.axis.bottom')
        .attr('width', width)
        .attr('transform', 'translate(0,' + height + ')')
        .call(xAxis.orient('bottom'));

      chart
        .selectAll('rect')
        .attr('class', 'percent')
        .attr('height', y.rangeBand())
        .attr('width', function(d) {
          return d[typeOfData] / max * widthPercent + '%';
        });
    }

    function changeDate(data) {
      var chartId = InsightsTopWebsitesConfig.chartId;
      data = data;
      var t0;
      typeOfData = getTypeOfData();
      var finalData = [];
      var width = InsightsTopWebsitesConfig.width();
      var widthPercent = InsightsTopWebsitesConfig.widthPercent();

      var websites = getUniqWebsites(data);
      angular.forEach(websites, function(value) {
        var impressions = 0;
        var clicks = 0;
        var ua = 0;
        if (blacklistedSites.indexOf(value) === -1) {
          for (var i = 0; i < data.length; i++) {
            if (data[i].website === value) {
              ua = parseInt(Number(data[i].UA), 10);
              impressions = parseInt(Number(data[i].impressions), 10);
              clicks = parseInt(Number(data[i].clicks), 10);
            }
          }

          finalData.push({
            Website: value,
            ua: ua,
            impressions: impressions,
            clicks: clicks
          });
        }
      });

      // Get Old Data.
      var chart = d3.select(chartId);
      var oldData = chart.selectAll('.bar').data();

      finalData = finalData.sort(compare).splice(0, 100);

      // Put New Data in Same order as Old Data.
      var newData = [];
      oldData.forEach(function(key) {
        var found = false;
        finalData = finalData.filter(function(item) {
          if (!found && item.Website === key.Website) {
            newData.push(item);
            found = true;
            return false;
          }
          return true;
        });
      });

      // Merge Matching New Data with Missing from Old
      var newAndOld = newData.concat(finalData);

      // Replace existing data with new data.
      splicedFinal = newAndOld;
      chart.selectAll('.bar').data(splicedFinal);
      chart.selectAll('rect.percent').data(splicedFinal);
      chart.selectAll('.number').data(splicedFinal);
      var names = chart.selectAll('text.name');
      for (var i = 0; i < names[0].length; i++) {
        // Replace Missing Old Data with New Data name
        names[0][i].innerHTML = newAndOld[i].Website;
      }

      var myMax = Math.max.apply(
        Math,
        splicedFinal.map(function(o) {
          if (isNaN(o[typeOfData])) return 0;
          return o[typeOfData];
        })
      );

      // First transition the line & label.
      t0 = d3
        .select('#website-chart svg')
        .transition()
        .duration(750);
      if (myMax > 0) {
        t0
          .selectAll('rect.percent')
          .attr('height', y.rangeBand())
          .attr('width', function(d) {
            return d[typeOfData] / myMax * widthPercent + '%';
          });
      } else {
        t0
          .selectAll('rect.percent')
          .attr('height', y.rangeBand())
          .attr('width', '0%');
      }

      t0
        .selectAll('.number')
        .text(function(d) {
          return d3.format(',d')(d[typeOfData]);
        })
        .attr('class', 'number')
        .attr('y', y.rangeBand() - 5)
        .attr('x', 0)
        .attr('transform', 'translate(' + [10, -5] + ')');
      x = d3.scale
        .linear()
        .range([0, width - 210])
        .domain([0, myMax]);

      y = d3.scale.ordinal();
      y
        .domain(d3.range(splicedFinal.length))
        .rangeBands([0, splicedFinal.length * barHeight]);
      xAxis = d3.svg
        .axis()
        .ticks(3)
        .tickFormat(function(d) {
          var prefix = d3.formatPrefix(d);
          return prefix.scale(d) + prefix.symbol;
        })
        .scale(x);

      t0.selectAll('.x.axis.bottom').call(xAxis.orient('bottom'));

      sortOrder = !sortOrder;
      sortData(typeOfData);
    }

    function roundFinalData(dataToRound) {
      angular.forEach(dataToRound, function(value) {
        value.UA = Math.round(value.UA);
        value.clicks = Math.round(value.clicks);
        value.impressions = Math.round(value.impressions);
      });

      return dataToRound;
    }

    return {
      roundData: roundFinalData,
      checkService: function(jsonData) {
        var chartId = InsightsTopWebsitesConfig.chartId;
        var chart = d3.select(chartId);
        chart.selectAll('svg').remove();
        return readData(jsonData);
      },

      resize: function() {
        return resize();
      },

      changeDate: function(jsonData) {
        var chartId = InsightsTopWebsitesConfig.chartId;
        var chart = d3.select(chartId);
        chart.selectAll('svg').remove();
        return readData(jsonData);
      }
    };
  }
})();
