'use strict';

angular.module('insights').factory('InsightsDeviceConfig', [
  function() {
    return {
      width: function() {
        return d3
          .select(this.chartId)
          .node()
          .getBoundingClientRect().width;
      },

      chartId: '#device-chart',
      height: 420
    };
  }
]);
