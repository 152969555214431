'use strict';

angular.module('insights').factory('InsightsCumulativeConfig', [
  'InsightsConfig',
  function(InsightsConfig) {
    return {
      chartId: function() {
        return InsightsConfig.cumulativeAreaChart;
      },

      defaultColor: '#aaaaaa'
    };
  }
]);
