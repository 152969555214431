(function() {
  'use strict';

  angular
    .module('campaigns')
    .factory('helixChartsCampaign', helixChartsCampaign);

  helixChartsCampaign.$inject = [
    '$http',
    'commonCampaign',
    '$q',
    'HelixData',
    '$log',
    'HelixPersonas',
    'downloads'
  ];

  function helixChartsCampaign(
    $http,
    commonCampaign,
    $q,
    HelixData,
    $log,
    HelixPersonas,
    downloads
  ) {
    return {
      chart: chart,
      resize: resize,
      changeDate: changeDate,
      getData: getData
    };

    /**
     * Helix Chart based on date period
     * @param {string} period
     * @param {string} campaignId
     * @param {Object} startEnd - Insight start and end dates
     * @param {?Object} variable
     * @param {string} group
     * @param {string} helixYear
     */
    function chart(period, campaignId, startEnd, variable, group, helixYear) {
      var companyUID = commonCampaign.getUID();
      if (period === 'all') {
        allChart(
          campaignId,
          companyUID,
          startEnd.end,
          variable,
          group,
          helixYear
        );
      } else if (period === 'month') {
        monthChart(
          campaignId,
          companyUID,
          startEnd.end,
          variable,
          group,
          helixYear
        );
      } else if (period === 'week') {
        weekChart(
          campaignId,
          companyUID,
          startEnd.end,
          variable,
          group,
          helixYear
        );
      } else if (period === 'day') {
        dayChart(
          campaignId,
          companyUID,
          startEnd.end,
          variable,
          group,
          helixYear
        );
      }
    }

    /**
     * All Time Helix Chart
     * @param {string} campaignId
     * @param {?string} endDate - Campaign End Date YYYY-MM-DD
     * @param {?Object} variable
     * @param {string} group
     * @param {string} helixYear
     */
    function allChart(
      campaignId,
      companyUID,
      endDate,
      variable,
      group,
      helixYear
    ) {
      var yesterday = commonCampaign.formatEndDate();
      $http
        .post('/api/insights/campaign', {
          campaign: campaignId,
          companyUID: companyUID,
          endDate: endDate || yesterday,
          chart: 'helix',
          period: 'all',
          type: 'campaign',
          variable: variable
        })
        .then(
          function(result) {
            if (result.data.insights.length > 0) {
              HelixPersonas.formatData(
                result.data.insights,
                group,
                helixYear
              ).then(function(res) {
                HelixData.setData(res);
                HelixPersonas.checkService(HelixData.getData(), group);
              });
            } else {
              commonCampaign.hideSpinner('helix-people-chart', 'showNoDataMsg');
              commonCampaign.hideSpinner('helix-index-chart', 'showNoDataMsg');
            }
          },
          function(reason) {
            commonCampaign.hideSpinner('helix-people-chart', 'showNoDataMsg');
            commonCampaign.hideSpinner('helix-index-chart', 'showNoDataMsg');
            $log.error('Error: Campaigns helix charts allchart');
            $log.error(reason);
          }
        );
    }

    /**
     * Month Helix Chart
     * @param {string} campaignId
     * @param {?string} endDate - Campaign End Date YYYY-MM-DD
     * @param {?Object} variable
     * @param {string} group
     * @param {string} helixYear
     */
    function monthChart(
      campaignId,
      companyUID,
      endDate,
      variable,
      group,
      helixYear
    ) {
      var startOfMonth = moment(endDate || undefined)
        .startOf('month')
        .format('YYYY-MM-DD');

      $http
        .post('/api/insights/campaign', {
          campaign: campaignId,
          companyUID: companyUID,
          endDate: startOfMonth,
          chart: 'helix',
          period: 'month',
          type: 'campaign',
          variable: variable
        })
        .then(
          function(result) {
            if (result.data.insights.length > 0) {
              HelixPersonas.formatData(
                result.data.insights,
                group,
                helixYear
              ).then(function(res) {
                HelixData.setData(res);
                HelixPersonas.checkService(HelixData.getData(), group);
              });
            } else {
              commonCampaign.hideSpinner('helix-people-chart', 'showNoDataMsg');
              commonCampaign.hideSpinner('helix-index-chart', 'showNoDataMsg');
            }
          },
          function(reason) {
            commonCampaign.hideSpinner('helix-people-chart', 'showNoDataMsg');
            commonCampaign.hideSpinner('helix-index-chart', 'showNoDataMsg');
            $log.error('Error: campaign-helix-month');
            $log.error(reason);
          }
        );
    }

    /**
     * Week Helix Chart
     * @param {string} campaignId
     * @param {?string} endDate - Campaign End Date YYYY-MM-DD
     * @param {?Object} variable
     * @param {string} group
     * @param {string} helixYear
     */
    function weekChart(
      campaignId,
      companyUID,
      endDate,
      variable,
      group,
      helixYear
    ) {
      var startOfWeek = moment(endDate || undefined)
        .startOf('isoWeek')
        .format('YYYY-MM-DD');

      $http
        .post('/api/insights/campaign', {
          campaign: campaignId,
          companyUID: companyUID,
          endDate: startOfWeek,
          chart: 'helix',
          period: 'week',
          type: 'campaign',
          variable: variable
        })
        .then(
          function(result) {
            if (result.data.insights.length > 0) {
              HelixPersonas.formatData(
                result.data.insights,
                group,
                helixYear
              ).then(function(res) {
                HelixData.setData(res);
                HelixPersonas.checkService(HelixData.getData(), group);
              });
            } else {
              commonCampaign.hideSpinner('helix-people-chart', 'showNoDataMsg');
              commonCampaign.hideSpinner('helix-index-chart', 'showNoDataMsg');
            }
          },
          function(reason) {
            commonCampaign.hideSpinner('helix-people-chart', 'showNoDataMsg');
            commonCampaign.hideSpinner('helix-index-chart', 'showNoDataMsg');
            $log.error('Error: Campaigns helix charts weekChart');
            $log.error(reason);
          }
        );
    }

    /**
     * Day Helix Chart
     * @param {string} campaignId
     * @param {?string} endDate - Campaign End Date YYYY-MM-DD
     * @param {?Object} variable
     * @param {string} group
     * @param {string} helixYear
     */
    function dayChart(
      campaignId,
      companyUID,
      endDate,
      variable,
      group,
      helixYear
    ) {
      var yesterday = commonCampaign.formatEndDate();
      $http
        .post('/api/insights/campaign', {
          campaign: campaignId,
          companyUID: companyUID,
          endDate: endDate || yesterday,
          chart: 'helix',
          period: 'day',
          type: 'campaign',
          variable: variable
        })
        .then(
          function(result) {
            if (result.data.insights.length > 0) {
              HelixPersonas.formatData(
                result.data.insights,
                group,
                helixYear
              ).then(function(res) {
                HelixData.setData(res);
                HelixPersonas.checkService(HelixData.getData(), group);
              });
            } else {
              commonCampaign.hideSpinner('helix-people-chart', 'showNoDataMsg');
              commonCampaign.hideSpinner('helix-index-chart', 'showNoDataMsg');
            }
          },
          function(reason) {
            commonCampaign.hideSpinner('helix-people-chart', 'showNoDataMsg');
            commonCampaign.hideSpinner('helix-index-chart', 'showNoDataMsg');
            $log.error('Error: Campaigns helix charts dayChart');
            $log.error(reason);
          }
        );
    }

    /**
     * Resize chart
     */
    function resize() {
      HelixPersonas.resize();
    }

    /**
     * Change Date
     * @param {string} campaignId
     * @param {string} period - Date period
     * @param {Object} startEnd - Insight start and end dates
     * @param {?Object} variable
     * @param {string} group
     * @param {string} helixYear
     */
    function changeDate(
      campaignId,
      period,
      startEnd,
      variable,
      group,
      helixYear
    ) {
      var companyUID = commonCampaign.getUID();
      if (period === 'all') {
        allChart(
          campaignId,
          companyUID,
          startEnd.end,
          variable,
          group,
          helixYear
        );
      } else if (period === 'month') {
        monthChart(
          campaignId,
          companyUID,
          startEnd.end,
          variable,
          group,
          helixYear
        );
      } else if (period === 'week') {
        weekChart(
          campaignId,
          companyUID,
          startEnd.end,
          variable,
          group,
          helixYear
        );
      } else if (period === 'day') {
        dayChart(
          campaignId,
          companyUID,
          startEnd.end,
          variable,
          group,
          helixYear
        );
      }
    }

    /**
     * Which data to download
     * @param {string} campaignId
     * @param {string} type
     * @param {Object} startEnd - Insight start and end dates
     * @param {?Object} variable
     * @param {string} group
     * @param {string} helixYear
     * @returns {Promise} Promise
     */
    function getData(campaignId, type, startEnd, variable, group, helixYear) {
      var companyUID = commonCampaign.getUID();
      if (type === 'day') {
        return getDataDay(
          campaignId,
          companyUID,
          startEnd.end,
          variable,
          group,
          helixYear
        );
      }
      if (type === 'week') {
        return getDataWeek(
          campaignId,
          companyUID,
          startEnd.end,
          variable,
          group,
          helixYear
        );
      }
      if (type === 'all') {
        return getDataAll(
          campaignId,
          companyUID,
          startEnd.end,
          variable,
          group,
          helixYear
        );
      }
      return getDataMonth(
        campaignId,
        companyUID,
        startEnd.end,
        variable,
        group,
        helixYear
      );
    }

    /**
     * Get Day Data for Download
     * @param {string} campaignId
     * @param {?string} endDate - Campaign End Date YYYY-MM-DD
     * @param {?Object} variable
     * @param {string} group
     * @param {string} helixYear
     * @returns {Promise} Promise
     */
    function getDataDay(
      campaignId,
      companyUID,
      endDate,
      variable,
      group,
      helixYear
    ) {
      var yesterday = commonCampaign.formatEndDate();
      return $q(function(resolve, reject) {
        $http
          .post('/api/insights/campaign', {
            campaign: campaignId,
            companyUID: companyUID,
            endDate: endDate || yesterday,
            chart: 'helix',
            period: 'day',
            type: 'campaign',
            variable: variable,
            helixVersion: helixYear
          })
          .then(
            function(result) {
              var insights = result.data.insights;
              HelixPersonas.roundData(insights, group, helixYear).then(function(
                res
              ) {
                var data = formatDownload(
                  res,
                  'Date',
                  endDate || yesterday,
                  helixYear,
                  group
                );
                resolve(data);
              });
            },
            function(reason) {
              $log.error('Error: Campaigns helix charts getDataDay');
              $log.error(reason);
              reject();
            }
          );
      });
    }

    /**
     * Get All Data for Download
     * @param {string} campaignId
     * @param {?string} endDate - Campaign End Date YYYY-MM-DD
     * @param {?Object} variable
     * @param {string} group
     * @param {string} helixYear
     * @returns {Promise} Promise
     */
    function getDataAll(
      campaignId,
      companyUID,
      endDate,
      variable,
      group,
      helixYear
    ) {
      var yesterday = commonCampaign.formatEndDate();
      return $q(function(resolve, reject) {
        $http
          .post('/api/insights/campaign', {
            campaign: campaignId,
            companyUID: companyUID,
            endDate: endDate || yesterday,
            chart: 'helix',
            period: 'all',
            type: 'campaign',
            variable: variable,
            helixVersion: helixYear
          })
          .then(
            function(result) {
              var insights = result.data.insights;
              HelixPersonas.roundData(insights, group, helixYear).then(function(
                res
              ) {
                var data = formatDownload(
                  res,
                  'Period_End_Date',
                  endDate || yesterday,
                  helixYear,
                  group
                );
                resolve(data);
              });
            },
            function(reason) {
              $log.error('Error: Campaigns helix charts getDataDay');
              $log.error(reason);
              reject();
            }
          );
      });
    }

    /**
     * Get Week Data for Download
     * @param {string} campaignId
     * @param {?string} endDate - Campaign End Date YYYY-MM-DD
     * @param {?Object} variable
     * @param {string} group
     * @param {string} helixYear
     * @returns {Promise} Promise
     */
    function getDataWeek(
      campaignId,
      companyUID,
      endDate,
      variable,
      group,
      helixYear
    ) {
      var startOfWeek = moment(endDate || undefined)
        .startOf('isoWeek')
        .format('YYYY-MM-DD');
      return $q(function(resolve, reject) {
        $http
          .post('/api/insights/campaign', {
            campaign: campaignId,
            companyUID: companyUID,
            endDate: startOfWeek,
            chart: 'helix',
            period: 'week',
            type: 'campaign',
            variable: variable,
            helixVersion: helixYear
          })
          .then(
            function(result) {
              var insights = result.data.insights;
              HelixPersonas.roundData(insights, group, helixYear).then(function(
                res
              ) {
                var data = formatDownload(
                  res,
                  'Start_Date_of_Week',
                  startOfWeek,
                  helixYear,
                  group
                );
                resolve(data);
              });
            },
            function(reason) {
              $log.error('Error: Campaigns helix charts getDataWeek');
              $log.error(reason);
              reject();
            }
          );
      });
    }

    /**
     * Get Month Data for Download
     * @param {string} campaignId
     * @param {?string} endDate - Campaign End Date YYYY-MM-DD
     * @param {?Object} variable
     * @param {string} group
     * @param {string} helixYear
     * @returns {Promise} Promise
     */
    function getDataMonth(
      campaignId,
      companyUID,
      endDate,
      variable,
      group,
      helixYear
    ) {
      var startOfMonth = moment(endDate || undefined)
        .startOf('month')
        .format('YYYY-MM-DD');
      return $q(function(resolve, reject) {
        $http
          .post('/api/insights/campaign', {
            campaign: campaignId,
            companyUID: companyUID,
            endDate: startOfMonth,
            chart: 'helix',
            period: 'month',
            type: 'campaign',
            variable: variable,
            helixVersion: helixYear
          })
          .then(
            function(result) {
              var insights = result.data.insights;
              HelixPersonas.roundData(insights, group, helixYear).then(function(
                res
              ) {
                var data = formatDownload(
                  res,
                  'Start_Date_of_Month',
                  startOfMonth,
                  helixYear,
                  group
                );
                resolve(data);
              });
            },
            function(reason) {
              $log.error('Error: Campaigns helix charts getDataMonth');
              $log.error(reason);
              reject();
            }
          );
      });
    }

    /**
     * Format data for downloads.
     * @param {Array<Object>} rawData
     * @param {string} dateHeading
     * @param {string} date
     * @param {string} helixYear
     * @param {string} group
     * @returns {Array<Object>}
     */
    function formatDownload(rawData, dateHeading, date, helixYear, group) {
      var data = downloads.addHeading(rawData, dateHeading, date);
      if (group === 'Communities') {
        data = downloads.removeHeading(data, 'Helix_Community');
      }
      var code;
      if (group === 'Communities') {
        code =
          helixYear === '2014' ? 'Helix_Community_2014' : 'Helix_Community';
      } else {
        code = helixYear === '2014' ? 'Helix_Persona_2014' : 'Helix_Persona';
      }
      data = downloads.renameHeading(data, 'Helix_Code', code);
      var name;
      if (group === 'Communities') {
        name =
          helixYear === '2014'
            ? 'Helix_Community_2014_Name'
            : 'Helix_Community_Name';
      } else {
        name =
          helixYear === '2014'
            ? 'Helix_Persona_2014_Name'
            : 'Helix_Persona_Name';
      }
      data = downloads.renameHeading(data, 'Helix_Name', name);
      if (group === 'Personas') {
        if (helixYear === '2014') {
          data = downloads.renameHeading(
            data,
            'Helix_Community_Name',
            'Helix_Community_2014_Name'
          );
        }
      }
      data = downloads.renameHeading(data, 'index', 'Index');
      data = downloads.renameHeading(data, 'impressions', 'Impressions');
      data = downloads.renameHeading(data, 'freq', 'Frequency');
      data = downloads.renameHeading(data, 'clicks', 'Clicks');
      data = downloads.renameHeading(data, 'ctr', 'CTR');
      data = downloads.renameHeading(data, 'desc', 'Description');

      var headingOrder = [];
      if (group === 'Personas') {
        if (helixYear === '2014') {
          headingOrder = [
            dateHeading,
            'Helix_Persona_2014',
            'Helix_Persona_2014_Name',
            'Helix_Community_2014_Name',
            'UA',
            'Index',
            'Impressions',
            'Frequency',
            'Clicks',
            'CTR',
            'Description'
          ];
        } else {
          headingOrder = [
            dateHeading,
            'Helix_Persona',
            'Helix_Persona_Name',
            'Helix_Community_Name',
            'UA',
            'Index',
            'Impressions',
            'Frequency',
            'Clicks',
            'CTR',
            'Description'
          ];
        }
      } else if (helixYear === '2014') {
        headingOrder = [
          dateHeading,
          'Helix_Community_2014',
          'Helix_Community_2014_Name',
          'UA',
          'Index',
          'Impressions',
          'Frequency',
          'Clicks',
          'CTR',
          'Description'
        ];
      } else {
        headingOrder = [
          dateHeading,
          'Helix_Community',
          'Helix_Community_Name',
          'UA',
          'Index',
          'Impressions',
          'Frequency',
          'Clicks',
          'CTR',
          'Description'
        ];
      }
      data = downloads.orderFields(data, headingOrder);

      return data;
    }
  }
})();
