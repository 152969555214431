(function() {
  'use strict';

  angular.module('settings').factory('Settings', Settings);

  Settings.$inject = ['AdminSettings', '$rootScope', 'store'];
  function Settings(AdminSettings, $rootScope, store) {
    return {
      checkNotification: checkNotification
    };

    /**
     * Check if notification
     */
    function checkNotification() {
      AdminSettings.get().then(function(settings) {
        var isDismissed = checkIfDismissed(settings.dateUpdated);

        if (settings.dashboardNotificationOn && !isDismissed) {
          $rootScope.alert = {
            msg: 'Issue/Delay with data processing.',
            type: 'danger',
            lastUpdated: null
          };

          if ($rootScope.alert) {
            $rootScope.alert.lastUpdated = settings.dateUpdated;
          }
        }
      });
    }

    /**
     * Check if Notification has been dismissed
     * @returns Boolean
     */
    function checkIfDismissed(dateUpdated) {
      var notifyStore = store.get('notification-dismissed');

      if (notifyStore) {
        return notifyStore === dateUpdated;
      }

      return false;
    }
  }
})();
