'use strict';

angular.module('resources').controller('ResourcesController', [
  '$rootScope',
  'Settings',
  '$http',
  function($rootScope, Settings, $http) {
    var vm = this;
    $rootScope.title = 'Support';
    Settings.checkNotification();
    vm.url =
      'https://audienceshelpdesk.roymorgan.com/Account/TokenLogin?token=';
  }
]);
