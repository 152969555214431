'use strict';

angular.module('resources').filter('resources', [
  function() {
    return function(input) {
      // Resources directive logic
      // ...

      return 'resources filter: ' + input;
    };
  }
]);
