'use strict';

angular.module('insights').factory('InsightsConfig', [
  function() {
    // InsightsConfig service logic

    // Public API
    return {
      gray: '#eee',
      rmrRed: '#ce282e',
      singleHeight: 330,
      doubleHeight: 630,
      areaChartHeight: 200,
      barHeight: 30,
      maxDoubleVerticalBars: 20,
      helixPeopleYLabelOffset: 220,
      premiumPeopleYLabelOffset: 220,
      noInsights: '<div class="no-insights">Data Unavailable</div>',
      areaComparisonChart: '#area-comparison-chart',
      helixIndexChart: '#helix-index-chart',
      helixPeopleChart: '#helix-people-chart',
      premiumIndexChart: '#premium-index-chart',
      premiumPeopleChart: '#premium-people-chart',
      uniqueAreaChart: '#unique-area-chart',
      cumulativeAreaChart: '#cumulative-area-chart',
      dateRanges: {
        week: ['4 Weeks', '12 Weeks', '24 Weeks', '52 Weeks', 'All'],
        month: ['3 Months', '6 Months', '9 Months', '12 Months', 'All'],
        day: ['7 Days', '15 Days', '30 Days', '90 Days', '365 Days', 'All'],
        all: ['All']
      },
      australianAreas: [
        {
          QLD: [
            {
              area: 'Brisbane',
              area_code: '1'
            },
            {
              area: 'Rest of QLD',
              area_code: '2'
            }
          ]
        },
        {
          NSW: [
            {
              area: 'Sydney',
              area_code: '3'
            },
            {
              area: 'Rest of NSW',
              area_code: '4'
            }
          ]
        },
        {
          VIC: [
            {
              area: 'Melbourne',
              area_code: '5'
            },
            {
              area: 'Rest of VIC',
              area_code: '6'
            }
          ]
        },
        {
          TAS: [
            {
              area: 'Hobart',
              area_code: '7'
            },
            {
              area: 'Rest of TAS',
              area_code: '8'
            }
          ]
        },
        {
          SA: [
            {
              area: 'Adelaide',
              area_code: '9'
            },
            {
              area: 'Rest of SA',
              area_code: '10'
            }
          ]
        },
        {
          WA: [
            {
              area: 'Perth',
              area_code: '11'
            },
            {
              area: 'Rest of WA',
              area_code: '12'
            }
          ]
        },
        {
          NT: [
            {
              area: 'Darwin',
              area_code: '13'
            },
            {
              area: 'Rest of NT',
              area_code: '14'
            }
          ]
        }
      ]
    };
  }
]);
