'use strict';

angular.module('insights').directive('insights', [
  function() {
    return {
      template: '<div></div>',
      restrict: 'E',
      link: function postLink(scope, element, attrs) {
        // Insights directive logic
        // ...

        element.text('this is the insights directive');
      }
    };
  }
]);
