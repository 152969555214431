(function() {
  'use strict';

  angular
    .module('campaigns')
    .factory('premiumChartsCampaign', premiumChartsCampaign);

  premiumChartsCampaign.$inject = [
    '$http',
    'commonCampaign',
    '$q',
    'HelixData',
    '$log',
    'Premium',
    'downloads'
  ];

  function premiumChartsCampaign(
    $http,
    commonCampaign,
    $q,
    HelixData,
    $log,
    Premium,
    downloads
  ) {
    return {
      chart: chart,
      resize: resize,
      changeDate: changeDate,
      getData: getData
    };

    /**
     * Premium Chart based on date period
     * @param {string} period
     * @param {string} campaignId
     * @param {Object} startEnd - Insight start and end dates
     * @param {?Object} variable - Audience Filter
     */
    function chart(period, campaignId, startEnd, variable) {
      var companyUID = commonCampaign.getUID();
      if (
        variable &&
        (variable.category !== 'STATE' && variable.category !== undefined)
      ) {
        handleNoData();
      } else if (period === 'all') {
        allChart(campaignId, companyUID, startEnd.end, variable);
      } else if (period === 'month') {
        monthChart(campaignId, companyUID, startEnd.end, variable);
      } else if (period === 'week') {
        weekChart(campaignId, companyUID, startEnd.end, variable);
      } else if (period === 'day') {
        dayChart(campaignId, companyUID, startEnd.end, variable);
      }
    }

    /**
     * All Time Premium Chart
     * @param {string} campaignId
     * @param {?string} endDate - Campaign End Date YYYY-MM-DD
     * @param {?Object} variable - Audience Filter
     */
    function allChart(campaignId, companyUID, endDate, variable) {
      var yesterday = commonCampaign.formatEndDate();
      $http
        .post('/api/insights/campaign', {
          campaign: campaignId,
          companyUID: companyUID,
          endDate: endDate || yesterday,
          chart: 'premium',
          period: 'all',
          type: 'campaign',
          variable: variable
        })
        .then(
          function(result) {
            $http
              .get('/api/insights/premium-index')
              .then(function(premiumIndexData) {
                if (
                  result.data.insights.length > 0 &&
                  premiumIndexData.data.length > 0
                ) {
                  Premium.formatData(
                    result.data.insights,
                    premiumIndexData.data
                  ).then(function(res) {
                    HelixData.setData(res);
                    Premium.checkService(HelixData.getData());
                  });
                } else {
                  handleNoData();
                }
              });
          },
          function(reason) {
            handleNoData();
            $log.error('Error: Campaigns premium charts allchart');
            $log.error(reason);
          }
        );
    }

    /**
     * Month Premium Chart
     * @param {string} campaignId
     * @param {?string} endDate - Campaign End Date YYYY-MM-DD
     * @param {?Object} variable - Audience Filter
     */
    function monthChart(campaignId, companyUID, endDate, variable) {
      var startOfMonth = moment(endDate || undefined)
        .startOf('month')
        .format('YYYY-MM-DD');

      $http
        .post('/api/insights/campaign', {
          campaign: campaignId,
          companyUID: companyUID,
          endDate: startOfMonth,
          chart: 'premium',
          period: 'month',
          type: 'campaign',
          variable: variable
        })
        .then(
          function(result) {
            $http
              .get('/api/insights/premium-index')
              .then(function(premiumIndexData) {
                if (
                  result.data.insights.length > 0 &&
                  premiumIndexData.data.length > 0
                ) {
                  Premium.formatData(
                    result.data.insights,
                    premiumIndexData.data
                  ).then(function(res) {
                    HelixData.setData(res);
                    Premium.checkService(HelixData.getData());
                  });
                } else {
                  handleNoData();
                }
              });
          },
          function(reason) {
            handleNoData();
            $log.error('Error: Campaigns premium charts monthChart');
            $log.error(reason);
          }
        );
    }

    /**
     * Week Premium Chart
     * @param {string} campaignId
     * @param {?string} endDate - Campaign End Date YYYY-MM-DD
     * @param {?Object} variable - Audience Filter
     */
    function weekChart(campaignId, companyUID, endDate, variable) {
      var startOfWeek = moment(endDate || undefined)
        .startOf('isoWeek')
        .format('YYYY-MM-DD');

      $http
        .post('/api/insights/campaign', {
          campaign: campaignId,
          companyUID: companyUID,
          endDate: startOfWeek,
          chart: 'premium',
          period: 'week',
          type: 'campaign',
          variable: variable
        })
        .then(
          function(result) {
            $http
              .get('/api/insights/premium-index')
              .then(function(premiumIndexData) {
                if (
                  result.data.insights.length > 0 &&
                  premiumIndexData.data.length > 0
                ) {
                  Premium.formatData(
                    result.data.insights,
                    premiumIndexData.data
                  ).then(function(res) {
                    HelixData.setData(res);
                    Premium.checkService(HelixData.getData());
                  });
                } else {
                  handleNoData();
                }
              });
          },
          function(reason) {
            handleNoData();
            $log.error('Error: Campaigns premium charts weekChart');
            $log.error(reason);
          }
        );
    }

    /**
     * Day Premium Chart
     * @param {string} campaignId
     * @param {?string} endDate - Campaign End Date YYYY-MM-DD
     * @param {?Object} variable - Audience Filter
     */
    function dayChart(campaignId, companyUID, endDate, variable) {
      var yesterday = commonCampaign.formatEndDate();
      $http
        .post('/api/insights/campaign', {
          campaign: campaignId,
          companyUID: companyUID,
          endDate: endDate || yesterday,
          chart: 'premium',
          period: 'day',
          type: 'campaign',
          variable: variable
        })
        .then(
          function(result) {
            $http
              .get('/api/insights/premium-index')
              .then(function(premiumIndexData) {
                if (
                  result.data.insights.length > 0 &&
                  premiumIndexData.data.length > 0
                ) {
                  Premium.formatData(
                    result.data.insights,
                    premiumIndexData.data
                  ).then(function(res) {
                    HelixData.setData(res);
                    Premium.checkService(HelixData.getData());
                  });
                } else {
                  handleNoData();
                }
              });
          },
          function(reason) {
            handleNoData();
            $log.error('Error: Campaigns premium charts dayChart');
            $log.error(reason);
          }
        );
    }

    /**
     * Resize chart
     */
    function resize() {
      Premium.resize();
    }

    /**
     * Change Date
     * @param {string} campaignId
     * @param {string} period - Date period
     * @param {Object} startEnd - Insight start and end dates
     * @param {?Object} variable - Audience Filter
     */
    function changeDate(campaignId, period, startEnd, variable) {
      var companyUID = commonCampaign.getUID();
      if (
        variable &&
        (variable.category !== 'STATE' && variable.category !== undefined)
      ) {
        handleNoData();
      } else if (period === 'all') {
        allChart(campaignId, companyUID, startEnd.end, variable);
      } else if (period === 'month') {
        monthChart(campaignId, companyUID, startEnd.end, variable);
      } else if (period === 'week') {
        weekChart(campaignId, companyUID, startEnd.end, variable);
      } else if (period === 'day') {
        dayChart(campaignId, companyUID, startEnd.end, variable);
      }
    }

    /**
     * Which data to download
     * @param {string} campaignId
     * @param {string} type
     * @param {Object} startEnd - Insight start and end dates
     * @param {?Object} variable - Audience Filter
     * @returns {Promise} Promise
     */
    function getData(campaignId, type, startEnd, variable) {
      var companyUID = commonCampaign.getUID();
      if (type === 'day') {
        return getDataDay(campaignId, companyUID, startEnd.end, variable);
      }
      if (type === 'week') {
        return getDataWeek(campaignId, companyUID, startEnd.end, variable);
      }
      if (type === 'all') {
        return getDataAll(campaignId, companyUID, startEnd.end, variable);
      }
      return getDataMonth(campaignId, companyUID, startEnd.end, variable);
    }

    /**
     * Get Day Data for Download
     * @param {string} campaignId
     * @param {?string} endDate - Campaign End Date YYYY-MM-DD
     * @param {?Object} variable - Audience Filter
     * @returns {Promise} Promise
     */
    function getDataDay(campaignId, companyUID, endDate, variable) {
      var yesterday = commonCampaign.formatEndDate();
      return $q(function(resolve, reject) {
        $http
          .post('/api/insights/campaign', {
            campaign: campaignId,
            companyUID: companyUID,
            endDate: endDate || yesterday,
            chart: 'premium',
            period: 'day',
            type: 'campaign',
            variable: variable
          })
          .then(
            function(result) {
              $http
                .get('/api/insights/premium-index')
                .then(function(premiumIndexData) {
                  var insights = result.data.insights;
                  Premium.roundData(insights, premiumIndexData.data).then(
                    function(res) {
                      var data = formatDownload(
                        res,
                        'Date',
                        endDate || yesterday
                      );
                      resolve(data);
                    }
                  );
                });
            },
            function(reason) {
              $log.error('Error: Campaigns premium charts getDataDay');
              $log.error(reason);
              reject();
            }
          );
      });
    }

    /**
     * Get All Data for Download
     * @param {string} campaignId
     * @param {?string} endDate - Campaign End Date YYYY-MM-DD
     * @param {?Object} variable - Audience Filter
     * @returns {Promise} Promise
     */
    function getDataAll(campaignId, companyUID, endDate, variable) {
      var yesterday = commonCampaign.formatEndDate();
      return $q(function(resolve, reject) {
        $http
          .post('/api/insights/campaign', {
            campaign: campaignId,
            companyUID: companyUID,
            endDate: endDate || yesterday,
            chart: 'premium',
            period: 'all',
            type: 'campaign',
            variable: variable
          })
          .then(
            function(result) {
              $http
                .get('/api/insights/premium-index')
                .then(function(premiumIndexData) {
                  var insights = result.data.insights;
                  Premium.roundData(insights, premiumIndexData.data).then(
                    function(res) {
                      var data = formatDownload(
                        res,
                        'Period_End_Date',
                        endDate || yesterday
                      );
                      resolve(data);
                    }
                  );
                });
            },
            function(reason) {
              $log.error('Error: Campaigns premium charts getDataDay');
              $log.error(reason);
              reject();
            }
          );
      });
    }

    /**
     * Get Week Data for Download
     * @param {string} campaignId
     * @param {?string} endDate - Campaign End Date YYYY-MM-DD
     * @param {?Object} variable - Audience Filter
     * @returns {Promise} Promise
     */
    function getDataWeek(campaignId, companyUID, endDate, variable) {
      var startOfWeek = moment(endDate || undefined)
        .startOf('isoWeek')
        .format('YYYY-MM-DD');
      return $q(function(resolve, reject) {
        $http
          .post('/api/insights/campaign', {
            campaign: campaignId,
            companyUID: companyUID,
            endDate: startOfWeek,
            chart: 'premium',
            period: 'week',
            type: 'campaign',
            variable: variable
          })
          .then(
            function(result) {
              $http
                .get('/api/insights/premium-index')
                .then(function(premiumIndexData) {
                  var insights = result.data.insights;
                  Premium.roundData(insights, premiumIndexData.data).then(
                    function(res) {
                      var data = formatDownload(
                        res,
                        'Start_Date_of_Week',
                        startOfWeek
                      );
                      resolve(data);
                    }
                  );
                });
            },
            function(reason) {
              $log.error('Error: Campaigns premium charts getDataWeek');
              $log.error(reason);
              reject();
            }
          );
      });
    }

    /**
     * Get Month Data for Download
     * @param {string} campaignId
     * @param {?string} endDate - Campaign End Date YYYY-MM-DD
     * @param {?Object} variable - Audience Filter
     * @returns {Promise} Promise
     */
    function getDataMonth(campaignId, companyUID, endDate, variable) {
      var startOfMonth = moment(endDate || undefined)
        .startOf('month')
        .format('YYYY-MM-DD');
      return $q(function(resolve, reject) {
        $http
          .post('/api/insights/campaign', {
            campaign: campaignId,
            companyUID: companyUID,
            endDate: startOfMonth,
            chart: 'premium',
            period: 'month',
            type: 'campaign',
            variable: variable
          })
          .then(
            function(result) {
              $http
                .get('/api/insights/premium-index')
                .then(function(premiumIndexData) {
                  var insights = result.data.insights;
                  Premium.roundData(insights, premiumIndexData.data).then(
                    function(res) {
                      var data = formatDownload(
                        res,
                        'Start_Date_of_Month',
                        startOfMonth
                      );
                      resolve(data);
                    }
                  );
                });
            },
            function(reason) {
              $log.error('Error: Campaigns premium charts getDataMonth');
              $log.error(reason);
              reject();
            }
          );
      });
    }

    /**
     * Wrapper function for hiding data loading spinners and
     * displaying "No Data" frontend message
     */
    function handleNoData() {
      commonCampaign.noData('premium-charts');
      commonCampaign.hideSpinner('premium-people-chart');
      commonCampaign.hideSpinner('premium-index-chart');
    }

    /**
     * Format data for downloads.
     * @param {Array<Object>} rawData
     * @param {string} dateHeading
     * @param {string} date
     * @returns {Array<Object>}
     */
    function formatDownload(rawData, dateHeading, date) {
      var data = downloads.addHeading(rawData, dateHeading, date);
      data = downloads.removeHeading(data, 'className');

      return data;
    }
  }
})();
