(function() {
  'use strict';

  angular.module('websites').factory('genderChartWebsite', genderChartWebsite);

  genderChartWebsite.$inject = [
    '$http',
    'commonWebsite',
    'InsightsGender',
    '$q',
    '$log'
  ];

  function genderChartWebsite($http, commonWebsite, InsightsGender, $q, $log) {
    return {
      chart: chart,
      resize: resize,
      changeDate: changeDate
    };

    /**
     * Gender Chart based on date period
     * @param {string} period
     * @param {string} websiteId
     * @param {Object} startEnd - Insight start and end dates
     * @param {Object} variable
     */
    function chart(period, websiteId, startEnd, variable) {
      var companyUID = commonWebsite.getUID();
      if (period === 'month') {
        monthChart(websiteId, companyUID, startEnd.end, variable);
      } else if (period === 'week') {
        weekChart(websiteId, companyUID, startEnd.end, variable);
      } else if (period === 'day') {
        dayChart(websiteId, companyUID, startEnd.end, variable);
      }
    }

    /**
     * Month Gender Chart
     * @param {string} websiteId
     * @param {?string} endDate - Campaign End Date YYYY-MM-DD
     * @param {Object} variable
     */
    function monthChart(websiteId, companyUID, endDate, variable) {
      var startOfMonth = moment(endDate || undefined)
        .startOf('month')
        .format('YYYY-MM-DD');
      $http
        .post('/api/insights/website', {
          campaign: websiteId,
          companyUID: companyUID,
          endDate: startOfMonth,
          chart: 'gender',
          period: 'month',
          type: 'website',
          variable: variable
        })
        .then(
          function(result) {
            if (result.data.insights.length > 0) {
              InsightsGender.checkService(result.data.insights);
            } else {
              commonWebsite.hideSpinner('gender-chart', 'showNoDataMsg');
              InsightsGender.clearData();
            }
          },
          function(reason) {
            commonWebsite.hideSpinner('gender-chart', 'showNoDataMsg');
            $log.error('Error: website-gender-month');
            $log.error(reason);
          }
        );
    }

    /**
     * Week Gender Chart
     * @param {string} websiteId
     * @param {?string} endDate - Campaign End Date YYYY-MM-DD
     * @param {Object} variable
     */
    function weekChart(websiteId, companyUID, endDate, variable) {
      var startOfWeek = moment(endDate || undefined)
        .startOf('isoWeek')
        .format('YYYY-MM-DD');
      $http
        .post('/api/insights/website', {
          campaign: websiteId,
          companyUID: companyUID,
          endDate: startOfWeek,
          chart: 'gender',
          period: 'week',
          type: 'website',
          variable: variable
        })
        .then(
          function(result) {
            if (result.data.insights.length > 0) {
              var insights = result.data.insights;
              InsightsGender.checkService(insights);
            } else {
              commonWebsite.hideSpinner('gender-chart', 'showNoDataMsg');
              InsightsGender.clearData();
            }
          },
          function(reason) {
            commonWebsite.hideSpinner('gender-chart', 'showNoDataMsg');
            InsightsGender.clearData();
            $log.error('Error: website-gender-week');
            $log.error(reason);
          }
        );
    }

    /**
     * Day Gender Chart
     * @param {string} websiteId
     * @param {?string} endDate - Campaign End Date YYYY-MM-DD
     * @param {Object} variable
     */
    function dayChart(websiteId, companyUID, endDate, variable) {
      var yesterday = commonWebsite.formatEndDate();
      $http
        .post('/api/insights/website', {
          campaign: websiteId,
          companyUID: companyUID,
          endDate: endDate || yesterday,
          chart: 'gender',
          period: 'day',
          type: 'website',
          variable: variable
        })
        .then(
          function(result) {
            if (result.data.insights.length > 0) {
              var insights = result.data.insights;
              InsightsGender.checkService(insights);
            } else {
              commonWebsite.hideSpinner('gender-chart', 'showNoDataMsg');
              InsightsGender.clearData();
            }
          },
          function(reason) {
            commonWebsite.hideSpinner('gender-chart', 'showNoDataMsg');
            InsightsGender.clearData();
            $log.error('Error: website-gender-day');
            $log.error(reason);
          }
        );
    }

    /**
     * Resize chart
     */
    function resize() {
      InsightsGender.resize();
    }

    /**
     * Change Date
     * @param {string} websiteId
     * @param {Object} startEnd - Insight start and end dates
     * @param {string} period - Date period
     * @param {Object} variable
     */
    function changeDate(websiteId, startEnd, period, variable) {
      var companyUID = commonWebsite.getUID();
      if (period === 'month') {
        monthChart(websiteId, companyUID, startEnd.end, variable);
      } else if (period === 'week') {
        weekChart(websiteId, companyUID, startEnd.end, variable);
      } else if (period === 'day') {
        dayChart(websiteId, companyUID, startEnd.end, variable);
      }
    }
  }
})();
