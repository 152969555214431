(function() {
  'use strict';

  angular.module('websites').factory('websitesResize', websitesResize);

  websitesResize.$inject = [
    'helixChartsWebsite',
    'premiumChartsWebsite',
    'uniqueChartWebsite',
    'areaChartWebsite',
    'deviceChartWebsite',
    'localVsInternationalChartWebsite',
    'genderChartWebsite',
    'ageChartWebsite',
    'browserChartWebsite'
  ];
  function websitesResize(
    helixChartsWebsite,
    premiumChartsWebsite,
    uniqueChartWebsite,
    areaChartWebsite,
    deviceChartWebsite,
    localVsInternationalChartWebsite,
    genderChartWebsite,
    ageChartWebsite,
    browserChartWebsite
  ) {
    return {
      resize: resizeCharts
    };

    /**
     * Resize website charts
     * @param  {string} [selectedRange] Optional
     * @param  {string} [datePeriod]    Optional
     * @param  {string} [datePicker]    Optional
     */
    function resizeCharts(selectedRange, datePeriod, datePicker) {
      selectedRange = selectedRange || getDateRange();
      datePicker = datePicker || getDatePicker();
      datePeriod = datePeriod || getDatePeriod();
      helixChartsWebsite.resize();
      premiumChartsWebsite.resize();
      uniqueChartWebsite.resize(selectedRange, datePeriod, datePicker);
      areaChartWebsite.resize();
      deviceChartWebsite.resize();
      localVsInternationalChartWebsite.resize();
      browserChartWebsite.resize();
      genderChartWebsite.resize();
      ageChartWebsite.resize();
    }

    /**
     * Get currently selected date range
     * @returns {?string}
     */
    function getDateRange() {
      var activeDateRange = document
        .getElementById('date-range-picker')
        .getElementsByClassName('selected');
      if (activeDateRange) {
        return activeDateRange[0].innerHTML;
      }
      return null;
    }

    /**
     * Get currently selected date period
     * @return {string} 'all', 'month', 'week', 'day'
     */
    function getDatePeriod() {
      var period = document
        .querySelector('.date-period-picker')
        .querySelector('.active')
        .innerHTML.toLowerCase();
      if (period === 'total') {
        period = 'all';
      }

      return period;
    }

    /**
     * Gets currently selected Date Picker
     * @return {string} Date in format YYYY-MM-DD
     */
    function getDatePicker() {
      var date = angular.element(document.querySelector('#date-picker')).val();
      return shortDateFormat(new Date(date));
    }

    /**
     * Return date in short format (YYYY-MM-DD)
     * @param {Date} date
     * @returns {string} - formatted date
     */
    function shortDateFormat(date) {
      return (
        date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
      );
    }
  }
})();
