'use strict';

// Websites module config
angular.module('websites').run([
  'Menus',
  function(Menus) {
    // Config logic
    // ...
  }
]);
