(function() {
  'use strict';

  angular
    .module('campaigns')
    .factory('topWebsiteChartCampaign', topWebsiteChartCampaign);

  topWebsiteChartCampaign.$inject = [
    '$http',
    'commonCampaign',
    'InsightsTopWebsites',
    '$q',
    '$log',
    'downloads'
  ];

  function topWebsiteChartCampaign(
    $http,
    commonCampaign,
    InsightsTopWebsites,
    $q,
    $log,
    downloads
  ) {
    return {
      chart: chart,
      resize: resize,
      changeDate: changeDate,
      getData: getData
    };

    /**
     * Top Websites Chart based on date period
     * @param {string} period
     * @param {string} campaignId
     * @param {Object} startEnd - Insight start and end dates
     * @param {Object=} variable
     */
    function chart(period, campaignId, startEnd, variable) {
      var companyUID = commonCampaign.getUID();
      if (period === 'all') {
        allChart(campaignId, companyUID, startEnd.end, variable);
      } else if (period === 'month') {
        monthChart(campaignId, companyUID, startEnd.end, variable);
      } else if (period === 'week') {
        weekChart(campaignId, companyUID, startEnd.end, variable);
      } else if (period === 'day') {
        dayChart(campaignId, companyUID, startEnd.end, variable);
      }
    }

    /**
     * All Time Chart
     * @param {string} campaignId
     * @param {?string} endDate - Campaign End Date YYYY-MM-DD
     * @param {Object=} variable
     */
    function allChart(campaignId, companyUID, endDate, variable) {
      var yesterday = commonCampaign.formatEndDate();
      $http
        .post('/api/insights/campaign', {
          campaign: campaignId,
          companyUID: companyUID,
          endDate: endDate || yesterday,
          chart: 'top-website',
          period: 'all',
          type: 'campaign',
          variable: variable
        })
        .then(
          function(result) {
            if (result.data.insights.length > 0) {
              var insights = result.data.insights;
              InsightsTopWebsites.checkService(insights);
            } else {
              commonCampaign.hideSpinner('website-chart', 'showNoDataMsg');
            }
          },
          function(reason) {
            commonCampaign.hideSpinner('website-chart', 'showNoDataMsg');
            $log.error('Error: campaign-top-websites-all');
            $log.error(reason);
          }
        );
    }

    /**
     * Day Chart
     * @param {string} campaignId
     * @param {?string} endDate - Campaign End Date YYYY-MM-DD
     * @param {Object=} variable
     */
    function dayChart(campaignId, companyUID, endDate, variable) {
      var yesterday = commonCampaign.formatEndDate();

      $http
        .post('/api/insights/campaign', {
          campaign: campaignId,
          companyUID: companyUID,
          endDate: endDate || yesterday,
          chart: 'top-website',
          period: 'day',
          type: 'campaign',
          variable: variable
        })
        .then(
          function(result) {
            if (result.data.insights.length > 0) {
              var insights = result.data.insights;
              dateOrCreate(insights, moment(endDate || yesterday).toDate());
            } else {
              commonCampaign.hideSpinner('website-chart', 'showNoDataMsg');
            }
          },
          function(reason) {
            commonCampaign.hideSpinner('website-chart', 'showNoDataMsg');
            $log.error('Error: campaign-top-websites-day');
            $log.error(reason);
          }
        );
    }

    /**
     * Week Chart
     * @param {string} campaignId
     * @param {?string} endDate - Campaign End Date YYYY-MM-DD
     * @param {Object=} variable
     */
    function weekChart(campaignId, companyUID, endDate, variable) {
      var startOfWeek = moment(endDate || undefined)
        .startOf('isoWeek')
        .format('YYYY-MM-DD');

      $http
        .post('/api/insights/campaign', {
          campaign: campaignId,
          companyUID: companyUID,
          endDate: startOfWeek,
          chart: 'top-website',
          period: 'week',
          type: 'campaign',
          variable: variable
        })
        .then(
          function(result) {
            if (result.data.insights.length > 0) {
              var insights = result.data.insights;
              dateOrCreate(insights, moment(startOfWeek).toDate());
            } else {
              commonCampaign.hideSpinner('website-chart', 'showNoDataMsg');
            }
          },
          function(reason) {
            commonCampaign.hideSpinner('website-chart', 'showNoDataMsg');
            $log.error('Error: campaign-top-websites-week');
            $log.error(reason);
          }
        );
    }

    /**
     * Month Chart
     * @param {string} campaignId
     * @param {?string} endDate - Campaign End Date YYYY-MM-DD
     * @param {Object=} variable
     */
    function monthChart(campaignId, companyUID, endDate, variable) {
      var startOfMonth = moment(endDate || undefined)
        .startOf('month')
        .format('YYYY-MM-DD');

      $http
        .post('/api/insights/campaign', {
          campaign: campaignId,
          companyUID: companyUID,
          endDate: startOfMonth,
          chart: 'top-website',
          period: 'month',
          type: 'campaign',
          variable: variable
        })
        .then(
          function(result) {
            if (result.data.insights.length > 0) {
              var insights = result.data.insights;
              dateOrCreate(insights, moment(startOfMonth).toDate());
            } else {
              commonCampaign.hideSpinner('website-chart', 'showNoDataMsg');
            }
          },
          function(reason) {
            commonCampaign.hideSpinner('website-chart', 'showNoDataMsg');
            $log.error('Error: campaign-top-websites-month');
            $log.error(reason);
          }
        );
    }

    /**
     * Create chart or change date
     * @param {object[]} data
     * @param {Date} date
     */
    function dateOrCreate(data, date) {
      if (date) {
        InsightsTopWebsites.changeDate(data);
      } else {
        InsightsTopWebsites.checkService(data);
      }
    }

    /**
     * Resize chart
     */
    function resize() {
      InsightsTopWebsites.resize();
    }

    /**
     * Change Date
     * @param {string} campaignId
     * @param {string} period - Date period
     * @param {Object} startEnd - Insight start and end dates
     * @param {Object=} variable
     */
    function changeDate(campaignId, period, startEnd, variable) {
      var companyUID = commonCampaign.getUID();
      if (period === 'month') {
        monthChart(campaignId, companyUID, startEnd.end, variable);
      } else if (period === 'week') {
        weekChart(campaignId, companyUID, startEnd.end, variable);
      } else if (period === 'day') {
        dayChart(campaignId, companyUID, startEnd.end, variable);
      }
    }

    /**
     * Which data to download
     * @param {string} campaignId
     * @param {string} type
     * @param {Object} startEnd - Insight start and end dates
     * @param {Object=} variable
     * @returns {Promise} Promise
     */
    function getData(campaignId, type, startEnd, variable) {
      var companyUID = commonCampaign.getUID();
      if (type === 'day') {
        return getDataDay(campaignId, companyUID, startEnd.end, variable);
      }
      if (type === 'week') {
        return getDataWeek(campaignId, companyUID, startEnd.end, variable);
      }
      if (type === 'all') {
        return getDataAll(campaignId, companyUID, startEnd.end, variable);
      }
      return getDataMonth(campaignId, companyUID, startEnd.end, variable);
    }

    /**
     * Get Day Data for Download
     * @param {string} campaignId
     * @param {?string} endDate - Campaign End Date YYYY-MM-DD
     * @param {Object=} variable
     * @returns {Promise} Promise
     */
    function getDataDay(campaignId, companyUID, endDate, variable) {
      var yesterday = commonCampaign.formatEndDate();
      return $q(function(resolve, reject) {
        $http
          .post('/api/insights/campaign', {
            campaign: campaignId,
            companyUID: companyUID,
            endDate: endDate || yesterday,
            chart: 'top-website',
            period: 'day',
            type: 'campaign',
            variable: variable
          })
          .then(
            function(result) {
              resolve(
                formatData(result.data.insights, 'Date', endDate || yesterday)
              );
            },
            function(reason) {
              $log.error('Error: campaign-top-websites-data-day');
              $log.error(reason);
              reject();
            }
          );
      });
    }

    /**
     * Get All Data for Download
     * @param {string} campaignId
     * @param {?string} endDate - Campaign End Date YYYY-MM-DD
     * @param {Object=} variable
     * @returns {Promise} Promise
     */
    function getDataAll(campaignId, companyUID, endDate, variable) {
      var yesterday = commonCampaign.formatEndDate();
      return $q(function(resolve, reject) {
        $http
          .post('/api/insights/campaign', {
            campaign: campaignId,
            companyUID: companyUID,
            endDate: endDate || yesterday,
            chart: 'top-website',
            period: 'all',
            type: 'campaign',
            variable: variable
          })
          .then(
            function(result) {
              resolve(
                formatData(
                  result.data.insights,
                  'Period_End_Date',
                  endDate || yesterday
                )
              );
            },
            function(reason) {
              $log.error('Error: campaign-top-websites-data-day');
              $log.error(reason);
              reject();
            }
          );
      });
    }

    /**
     * Get Week Data for Download
     * @param {string} campaignId
     * @param {?string} endDate - Campaign End Date YYYY-MM-DD
     * @param {Object=} variable
     * @returns {Promise} Promise
     */
    function getDataWeek(campaignId, companyUID, endDate, variable) {
      var startOfWeek = moment(endDate || undefined)
        .startOf('isoWeek')
        .format('YYYY-MM-DD');
      return $q(function(resolve, reject) {
        $http
          .post('/api/insights/campaign', {
            campaign: campaignId,
            companyUID: companyUID,
            endDate: startOfWeek,
            chart: 'top-website',
            period: 'week',
            type: 'campaign',
            variable: variable
          })
          .then(
            function(result) {
              resolve(
                formatData(
                  result.data.insights,
                  'Start_Date_of_Week',
                  startOfWeek
                )
              );
            },
            function(reason) {
              $log.error('Error: campaign-top-websites-data-week');
              $log.error(reason);
              reject();
            }
          );
      });
    }

    /**
     * Get Month Data for Download
     * @param {string} campaignId
     * @param {?string} endDate - Campaign End Date YYYY-MM-DD
     * @param {Object=} variable
     * @returns {Promise} Promise
     */
    function getDataMonth(campaignId, companyUID, endDate, variable) {
      var startOfMonth = moment(endDate || undefined)
        .startOf('month')
        .format('YYYY-MM-DD');
      return $q(function(resolve, reject) {
        $http
          .post('/api/insights/campaign', {
            campaign: campaignId,
            companyUID: companyUID,
            endDate: startOfMonth,
            chart: 'top-website',
            period: 'month',
            type: 'campaign',
            variable: variable
          })
          .then(
            function(result) {
              resolve(
                formatData(
                  result.data.insights,
                  'Start_Date_of_Month',
                  startOfMonth
                )
              );
            },
            function(reason) {
              $log.error('Error: campaign-top-websites-data-month');
              $log.error(reason);
              reject();
            }
          );
      });
    }

    function formatData(rawData, dateHeading, date) {
      var data = InsightsTopWebsites.roundData(rawData);
      data = downloads.addHeading(data, dateHeading, date);
      data = downloads.renameHeading(data, 'website', 'Website');
      data = downloads.renameHeading(data, 'impressions', 'Impressions');
      data = downloads.renameHeading(data, 'clicks', 'Clicks');
      data = downloads.orderFields(data, [
        dateHeading,
        'Website',
        'UA',
        'Impressions',
        'Clicks'
      ]);

      return data;
    }
  }
})();
