'use strict';

// Init the application configuration module for AngularJS application
// eslint-disable-next-line no-unused-vars
var ApplicationConfiguration = (function() {
  // Init module configuration options
  var applicationModuleName = 'rmr-pixel';
  var applicationModuleVendorDependencies = [
    'ngResource',
    'ngCookies',
    'ngAnimate',
    'ngTouch',
    'ngSanitize',
    'ui.router',
    'ui.bootstrap',
    'ui.utils',
    'ui.select',
    'angularUtils.directives.dirPagination',
    'ngFileUpload',
    'angularSpinners',
    'angular-storage',
    'matchMedia',
    'ui-notification',
    'angular-confirm',
    'angulartics',
    'angulartics.google.tagmanager',
    'rmrLogin',
    'angularSpinner',
    'ngRaven'
  ];

  // Add a new vertical module
  var registerModule = function(moduleName, dependencies) {
    // Create angular module
    angular.module(moduleName, dependencies || []);

    // Add the module to the AngularJS configuration file
    angular.module(applicationModuleName).requires.push(moduleName);
  };

  return {
    applicationModuleName: applicationModuleName,
    applicationModuleVendorDependencies: applicationModuleVendorDependencies,
    registerModule: registerModule
  };
})();
