'use strict';

// Authentication service for user variables
angular.module('users').factory('Authentication', [
  '$injector',
  '$q',
  '$window',
  'store',
  'RmrAuthenticationService',
  '$cookies',
  '$filter',
  '$location',
  function(
    $injector,
    $q,
    $window,
    store,
    RmrAuthenticationService,
    $cookies,
    $filter,
    $location
  ) {
    var features = {
      CAMPAIGNS_READ: 42,
      CAMPAIGNS_WRITE: 2,
      CAMPAIGNS_ADMIN: 44,
      WEBSITES_READ: 43,
      WEBSITES_WRITE: 3,
      WEBSITES_ADMIN: 45,
      PREMIUM: 20
    };

    function getEnv() {
      var env;
      if (document.location.hostname === 'localhost') {
        env = 'dev';
      } else if (document.location.hostname === 'audiences.roymorganuat.com') {
        env = 'uat';
      } else {
        env = 'production';
      }

      return env;
    }

    /**
     * Get all companies and their teams with permissions
     */
    function getAllCompaniesAndTeams(defaultCompany) {
      var deferred = $q.defer();

      getActiveUsersOrganisations().then(function(organisations) {
        store.set('companies', organisations);

        // set current organisation
        var orgId = getCookie('currentOrganisationId');
        // if cookie doesnt exists the set the cookie for first organisation from the organisation array
        if (!orgId) {
          orgId = defaultCompany.id;
        }
        getOrgFromOrgObjByOrgId(parseInt(orgId)).then(function(currentOrg) {
          if (organisations.length && orgId) {
            getTeamsAndPermissions(currentOrg).then(function() {
              return deferred.resolve(true);
            });
          } else {
            return deferred.resolve(false);
          }
        });
      });

      return deferred.promise;
    }

    /**
     * Get Active User’s Accessible Organisations
     */
    function getActiveUsersOrganisations() {
      var deferred = $q.defer();
      var token = RmrAuthenticationService.getToken();
      $injector.invoke(function(AudiencesApi) {
        AudiencesApi.get('/organisations', {
          headers: {
            token: token
          }
        }).then(function(result, err) {
          if (err) {
            console.error(
              'There was an error getting active users organisations'
            );
            deferred.resolve(false);
          } else {
            deferred.resolve(result.data);
          }
        });
      });

      return deferred.promise;
    }

    /**
     * Get Active User's Organisation teams
     * @param {number} organisationId
     */
    function getActiveUsersOrganisationsTeams(organisationId) {
      var deferred = $q.defer();

      var userManagementURL = RmrAuthenticationService.getUserManagementUrl();
      var token = RmrAuthenticationService.getToken();
      $injector.invoke(function($http) {
        $http
          .get(
            userManagementURL +
              '/active-user/organisations/' +
              organisationId +
              '/teams',
            {
              headers: {
                token: token
              }
            }
          )
          .then(function(result, err) {
            if (err) {
              console.error(
                'There was an error getting active users organisations teams'
              );
              deferred.resolve(false);
            } else {
              deferred.resolve(result.data);
            }
          });
      });

      return deferred.promise;
    }

    /**
     * Get Active User's feature access in a team
     * @param {Object} defaultTeam
     */
    function getActiveUseFeatureAccessInTeam(defaultTeam) {
      var deferred = $q.defer();
      var userManagementURL = RmrAuthenticationService.getUserManagementUrl();
      var token = RmrAuthenticationService.getToken();
      $injector.invoke(function($http) {
        $http
          .get(
            userManagementURL +
              '/active-user/organisations/' +
              defaultTeam.organisationId +
              '/teams/' +
              defaultTeam.id +
              '/feature-access',
            {
              headers: {
                token: token
              }
            }
          )
          .then(function(result, err) {
            if (err) {
              console.error(
                'There was an error getting sctive users feature access in a team'
              );
              deferred.resolve(false);
            } else {
              deferred.resolve(result.data);
            }
          });
      });

      return deferred.promise;
    }

    /**
     * Get Active User's feature access permissions
     * @param {Number} organisationId
     */
    function getActiveUserFeatureAccessInOrg(organisationId) {
      var deferred = $q.defer();
      var userManagementURL = RmrAuthenticationService.getUserManagementUrl();
      var token = RmrAuthenticationService.getToken();
      $injector.invoke(function($http) {
        $http
          .get(
            userManagementURL +
              '/active-user/organisations/' +
              organisationId +
              '/feature-access',
            {
              headers: {
                token: token
              }
            }
          )
          .then(function(result, err) {
            if (err) {
              console.error(
                'There was an error getting active users feature access in organisations'
              );
              deferred.resolve(false);
            } else {
              deferred.resolve(result.data);
            }
          });
      });

      return deferred.promise;
    }

    /**
     * Convert feature access into permissions
     */
    function convertPermissionSetsToPermissions(permissionSet) {
      if (!permissionSet || !permissionSet.length) {
        console.error('Permission set not there');
        return;
      }

      var permissionsArr = [];

      permissionSet.forEach(function(featureInfo) {
        // if the product info is for campaigns and websites only
        if (featureInfo.productId === 2) {
          // check if the user is admin
          if (
            (featureInfo.id === features.CAMPAIGNS_ADMIN ||
              featureInfo.id === features.WEBSITES_ADMIN) &&
            featureInfo.hasAccess &&
            permissionsArr.indexOf('Admin') === -1
          ) {
            permissionsArr.push('Admin');
          }
          // check if the product family is CAMPAIGNS and user is admin or have write access
          if (
            (featureInfo.id === features.CAMPAIGNS_ADMIN ||
              featureInfo.id === features.CAMPAIGNS_WRITE) &&
            featureInfo.hasAccess &&
            permissionsArr.indexOf('Campaigns') === -1
          ) {
            permissionsArr.push('Campaigns');
          }
          // check if the product family is WEBSITES and user is admin or have write access
          if (
            (featureInfo.id === features.WEBSITES_ADMIN ||
              featureInfo.id === features.WEBSITES_WRITE) &&
            featureInfo.hasAccess &&
            permissionsArr.indexOf('Websites') === -1
          ) {
            permissionsArr.push('Websites');
          }
          // check if the product family is CAMPAIGNS and user is admin or have READ access
          if (
            featureInfo.id === features.CAMPAIGNS_READ &&
            featureInfo.hasAccess &&
            permissionsArr.indexOf('Campaigns (view only)') === -1
          ) {
            permissionsArr.push('Campaigns (view only)');
          }
          // check if the product family is WEBSITES and user is admin or have READ access
          if (
            featureInfo.id === features.WEBSITES_READ &&
            featureInfo.hasAccess &&
            permissionsArr.indexOf('Websites (view only)') === -1
          ) {
            permissionsArr.push('Websites (view only)');
          }
        }

        // check if the product family is "Premium" and user has access to
        // product
        if (featureInfo.id === features.PREMIUM && featureInfo.hasAccess) {
          permissionsArr.push('Premium');
        }
      });

      store.set('permissions', permissionsArr);
      return permissionsArr;
    }

    /**
     * Get all teams and logged in user permissions
     */
    function getTeamsAndPermissions(organisations) {
      var deferred = $q.defer();

      // set current organisations
      store.set('currentCompany', organisations);
      // setting cookie for cross product access
      setCookie('currentOrganisationId', organisations.id);

      getActiveUsersOrganisationsTeams(organisations.id).then(function(teams) {
        if (teams.length && teams[0]) {
          // set list of teams
          store.set('teams', teams);

          // get current team
          var teamId = getCookie('currentTeamId');

          // if cookie doesnt exists then set the cookie for first team from the team array
          if (!teamId) {
            teamId = teams[0].id;
          }

          getTeamFromTeamObjByTeamId(parseInt(teamId)).then(function(
            currentTeam
          ) {
            getActiveUserNineDotMenuProductAccess(
              organisations.id,
              currentTeam.id
            ).then(function(productsAccess) {
              store.set('productsAccess', productsAccess);

              if (currentTeam) {
                updateStorageValue('currentTeam', currentTeam);
              } else {
                store.remove('currentTeam');
                setCookie('currentTeamId', '');
              }

              // get user permissions
              getActiveUseFeatureAccessInTeam(currentTeam).then(function(
                permissions
              ) {
                if (permissions.length && permissions) {
                  store.set('collections', {});
                  store.set('permissionSet', permissions);
                  var permissionList = convertPermissionSetsToPermissions(
                    permissions
                  );
                  getCollections(
                    organisations.id,
                    currentTeam.id,
                    permissionList
                  ).then(function() {
                    return deferred.resolve(true);
                  });
                } else {
                  store.remove('permissionSet');
                  store.remove('permission');
                  store.remove('collections');
                  console.error('Looks like you do not have permissions.');
                  return deferred.resolve(false);
                }
              });
            });
          });
        } else {
          // company without teams
          store.remove('teams');
          store.remove('currentTeam');
          setCookie('currentTeamId', '');

          getActiveUserNineDotMenuProductAccess(organisations.id, '').then(
            function(productsAccess) {
              store.set('productsAccess', productsAccess);

              // get user permissions
              getActiveUserFeatureAccessInOrg(organisations.id).then(function(
                permissions
              ) {
                if (permissions.length && permissions) {
                  store.set('collections', {});
                  store.set('permissionSet', permissions);
                  var permissionList = convertPermissionSetsToPermissions(
                    permissions
                  );
                  getCollections(organisations.id, '', permissionList).then(
                    function() {
                      return deferred.resolve(true);
                    }
                  );
                } else {
                  store.remove('permissionSet');
                  store.remove('permission');
                  store.remove('collections');
                  console.error('Looks like you do not have permissions.');
                  return deferred.resolve(false);
                }
              });
            }
          );
        }
      });
      return deferred.promise;
    }

    function removeDataFromStorage() {
      store.remove('client');
      store.remove('permissions');
      store.remove('permissionSet');
      store.remove('companies');
      store.remove('currentTeam');
      store.remove('currentCompany');
      store.remove('teams');
      store.remove('collections');
      store.remove('productsAccess');
      store.remove('helixVersion');
    }

    function removeCookies() {
      setCookie('currentTeamId', '');
      setCookie('currentOrganisationId', '');
    }

    function getCollections(organisationId, teamId, permissions) {
      return $q(function(resolve, reject) {
        var promises = [];
        var featuresIds = getCollectionFeatures(permissions);
        featuresIds.forEach(function(featureId) {
          var promise;
          if (teamId) {
            promise = getTeamCollection(organisationId, teamId, featureId);
          } else {
            promise = getCollection(organisationId, featureId);
          }
          promises.push(promise);
        });
        return resolve($q.all(promises));
      });
    }

    function getCollectionFeatures(permissions) {
      var collectionFeatures = [];

      if (
        permissions.indexOf('Campaigns (view only)') >= 0 ||
        permissions.indexOf('Campaigns') >= 0
      ) {
        collectionFeatures.push(features.CAMPAIGNS_WRITE);
      }

      if (
        permissions.indexOf('Websites (view only)') >= 0 ||
        permissions.indexOf('Websites') >= 0
      ) {
        collectionFeatures.push(features.WEBSITES_WRITE);
      }

      return collectionFeatures;
    }

    function getTeamCollection(organisationId, teamId, featureId) {
      var deferred = $q.defer();
      var userManagementURL = RmrAuthenticationService.getUserManagementUrl();
      var token = RmrAuthenticationService.getToken();
      $injector.invoke(function($http) {
        $http
          .get(
            userManagementURL +
              '/organisations/' +
              organisationId +
              '/teams/' +
              teamId +
              '/features/' +
              featureId +
              '/collections?excludeArchived=true',
            {
              headers: {
                token: token
              }
            }
          )
          .then(function(result, err) {
            if (err) {
              console.error('Error getting collections');
              deferred.resolve(false);
            } else {
              var collections = store.get('collections');
              collections[featureId] = result.data;
              store.set('collections', collections);
              deferred.resolve(true);
            }
          })
          .catch(function(err) {
            if (
              err.status === 403 &&
              $location.url() !== '/no-access' &&
              store.get('permissions').length === 0
            ) {
              $window.location.href = '/no-access';
            }
            deferred.resolve(false);
          });
      });

      return deferred.promise;
    }

    function getCollection(organisationId, featureId) {
      var deferred = $q.defer();
      var userManagementURL = RmrAuthenticationService.getUserManagementUrl();
      var token = RmrAuthenticationService.getToken();
      $injector.invoke(function($http) {
        $http
          .get(
            userManagementURL +
              '/organisations/' +
              organisationId +
              '/features/' +
              featureId +
              '/collections?excludeArchived=true',
            {
              headers: {
                token: token
              }
            }
          )
          .then(function(result, err) {
            if (err) {
              console.error('Error getting collections');
              deferred.resolve(false);
            } else {
              var collections = store.get('collections');
              collections[featureId] = result.data;
              store.set('collections', collections);
              deferred.resolve(true);
            }
          })
          .catch(function(err) {
            if (
              err.status === 403 &&
              $location.url() !== '/no-access' &&
              store.get('permissions').length === 0
            ) {
              $window.location.href = '/no-access';
            }
            deferred.resolve(false);
          });
      });

      return deferred.promise;
    }

    /**
     * Get Active User's product access - NINE dot menu
     * @param {Integer} organisationId
     * @param {Integer} teamId
     */
    function getActiveUserNineDotMenuProductAccess(organisationId, teamId) {
      var deferred = $q.defer();
      var userManagementURL = RmrAuthenticationService.getUserManagementUrl();
      var token = RmrAuthenticationService.getToken();

      if (!organisationId && !teamId) {
        console.error(
          'Organisation id and team id both are missing to get product family access'
        );
        deferred.resolve(false);
      }

      // if there's team exists
      if (teamId) {
        $injector.invoke(function($http) {
          $http
            .get(
              userManagementURL +
                '/active-user/organisations/' +
                organisationId +
                '/teams/' +
                teamId +
                '/product-access',
              {
                headers: {
                  token: token
                }
              }
            )
            .then(function(result, err) {
              if (err) {
                console.error(
                  'There was an error getting active users product family access in a team'
                );
                deferred.resolve(false);
              } else {
                deferred.resolve(result.data);
              }
            });
        });
      } else {
        $injector.invoke(function($http) {
          $http
            .get(
              userManagementURL +
                '/active-user/organisations/' +
                organisationId +
                '/product-access',
              {
                headers: {
                  token: token
                }
              }
            )
            .then(function(result, err) {
              if (err) {
                console.error(
                  'There was an error getting active users product family access'
                );
                deferred.resolve(false);
              } else {
                deferred.resolve(result.data);
              }
            });
        });
      }
      return deferred.promise;
    }

    /**
     * get single organisation from organisation array by organisation id
     * @PARAM orgId (integer)
     */
    function getOrgFromOrgObjByOrgId(orgId) {
      var deferred = $q.defer();
      var companiesObj = store.get('companies');
      if (companiesObj.length && orgId) {
        var selectedOrg = '';
        selectedOrg = $filter('filter')(
          companiesObj,
          {
            id: orgId
          },
          true
        )[0];
        deferred.resolve(selectedOrg);
      } else {
        console.error(
          'Company array doesnt exists or organisation id doesnt come through'
        );
        deferred.resolve(false);
      }
      return deferred.promise;
    }

    /**
     * get single team from teams array by team id
     * @PARAM teamId (integer)
     */
    function getTeamFromTeamObjByTeamId(teamId) {
      var deferred = $q.defer();
      var teamsObj = store.get('teams');
      if (teamsObj.length && teamId) {
        var selectedTeam = '';
        selectedTeam = $filter('filter')(
          teamsObj,
          {
            id: teamId
          },
          true
        )[0];
        //  if selected team doesnot found in the array then select the first value
        if (!selectedTeam || selectedTeam === undefined) {
          selectedTeam = teamsObj[0];
        }
        deferred.resolve(selectedTeam);
      } else {
        console.error(
          'Team array doesnt exists or team id doesnt come through'
        );
        deferred.resolve(false);
      }
      return deferred.promise;
    }

    /**
     * Get cookie value by its name
     * @param {string} cname
     */
    function getCookie(cname) {
      var name = cname + '=';
      var ca = document.cookie.split(';');
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1);
        if (c.indexOf(name) === 0) return c.substring(name.length, c.length);
      }
      return '';
    }

    /**
     * Set cookie value by its name
     * @param {string} cname
     * @param value
     */
    function setCookie(cname, value) {
      // setting cookie for cross product access
      var d = new Date();
      d.setTime(d.getTime() + 8 * 60 * 60 * 1000);
      //  set cookie cross domain
      RmrAuthenticationService.setCookie(cname, value, d);
    }

    /**
     * Update storage value here
     * @param {string} storageKey
     * @param {*} storageValue
     */

    function updateStorageValue(storageKey, storageValue) {
      if (storageKey === 'currentCompany') {
        setCookie('currentOrganisationId', storageValue.id);
      }
      if (storageKey === 'currentTeam') {
        setCookie('currentTeamId', storageValue.id);
      }
      return store.set(storageKey, storageValue);
    }

    /**
     * Get Active User's organisations with a product access too
     * @param {Number} productId
     */
    function getActiveUserAccessedOrg(productId) {
      var deferred = $q.defer();
      var userManagementURL = RmrAuthenticationService.getUserManagementUrl();
      var token = RmrAuthenticationService.getToken();
      $injector.invoke(function($http) {
        $http
          .get(
            userManagementURL +
              '/active-user/products/' +
              productId +
              '/organisations',
            {
              headers: {
                token: token
              }
            }
          )
          .then(function(result, err) {
            if (err) {
              console.error(
                'There was an error getting active Users organisations with a product access too '
              );
              deferred.resolve(false);
            } else {
              deferred.resolve(result.data);
            }
          });
      });

      return deferred.promise;
    }

    /**
     * Get Active User's teams with current product and organisation id
     * @param {Number} productId
     * @param {Number} organisationId
     */
    function getActiveUserAccessibleTeams(productId, organisationId) {
      var deferred = $q.defer();
      var userManagementURL = RmrAuthenticationService.getUserManagementUrl();
      var token = RmrAuthenticationService.getToken();
      $injector.invoke(function($http) {
        $http
          .get(
            userManagementURL +
              '/active-user/products/' +
              productId +
              '/organisations/' +
              organisationId +
              '/teams',
            {
              headers: {
                token: token
              }
            }
          )
          .then(function(result, err) {
            if (err) {
              console.error(
                'There was an error getting active Users organisations with a product access too '
              );
              deferred.resolve(false);
            } else {
              deferred.resolve(result.data);
            }
          });
      });

      return deferred.promise;
    }

    /**
     * Get SSO Cayzu link with current product
     * @param {Number} productId
     */
    function getSSOCayzuLink(productId) {
      var deferred = $q.defer();
      var userManagementURL = RmrAuthenticationService.getUserManagementUrl();
      var token = RmrAuthenticationService.getToken();
      $injector.invoke(function($http) {
        $http
          .get(userManagementURL + '/sso/' + productId + '/cayzu', {
            headers: {
              token: token
            }
          })
          .then(function(result, err) {
            if (err) {
              console.error('There was an error getting SSO cayzu link');
              deferred.resolve(false);
            } else {
              deferred.resolve(result.data);
            }
          });
      });

      return deferred.promise;
    }

    return {
      isLoggedIn: function() {
        return RmrAuthenticationService.isLoggedIn();
      },

      getValidToken: function() {
        return RmrAuthenticationService.getValidToken();
      },

      storeClientInfo: function() {
        var deferred = $q.defer();
        var clientInfo = {};

        var client = store.get('client');

        RmrAuthenticationService.decodeToken().then(
          function(result) {
            if (client && client.userName === result.content.email) {
              deferred.resolve(true);
              return deferred.promise;
            }

            clientInfo.isInternalUser = result.content.isInternalUser;

            var token = RmrAuthenticationService.getToken();
            var userManagementURL = RmrAuthenticationService.getUserManagementUrl();
            var config = {};

            if (token) {
              config = {
                headers: {
                  token: token
                }
              };

              $injector.invoke(function($http) {
                $http
                  .get(userManagementURL + '/active-user', config)
                  .then(function(result, err) {
                    if (err) {
                      console.error('There was an error getting client info');
                    } else {
                      clientInfo.userName = result.data.email;
                      clientInfo.clientName = result.data.name;
                      var myOrganisationId = result.data.myOrganisationId;
                      $http
                        .get(
                          userManagementURL + '/active-user/organisations/',
                          config
                        )
                        .then(function(result, err) {
                          if (err) {
                            console.error(
                              'There was an error getting all companies and teams'
                            );
                          } else {
                            // TODO: need to refine this organisation and records as we are getting all the results from the above API
                            var idx = result.data
                              .map(function(d) {
                                return d.id;
                              })
                              .indexOf(myOrganisationId);
                            var defaultCompany = result.data[idx];
                            clientInfo.company = defaultCompany.name;
                            clientInfo.company_id = defaultCompany.id;
                            store.set('client', clientInfo);
                            getAllCompaniesAndTeams(defaultCompany).then(
                              function(success) {
                                if (success) {
                                  // user is logged in
                                  return deferred.resolve(true);
                                }
                                // issue with login
                                return deferred.resolve(false);
                              }
                            );
                          }
                        });
                    }
                  });
              });
            }
          },
          function(err) {
            deferred.reject(err);
          }
        );
        return deferred.promise;
      },

      getActiveUserAccessedOrg: function(productId) {
        return getActiveUserAccessedOrg(productId);
      },

      getActiveUserAccessibleTeams: function(productId, orgId) {
        return getActiveUserAccessibleTeams(productId, orgId);
      },

      getSSOCayzuLink: function(productId) {
        return getSSOCayzuLink(productId);
      },

      logout: function() {
        removeDataFromStorage();
        removeCookies();
        RmrAuthenticationService.logout();
      },

      /**
       * Get Env
       * @return {string}
       */
      getEnv: function() {
        return getEnv();
      },

      getTeamsAndPermissions: function(organisation) {
        return getTeamsAndPermissions(organisation);
      },

      updateStorageValue: function(key, value) {
        return updateStorageValue(key, value);
      },

      getOrgFromOrgObjByOrgId: function(orgId) {
        return RmrAuthenticationService.getOrgFromOrgObjByOrgId(orgId);
      },

      setCookie: function(cname, value) {
        return setCookie(cname, value);
      },

      getActiveUseFeatureAccessInTeam: function(team) {
        return getActiveUseFeatureAccessInTeam(team);
      },

      getActiveUsersOrganisationsTeams: function(organisationId) {
        return getActiveUsersOrganisationsTeams(organisationId);
      },

      convertPermissionSetsToPermissions: function(permissions) {
        return convertPermissionSetsToPermissions(permissions);
      },

      getCollections: function(organisationId, teamId, permissions) {
        return getCollections(organisationId, teamId, permissions);
      },

      getActiveUserFeatureAccessInOrg: function(organisationId) {
        return getActiveUserFeatureAccessInOrg(organisationId);
      },

      getCookie: function(cname) {
        return getCookie(cname);
      },

      getActiveUserNineDotMenuProductAccess: function(organisationId, teamId) {
        return getActiveUserNineDotMenuProductAccess(organisationId, teamId);
      },
      removeSwitchFromCookies: function() {
        RmrAuthenticationService.removeCookie('switchFromToken');
        RmrAuthenticationService.removeCookie('switchFromEmail');
      }
    };
  }
]);
