(function() {
  'use strict';

  angular
    .module('campaigns')
    .controller('CampaignsPixelsController', CampaignsPixelsController);

  CampaignsPixelsController.$inject = [
    '$rootScope',
    '$stateParams',
    'Pixels',
    '$http',
    '$uibModal',
    'Campaigns',
    '$location',
    'Authorization',
    'Authentication',
    'Settings',
    '$q',
    'commonCampaign',
    'store',
    '$resource',
    '$window',
    '$document',
    '$log'
  ];
  function CampaignsPixelsController(
    $rootScope,
    $stateParams,
    Pixels,
    $http,
    $uibModal,
    Campaigns,
    $location,
    Authorization,
    Authentication,
    Settings,
    $q,
    commonCampaign,
    store,
    $resource,
    $window,
    $document,
    $log
  ) {
    var vm = this;
    Authorization.authorize().then(function() {
      vm.user = store.get('client');
      vm.permissionSet = store.get('permissionSet');
      vm.currentCompany = store.get('currentCompany');
      vm.companies = store.get('companies');
    });

    Settings.checkNotification();

    vm.archive = '';
    vm.orderByField = 'pixelNum';
    $rootScope.title = 'Campaign Evaluation';

    var endDate;
    var startDate;
    var insightName;
    var endDateMin;
    var startDateMax;

    Campaigns.get({
      id: $stateParams.campaignId
    }).$promise.then(function(result) {
      vm.campaign = result;
      endDate = moment(vm.campaign.endDate);
      startDate = moment(vm.campaign.startDate);
      startDateMax = moment(vm.campaign.endDate).subtract(1, 'days');
      endDateMin = moment(vm.campaign.startDate).add(1, 'days');
      insightName = vm.campaign.name;
      vm.startDateMax = startDateMax.toDate();
      vm.endDateMin = endDateMin.toDate();

      vm.pixels = Pixels.query(
        {
          campaignId: $stateParams.campaignId
        },
        function() {
          vm.pixels.forEach(function(pixel) {
            pixel.impressionsURL = generatePixelURL(pixel, vm.campaign, false);
            pixel.clicksURL = generatePixelURL(pixel, vm.campaign, true);
            getPixelImpressions(pixel, vm.campaign).then(function(res) {
              if (res) {
                pixel.impressions = res;
              } else {
                pixel.impressions = 0;
              }
            });
          });
        }
      );
    });

    vm.campaigns = Campaigns.query();
    vm.adFormat = {};
    vm.loadFormats = loadFormats;
    vm.open = openUploadModal;
    vm.toggleArchiveFilter = toggleArchiveFilter;
    vm.createPixel = createPixel;
    vm.checkURL = checkURL;
    vm.expandPixelRow = expandPixelRow;
    vm.copyUrl = copyUrl;
    vm.archivePixel = archivePixel;
    vm.archiveInsight = archiveInsight;
    vm.activateInsight = activateInsight;
    vm.toggleCampaignCaret = toggleCampaignCaret;
    vm.editorEnabled = false;
    vm.startDateEditorEnabled = false;
    vm.changeEndDate = changeEndDate;
    vm.changeStartDate = changeStartDate;
    vm.downloadPixels = downloadPixels;
    vm.nameEdit = false;
    vm.updateName = updateName;
    vm.showNewPixel = false;

    vm.isDynamic = false;
    vm.resetClickthrough = function() {
      if (vm.isDynamic) {
        vm.errorClass = null;
        vm.urlErrorMsg = null;
        vm.invalidURL = null;
        vm.validURL = null;
        vm.processingURL = null;
        vm.processing = null;
        if (vm.pixel) {
          vm.pixel.clickthroughUrl = null;
        }
      }
    };

    vm.dateOptions = {
      formatYear: 'yy',
      startingDay: 1
    };
    vm.formats = [
      'dd-MMMM-yyyy',
      'yyyy/MM/dd',
      'dd.MM.yyyy',
      'shortDate',
      'dd MMM yyyy'
    ];
    vm.format = vm.formats[0];
    vm.toggleMin = toggleMin;
    vm.startopen = startopen;
    vm.startDateOpen = startDateOpen;

    function validatePixel() {
      return vm.errorClass !== 'fail';
    }

    function createPixel() {
      if (!validatePixel()) {
        vm.error = 'Pixel is not valid.';
        return;
      }

      vm.processing = true;
      var spinner = $document[0].getElementById('create-spinner-button');
      spinner.classList.remove('hidden');

      // Create new Pixel object
      var adFormat = null;
      var creativeName = null;
      var placementName = null;
      var clickthroughURL = null;
      var isDynamic = false;

      // Set Ad Format
      if (vm.pixel && vm.pixel.adFormat && vm.pixel.adFormat.id) {
        adFormat = vm.pixel.adFormat.id['Ad Format']['Ad Format'];
      }

      // Set Creative Name
      if (vm.pixel && vm.pixel.creativeName) {
        creativeName = vm.pixel.creativeName.toUpperCase();
      }

      // Set Placement Name
      if (vm.pixel && vm.pixel.placementName) {
        placementName = vm.pixel.placementName.toUpperCase();
      }

      // Set Clickthrough URL
      if (vm.pixel && vm.pixel.clickthroughUrl) {
        clickthroughURL = vm.pixel.clickthroughUrl;
      }

      if (vm.pixel && vm.isDynamic) {
        isDynamic = true;
      }

      var pixel = new Pixels({
        creativeName: creativeName,
        adFormat: adFormat,
        placementName: placementName,
        clickthroughUrl: clickthroughURL,
        campaignId: $stateParams.campaignId,
        isDynamic: isDynamic
      });

      // Redirect after save
      pixel.$save(
        function(response) {
          // $location.path('/campaigns/' + $stateParams.campaignId + '/pixels'); ??  ToDo: check why this was here, ok to remove?

          // Clear form fields
          vm.pixels.push(
            Pixels.get(
              {
                campaignId: $stateParams.campaignId,
                pixelId: response.id
              },
              function(pixel) {
                pixel.impressionsURL = generatePixelURL(
                  pixel,
                  vm.campaign,
                  false
                );
                pixel.clicksURL = generatePixelURL(pixel, vm.campaign, true);
              }
            )
          );

          if (vm.pixel) {
            vm.pixel.creativeName = '';
            vm.pixel.adFormat = '';
            vm.pixel.placementName = '';
            vm.pixel.clickthroughUrl = '';
          }

          vm.processing = false;
          spinner.className += ' hidden';
          vm.showNewPixel = false;
          vm.isDynamic = false;
        },
        function(errorResponse) {
          vm.processing = false;
          spinner.className += ' hidden';
          $log.error('error' + errorResponse.data.message);
          vm.error = errorResponse.data.errors[0].message;
        }
      );
    }

    function loadFormats() {
      return $http.get('/static-data/ad_formats.json').then(function(response) {
        vm.adFormats = response.data;
      });
    }

    function openUploadModal(size) {
      var modalInstance = $uibModal.open({
        animation: true,
        templateUrl: 'bulkPixelUploader.html',
        controller: 'bulkPixelModalCtrl',
        size: size,
        resolve: {
          pixels: function() {
            return vm.pixels;
          },

          campaignId: function() {
            return $stateParams.campaignId;
          }
        }
      });

      modalInstance.result.then(function(pixels) {
        pixels.forEach(function(pixel) {
          pixel.impressionsURL = generatePixelURL(pixel, vm.campaign, false);
          pixel.clicksURL = generatePixelURL(pixel, vm.campaign, true);
        });

        vm.pixels = pixels;
      });
    }

    function toggleArchiveFilter() {
      if (vm.archive === '') {
        vm.archive = 'Active';
      } else {
        vm.archive = '';
      }
    }

    function checkURL(url) {
      if (vm.isDynamic) {
        return;
      }
      vm.invalidURL = false;
      vm.validURL = false;
      vm.processingURL = true;
      vm.processing = true;
      vm.urlErrorMsg = 'Validating URL...';
      vm.errorClass = 'text-info';

      if (url) {
        $http
          .post('/api/pixels/validate-url', { url: url })
          .then(function(result) {
            if (!vm.isDynamic) {
              if (result.data) {
                vm.errorClass = 'text-success';
                vm.urlErrorMsg = 'The entered URL can be loaded';
                vm.processingURL = false;
                vm.validURL = true;
                vm.processing = false;
              } else {
                vm.processingURL = false;
                vm.errorClass = 'text-danger';
                vm.urlErrorMsg = 'The entered URL can not be loaded';
                vm.invalidURL = true;
              }
            }
          });
      } else {
        vm.errorClass = 'success';
        vm.urlErrorMsg = '';
        vm.processingURL = false;
        vm.processing = false;
      }
    }

    function expandPixelRow($event) {
      if (!vm.hasWritePermission()) {
        return false;
      }
      if (
        angular.element($event.target).hasClass('pixel-row') ||
        angular.element($event.target).hasClass('pixel-details') ||
        angular.element($event.target).hasClass('list-group-item') ||
        angular.element($event.target).hasClass('hide-details')
      ) {
        angular.element($event.currentTarget).toggleClass('edit-pixel');
      }
    }

    function archivePixel(pixel) {
      pixel.isArchived = !pixel.isArchived;

      Pixels.update(
        {
          campaignId: $stateParams.campaignId,
          pixelId: pixel.id
        },
        pixel,
        function(result) {
          pixel.status = result.status;
          $log.log(result);
        },
        function(errorResponse) {
          vm.error = errorResponse.data.errors[0].message;
        }
      );
    }

    // Archive Campaign
    function archiveInsight() {
      var campaign = vm.campaign;

      Campaigns.update(
        {
          id: campaign.id
        },
        {
          isArchived: true
        },
        function(result) {
          campaign.status = result.status;
          $http.post(
            '/api/pixels/logevents',
            JSON.stringify({
              action: 'Pixel archived',
              dataString: campaign.id.toString()
            })
          );
          $log.log(result);
        },
        function(errorResponse) {
          vm.error = errorResponse.data.errors[0].message;
        }
      );
    }

    // Activate Campaign
    function activateInsight() {
      var campaign = vm.campaign;

      Campaigns.update(
        {
          id: campaign.id
        },
        {
          isArchived: false
        },
        function(result) {
          $log.log(result);
        },
        function(errorResponse) {
          vm.error = errorResponse.data.errors[0].message;
        }
      );
    }

    // Change End Date
    function changeEndDate() {
      var campaign = vm.campaign;
      var newEndDate = moment(campaign.endDate);

      if (commonCampaign.isSameDay(endDate, newEndDate)) {
        return;
      }

      Campaigns.update(
        {
          id: campaign.id
        },
        {
          endDate: campaign.endDate
        },
        function(result) {
          endDate = moment(result.endDate);
          startDateMax = moment(result.endDate).subtract(1, 'days');
          vm.startDateMax = startDateMax.toDate();
        },
        function(errorResponse) {
          $log.error(errorResponse.data.errors[0].message);
          vm.error = errorResponse.data.errors[0].message;
        }
      );
    }

    // Change Start Date
    function changeStartDate() {
      var campaign = vm.campaign;
      var newStartDate = moment(campaign.startDate);

      if (commonCampaign.isSameDay(startDate, newStartDate)) {
        return;
      }

      Campaigns.update(
        {
          id: campaign.id
        },
        {
          startDate: campaign.startDate
        },
        function(result) {
          startDate = moment(result.startDate);
          endDateMin = moment(vm.campaign.startDate).add(1, 'days');
          vm.endDateMin = endDateMin.toDate();
        },
        function(errorResponse) {
          $log.error(errorResponse.data.errors[0].message);
          vm.error = errorResponse.data.errors[0].message;
        }
      );
    }

    function toggleCampaignCaret() {
      var myEl = angular.element($document[0].querySelector('#campaign-caret'));
      if (myEl.hasClass('fa-caret-down')) {
        myEl.removeClass('fa-caret-down');
        myEl.addClass('fa-caret-up');
      } else {
        myEl.addClass('fa-caret-down');
        myEl.removeClass('fa-caret-up');
      }
    }

    /**
     * Generate Pixel Impression URL and Clickthrough URL
     * @param {Object} pixel Pixel Resource
     * @param {Object} campaign Campaign Resource
     * @param {boolean} clicks
     * @returns {string}
     */
    function generatePixelURL(pixel, campaign, clicks) {
      var env = Authentication.getEnv();
      var url;
      if (env === 'production') {
        url = 'https://pixel.roymorgan.com/stats_v2/Tress.php?';
      } else {
        url = 'https://pixel.roymorganuat.com/stats_v2/Tress.php?';
      }

      url += 'u=' + commonCampaign.getUID();
      url += '&';
      url += 'ca=' + campaign.id;
      url += '&';
      url += 'a=' + campaign.randAdvertiserId;
      if (pixel.creativeId) {
        url += '&';
        url += 'cr=' + pixel.creativeId;
      }

      if (pixel.placementId) {
        url += '&';
        url += 'pl=' + pixel.placementId;
      }

      if (pixel.adFormatId) {
        url += '&';
        url += 'af=' + pixel.adFormatId;
      }

      if (clicks) {
        url += '&';
        url += 'r=';
        if (pixel.encodedUrl) {
          url += pixel.encodedUrl;
        }
      }
      return url;
    }

    function copyUrl(id) {
      var copyTextArea = document.querySelector(id);
      copyTextArea.select();

      try {
        var successful = document.execCommand('copy');
        document.getSelection().removeAllRanges();
      } catch (err) {
        $log.log(err);
        // eslint-disable-next-line no-alert
        window.prompt('Copy to clipboard: Ctrl+C', copyTextArea.value);
      }
    }

    function toggleMin() {
      vm.minDate = new Date();
    }

    vm.toggleMin();

    function endopen($event) {
      $event.preventDefault();
      $event.stopPropagation();

      vm.endOpened = true;
    }

    function startopen($event) {
      $event.preventDefault();
      $event.stopPropagation();

      vm.startOpened = true;
    }

    function startDateOpen($event) {
      $event.preventDefault();
      $event.stopPropagation();

      vm.startDateOpened = true;
    }

    /**
     * Return date in short format (DD/MM/YYYY)
     * @param {Date} date
     * @returns {string} - formatted date
     */
    function shortDateFormat(date) {
      return (
        date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear()
      );
    }

    /**
     * Replace all instances in string
     * @param {string} target - target string to be replaced
     * @param {string} replacement - string to replace target with
     * @returns {string} - original string with replacements
     */
    String.prototype.replaceAll = function(target, replacement) {
      return this.split(target).join(replacement);
    };

    /**
     * Download Pixels as a CSV
     */
    function downloadPixels() {
      // noinspection JSClosureCompilerSyntax
      var zip = new JSZip();
      var date = shortDateFormat(new Date())
        .replaceAll('/', '-')
        .replaceAll(':', '-')
        .replaceAll(',', '-')
        .replaceAll(' ', '');
      $log.log(vm.pixels);
      var pixels = formatDownloadData(vm.pixels);
      $log.log(pixels);
      zip.file(
        vm.campaign.name.replaceAll(' ', '-') + '-pixels-' + date + '.csv',
        convertToCSV(JSON.stringify(pixels))
      );
      var content = zip.generate({ type: 'blob' });
      saveAs(content, 'download.zip');
    }

    /**
     * Format Download data
     * @param {Array} dataArray
     * @returns {Array}
     */
    function formatDownloadData(dataArray) {
      var newData = [];
      angular.forEach(dataArray, function(data) {
        var newObject = {};
        newObject.pixelId = data.pixelNum;
        newObject.status = data.status;
        newObject.dateCreated = new Date(data.dateCreated).toString();
        newObject.creativeName = data.creativeName ? data.creativeName : null;
        newObject.adFormat = data.adFormat ? data.adFormat : null;
        newObject.placementName = data.placementName
          ? data.placementName
          : null;
        newObject.impressionsURL = data.impressionsURL
          ? data.impressionsURL
          : null;
        newObject.clicksURL = data.clicksURL ? data.clicksURL : null;

        newData.push(newObject);
      });

      return newData;
    }

    /**
     * Convert object to CSV
     * @param {string} objArray - JSON string of object or array
     * @returns {string}
     */
    function convertToCSV(objArray) {
      var array =
        typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
      var str = '';
      var line = '';

      // Create CSV Header
      if (array.length > 0) {
        line = '';
        for (var index in array[0]) {
          if (index !== 'v') {
            if (line !== '') line += ',';

            line += index;
          }
        }

        str += line + '\r\n';
      }

      for (var i = 0; i < array.length; i++) {
        line = '';
        for (var j in array[i]) {
          if (j !== 'v') {
            if (line !== '') line += ',';
            if (array[i]) {
              if (array[i].hasOwnProperty(j) && array[i][j]) {
                line += array[i][j].toString().replace(',', '');
              }
            }
          }
        }

        str += line + '\r\n';
      }

      return str;
    }

    /**
     * Get impression count for current pixel
     * @param {Object} pixel
     * @param {Object} insight
     * @returns {Promise<*>} Promise
     */
    function getPixelImpressions(pixel, insight) {
      var companyUID = commonCampaign.getUID();
      return $q(function(resolve, reject) {
        $http
          .post('/api/pixels/impression-count', {
            pixel: pixel,
            insight: insight,
            companyUID: companyUID
          })
          .then(
            function(result) {
              if (result.data) {
                var impressions = result.data.impressions[0];
                var local = parseInt(
                  Number(impressions.impressions_local).toString(),
                  10
                );
                var int = parseInt(
                  Number(impressions.impressions_int).toString(),
                  10
                );
                var total = local + int;
                resolve(total);
              } else {
                resolve(false);
              }
            },
            function(reason) {
              $log.log('ERROR: ', reason.data);
              reject();
            }
          );
      });
    }

    function updateName(newName) {
      var campaign = vm.campaign;

      if (newName !== insightName) {
        Campaigns.update(
          {
            id: campaign.id
          },
          {
            name: campaign.name
          },
          function(result) {
            insightName = result.name;
          },
          function(errorResponse) {
            $log.log(errorResponse.data.errors[0].message);
            vm.error = errorResponse.data.errors[0].message;
          }
        );
      }
    }

    vm.hasWritePermission = function() {
      return Authorization.hasPermission(['Campaigns']);
    };

    vm.clickthrough_url_display = function(pixel) {
      if (pixel) {
        if (pixel.clickthroughUrl && pixel.clickthroughUrl.length) {
          return pixel.clickthroughUrl;
        }
        if (pixel.isDynamic) {
          return 'Various';
        }
        return 'Impression Only';
      }
    };

    vm.display_clickthrough_url = function(pixel) {
      if (vm.clickthrough_url_display(pixel) === 'Impression Only') {
        return false;
      }
      // display for pixels with clickthrough url and dynamic clickthrough url pixels
      return true;
    };
  }
})();
