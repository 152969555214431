'use strict';

// Authorization service for user
angular.module('users').factory('Authorization', [
  'Authentication',
  'store',
  '$q',
  '$window',
  '$log',
  function(Authentication, store, $q, $window, $log) {
    /**
     * Check the permissions from array
     */
    function checkPermissions(productArray) {
      var permissions = [];
      if (productArray && productArray.length) {
        productArray.forEach(function(product) {
          permissions.push();
        });
      }
    }

    function permissions() {
      var permissionsArray = store.get('permissions');
      return permissionsArray || null;
    }

    function hasPermission(allowedPermissions) {
      var allowed = false;
      var currentPermissions = permissions();

      if (currentPermissions) {
        allowedPermissions.forEach(function(permission) {
          if (currentPermissions.indexOf(permission) > -1) {
            allowed = true;
          }
        });
      }
      return allowed;
    }

    return {
      /**
       * Authorize User
       * - Check if logged in (token exists and has not expired); If not log out and redirect to home page
       * - Gets Permissions if valid Cookie
       * @returns {promise}
       */
      authorize: function(withoutRedirect) {
        return $q(function(resolve) {
          Authentication.isLoggedIn().then(
            function(isLoggedIn) {
              if (!isLoggedIn) {
                Authentication.logout();
                if (!withoutRedirect) {
                  $window.location.href = '/';
                }
                resolve(false);
                return;
              }
              Authentication.storeClientInfo().then(function(result) {
                if (result) {
                  resolve(result);
                } else {
                  Authentication.logout();
                  if (!withoutRedirect) {
                    $window.location.href = '/';
                  }
                  resolve(false);
                }
              });
            },
            function(err) {
              $log.error(err);
              Authentication.logout();
              if (!withoutRedirect) {
                $window.location.href = '/';
              }
              resolve(false);
            }
          );
        });
      },

      /**
       * Get Permissions for the current team.
       * @return {Array}
       */
      permissions: permissions,

      /**
       * Get the whole Permissions object.
       * @return {Array}
       */
      permissionSet: function() {
        return store.get('permissionSet');
      },

      hasPermission: hasPermission,

      hasGlobalPermission: function() {
        var client = store.get('client');
        if (client && client.isInternalUser) {
          return true;
        }
        return false;
      },

      /**
       * Get collections that current user has permission to access.
       * @param  {string} insightType 'Websites' or 'Campaigns'.
       * @return {boolean|string[]} false if not found, array of directories.
       */
      getCollections: function(insightType) {
        var collections = store.get('collections');
        if (
          !collections ||
          (Object.keys(collections).length === 0 &&
            collections.constructor === Object)
        ) {
          return [];
        }
        var filteredCollections;
        if (insightType === 'Campaigns') {
          filteredCollections = collections[2];
        } else {
          filteredCollections = collections[3];
        }
        return filteredCollections.map(function(c) {
          return { name: c.name, id: c.id };
        });
      },
      /**
       * check if the current user have permissions, if not then redirect to no access page
       */
      checkPerms: function() {
        var permissions = store.get('permissions');
        if (!permissions || Object.keys(permissions).length === 0) {
          $window.location.href = '/no-access';
        } else {
          $window.location.href = '/';
        }
      },

      switchBack: function() {
        var switchFromToken = Authentication.getCookie('switchFromToken');
        Authentication.removeSwitchFromCookies();
        Authentication.setCookie('token', switchFromToken);
        $window.location.href = '/';
      },

      isUserSwitched: function() {
        var switchFromToken = Authentication.getCookie('switchFromToken');
        return switchFromToken !== '';
      }
    };
  }
]);
