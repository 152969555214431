(function() {
  'use strict';

  angular.module('insights').factory('Premium', Premium);

  Premium.$inject = [
    'PremiumPeople',
    'PremiumIndex',
    '$document',
    '$q',
    '$http',
    'store'
  ];

  function Premium(PremiumPeople, PremiumIndex, $document, $q, $http, store) {
    var activeSortOrder = 'ua';
    // Start descending
    var sortOrder = false;

    return {
      checkService: createOrChange,
      resize: resize,
      formatData: formatData,
      roundData: roundFinalData,
      sortData: sortData,
      indexOnClick: indexOnClick,
      ctrOnClick: ctrOnClick,
      freqOnClick: freqOnClick,
      peopleOnClick: peopleOnClick,
      personaOnClick: personaOnClick
    };

    /**
     * Create chart or change date
     * @param {Array} jsonData
     * @param {string} chartType // Undefined
     */
    function createOrChange(jsonData, chartType) {
      var sortedData = jsonData.sort(sortByUA);
      PremiumPeople.createOrChange(sortedData, chartType);
      PremiumIndex.createOrChange(sortedData, chartType);
    }

    /**
     * Sort data by UA
     * @param  {Object} a
     * @param  {Object} b
     * @return {number}
     */
    function sortByUA(a, b) {
      if (a.ua > b.ua) return -1;
      if (a.ua < b.ua) return 1;
      return 0;
    }

    /**
     * Resize charts
     */
    function resize() {
      PremiumPeople.resize();
      PremiumIndex.resize();
    }

    /**
     * Calculate Total UA
     * @param {Object[]} data The Premium BigQuery data
     * @param {Object[]} labelData The Label information used to group BigQuery Data
     * @returns {*}
     */
    function calculateTotalUA(data, labelData) {
      var totalUA = 0;
      // validUAFields references the values used in `premium_output.json`
      var validUAFields = ['Aspiring_NEOs', 'NEOs', 'Traditionals'];

      angular.forEach(labelData, function(label) {
        if (label.id && validUAFields.includes(label.id)) {
          var ua = formatToInt(data[0][label.dataLabels.ua]);
          totalUA += ua;
        }
      });

      return totalUA;
    }

    /**
     * Get Premium names from JSON file
     * @returns {promise}
     */
    function loadPremiumOutput() {
      return $q(function(resolve, reject) {
        return d3.json('/static-data/premium_output.json', function(
          error,
          data
        ) {
          if (error) {
            return reject(Error(error));
          }

          return resolve(data);
        });
      });
    }

    /**
     * @param {Object[]} data The Premium BigQuery data
     * @param {Object[]} labelData The Label information used to group BigQuery Data
     * @returns {Object[]} Promise containing formatted data
     */
    function formatDataWithLabels(data, premiumIndexData, labelData) {
      var newData = [];

      if (data.length <= 0) {
        return newData;
      }

      var totalUA = calculateTotalUA(data, labelData);

      angular.forEach(labelData, function(label) {
        var ua = formatToInt(data[0][label.dataLabels.ua]);
        var clicks = formatToInt(data[0][label.dataLabels.clicks]);
        var impressions = formatToInt(data[0][label.dataLabels.impressions]);
        var share;

        angular.forEach(premiumIndexData, function(premiumIndex) {
          if (premiumIndex.Roy_Morgan_Premium == label.id) {
            share = premiumIndex.V;
          }
        });

        var index = calculateIndex(ua, totalUA, share);

        newData.push({
          name: label.name,
          className: label.className,
          ua: ua,
          clicks: clicks,
          impressions: impressions,
          index: index,
          freq: Number(impressions / ua) || 0,
          ctr: Number((clicks / impressions) * 100) || 0
        });
      });

      return newData;
    }

    /**
     * Format data for charts
     * @param {Object[]} data Premium data
     * @param {Object[]} premiumIndexData Premium Index data
     * @returns {Promise<*>} Promise containing data
     */
    function formatData(data, premiumIndexData) {
      var newData = [];
      var labelData;
      return $q(function(resolve) {
        loadPremiumOutput().then(function(result) {
          labelData = result;
          newData = formatDataWithLabels(data, premiumIndexData, labelData);
          resolve(newData);
        });
      });
    }

    /**
     * Round download data
     * @param {Array<Object>} dataToRound Base Premium data
     * @param {Array<Object>} premiumIndexData Data containing V%
     * @returns {Promise<Array<Object>>} Promise
     */
    function roundFinalData(dataToRound, premiumIndexData) {
      return $q(function(resolve) {
        formatData(dataToRound, premiumIndexData).then(function(result) {
          dataToRound = result;
          angular.forEach(dataToRound, function(value) {
            value.UA = Math.round(value.ua);
            if (value.clicks) {
              value.clicks = Math.round(value.clicks);
            } else {
              value.clicks = 0;
            }

            value.impressions = Math.round(value.impressions);
            delete value.ua;
          });

          resolve(dataToRound);
        });
      });
    }

    /**
     * Calculate Helix Index
     * @param {number} sumUAPersona
     * @param {number} sumUACampaign
     * @param {number} personaShare
     * @returns {number|null}
     */
    function calculateIndex(sumUAPersona, sumUACampaign, personaShare) {
      if (personaShare) {
        var index = Math.round(
          (sumUAPersona / sumUACampaign / personaShare) * 10000
        );
        return index || null;
      }
      return null;
    }

    function peopleOnClick() {
      var typeOfData = getTypeOfData();
      var peopleCaret = $document[0].getElementById('premium-people-caret');
      var helixCaret = $document[0].getElementById('premium-caret');
      var indexCaret = $document[0].getElementById('premium-index-caret');

      helixCaret.className = 'fa';
      indexCaret.className = 'fa';
      sortData(typeOfData);

      if (sortOrder) {
        peopleCaret.className = 'fa fa-caret-up';
      } else {
        peopleCaret.className = 'fa fa-caret-down';
      }
    }

    function personaOnClick() {
      var peopleCaret = $document[0].getElementById('premium-people-caret');
      var helixCaret = $document[0].getElementById('premium-caret');
      var indexCaret = $document[0].getElementById('premium-index-caret');
      peopleCaret.className = 'fa';
      indexCaret.className = 'fa';
      sortData('name');

      if (sortOrder) {
        helixCaret.className = 'fa fa-caret-up';
      } else {
        helixCaret.className = 'fa fa-caret-down';
      }
    }

    function indexOnClick() {
      var typeOfData = getTypeOfData();
      var peopleCaret = $document[0].getElementById('premium-people-caret');
      var helixCaret = $document[0].getElementById('premium-caret');
      var indexCaret = $document[0].getElementById('premium-index-caret');
      if (typeOfData === 'ua') {
        sortData('index');
        helixCaret.className = 'fa';
        peopleCaret.className = 'fa';
        if (sortOrder) {
          indexCaret.className = 'fa fa-caret-up';
        } else {
          indexCaret.className = 'fa fa-caret-down';
        }
      }
    }

    function freqOnClick() {
      var typeOfData = getTypeOfData();
      var peopleCaret = $document[0].getElementById('premium-people-caret');
      var helixCaret = $document[0].getElementById('premium-caret');
      var indexCaret = $document[0].getElementById('premium-index-caret');
      if (typeOfData === 'impressions') {
        sortData('freq');
        helixCaret.className = 'fa';
        peopleCaret.className = 'fa';
        if (sortOrder) {
          indexCaret.className = 'fa fa-caret-up';
        } else {
          indexCaret.className = 'fa fa-caret-down';
        }
      }
    }

    function ctrOnClick() {
      var typeOfData = getTypeOfData();
      var peopleCaret = $document[0].getElementById('premium-people-caret');
      var helixCaret = $document[0].getElementById('premium-caret');
      var indexCaret = $document[0].getElementById('premium-index-caret');
      if (typeOfData === 'clicks') {
        sortData('ctr');
        helixCaret.className = 'fa';
        peopleCaret.className = 'fa';
        if (sortOrder) {
          indexCaret.className = 'fa fa-caret-up';
        } else {
          indexCaret.className = 'fa fa-caret-down';
        }
      }
    }

    function sortData(sortable) {
      if (sortable === activeSortOrder) {
        // change sort direction, keep same data
        sortOrder = !sortOrder;
      } else {
        // keep same sort direction, change data
        activeSortOrder = sortable;
      }

      PremiumPeople.sortData(sortable, sortOrder);
      PremiumIndex.sortData(sortable, sortOrder);
    }

    /**
     * Gets the currently showed data type. E.g. UA, Impressions or Clicks.
     * @returns {string} - Type of data shown
     */
    function getTypeOfData() {
      var data = angular
        .element($document[0].querySelector('.switch.active'))
        .attr('value');
      if (data === 'UA') {
        data = 'ua';
      } else if (data === 'Impressions') {
        data = 'impressions';
      } else if (data === 'Clicks') {
        data = 'clicks';
      }

      return data;
    }

    /**
     * Formats the BigQuery values in Integers
     * @param {string} dataPoint
     * @return {number}
     */
    function formatToInt(dataPoint) {
      if (!dataPoint) {
        return 0;
      }
      return parseInt(dataPoint, 10);
    }
  }
})();
