(function() {
  'use strict';

  angular.module('insights').factory('InsightsArea', InsightsArea);

  InsightsArea.$inject = [
    '$q',
    'InsightsAreaConfig',
    '$document',
    'Utility',
    '$http',
    'commonWebsite'
  ];

  function InsightsArea(
    $q,
    InsightsAreaConfig,
    $document,
    Utility,
    $http,
    commonWebsite
  ) {
    var prevChartType = null;
    var tip = d3
      .tip()
      .attr('class', 'persona-tooltip tooltip')
      .direction('e')
      .offset([0, 20])
      .html(function(d) {
        return '<b>' + d.Name + '</b> <br>' + d.desc;
      });

    function compare(a, b) {
      if (a.ua > b.ua) {
        return -1;
      }
      if (a.ua < b.ua) {
        return 1;
      }
      return 0;
    }

    function getJsonData() {
      return $q(function(resolve) {
        $http
          .get('/static-data/postcode_to_suburb.json')
          .success(function(data) {
            return resolve(data);
          });
      });
    }

    function addSuburbsToData(data, chartType) {
      var newData = angular.copy(data);
      return $q(function(resolve) {
        if (chartType === 'postcode') {
          getJsonData().then(function(json) {
            for (var i = 0; i < newData.length; i++) {
              var name = newData[i].Name;
              var line = json[name];
              var desc = '';
              if (line) {
                desc = line.join(', ');
              }
              newData[i].desc = desc;
            }
            return resolve(newData);
          });
        } else {
          return resolve(newData);
        }
      });
    }

    /**
     * Load Supplementary Area Information
     * @param {string} chartType - area, state or postcode.
     * @return {Promise<Object[]>}
     */
    function loadAreaInfo(chartType) {
      return $q(function(resolve, reject) {
        if (chartType === 'area') {
          return d3.json('/static-data/area_codes.json', function(error, data) {
            if (error) {
              return reject(Error(error));
            }

            return resolve(data);
          });
        }
        if (chartType === 'postcode') {
          return $http
            .get('/api/static-data/postcode-population')
            .then(function(response) {
              return resolve(response.data);
            })
            .catch(function(error) {
              console.error(error);
              return reject(error);
            });
        }
        if (chartType === 'state') {
          return $http
            .get('/api/static-data/state-population')
            .then(function(response) {
              return resolve(response.data);
            })
            .catch(function(error) {
              console.error(error);
              return reject(error);
            });
        }
        return resolve([{}]);
      });
    }

    function sortData(sortable, y) {
      // Sort Data
      sortOrder = !sortOrder;
      var chartId = InsightsAreaConfig.chartId;
      var chart = d3.select(chartId);
      chart
        .selectAll('.bar')
        .sort(function(a, b) {
          if (sortOrder) {
            return d3.ascending(a[sortable], b[sortable]);
          }
          return d3.descending(a[sortable], b[sortable]);
        })
        .transition()
        .delay(function(d, i) {
          return i * 25;
        })
        .duration(750)
        .attr('transform', function(d, i) {
          return 'translate(0,' + y(i) + ')';
        });
    }

    var finalData = [];
    var barHeight = InsightsAreaConfig.barHeight;
    var spacing = InsightsAreaConfig.spacing;
    var sortOrder = false;

    /**
     * Format data for chart or download
     * @param {Object[]} data
     * @param {string} chartType - area, state or postcode.
     * @return {PromiseLike<Object[]>} Promise containing data
     */
    function normaliseData(data, chartType) {
      var relevantData = data;
      var newData = [];
      return $q(function(resolve) {
        loadAreaInfo(chartType).then(function(areaData) {
          if (chartType === 'area') {
            angular.forEach(areaData, function(value) {
              var impressions = 0;
              var clicks = 0;
              var ua = 0;
              var percentage;

              for (var i = 0; i < relevantData.length; i++) {
                if (relevantData[i].Area_Code === value.area_code) {
                  ua = Math.floor(Number(relevantData[i].UA));
                  impressions = Math.floor(Number(relevantData[i].impressions));
                  clicks = Math.floor(Number(relevantData[i].clicks));
                }
              }

              var population = parseInt(value.population, 10);
              percentage = ua / population;
              percentage = Math.round(percentage * 1000) / 10;

              // Other has a massive amount of data as it's not recorded
              if (value.area !== 'Other') {
                newData.push({
                  Name: value.area,
                  ua: ua,
                  impressions: impressions,
                  clicks: clicks,
                  percentage: percentage,
                  population: population
                });
              }
            });
          } else if (chartType === 'postcode') {
            areaData.forEach(function(value) {
              for (var i = 0; i < relevantData.length; i++) {
                var impressions = 0;
                var clicks = 0;
                var ua = 0;
                ua = parseInt(relevantData[i].UA, 10);
                impressions = parseInt(relevantData[i].impressions, 10);
                clicks = parseInt(relevantData[i].clicks, 10);

                // if postcode is not null and is not N/A
                if (
                  relevantData[i].Postcode !== null &&
                  relevantData[i].Postcode !== 'N/A'
                ) {
                  var name = relevantData[i].Postcode;

                  if (relevantData[i].Postcode === value.postcode) {
                    var percentage;
                    var population = parseInt(value.population, 10);
                    percentage = ua / population;
                    percentage = Math.round(percentage * 10000) / 100;

                    newData.push({
                      Name: name,
                      ua: ua,
                      impressions: impressions,
                      clicks: clicks,
                      percentage: percentage,
                      population: population
                    });
                  }
                }
              }
            });
          } else if (chartType === 'state') {
            areaData.forEach(function(value) {
              for (var i = 0; i < relevantData.length; i++) {
                var impressions = 0;
                var clicks = 0;
                var ua = 0;
                ua = parseInt(relevantData[i].UA, 10);
                impressions = parseInt(relevantData[i].impressions, 10);
                clicks = parseInt(relevantData[i].clicks, 10);
                var name = relevantData[i].State;

                if (relevantData[i].State === value.State) {
                  var percentage;
                  var population = parseInt(value.ABS_Pop_0, 10);
                  percentage = ua / population;
                  percentage = Math.round(percentage * 1000) / 10;

                  newData.push({
                    Name: name,
                    ua: ua,
                    impressions: impressions,
                    clicks: clicks,
                    percentage: percentage,
                    population: population
                  });
                }
              }
            });
          }

          resolve(newData);
        });
      });
    }

    /**
     * Draw graph
     * @param {Array} data
     * @param {string} chartType - area, state or postcode.
     */
    function createChart(data, chartType) {
      if (chartType === 'postcode') {
        data = data.slice(0, 10); // show top 10 postcodes
      }
      var typeOfData = Utility.getTypeOfData();
      var margin = InsightsAreaConfig.margin;
      var width = InsightsAreaConfig.width();
      var height = InsightsAreaConfig.height;
      var widthPercent = InsightsAreaConfig.widthPercent();
      finalData = [];

      normaliseData(data, chartType)
        .then(function(result) {
          // add suburbs to data
          return addSuburbsToData(result, chartType);
        })
        .then(function(dataWithSuburbs) {
          finalData = dataWithSuburbs;

          var myMax = Math.max.apply(
            Math,
            finalData.map(function(o) {
              return o[typeOfData];
            })
          );

          finalData.sort(compare);

          var x = d3.scale
            .linear()
            .range([0, width - 100])
            .domain([0, myMax]);

          var y = d3.scale.ordinal();

          var xAxis = d3.svg
            .axis()
            .ticks(4)
            .tickFormat(function(d) {
              var prefix = d3.formatPrefix(d);
              return prefix.scale(d) + prefix.symbol;
            })
            .scale(x);

          // yAxis
          d3.svg
            .axis()
            .scale(y)
            .tickFormat('');

          // create the chart
          var chart = d3
            .select('#area-chart')
            .append('svg')
            .style('width', width + margin.left + margin.right + 'px')
            .append('g')
            .attr('transform', 'translate(' + [100, margin.top] + ')');

          // set y domain
          var yRange = d3.range(finalData.length);
          y.domain(yRange).rangeBands([0, finalData.length * barHeight]);

          // set height based on data
          height = y.rangeExtent()[1];
          d3.select(chart.node().parentNode).style(
            'height',
            height + margin.top + margin.bottom + 'px'
          );

          chart
            .append('g')
            .attr('class', 'x axis bottom')
            .attr('stroke', '#000')
            .attr('fill', 'none')
            .attr(
              'transform',
              'translate(0,' + parseInt(height + 10, 10).toString() + ')'
            )
            .call(xAxis.orient('bottom'));

          var bars = chart
            .selectAll('.bar')
            .data(finalData)
            .enter()
            .append('g')
            .attr('class', 'bar')
            .attr('transform', function(d, i) {
              return 'translate(0,' + y(i.toString()) + ')';
            });

          bars
            .append('rect')
            .attr('class', 'percent')
            .attr('height', y.rangeBand())
            .attr('width', function(d) {
              var barWidth = d[typeOfData] / myMax;
              barWidth *= widthPercent;
              return barWidth + '%';
            });

          if (chartType === 'postcode') {
            bars.call(tip);
            bars
              .append('text')
              .text(function(d) {
                return d.Name;
              })
              .attr('class', 'name')
              .attr('y', y.rangeBand() - 10)
              .attr('x', spacing)
              .attr('transform', 'translate(' + [-90, 0] + ')')
              .style('cursor', 'help')
              .on('mouseover', tip.show)
              .on('mouseout', tip.hide);
          } else {
            bars
              .append('text')
              .text(function(d) {
                return d.Name;
              })
              .attr('class', 'name')
              .attr('y', y.rangeBand() - 10)
              .attr('x', spacing)
              .attr('transform', 'translate(' + [-90, 0] + ')');
          }

          bars
            .append('text')
            .text(function(d) {
              if (typeOfData === 'ua' && d.percentage) {
                return (
                  d3.format(',d')(d[typeOfData]) + ' (' + d.percentage + '%)'
                );
              }
              return d3.format(',d')(d[typeOfData]);
            })
            .attr('class', 'number')
            .attr('y', y.rangeBand() - 5)
            .attr('x', spacing)
            .style('text-anchor', 'start')
            .attr('transform', 'translate(' + [10, -5] + ')');

          commonWebsite.hideSpinner('area-chart', 'hideNoDataMsg');

          var areaCaret = $document[0].getElementById('area-caret');

          d3.selectAll('.switch').on('click.area', changeDataType);
          var toggleArea = $document[0].getElementById('toggle-area');
          toggleArea.addEventListener(
            'click',
            function() {
              sortData(typeOfData, y);
              if (sortOrder) {
                areaCaret.className = 'fa fa-caret-up';
              } else {
                areaCaret.className = 'fa fa-caret-down';
              }
            },
            false
          );
        });
    }

    /**
     * Get the currently selected area chart.
     *
     * @returns {string} area, state or postcode
     */
    function getTypeOfChart() {
      return $document[0].getElementById('active-area-chart').value;
    }

    function changeDataType() {
      var chartType = getTypeOfChart();
      if (chartType === 'postcode') {
        return;
      }
      var typeOfData = Utility.getTypeOfData();
      sortOrder = true;
      var width = InsightsAreaConfig.width();
      var widthPercent = InsightsAreaConfig.widthPercent();
      d3.selectAll('#toggle-area').append('i');
      d3.selectAll('#toggle-area i').attr('id', 'area-caret');
      var areaCaret = $document[0].getElementById('area-caret');
      areaCaret.className = 'fa fa-caret-down';

      // Get the max so our table doesn't go outside the bounds
      var max = Math.max.apply(
        Math,
        finalData.map(function(o) {
          return o[typeOfData];
        })
      );

      if (max > 0) {
        var x = d3.scale
          .linear()
          .range([0, width - 100])
          .domain([0, max]);

        var y = d3.scale.ordinal();

        var xAxis = d3.svg
          .axis()
          .ticks(4)
          .tickFormat(function(d) {
            var prefix = d3.formatPrefix(d);
            return prefix.scale(d) + prefix.symbol;
          })
          .scale(x);

        // yAxis
        d3.svg
          .axis()
          .scale(y)
          .tickFormat('');

        var yRange = d3.range(finalData.length);
        y.domain(yRange).rangeBands([0, finalData.length * barHeight]);

        // First transition the line & label.
        var t0 = d3
          .select('#area-chart svg')
          .transition()
          .duration(750);
        if (max > 0) {
          t0.selectAll('rect.percent')
            .attr('height', y.rangeBand())
            .attr('width', function(d) {
              var barWidth = d[typeOfData] / max;
              barWidth *= widthPercent;
              return barWidth + '%';
            });
        } else {
          t0.selectAll('rect.percent')
            .attr('height', y.rangeBand())
            .attr('width', '0%');
        }

        t0.selectAll('.number')
          .text(function(d) {
            if (typeOfData === 'ua' && d.percentage) {
              return (
                d3.format(',d')(d[typeOfData]) + ' (' + d.percentage + '%)'
              );
            }
            return d3.format(',d')(d[typeOfData]);
          })
          .attr('class', 'number')
          .attr('y', y.rangeBand() - 5)
          .attr('x', 0)
          .attr('transform', 'translate(' + [10, -5] + ')');

        t0.selectAll('.x.axis.bottom').call(xAxis.orient('bottom'));
        sortData(typeOfData, y);
      }
    }

    function resize() {
      var chartId = InsightsAreaConfig.chartId;
      var margin = InsightsAreaConfig.margin;
      var chart = d3.select(chartId);
      var typeOfData = Utility.getTypeOfData();
      var widthPercent = InsightsAreaConfig.widthPercent();

      // update width
      var width = InsightsAreaConfig.width();

      // Get the max so our table doesn't go outside the bounds
      var max = Math.max.apply(
        Math,
        finalData.map(function(o) {
          return o[typeOfData];
        })
      );

      var x = d3.scale
        .linear()
        .range([0, width - 100])
        .domain([0, max]);

      var y = d3.scale.ordinal();

      var yRange = d3.range(finalData.length);
      y.domain(yRange).rangeBands([0, finalData.length * barHeight]);
      var height = y.rangeExtent()[1];

      var xAxis = d3.svg
        .axis()
        .ticks(4)
        .tickFormat(function(d) {
          var prefix = d3.formatPrefix(d);
          return prefix.scale(d) + prefix.symbol;
        })
        .scale(x);

      chart
        .selectAll('svg')
        .style('width', width + margin.left + margin.right + 'px');

      chart
        .selectAll('.x.axis.bottom')
        .attr('width', width)
        .attr('transform', 'translate(0,' + height + ')')
        .call(xAxis.orient('bottom'));

      chart
        .selectAll('rect')
        .attr('class', 'percent')
        .attr('height', y.rangeBand())
        .attr('width', function(d) {
          var barWidth = d[typeOfData] / max;
          barWidth *= widthPercent;
          return barWidth + '%';
        });
    }

    function changeDate(data, chartType) {
      var relevantData = data;
      var chartId = InsightsAreaConfig.chartId;
      var width = InsightsAreaConfig.width();
      var widthPercent = InsightsAreaConfig.widthPercent();
      var typeOfData = Utility.getTypeOfData();
      sortOrder = true;
      finalData = [];
      normaliseData(relevantData, chartType)
        .then(function(result) {
          return addSuburbsToData(result, chartType);
        })
        .then(function(normalisedData) {
          finalData = normalisedData;

          // Get current sorting order.
          var areaToggle = $document[0].getElementById('toggle-area');
          var caretDown = areaToggle.getElementsByClassName('fa-caret-down');
          var caretUp = areaToggle.getElementsByClassName('fa-caret-up');
          var sortingAsc;
          if (caretDown.length > 0) {
            sortingAsc = false;
          } else if (caretUp.length > 0) {
            sortingAsc = true;
          }

          // Get Old Data.
          var chart = d3.select(chartId);
          var oldData = chart.selectAll('.bar').data();

          // Put New Data in Same order as Old Data.
          var newData = [];
          oldData.forEach(function(key) {
            var found = false;
            finalData = finalData.filter(function(item) {
              if (!found && item.Name === key.Name) {
                newData.push(item);
                found = true;
                return false;
              }
              return true;
            });
          });

          // Replace existing data with new data.
          finalData = newData;
          chart.selectAll('.bar').data(finalData);
          chart.selectAll('rect.percent').data(finalData);
          chart.selectAll('.number').data(finalData);

          var myMax = Math.max.apply(
            Math,
            finalData.map(function(o) {
              return o[typeOfData];
            })
          );

          var x = d3.scale
            .linear()
            .range([0, width - 100])
            .domain([0, myMax]);
          var y = d3.scale.ordinal();

          var xAxis = d3.svg
            .axis()
            .ticks(4)
            .tickFormat(function(d) {
              var prefix = d3.formatPrefix(d);
              return prefix.scale(d) + prefix.symbol;
            })
            .scale(x);

          // yAxis
          d3.svg
            .axis()
            .scale(y)
            .tickFormat('');

          var yRange = d3.range(finalData.length);
          y.domain(yRange).rangeBands([0, finalData.length * barHeight]);

          // First transition the line & label.
          var t0 = chart
            .select('svg')
            .transition()
            .duration(750);
          if (myMax > 0) {
            t0.selectAll('rect.percent')
              .attr('height', y.rangeBand())
              .attr('width', function(d) {
                var barWidth = d[typeOfData] / myMax;
                barWidth *= widthPercent;
                return barWidth + '%';
              });
          } else {
            t0.selectAll('rect.percent')
              .attr('height', y.rangeBand())
              .attr('width', '0%');
          }

          t0.selectAll('.number')
            .text(function(d) {
              if (typeOfData === 'ua' && d.percentage) {
                return (
                  d3.format(',d')(d[typeOfData]) + ' (' + d.percentage + '%)'
                );
              }
              return d3.format(',d')(d[typeOfData]);
            })
            .attr('class', 'number')
            .attr('y', y.rangeBand() - 5)
            .attr('x', 0)
            .attr('transform', 'translate(' + [10, -5] + ')');

          t0.selectAll('.x.axis.bottom').call(xAxis.orient('bottom'));
          sortOrder = !sortingAsc;
          sortData(typeOfData, y);
        });
    }

    function roundFinalData(dataToRound, chartType) {
      return $q(function(resolve) {
        normaliseData(dataToRound, chartType).then(function(result) {
          var newData = result;

          angular.forEach(newData, function(value) {
            value.Area = value.Name;
            value.UA = Math.round(value.ua);
            if (value.clicks) {
              value.clicks = Math.round(value.clicks);
            } else {
              value.clicks = 0;
            }

            value.impressions = Math.round(value.impressions);
            value['% of Area Reached'] = value.percentage;
            value['Area Population'] = value.population;

            delete value.population;
            delete value.percentage;
            delete value.ua;
            delete value.Name;
          });

          resolve(newData);
        });
      });
    }

    /**
     * Check if graph has been created
     * @returns {boolean}
     */
    function graphInitialised() {
      var chart = $document[0].getElementById('area-chart');
      var svg = chart.getElementsByTagName('svg');
      return svg.length > 0;
    }

    /**
     * Remove chart
     * - Used when transitioning between chart types.
     */
    function removeChart() {
      var chart = d3.select('#area-chart');
      chart.selectAll('svg').remove();
    }

    return {
      roundData: roundFinalData,
      checkService: function(jsonData, chartType) {
        if (chartType !== prevChartType || chartType === 'postcode') {
          prevChartType = chartType;
          removeChart();
        }
        if (!graphInitialised()) {
          return createChart(jsonData, chartType);
        }
        return changeDate(jsonData, chartType);
      },

      resize: function() {
        return resize();
      },

      changeDate: function(jsonData, chartType) {
        if (chartType !== prevChartType) {
          prevChartType = chartType;
          removeChart();
        }
        return changeDate(jsonData, chartType);
      }
    };
  }
})();
