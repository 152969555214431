'use strict';

angular.module('insights').controller('InsightsController', [
  '$scope',
  'Insights',
  'CampaignInsights',
  function($scope, Insights, CampaignInsights) {
    // Find existing Campaign
    $scope.getInsight = function() {
      $scope.insights = Insights.get();
    };

    $scope.checkService = function() {
      $scope.xxxxx = CampaignInsights.checkService();
    };
  }
]);
