(function() {
  'use strict';

  angular.module('websites').factory('deviceChartWebsite', deviceChartWebsite);

  deviceChartWebsite.$inject = [
    '$http',
    'commonWebsite',
    'InsightsDevice',
    '$q',
    '$log',
    'downloads'
  ];

  function deviceChartWebsite(
    $http,
    commonWebsite,
    InsightsDevice,
    $q,
    $log,
    downloads
  ) {
    return {
      chart: chart,
      resize: resize,
      getData: getData
    };

    /**
     * StartEnd type
     * @typedef {Object} StartEnd
     * @property {string} start Campaign start date
     * @property {?string} end Campaign end date or selected date.
     */

    /**
     * Device Chart based on date period
     * @param {string} period
     * @param {string} websiteId
     * @param {StartEnd} startEnd - Insight start and end dates.
     * @param {Object} variable
     */
    function chart(period, websiteId, startEnd, variable) {
      var companyUID = commonWebsite.getUID();
      if (period === 'month') {
        monthChart(websiteId, companyUID, startEnd.end, variable);
      } else if (period === 'week') {
        weekChart(websiteId, companyUID, startEnd.end, variable);
      } else if (period === 'day') {
        dayChart(websiteId, companyUID, startEnd.end, variable);
      }
    }

    /**
     * Month Device Chart
     * @param {string} websiteId
     * @param {?string} endDate - Campaign End Date YYYY-MM-DD
     * @param {Object} variable
     */
    function monthChart(websiteId, companyUID, endDate, variable) {
      var startOfMonth = moment(endDate || undefined)
        .startOf('month')
        .format('YYYY-MM-DD');

      $http
        .post('/api/insights/website', {
          campaign: websiteId,
          companyUID: companyUID,
          endDate: startOfMonth,
          chart: 'device',
          period: 'month',
          type: 'website',
          variable: variable
        })
        .then(
          function(result) {
            if (result.data.insights.length > 0) {
              InsightsDevice.createChart(angular.copy(result.data.insights));
            } else {
              commonWebsite.hideSpinner('device-chart', 'showNoDataMsg');
            }
          },
          function(reason) {
            commonWebsite.hideSpinner('device-chart', 'showNoDataMsg');
            $log.error('Error: website-device-month');
            $log.error(reason);
          }
        );
    }

    /**
     * Weekly Device Chart
     * @param {string} websiteId
     * @param {?string} endDate - Campaign End Date YYYY-MM-DD
     * @param {Object} variable
     */
    function weekChart(websiteId, companyUID, endDate, variable) {
      var startOfWeek = moment(endDate || undefined)
        .startOf('isoWeek')
        .format('YYYY-MM-DD');

      $http
        .post('/api/insights/website', {
          campaign: websiteId,
          companyUID: companyUID,
          endDate: startOfWeek,
          chart: 'device',
          period: 'week',
          type: 'website',
          variable: variable
        })
        .then(
          function(result) {
            if (result.data.insights.length > 0) {
              InsightsDevice.createChart(angular.copy(result.data.insights));
            } else {
              commonWebsite.hideSpinner('device-chart', 'showNoDataMsg');
            }
          },
          function(reason) {
            commonWebsite.hideSpinner('device-chart', 'showNoDataMsg');
            $log.error('Error: website-device-week');
            $log.error(reason);
          }
        );
    }

    /**
     * Day Device Chart
     * @param {string} websiteId
     * @param {?string} endDate - Campaign End Date YYYY-MM-DD
     * @param {Object} variable
     */
    function dayChart(websiteId, companyUID, endDate, variable) {
      var yesterday = commonWebsite.formatEndDate();

      $http
        .post('/api/insights/website', {
          campaign: websiteId,
          companyUID: companyUID,
          endDate: endDate || yesterday,
          chart: 'device',
          period: 'day',
          type: 'website',
          variable: variable
        })
        .then(
          function(result) {
            if (result.data.insights.length > 0) {
              InsightsDevice.createChart(angular.copy(result.data.insights));
            } else {
              commonWebsite.hideSpinner('device-chart', 'showNoDataMsg');
            }
          },
          function(reason) {
            commonWebsite.hideSpinner('device-chart', 'showNoDataMsg');
            $log.error('Error: website-device-day');
            $log.error(reason);
          }
        );
    }

    /**
     * Resize chart
     */
    function resize() {
      InsightsDevice.resize();
    }

    /**
     * Which data to download
     * @param {string} websiteId
     * @param {string} type
     * @param {StartEnd} startEnd - Insight start and end dates
     * @param {Object} variable
     * @return {Promise<Array<Object>>}
     */
    function getData(websiteId, type, startEnd, variable) {
      var companyUID = commonWebsite.getUID();
      if (type === 'day') {
        return getDataDay(websiteId, companyUID, startEnd.end, variable);
      }
      if (type === 'week') {
        return getDataWeek(websiteId, companyUID, startEnd.end, variable);
      }
      return getDataMonth(websiteId, companyUID, startEnd.end, variable);
    }

    /**
     * Download all day data
     * @param {string} websiteId
     * @param {?string} endDate - Campaign End Date YYYY-MM-DD
     * @param {Object} variable
     * @return {Promise<Array<Object>>}
     */
    function getDataDay(websiteId, companyUID, endDate, variable) {
      var yesterday = commonWebsite.formatEndDate();
      return $q(function(resolve, reject) {
        $http
          .post('/api/insights/website', {
            campaign: websiteId,
            companyUID: companyUID,
            endDate: endDate || yesterday,
            chart: 'device',
            period: 'day',
            type: 'website',
            variable: variable
          })
          .then(
            function(result) {
              var finalResult;
              if (result.data.insights.length) {
                finalResult = formatDownloads(
                  result.data.insights,
                  'Date',
                  endDate || yesterday
                );
              }
              resolve(finalResult);
            },
            function(reason) {
              $log.error('Error: website-device-data-day');
              $log.error(reason);
              reject();
            }
          );
      });
    }

    /**
     * Download all week data
     * @param {string} websiteId
     * @param {?string} endDate - Campaign End Date YYYY-MM-DD
     * @param {Object} variable
     * @return {Promise<Array<Object>>}
     */
    function getDataWeek(websiteId, companyUID, endDate, variable) {
      var startOfWeek = moment(endDate || undefined)
        .startOf('isoWeek')
        .format('YYYY-MM-DD');
      var finalResult;
      return $q(function(resolve, reject) {
        $http
          .post('/api/insights/website', {
            campaign: websiteId,
            companyUID: companyUID,
            endDate: startOfWeek,
            chart: 'device',
            period: 'week',
            type: 'website',
            variable: variable
          })
          .then(
            function(result) {
              if (result.data.insights.length) {
                finalResult = formatDownloads(
                  result.data.insights,
                  'Start_Date_of_Week',
                  startOfWeek
                );
              }
              resolve(finalResult);
            },
            function(reason) {
              $log.error('Error: website-device-data-week');
              $log.error(reason);
              reject();
            }
          );
      });
    }

    /**
     * Download all day data
     * @param {string} websiteId
     * @param {?string} endDate - Campaign End Date YYYY-MM-DD
     * @param {Object} variable
     * @return {Promise<Array<Object>>}
     */
    function getDataMonth(websiteId, companyUID, endDate, variable) {
      var startOfMonth = moment(endDate || undefined)
        .startOf('month')
        .format('YYYY-MM-DD');
      return $q(function(resolve, reject) {
        $http
          .post('/api/insights/website', {
            campaign: websiteId,
            companyUID: companyUID,
            endDate: startOfMonth,
            chart: 'device',
            period: 'month',
            type: 'website',
            variable: variable
          })
          .then(
            function(result) {
              var finalResult;
              if (result.data.insights.length) {
                finalResult = formatDownloads(
                  result.data.insights,
                  'Start_Date_of_Month',
                  startOfMonth
                );
              }
              resolve(finalResult);
            },
            function(reason) {
              $log.error('Error: website-device-data-month');
              $log.error(reason);
              reject();
            }
          );
      });
    }

    function formatDownloads(rawData, dateHeading, date) {
      var data = addVennsSingleValues(rawData);
      data = downloads.addHeading(data, dateHeading, date);
      data = downloads.renameHeading(data, 'ua_d', 'Desktop_UA');
      data = downloads.renameHeading(data, 'ua_m', 'Mobile_UA');
      data = downloads.renameHeading(data, 'ua_t', 'Tablet_UA');
      data = downloads.renameHeading(data, 'ua_dm', 'Desktop_Mobile_UA');
      data = downloads.renameHeading(data, 'ua_mt', 'Mobile_Tablet_UA');
      data = downloads.renameHeading(data, 'ua_dt', 'Desktop_Tablet_UA');
      data = downloads.renameHeading(
        data,
        'ua_dmt',
        'Desktop_Mobile_Tablet_UA'
      );
      data = downloads.renameHeading(data, 'ua_d_only', 'Desktop_Only_UA');
      data = downloads.renameHeading(data, 'ua_m_only', 'Mobile_Only_UA');
      data = downloads.renameHeading(data, 'ua_t_only', 'Tablet_Only_UA');
      data = downloads.renameHeading(data, 'imp_d', 'Desktop_Impressions');
      data = downloads.renameHeading(data, 'imp_m', 'Mobile_Impressions');
      data = downloads.renameHeading(data, 'imp_t', 'Tablet_Impressions');

      return data;
    }

    /**
     * Calculate Desktop/Mobile/Tablet only data
     * @param {Array<Object>} dataValues
     *
     * @return {Array<Object>}
     */
    function addVennsSingleValues(dataValues) {
      // add charts "only" values
      angular.forEach(dataValues, function(value) {
        if (value && (value.ua_d > 0 || value.ua_m > 0 || value.ua_t > 0)) {
          value.ua_d_only =
            parseInt(value.ua_d, 10) -
            parseInt(value.ua_dt, 10) -
            parseInt(value.ua_dm, 10) +
            parseInt(value.ua_dmt, 10);
          value.ua_m_only =
            parseInt(value.ua_m, 10) -
            parseInt(value.ua_mt, 10) -
            parseInt(value.ua_dm, 10) +
            parseInt(value.ua_dmt, 10);
          value.ua_t_only =
            parseInt(value.ua_t, 10) -
            parseInt(value.ua_dt, 10) -
            parseInt(value.ua_mt, 10) +
            parseInt(value.ua_dmt, 10);
        }
      });
      return dataValues;
    }
  }
})();
