(function() {
  'use strict';

  angular.module('settings').factory('AdminSettings', AdminSettings);

  AdminSettings.$inject = ['AudiencesApi', '$q', '$log'];

  function AdminSettings(audiencesApi, $q, $log) {
    return {
      get: get,
      update: update
    };

    /**
     * Get Admin settings
     * @param {string} [param]
     * @returns {PromiseLike<any>} Promise
     */
    function get(param) {
      return $q(function(resolve, reject) {
        audiencesApi.get('/settings/admin').then(
          function(result) {
            var settings = result.data;

            if (param) {
              resolve(settings[param]);
            } else {
              resolve(settings);
            }
          },
          function(reason) {
            // $log.error('ERROR: AdminSettings.get');
            // $log.error(reason);
            reject();
          }
        );
      });
    }

    /**
     * Update Admin settings
     * @param {Object} params
     * @returns {PromiseLike<boolean>} Promise
     */
    function update(params) {
      return $q(function(resolve, reject) {
        audiencesApi.put('/settings/admin', params).then(
          function(result) {
            if (result) {
              resolve(true);
            } else {
              reject();
            }
          },
          function(reason) {
            $log.error('ERROR: AdminSettings.update');
            $log.error(reason);
            reject();
          }
        );
      });
    }
  }
})();
