'use strict';

angular
  .module('insights')
  .factory('InsightsBrowser', function(InsightsBrowserConfig, commonWebsite) {
    var path = [];
    var typeOfData = 'impressions';
    var width = 0;
    var globalData = [];
    var browsers = [
      'Firefox',
      'Google Chrome',
      'Safari',
      'Internet Explorer',
      'Microsoft Edge'
    ];

    /**
     * Filter other Browser types, count, add back into data
     * and remove original.
     * @param  {Array} data
     * @return {Array}
     */
    function filterData(data) {
      var count = 0;
      for (var i = 0; i < data.length; i++) {
        var browser = data[i];
        if (browsers.indexOf(browser.browser_type) < 0) {
          count += parseInt(Number(browser.count), 10);
          data.splice(i, 1);
          i--;
        }
      }

      var otherTotals = {
        browser_type: 'Other',
        v: 'Other',
        count: count
      };
      data.push(otherTotals);
      return data;
    }

    /**
     * Cast Count into Number to remove 1.111E7 for large numbers.
     * @param  {Array} data
     * @return {Array}
     */
    function castToNumber(data) {
      for (var i = 0; i < data.length; i++) {
        data[i].count = parseInt(Number(data[i].count), 10);
      }

      return data;
    }

    var readData = function(data) {
      data = filterData(data);
      data = castToNumber(data);
      globalData = data;
      typeOfData = 'impressions';
      width = InsightsBrowserConfig.width();

      var height = d3
        .select(InsightsBrowserConfig.chartId)
        .node()
        .getBoundingClientRect().height;
      var radius = Math.min(width, height) / 2.3;
      var labelRadius = radius + 5;
      var colour = InsightsBrowserConfig.colors;
      var arc = d3.svg
        .arc()
        .outerRadius(radius - InsightsBrowserConfig.outerRadius)
        .innerRadius(radius - InsightsBrowserConfig.innerRadius);

      var pie = d3.layout
        .pie()
        .sort(null)
        .value(function(d) {
          return d.count;
        });

      var svg = d3
        .select(InsightsBrowserConfig.chartId)
        .append('svg')
        .attr('width', '100%')
        .attr('height', '100%')
        .style('position', 'relative')
        .style('z-index', '10')
        .attr(
          'viewBox',
          '0 -25 ' + Math.min(width, height) + ' ' + Math.min(width, height)
        )
        .attr('preserveAspectRatio', 'xMinYMin meet')
        .append('g')
        .attr(
          'transform',
          'translate(' + width / 2 + ',' + (height / 2 - 30) + ')'
        );

      path = svg
        .datum(globalData)
        .selectAll('path')
        .data(pie(globalData))
        .enter()
        .append('path');

      path
        .attr('fill', function(d, i) {
          return colour(i);
        })
        .transition()
        .delay(function(d, i) {
          return i * 500;
        })
        .duration(500)
        .attrTween('d', function(d) {
          var i = d3.interpolate(d.startAngle + 0.1, d.endAngle);
          return function(t) {
            d.endAngle = i(t);
            return arc(d);
          };
        });

      // Labels
      svg.append('g').attr('class', 'labels');
      var labels = d3.select(InsightsBrowserConfig.chartId + ' .labels');
      var enteringLabels = labels
        .selectAll('.label')
        .data(pie(globalData))
        .enter();
      var labelGroups = enteringLabels.append('g').attr('class', 'label');
      labelGroups.append('circle').attr({
        x: 0,
        y: 0,
        r: 2,
        fill: '#000',
        transform: function(d) {
          return 'translate(' + arc.centroid(d) + ')';
        },

        class: 'label-circle'
      });

      var textLines = labelGroups.append('line').attr({
        x1: function(d) {
          return arc.centroid(d)[0];
        },

        y1: function(d) {
          return arc.centroid(d)[1];
        },

        x2: function(d) {
          return arc.centroid(d)[0];
        },

        y2: function(d) {
          var centroid = arc.centroid(d);
          var midAngle = Math.atan2(centroid[1], centroid[0]);
          return Math.sin(midAngle) * labelRadius;
        },

        class: 'label-line'
      });

      var textLabels = labelGroups.append('text').attr({
        x: function(d) {
          var centroid = arc.centroid(d);
          return centroid[0] + 3;
        },

        y: function(d) {
          var centroid = arc.centroid(d);
          var midAngle = Math.atan2(centroid[1], centroid[0]);
          return Math.sin(midAngle) * (labelRadius + 5);
        },

        'text-anchor': function(d) {
          var centroid = arc.centroid(d);
          var midAngle = Math.atan2(centroid[1], centroid[0]);
          var x = Math.cos(midAngle) * labelRadius;
          return x > 0 ? 'start' : 'end';
        },

        class: 'label-text'
      });

      textLabels
        .append('tspan')
        .attr({
          x: function(d) {
            var centroid = arc.centroid(d);
            return centroid[0] + 3;
          },

          y: function(d) {
            var centroid = arc.centroid(d);
            var midAngle = Math.atan2(centroid[1], centroid[0]);
            return Math.sin(midAngle) * (labelRadius + 5);
          },

          class: 'label-text-span'
        })
        .text(function(d) {
          return d.data.browser_type;
        });

      textLabels
        .append('tspan')
        .attr({
          x: function(d) {
            var centroid = arc.centroid(d);
            return centroid[0] + 3;
          },

          y: function(d) {
            var centroid = arc.centroid(d);
            var midAngle = Math.atan2(centroid[1], centroid[0]);
            var y = Math.sin(midAngle) * (labelRadius + 5);
            return y + 12;
          },

          class: 'label-text-span'
        })
        .text(function(d) {
          var total = 0;
          for (var i = 0; i < globalData.length; i++) {
            total += parseInt(Number(globalData[i].count), 10);
          }

          var percentage = Math.round((d.value / total) * 100 * 10) / 10;
          return percentage + '%';
        });

      var alpha = 0.5;
      var spacing = 24;

      function relax() {
        var again = false;
        textLabels.each(function() {
          var a = this; // jscs:ignore safeContextKeyword
          var da = d3.select(a);
          var y1 = da.attr('y');
          textLabels.each(function() {
            var b = this; // jscs:ignore safeContextKeyword

            // a & b are the same element and don't collide
            if (a === b) return;
            var db = d3.select(b);

            // a & b are on opposite sides of the chart and
            // don't collide
            if (da.attr('text-anchor') !== db.attr('text-anchor')) return;

            // Now let's calculate the distance between
            // these elements
            var y2 = db.attr('y');
            var deltaY = y1 - y2;

            // If spacing is greater than our specified spacing,
            // they don't collide
            if (Math.abs(deltaY) > spacing) return;

            // If the labels collide, we'll push each
            // of the two labels up and down a little bit.
            again = true;
            var sign = deltaY > 0 ? 1 : -1;
            var adjust = sign * alpha;
            da.attr('y', +y1 + adjust);
            db.attr('y', +y2 - adjust);
            d3.select(a.childNodes[0]).attr('y', +y1 + adjust);
            d3.select(b.childNodes[0]).attr('y', +y2 - adjust);
            d3.select(a.childNodes[1]).attr('y', +y1 + adjust + 12);
            d3.select(b.childNodes[1]).attr('y', +y2 - adjust + 12);
          });
        });

        // Adjust our line leaders here
        // so that they follow the labels.
        if (again) {
          var labelElements = textLabels[0];
          textLines.attr('y2', function(d, i) {
            var labelForLine = d3.select(labelElements[i]);
            return labelForLine.attr('y');
          });

          setTimeout(relax, 20);
        }
      }

      commonWebsite.hideSpinner('browser-chart');

      function changeVs() {
        typeOfData = angular
          .element(document.querySelector('.switch.active'))
          .attr('value');
        var overlay = document.querySelector(
          InsightsBrowserConfig.chartId + ' .overlay'
        );
        var variable = document
          .querySelector('.variable-dropdown .ng-scope')
          .innerHTML.trim();

        // check if the variable is not state as if it is state we are showing the age chart but not for other categories
        if (variable.length > 0 && variable != 'STATE') {
          return;
        }

        if (typeOfData === 'Impressions') {
          angular.element(overlay).removeClass('active');
        } else if (typeOfData === 'Clicks') {
          angular.element(overlay).addClass('active');
        } else if (typeOfData === 'UA') {
          angular.element(overlay).addClass('active');
          d3.selectAll('#no-device-msg').style('opacity', '0');
        }
      }

      // scales and axes
      d3.selectAll('.switch').on('click', changeVs);
    };

    function changeDate(data) {
      var chartId = InsightsBrowserConfig.chartId;
      var chart = d3.select(chartId);
      chart.selectAll('svg').remove();
      readData(data);
    }

    return {
      checkService: function(jsonData) {
        var chartId = InsightsBrowserConfig.chartId;
        var chart = d3.select(chartId);
        chart.selectAll('svg').remove();
        return readData(jsonData);
      },

      resize: function() {
        var chartId = InsightsBrowserConfig.chartId;
        var chart = d3.select(chartId);
        chart.selectAll('svg').remove();
        return readData(globalData);
      },

      changeDate: changeDate
    };
  });
