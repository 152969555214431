(function() {
  'use strict';

  angular.module('websites').factory('areaChartWebsite', areaChartWebsite);

  areaChartWebsite.$inject = [
    '$http',
    'commonWebsite',
    'InsightsArea',
    '$q',
    '$log',
    'downloads'
  ];

  function areaChartWebsite(
    $http,
    commonWebsite,
    InsightsArea,
    $q,
    $log,
    downloads
  ) {
    return {
      chart: chart,
      resize: resize,
      changeDate: changeDate,
      getData: getData
    };

    /**
     * Area Chart based on date period
     * @param {string} period
     * @param {string} websiteId
     * @param {Object} startEnd - Insight start and end dates
     * @param {string} chartType - area, state or postcode.
     * @param {string} typeOfData - ua, impressions or clicks.
     * @param {Object=} variable
     */
    function chart(
      period,
      websiteId,
      startEnd,
      chartType,
      typeOfData,
      variable
    ) {
      var companyUID = commonWebsite.getUID();
      if (period === 'month') {
        monthChart(
          websiteId,
          companyUID,
          startEnd.end,
          chartType,
          typeOfData,
          variable
        );
      } else if (period === 'week') {
        weekChart(
          websiteId,
          companyUID,
          startEnd.end,
          chartType,
          typeOfData,
          variable
        );
      } else if (period === 'day') {
        dayChart(
          websiteId,
          companyUID,
          startEnd.end,
          chartType,
          typeOfData,
          variable
        );
      }
    }

    /**
     * Month Area Chart
     * @param {string} websiteId
     * @param {?string} endDate - Campaign End Date YYYY-MM-DD
     * @param {string} chartType - area, state or postcode.
     * @param {string} typeOfData - ua, impressions or clicks.
     * @param {Object=} variable
     */
    function monthChart(
      websiteId,
      companyUID,
      endDate,
      chartType,
      typeOfData,
      variable
    ) {
      var startOfMonth = moment(endDate || undefined)
        .startOf('month')
        .format('YYYY-MM-DD');

      $http
        .post('/api/insights/website', {
          campaign: websiteId,
          companyUID: companyUID,
          endDate: startOfMonth,
          chart: chartType,
          period: 'month',
          type: 'website',
          variable: variable,
          focus: typeOfData
        })
        .then(
          function(result) {
            if (result.data.insights.length > 0) {
              var insights = result.data.insights;

              InsightsArea.checkService(insights, chartType);
            } else {
              commonWebsite.hideSpinner('area-chart', 'showNoDataMsg');
            }
          },
          function(reason) {
            commonWebsite.hideSpinner('area-chart', 'showNoDataMsg');
            $log.error('Error: website-gender-month');
            $log.error(reason);
          }
        );
    }

    /**
     * Week Area Chart
     * @param {string} websiteId
     * @param {?string} endDate - Campaign End Date YYYY-MM-DD
     * @param {string} chartType - area, state or postcode.
     * @param {string} typeOfData - ua, impressions or clicks.
     * @param {Object=} variable
     */
    function weekChart(
      websiteId,
      companyUID,
      endDate,
      chartType,
      typeOfData,
      variable
    ) {
      var startOfWeek = moment(endDate || undefined)
        .startOf('isoWeek')
        .format('YYYY-MM-DD');
      $http
        .post('/api/insights/website', {
          campaign: websiteId,
          companyUID: companyUID,
          endDate: startOfWeek,
          chart: chartType,
          period: 'week',
          type: 'website',
          variable: variable,
          focus: typeOfData
        })
        .then(
          function(result) {
            if (result.data.insights.length > 0) {
              var insights = result.data.insights;

              InsightsArea.checkService(insights, chartType);
            } else {
              commonWebsite.hideSpinner('area-chart', 'showNoDataMsg');
            }
          },
          function(reason) {
            commonWebsite.hideSpinner('area-chart', 'showNoDataMsg');
            $log.error('Error: website-gender-week');
            $log.error(reason);
          }
        );
    }

    /**
     * Day Area Chart
     * @param {string} websiteId
     * @param {?string} endDate - Campaign End Date YYYY-MM-DD
     * @param {string} chartType - area, state or postcode.
     * @param {string} typeOfData - ua, impressions or clicks.
     * @param {Object=} variable
     */
    function dayChart(
      websiteId,
      companyUID,
      endDate,
      chartType,
      typeOfData,
      variable
    ) {
      var yesterday = commonWebsite.formatEndDate();
      $http
        .post('/api/insights/website', {
          campaign: websiteId,
          companyUID: companyUID,
          endDate: endDate || yesterday,
          chart: chartType,
          period: 'day',
          type: 'website',
          variable: variable,
          focus: typeOfData
        })
        .then(
          function(result) {
            if (result.data.insights.length > 0) {
              var insights = result.data.insights;

              InsightsArea.checkService(insights, chartType);
            } else {
              commonWebsite.hideSpinner('area-chart', 'showNoDataMsg');
            }
          },
          function(reason) {
            commonWebsite.hideSpinner('area-chart', 'showNoDataMsg');
            $log.error('Error: website-gender-day');
            $log.error(reason);
          }
        );
    }

    /**
     * Resize chart
     */
    function resize() {
      InsightsArea.resize();
    }

    /**
     * Change Date
     * @param {string} websiteId
     * @param {string} period - Date period
     * @param {Object} startEnd - Insight start and end dates
     * @param {string} chartType - area, state or postcode.
     * @param {string} typeOfData - ua, impressions or clicks.
     * @param {Object=} variable
     */
    function changeDate(
      websiteId,
      period,
      startEnd,
      chartType,
      typeOfData,
      variable
    ) {
      var companyUID = commonWebsite.getUID();
      if (period === 'month') {
        monthChart(
          websiteId,
          companyUID,
          startEnd.end,
          chartType,
          typeOfData,
          variable
        );
      } else if (period === 'week') {
        weekChart(
          websiteId,
          companyUID,
          startEnd.end,
          chartType,
          typeOfData,
          variable
        );
      } else if (period === 'day') {
        dayChart(
          websiteId,
          companyUID,
          startEnd.end,
          chartType,
          typeOfData,
          variable
        );
      }
    }

    /**
     * Which data to download
     * @param {string} websiteId
     * @param {string} period - Date period
     * @param {Object} startEnd - Insight start and end dates
     * @param {string} chartType - area, state or postcode.
     * @param {Object} variable
     * @param {string} [typeOfData] - ua, impressions, clicks, population.
     * @returns {Promise} Promise
     */
    function getData(
      websiteId,
      period,
      startEnd,
      chartType,
      variable,
      typeOfData
    ) {
      var companyUID = commonWebsite.getUID();
      if (period === 'day') {
        return getDataDay(
          websiteId,
          companyUID,
          startEnd.end,
          chartType,
          typeOfData,
          variable
        );
      }
      if (period === 'week') {
        return getDataWeek(
          websiteId,
          companyUID,
          startEnd.end,
          chartType,
          typeOfData,
          variable
        );
      }
      return getDataMonth(
        websiteId,
        companyUID,
        startEnd.end,
        chartType,
        typeOfData,
        variable
      );
    }

    /**
     * Get Day Data for Download
     * @param {string} websiteId
     * @param {?string} endDate - Campaign End Date YYYY-MM-DD
     * @param {string} chartType - area, state or postcode.
     * @param {?string} typeOfData - ua, impressions, clicks or population.
     * @param {Object=} variable
     * @returns {Promise} Promise
     */
    function getDataDay(
      websiteId,
      companyUID,
      endDate,
      chartType,
      typeOfData,
      variable
    ) {
      var yesterday = commonWebsite.formatEndDate();
      return $q(function(resolve, reject) {
        $http
          .post('/api/insights/website', {
            campaign: websiteId,
            companyUID: companyUID,
            endDate: endDate || yesterday,
            chart: chartType,
            period: 'day',
            type: 'website',
            variable: variable,
            focus: typeOfData === 'population' ? 'ua' : typeOfData
          })
          .then(
            function(result) {
              InsightsArea.roundData(result.data.insights, chartType).then(
                function(res) {
                  var data = formatDownloads(
                    res,
                    'Date',
                    endDate || yesterday,
                    chartType,
                    typeOfData
                  );
                  resolve(data);
                }
              );
            },
            function(reason) {
              $log.error('Error: website-area-data-day');
              $log.error(reason);
              reject();
            }
          );
      });
    }

    /**
     * Get Week Data for Download
     * @param {string} websiteId
     * @param {?string} endDate - Campaign End Date YYYY-MM-DD
     * @param {string} chartType - area, state or postcode.
     * @param {?string} typeOfData - ua, impressions, clicks or population.
     * @param {Object=} variable
     * @returns {Promise} Promise
     */
    function getDataWeek(
      websiteId,
      companyUID,
      endDate,
      chartType,
      typeOfData,
      variable
    ) {
      var startOfWeek = moment(endDate || undefined)
        .startOf('isoWeek')
        .format('YYYY-MM-DD');
      return $q(function(resolve, reject) {
        $http
          .post('/api/insights/website', {
            campaign: websiteId,
            companyUID: companyUID,
            endDate: startOfWeek,
            chart: chartType,
            period: 'week',
            type: 'website',
            variable: variable,
            focus: typeOfData === 'population' ? 'ua' : typeOfData
          })
          .then(
            function(result) {
              InsightsArea.roundData(result.data.insights, chartType).then(
                function(res) {
                  var data = formatDownloads(
                    res,
                    'Start_Date_of_Week',
                    startOfWeek,
                    chartType,
                    typeOfData
                  );
                  resolve(data);
                }
              );
            },
            function(reason) {
              $log.error('Error: website-gender-data-week');
              $log.error(reason);
              reject();
            }
          );
      });
    }

    /**
     * Get Month Data for Download
     * @param {string} websiteId
     * @param {?string} endDate - Campaign End Date YYYY-MM-DD
     * @param {string} chartType - area, state or postcode.
     * @param {?string} typeOfData - ua, impressions, clicks or population.
     * @param {Object=} variable
     * @returns {Promise} Promise
     */
    function getDataMonth(
      websiteId,
      companyUID,
      endDate,
      chartType,
      typeOfData,
      variable
    ) {
      var startOfMonth = moment(endDate || undefined)
        .startOf('month')
        .format('YYYY-MM-DD');
      return $q(function(resolve, reject) {
        $http
          .post('/api/insights/website', {
            campaign: websiteId,
            companyUID: companyUID,
            endDate: startOfMonth,
            chart: chartType,
            period: 'month',
            type: 'website',
            variable: variable,
            focus: typeOfData === 'population' ? 'ua' : typeOfData
          })
          .then(function(result) {
            InsightsArea.roundData(result.data.insights, chartType).then(
              function(res) {
                var data = formatDownloads(
                  res,
                  'Start_Date_of_Month',
                  startOfMonth,
                  chartType,
                  typeOfData
                );
                resolve(data);
              }
            );
          })
          .catch(function(error) {
            $log.error('Error: website-area-data-month');
            $log.error(error);
            reject();
          });
      });
    }

    /**
     * Format Download data
     * @param {Object[]} rawData
     * @param {string} dateHeading
     * @param {string} date
     * @param {string} chartType
     * @param {string} typeOfData - ua, impressions or clicks.
     * @returns {Object[]}
     */
    function formatDownloads(
      rawData,
      dateHeading,
      date,
      chartType,
      typeOfData
    ) {
      var data = downloads.addHeading(rawData, dateHeading, date);
      data = downloads.renameHeading(data, 'impressions', 'Impressions');
      if (chartType === 'state') {
        data = downloads.renameHeading(data, 'Area', 'State');
      }
      if (chartType === 'postcode') {
        data = downloads.renameHeading(data, 'Area', 'Postcode');

        if (typeOfData === 'ua') {
          data = data.map(function(d) {
            d.UA = Number(d.UA || 0);
            return d;
          });
        }
        if (typeOfData === 'impressions') {
          data = data.map(function(d) {
            d.Impressions = Number(d.Impressions || 0);
            return d;
          });
        }
      }
      var headingOrder = [];
      if (chartType === 'area') {
        headingOrder = [
          dateHeading,
          'Area',
          'UA',
          '% of Area Reached',
          'Impressions'
        ];
      } else if (chartType === 'state') {
        if (typeOfData !== 'population') {
          headingOrder = [
            dateHeading,
            'State',
            'UA',
            '% of Area Reached',
            'Impressions'
          ];
        } else {
          headingOrder = ['State', '% of Area Reached'];
        }
      } else if (chartType === 'postcode') {
        headingOrder = ['Postcode'];
        if (typeOfData === 'ua') {
          headingOrder.push('UA');
        } else if (typeOfData === 'impressions') {
          headingOrder.push('Impressions');
        } else if (typeOfData === 'population') {
          headingOrder.push('% of Area Reached');
        }
      }
      data = downloads.orderFields(data, headingOrder);

      return data;
    }
  }
})();
