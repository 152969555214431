(function() {
  'use strict';

  angular
    .module('campaigns')
    .factory('genderChartCampaign', genderChartCampaign);

  genderChartCampaign.$inject = [
    '$http',
    'commonCampaign',
    'InsightsGender',
    '$q',
    '$log'
  ];
  function genderChartCampaign(
    $http,
    commonCampaign,
    InsightsGender,
    $q,
    $log
  ) {
    return {
      chart: chart,
      resize: resize,
      changeDate: changeDate
    };

    /**
     * StartEnd type
     * @typedef {Object} StartEnd
     * @property {string} start Campaign start date
     * @property {?string} end Campaign end date or selected date.
     */
    /**
     * Variable type
     * @typedef {Object} Variable
     * @property {string} variable Variable name
     * @property {string} file Variable filename
     */

    /**
     * Gender Chart based on date period
     * @param {string} period
     * @param {string} campaignId
     * @param {StartEnd} startEnd - Insight start and end dates
     * @param {Object} variable
     */
    function chart(period, campaignId, startEnd, variable) {
      var companyUID = commonCampaign.getUID();
      if (period === 'all') {
        allChart(campaignId, companyUID, startEnd.end, variable);
      } else if (period === 'month') {
        monthChart(campaignId, companyUID, startEnd.end, variable);
      } else if (period === 'week') {
        weekChart(campaignId, companyUID, startEnd.end, variable);
      } else if (period === 'day') {
        dayChart(campaignId, companyUID, startEnd.end, variable);
      }
    }

    /**
     * All Time Gender Chart
     * @param {string} campaignId
     * @param {?string} endDate - Campaign End Date YYYY-MM-DD
     * @param {Object} variable
     */
    function allChart(campaignId, companyUID, endDate, variable) {
      var yesterday = commonCampaign.formatEndDate();

      $http
        .post('/api/insights/campaign', {
          campaign: campaignId,
          companyUID: companyUID,
          endDate: endDate || yesterday,
          chart: 'gender',
          period: 'all',
          type: 'campaign',
          variable: variable
        })
        .then(
          function(result) {
            if (result.data.insights.length > 0) {
              var insights = result.data.insights;
              InsightsGender.checkService(insights);
            } else {
              commonCampaign.hideSpinner('gender-chart', 'showNoDataMsg');
              InsightsGender.clearData();
            }
          },
          function(reason) {
            commonCampaign.hideSpinner('gender-chart', 'showNoDataMsg');
            InsightsGender.clearData();
            $log.error('Error: campaign-gender-all');
            $log.error(reason);
          }
        );
    }

    /**
     * Month Gender Chart
     * @param {string} campaignId
     * @param {?string} endDate - Campaign End Date YYYY-MM-DD
     * @param {Object} variable
     */
    function monthChart(campaignId, companyUID, endDate, variable) {
      var startOfMonth = moment(endDate || undefined)
        .startOf('month')
        .format('YYYY-MM-DD');

      $http
        .post('/api/insights/campaign', {
          campaign: campaignId,
          companyUID: companyUID,
          endDate: startOfMonth,
          chart: 'gender',
          period: 'month',
          type: 'campaign',
          variable: variable
        })
        .then(
          function(result) {
            if (result.data.insights.length > 0) {
              var insights = result.data.insights;
              InsightsGender.checkService(insights);
            } else {
              commonCampaign.hideSpinner('gender-chart', 'showNoDataMsg');
              InsightsGender.clearData();
            }
          },
          function(reason) {
            commonCampaign.hideSpinner('gender-chart', 'showNoDataMsg');
            InsightsGender.clearData();
            $log.error('Error: campaign-gender-month');
            $log.error(reason);
          }
        );
    }

    /**
     * Week Gender Chart
     * @param {string} campaignId
     * @param {?string} endDate - Campaign End Date YYYY-MM-DD
     * @param {Object} variable
     */
    function weekChart(campaignId, companyUID, endDate, variable) {
      var startOfWeek = moment(endDate || undefined)
        .startOf('isoWeek')
        .format('YYYY-MM-DD');

      $http
        .post('/api/insights/campaign', {
          campaign: campaignId,
          companyUID: companyUID,
          endDate: startOfWeek,
          chart: 'gender',
          period: 'week',
          type: 'campaign',
          variable: variable
        })
        .then(
          function(result) {
            if (result.data.insights.length > 0) {
              var insights = result.data.insights;
              InsightsGender.checkService(insights);
            } else {
              commonCampaign.hideSpinner('gender-chart', 'showNoDataMsg');
              InsightsGender.clearData();
            }
          },
          function(reason) {
            commonCampaign.hideSpinner('gender-chart', 'showNoDataMsg');
            InsightsGender.clearData();
            $log.error('Error: campaign-gender-week');
            $log.error(reason);
          }
        );
    }

    /**
     * Day Gender Chart
     * @param {string} campaignId
     * @param {?string} endDate - Campaign End Date YYYY-MM-DD
     * @param {Variable=} variable
     */
    function dayChart(campaignId, companyUID, endDate, variable) {
      var yesterday = commonCampaign.formatEndDate();

      $http
        .post('/api/insights/campaign', {
          campaign: campaignId,
          companyUID: companyUID,
          endDate: endDate || yesterday,
          chart: 'gender',
          period: 'day',
          type: 'campaign',
          variable: variable
        })
        .then(
          function(result) {
            if (result.data.insights.length > 0) {
              var insights = result.data.insights;
              InsightsGender.checkService(insights);
            } else {
              commonCampaign.hideSpinner('gender-chart', 'showNoDataMsg');
              InsightsGender.clearData();
            }
          },
          function(reason) {
            commonCampaign.hideSpinner('gender-chart', 'showNoDataMsg');
            InsightsGender.clearData();
            $log.error('Error: campaign-gender-day');
            $log.error(reason);
          }
        );
    }

    /**
     * Resize chart
     */
    function resize() {
      InsightsGender.resize();
    }

    /**
     * Change Date
     * @param {string} campaignId
     * @param {StartEnd} startEnd - Insight start and end dates.
     * @param {string} period - Date period
     * @param {Object} variable
     */
    function changeDate(campaignId, startEnd, period, variable) {
      var companyUID = commonCampaign.getUID();
      if (period === 'month') {
        monthChart(campaignId, companyUID, startEnd.end, variable);
      } else if (period === 'week') {
        weekChart(campaignId, companyUID, startEnd.end, variable);
      } else if (period === 'day') {
        dayChart(campaignId, companyUID, startEnd.end, variable);
      }
    }
  }
})();
