'use strict';

angular.module('core').controller('NotificationsController', [
  'Settings',
  '$rootScope',
  'store',
  'Authentication',
  function(Settings, $rootScope, store, Authentication) {
    var vm = this;
    vm.loggedIn = false;
    vm.closeAlert = closeAlert;

    $rootScope.$on('loggedIn', function() {
      vm.loggedIn = true;
      Settings.checkNotification();
    });

    Authentication.isLoggedIn().then(function(isLoggedIn) {
      if (isLoggedIn) {
        vm.loggedIn = true;
        Settings.checkNotification();
      }
    });

    function closeAlert() {
      store.set('notification-dismissed', $rootScope.alert.lastUpdated);
      $rootScope.alert = null;
    }
  }
]);
