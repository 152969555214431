(function() {
  'use strict';

  angular.module('core').factory('AudiencesApi', AudiencesApi);

  AudiencesApi.$inject = ['$resource', '$http'];

  function AudiencesApi($resource, $http) {
    return {
      resource: resource,
      get: get,
      post: post,
      put: put
    };

    function resource(endpoint, paramDefaults, actions) {
      var url = getUrl(endpoint);
      return $resource(url, paramDefaults, actions);
    }

    function get(endpoint) {
      var url = getUrl(endpoint);
      return $http.get(url);
    }

    function post(endpoint, body) {
      var url = getUrl(endpoint);
      return $http.post(url, body);
    }

    function put(endpoint, body) {
      var url = getUrl(endpoint);
      return $http.put(url, body);
    }

    function getUrl(endpoint) {
      return getAudiencesLiveApiUrl() + endpoint;
    }

    function getAudiencesLiveApiUrl() {
      switch (document.location.hostname) {
        case 'audiences.roymorgan.com':
          return 'https://liveapi.roymorgan.com/audiences/v2';
        case 'audiences.roymorganuat.com':
          return 'https://liveapi.roymorganuat.com/audiences/v2';
        default:
          return 'https://liveapi.roymorgandev.com/audiences/v2';
      }
    }
  }
})();
