'use strict';

angular.module('core').controller('HomeController', [
  '$scope',
  '$location',
  '$cookies',
  'Authorization',
  'Authentication',
  'RmrAuthenticationService',
  '$rootScope',
  'spinnerService',
  'Settings',
  '$window',
  'store',
  '$uibModal',
  'HelpersService',
  'AudiencesApi',
  function(
    $scope,
    $location,
    $cookies,
    Authorization,
    Authentication,
    RmrAuthenticationService,
    $rootScope,
    spinnerService,
    Settings,
    $window,
    store,
    $uibModal,
    HelpersService,
    audiencesApi
  ) {
    $scope.loggedIn = false;
    $scope.processing = true;
    $scope.showLoginDialog = false;
    $scope.loginError = null;
    $rootScope.title = 'Live Audience Evaluation';
    $scope.user = store.get('client');
    $scope.animationsEnabled = true;
    $scope.loginViewClass = 'login-view';
    var versionNumber;

    $scope.onLogin = function(response) {
      Authorization.authorize(true).then(function(result) {
        if (!result) {
          $scope.loginError =
            'User does not have permission to enter this website.';
          return;
        }
        $scope.user = store.get('client');
        $scope.loginError = null;
        $window.location.reload();
      });
    };

    $scope.hasPermission = function(allowedPermissions) {
      var allowed = false;
      if ($scope.permissions && $scope.permissions.length) {
        allowedPermissions.forEach(function(permission) {
          if ($scope.permissions.indexOf(permission) > -1) {
            allowed = true;
          }
        });
      }

      return allowed;
    };

    $scope.getResource = function() {
      spinnerService.show('homeSpinner');
      $scope.user = store.get('client');
      Authorization.authorize(true).then(function(isAuth) {
        if (isAuth) {
          initialise();
          $scope.modalContent = '';
          // check user status for the release
          audiencesApi.get('/accessed-releases/latest').then(function(result) {
            var show =
              result.data &&
              result.data.notificationEnabled &&
              !result.data.hasAccessed;
            if (show) {
              // open notification model
              var modalInstance = $uibModal.open({
                animation: $scope.animationsEnabled,
                templateUrl: 'notificationModal.html',
                controller: function($scope, $uibModalInstance) {
                  // update the model content
                  $scope.modalContent = result.data.notificationContent;
                  $scope.version = result.data.version;
                  versionNumber = result.data.version;
                  $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                  };
                }
              });
              // call function when modal is dismissed - insert record ion
              modalInstance.result.then(
                function() {
                  // Get triggers when modal is closed
                },
                function() {
                  var data = {
                    version: versionNumber
                  };

                  // gets triggers when modal is dismissed.
                  audiencesApi
                    .post('/accessed-releases', data)
                    .then(function(result) {
                      console.log('saved');
                    });
                }
              );
            }
          });
        } else {
          var ssoauth = $location.search().SSOAUTH;
          console.log(ssoauth);
          if (ssoauth) {
            $cookies.put('SSOAUTH', ssoauth);
            var ssoCookie = JSON.parse(ssoauth);
            console.log('sso cookie ');
            console.log(ssoCookie);
            if (ssoCookie.status === 200) {
              RmrAuthenticationService.setToken(ssoCookie.body.Token);
              $scope.onLogin(ssoCookie.body);
            }
          } else {
            angular
              .element($window.document.querySelector('#panel'))
              .addClass($scope.loginViewClass);
            angular
              .element($window.document.querySelector('body'))
              .addClass($scope.loginViewClass);
            $scope.showLoginDialog = true;
          }
        }
      });
    };

    function initialise() {
      angular
        .element($window.document.querySelector('#panel'))
        .removeClass($scope.loginViewClass);
      angular
        .element($window.document.querySelector('body'))
        .removeClass($scope.loginViewClass);
      Settings.checkNotification();
      $scope.permissions = Authorization.permissions();
      spinnerService.hide('homeSpinner');
      $scope.loggedIn = true;
      $scope.processing = false;
    }

    $scope.toggleAnimation = function() {
      $scope.animationsEnabled = !$scope.animationsEnabled;
    };
  }
]);
