'use strict';

// Add token to all http requests
angular
  .module('users')
  .factory('clientIDInterceptor', [
    'Authentication',
    'store',
    function(Authentication, store) {
      return {
        request: function(config) {
          var currentCompany = store.get('currentCompany');
          var currentTeam = store.get('currentTeam');
          var clientInfo = store.get('client');
          if (currentCompany && clientInfo) {
            // headers for Pixel MEANJS API
            config.headers.ClientID = currentCompany.id;
            config.headers.RandClientID = currentCompany.randomId;
            config.headers.CompanyName = currentCompany.name;
            config.headers.UserName = clientInfo.userName;

            // headers for Audiences Live API
            config.headers['context-organisation'] = currentCompany.id;
            config.headers['context-team'] = currentTeam && currentTeam.id;
          }
          return config;
        }
      };
    }
  ])
  .config(function($httpProvider) {
    $httpProvider.interceptors.push('clientIDInterceptor');
  });
