'use strict';

// https://github.com/nervgh/angular-file-upload/
angular.module('upload').controller('DocumentsController', [
  '$scope',
  '$q',
  '$location',
  '$rootScope',
  'Documents',
  'SignedUrlHelper',
  'Authorization',
  function(
    $scope,
    $q,
    $location,
    $rootScope,
    Documents,
    SignedUrlHelper,
    Authorization
  ) {
    Authorization.authorize();
    $scope.selectedDocument = null;
    $scope.error = null;
    $scope.documents = [];

    $rootScope.title = 'Upload';

    $scope.init = function() {
      $scope.documentQuery();
      $scope.error = null;
    };

    $scope.getSignedUrl = function(fileItem) {
      // console.log('++++++++++++++++++SignedUrlHelper getSignedUrl DocumentsController');
      // console.log(fileItem);
      var filename = fileItem.name;
      var deferred = $q.defer();
      var promise = SignedUrlHelper.getSignedUrlForDocument(filename);
      promise.then(
        function(data) {
          deferred.resolve(data.data.signedUrl);
        },
        function(err) {
          deferred.reject(err);
        }
      );
      return deferred.promise;
    };

    $scope.onSuccessUploadFile = function(fileItem, resp) {
      console.log('+++++++++++onSuccessUploadFile');
      console.log($scope.selectedReportFolder);
      $scope.documentQuery($scope.selectedReportFolder);
    };

    $scope.onBeforeUploadFile = function(fileItem) {
      $scope.error = null;
      var deferred = $q.defer();
      var filename = fileItem.name.toLowerCase();
      var extension = filename.substring(filename.lastIndexOf('.') + 1);
      console.log('extension: ' + extension);
      if (extension !== 'json') {
        deferred.reject({ data: { message: 'File is not a JSON file' } });
      } else {
        var reader = new FileReader();
        reader.onload = function(event) {
          var text = event.target.result;
          try {
            JSON.parse(text);
            deferred.resolve('do nothing');
          } catch (err) {
            deferred.reject({ data: { message: err } });
          }
        };
        reader.readAsText(fileItem);
      }
      return deferred.promise;
    };

    $scope.onErrorUploadFile = function(fileItem, resp) {
      $scope.documentRemove(null);
      console.log('error in uploading file: ' + JSON.stringify(resp));
      $scope.error = 'error in uploading file: ' + resp.data.message;
    };

    $scope.onProgressUploadFile = function(fileItem, evt) {};

    $scope.documentQuery = function() {
      Documents.query().$promise.then(function(data) {
        $scope.documents = data;
      }),
        function(error) {
          $scope.error =
            'listing document from aws s3 bucket failed: ' + error.message;
        };
      console.log($scope.documents);
    };

    $scope.documentRemove = function(document) {
      $scope.error = null;
      if (document) {
        // console.log(document);
        document.$remove(
          function(response) {
            // console.log(response);
            for (var i in $scope.documents) {
              if ($scope.documents[i] === document) {
                $scope.documents.splice(i, 1);
              }
            }
          },
          function(errorResponse) {
            // console.log(errorResponse);
            $scope.error = errorResponse.data.message;
          }
        );
      }
    };
  }
]);
