'use strict';

angular.module('core').controller('HeaderController', [
  '$scope',
  'Authentication',
  'Authorization',
  'Menus',
  '$location',
  'screenSize',
  '$rootScope',
  '$state',
  'campaignsResize',
  'websitesResize',
  '$window',
  function(
    $scope,
    Authentication,
    Authorization,
    Menus,
    $location,
    screenSize,
    $rootScope,
    $state,
    campaignsResize,
    websitesResize,
    $window
  ) {
    $scope.loggedIn = false;
    $scope.AUDIENCE_PRODUCT_FAMILY = 2;

    Authorization.authorize(true).then(function(isAuth) {
      if (isAuth) {
        $scope.loggedIn = true;
        $scope.permissions = Authorization.permissions();
      }
    });

    $scope.$on('loggedIn', function() {
      $scope.loggedIn = true;
      $scope.permissions = Authorization.permissions();
    });

    $rootScope.$on('teamChanged', function() {
      // Reload page to fix bug with Not-Authorised returned
      location.reload();
      $scope.permissions = Authorization.permissions();
    });

    $scope.slide = function() {
      $scope.isCollapsed ^= true;
      angular
        .element(document.querySelector('#side-header'))
        .toggleClass('wide');
      angular.element(document.querySelector('#panel')).toggleClass('wide');
      angular
        .element(document.querySelector('.btn-hamburger'))
        .toggleClass('closed');
      angular
        .element(document.querySelector('.small-logo img.rmr-logo'))
        .toggleClass('hidden');
      angular
        .element(document.querySelector('.small-logo img.rmr-logo-mobile'))
        .toggleClass('hidden');

      if (
        angular.element(document.querySelector('.campaign-insights')).length > 0
      ) {
        setTimeout(campaignsResize.resize, 750);
      }

      if (
        angular.element(document.querySelector('.website-insights')).length > 0
      ) {
        setTimeout(websitesResize.resize, 750);
      }
    };

    $scope.ssoCayzu = function(event) {
      event.preventDefault();
      Authentication.getSSOCayzuLink($scope.AUDIENCE_PRODUCT_FAMILY).then(
        function(data) {
          $window.open(data.link, '_blank');
        }
      );
    };

    $scope.isCollapsed = false;

    if (screenSize.is('xs, sm, md')) {
      $scope.slide();
    }

    screenSize.when('xs, sm, md', function() {
      if (!$scope.isCollapsed) {
        $scope.slide();
      }
    });

    screenSize.when('lg', function() {
      if ($scope.isCollapsed) {
        $scope.slide();
      }
    });

    $scope.hasPermission = function(allowed_permissions) {
      return Authorization.hasPermission(allowed_permissions);
    };

    $scope.hasGlobalPermission = function() {
      return Authorization.hasGlobalPermission();
    };

    // Register Page changes for Google Analytics
    var dataLayer = window.dataLayer || [];
    $rootScope.$on('$stateChangeSuccess', function() {
      dataLayer.push({
        event: 'stateChange',
        attributes: {
          route: $location.path()
        }
      });
    });
  }
]);
