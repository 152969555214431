(function() {
  'use strict';

  angular
    .module('insights')
    .factory('insightExclusionIds', insightExclusionIds);

  insightExclusionIds.$inject = ['$log', '$http', 'Utility'];

  function insightExclusionIds($log, $http, Utility) {
    return {
      hasId: hasId
    };

    /**
     * Check if Campaign is allowed to show gender/age chart
     * @param {string} campaignId
     * @returns {Promise<boolean>}
     */
    function hasId(campaignId) {
      var companyUID = Utility.getUID();
      return $http
        .get('/api/insights/' + campaignId + '/checkExclusion', {
          params: {
            companyUID: companyUID
          }
        })
        .then(function(result) {
          if (result && result.data) {
            return result.data;
          }
          return false;
        })
        .catch(function(error) {
          $log.error('Error: Campaign Exclusion Ids');
          $log.error(error);
          return false;
        });
    }
  }
})();
