'use strict';

// Pixels service used to communicate Pixels REST endpoints
angular.module('pixels').factory('PixelsWebsite', [
  'AudiencesApi',
  function(audiencesApi) {
    return audiencesApi.resource('/websites/:websiteId/pixels/:pixelId', {
      pixelId: '@pixelId',
      websiteId: '@websiteId'
    });
  }
]);
