'use strict';

// Resources module config
angular.module('resources').run([
  'Menus',
  function(Menus) {
    // Config logic
    // ...
  }
]);
