'use strict';

// Insights module config
angular.module('insights').run([
  'Menus',
  function(Menus) {
    // Config logic
    // ...
  }
]);
