(function() {
  'use strict';

  angular.module('campaigns').factory('campaignsResize', campaignsResize);

  campaignsResize.$inject = [
    'helixChartsCampaign',
    'premiumChartsCampaign',
    'uniqueChartCampaign',
    'cumulativeChartCampaign',
    'areaChartCampaign',
    'topWebsiteChartCampaign',
    'deviceChartCampaign',
    'localVsInternationalChartCampaign',
    'genderChartCampaign',
    'ageChartCampaign'
  ];

  function campaignsResize(
    helixChartsCampaign,
    premiumChartsCampaign,
    uniqueChartCampaign,
    cumulativeChartCampaign,
    areaChartCampaign,
    topWebsiteChartCampaign,
    deviceChartCampaign,
    localVsInternationalChartCampaign,
    genderChartCampaign,
    ageChartCampaign
  ) {
    return {
      resize: resizeCharts
    };

    /**
     * Resize campaign charts
     * @param  {string} [selectedRange] Optional
     * @param  {string} [datePeriod]    Optional
     * @param  {string} [datePicker]    Optional
     */
    function resizeCharts(selectedRange, datePeriod, datePicker) {
      selectedRange = selectedRange || getDateRange();
      datePicker = datePicker || getDatePicker();
      datePeriod = datePeriod || getDatePeriod();
      helixChartsCampaign.resize();
      premiumChartsCampaign.resize();
      uniqueChartCampaign.resize(selectedRange, datePeriod, datePicker);
      cumulativeChartCampaign.resize(selectedRange, datePeriod, datePicker);
      areaChartCampaign.resize();
      topWebsiteChartCampaign.resize();
      deviceChartCampaign.resize();
      localVsInternationalChartCampaign.resize();
      genderChartCampaign.resize();
      ageChartCampaign.resize();
    }

    /**
     * Get currently selected date range
     * @returns {?string}
     */
    function getDateRange() {
      var activeDateRange = document
        .getElementById('date-range-picker')
        .getElementsByClassName('selected');
      if (activeDateRange) {
        return activeDateRange[0].innerHTML;
      }
      return null;
    }

    /**
     * Get currently selected date period
     * @return {string} 'all', 'month', 'week', 'day'
     */
    function getDatePeriod() {
      var period = document
        .querySelector('.date-period-picker')
        .querySelector('.active')
        .innerHTML.toLowerCase();
      if (period === 'total') {
        period = 'all';
      }

      return period;
    }

    /**
     * Gets currently selected Date Picker
     * @return {string} Date in format YYYY-MM-DD
     */
    function getDatePicker() {
      var date = angular.element(document.querySelector('#date-picker')).val();
      return shortDateFormat(new Date(date));
    }

    /**
     * Return date in short format (YYYY-MM-DD)
     * @param {Date} date
     * @returns {string} - formatted date
     */
    function shortDateFormat(date) {
      return (
        date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
      );
    }
  }
})();
