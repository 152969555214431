(function() {
  'use strict';

  angular
    .module('settings')
    .controller('WebsiteTeamsController', WebsiteTeamsController);

  WebsiteTeamsController.$inject = [
    '$rootScope',
    'Websites',
    'WebsitesTeamAccess',
    'Teams',
    '$log',
    'Authorization',
    'store'
  ];

  function WebsiteTeamsController(
    $rootScope,
    Websites,
    WebsitesTeamAccess,
    Teams,
    $log,
    Authorization,
    store
  ) {
    var vm = this;
    Authorization.authorize().then(function() {
      vm.user = store.get('client');
      vm.permissions = Authorization.permissions();
      vm.permissionSet = Authorization.permissionSet();
      vm.currentCompany = store.get('currentCompany');
      vm.teams = Teams.getTeams(vm.user, vm.permissionSet, vm.currentCompany);
      getWebsites();
    });
    $rootScope.title = 'Website Teams';
    vm.toggleArchiveFilter = toggleArchiveFilter;
    vm.noToShowOptions = [
      { name: 10, value: 10 },
      { name: 20, value: 20 },
      { name: 'All', value: 0 }
    ];
    vm.noToShow = vm.noToShowOptions[0].value;
    vm.archive = 'Active';
    vm.orderByField = 'created';
    vm.reverseSort = true;
    vm.setTeam = setTeam;

    /**
     * Toggle displaying archived insights.
     */
    function toggleArchiveFilter() {
      if (vm.archive === '') {
        vm.archive = 'Active';
      } else {
        vm.archive = '';
      }
    }

    function getWebsites() {
      vm.websites = [];
      WebsitesTeamAccess.query(function(websites) {
        websites.forEach(function(website) {
          addTeamNameToWebsite(website);
        });
      });
    }

    function addTeamNameToWebsite(website) {
      if (website.teamId) {
        // Got team id, now get the team name
        getTeamName(website, website.teamId);
      } else {
        $log.warn('No team found for ' + website.name);
        vm.websites.push(website);
      }
    }

    function getTeamName(website, teamId) {
      Teams.getTeamName(vm.teams, teamId).then(function(teamName) {
        website.teamId = teamId;
        website.teamName = teamName;
        vm.websites.push(website);
      });
    }

    /**
     * Handle changing team for website.
     * @param {Object} team
     * @param {Object} website
     */
    function setTeam(team, website) {
      WebsitesTeamAccess.update(
        {
          websiteId: website.id
        },
        {
          teamId: team.id
        },
        function() {
          Teams.getTeamName(vm.teams, team.id).then(function(name) {
            website.teamName = name;
          });
        },
        function(err) {
          $log.error(err);
        }
      );
    }
  }
})();
