(function() {
  'use strict';

  angular
    .module('campaigns')
    .controller('CampaignsInsightsController', CampaignsInsightsController);

  CampaignsInsightsController.$inject = [
    'Campaigns',
    '$stateParams',
    '$rootScope',
    '$http',
    'InsightsConfig',
    'Authorization',
    'uniqueChartCampaign',
    'commonCampaign',
    'cumulativeChartCampaign',
    'deviceChartCampaign',
    'localVsInternationalChartCampaign',
    'helixChartsCampaign',
    'premiumChartsCampaign',
    'areaChartCampaign',
    'topWebsiteChartCampaign',
    'genderChartCampaign',
    'ageChartCampaign',
    'Settings',
    '$analytics',
    '$window',
    '$log',
    'Teams',
    '$document',
    'HelixPersonas',
    'Premium',
    '$timeout',
    'insightExclusionIds',
    'getLastDate',
    'getAusPopulation',
    'getAusStatePopulation',
    'store',
    'downloads',
    'convertToCSV',
    '$q'
  ];

  function CampaignsInsightsController(
    Campaigns,
    $stateParams,
    $rootScope,
    $http,
    InsightsConfig,
    Authorization,
    uniqueChartCampaign,
    commonCampaign,
    cumulativeChartCampaign,
    deviceChartCampaign,
    localVsInternationalChartCampaign,
    helixChartsCampaign,
    premiumChartsCampaign,
    areaChartCampaign,
    topWebsiteChartCampaign,
    genderChartCampaign,
    ageChartCampaign,
    Settings,
    $analytics,
    $window,
    $log,
    Teams,
    $document,
    HelixPersonas,
    Premium,
    $timeout,
    insightExclusionIds,
    getLastDate,
    getAusPopulation,
    getAusStatePopulation,
    store,
    downloads,
    convertToCSV,
    $q
  ) {
    /**
     * StartEnd type
     * @typedef {object} StartEnd
     * @property {string} start Campaign start date
     * @property {string} [end] Campaign end date or selected date.
     */

    /**
     * Variable type
     * @typedef {Object} Variable
     * @property {string} variable Variable name
     * @property {string} file Variable filename
     */

    var vm = this;
    vm.allowHelixChartSwitch = false; // ability to switch between Helix version has been removed (but keep code just in case) - https://roymorgan.atlassian.net/browse/RD-5439
    vm.currentDateToLog = null;
    vm.selectedVariable = null;
    vm.activeVariable = null;
    vm.premiumAccess = Authorization.hasPermission(['Premium']);
    vm.changeVariable = changeVariable;
    vm.changeVariableCategory = changeVariableCategory;
    vm.categorySelected = false;
    vm.activeAreaChart = 'area'; // default area chart type
    vm.areaCharts = ['area', 'state', 'postcode'];
    vm.changeAreaChart = changeAreaChart;
    vm.changeFocus = changeFocus;
    vm.activePersonasChart = 'Personas'; // default helix chart type
    vm.personaCharts = ['Personas', 'Communities'];
    vm.changePersonasChart = changePersonasChart;
    vm.showAreaChart = null;
    var variableFiles;
    var selectedVariableFile;
    var filtersWithIdx;
    var exclusionCheck = false;
    // year selected
    if (store.get('helixVersion')) {
      vm.yearSelected = store.get('helixVersion');
    } else {
      store.set('helixVersion', 2016);
      vm.yearSelected = 2016;
    }

    vm.changeYear = changeYear;

    vm.spinnerDesign = {
      radius: 25,
      width: 2,
      length: 30,
      color: '#00aec7'
    };
    /**
     * Set UA button label
     * - UA for window width less then 1500px
     * - Unique Audience greater than 1500px
     */
    vm.uaLabel = $window.innerWidth >= 1024 ? 'Unique Audience' : 'UA';
    vm.downloadLabel = $window.innerWidth >= 1500 ? 'Download Data' : '';
    vm.downloadText = $window.innerWidth >= 1200 ? 'Download' : '';
    vm.impressionLabel = $window.innerWidth >= 992 ? 'Impressions' : 'Imp';

    Authorization.authorize().then(function(result) {
      vm.user = result;
    });

    vm.campaign = Campaigns.get(
      {
        id: $stateParams.campaignId
      },
      function() {
        /**
         * check if campaign id is allowed to show age/gender chart
         */
        var campaignId = $stateParams.campaignId;
        insightExclusionIds.hasId(campaignId).then(function(result) {
          exclusionCheck = result;
          initialCharts(vm.datePeriod, campaign);
        });
      }
    );
    Teams.getCurrentTeam();
    var collections = Authorization.getCollections('Campaigns');
    /**
     * Get Variable Categories
     */
    $http
      .post('/api/insights/variable-categories', {
        allowedVariables: collections
      })
      .then(
        function(result) {
          variableFiles = result.data;
          // add state filter
          variableFiles.push({
            name: 'STATE'
          });
          vm.variableCategories = variableFiles.map(function(file) {
            return file.name;
          });
        },
        function(reason) {
          $log.error('Error: campaign-get-variable-category');
          $log.error(reason.data);
        }
      );

    Settings.checkNotification();

    vm.radioModel = 'UA';
    vm.toggleCampaignCaret = toggleCampaignCaret;

    /**
     * Only return active campaigns for search box
     */
    Campaigns.query({}, function(result) {
      vm.campaigns = result.filter(function(r) {
        if (r.status === 'Active') {
          return r;
        }
        return undefined;
      });
    });

    $rootScope.title = 'Campaign Evaluation';
    vm.dateRangeClick = dateRangeClick;
    var campaign = $stateParams.campaignId;
    clearTotals();
    vm.downloadAsCSV = downloadAsCSV;

    // Default selected period
    vm.datePeriod = 'all';
    vm.changePeriod = changePeriod;
    datePicker(vm.datePeriod);
    vm.disableButtons = true;

    /**
     * Display Initial Charts
     * @param {string} datePeriod - Currently selected date period. e.g. week, day, month.
     * @param {string} campaignId
     */
    function initialCharts(datePeriod, campaignId) {
      // Display Unique Chart Heading
      uniqueChartHeadings(datePeriod);

      // Calculate Totals
      parseTotals(datePeriod, campaignId);

      // Display Charts
      var dateRange = getActiveDateRange();
      var startEnd = {
        start: moment(vm.campaign.startDate).format('YYYY-MM-DD'),
        end: vm.campaign.endDate
          ? moment(vm.campaign.endDate).format('YYYY-MM-DD')
          : null
      };

      getLastDate
        .get(
          campaign,
          datePeriod,
          startEnd.start,
          startEnd.end ? startEnd.end : moment().format('YYYY-MM-DD'),
          'campaign'
        )
        .then(function(lastDateObj) {
          startEnd.end = lastDateObj.Period_End
            ? lastDateObj.Period_End
            : lastDateObj.Period_Start;
          cumulativeChartCampaign.chart(
            campaignId,
            dateRange,
            datePeriod,
            startEnd.start,
            startEnd.end
          );
          uniqueChartCampaign.chart(
            datePeriod,
            campaignId,
            dateRange,
            startEnd
          );
          helixChartsCampaign.chart(
            datePeriod,
            campaignId,
            startEnd,
            null,
            vm.activePersonasChart,
            vm.yearSelected.toString()
          );
          premiumChartsCampaign.chart(datePeriod, campaignId, startEnd, null);

          // check if the id is in array then dont show gender/age chart
          if (exclusionCheck) {
            commonCampaign.hideSpinner('gender-chart', 'showNoDataMsg');
            commonCampaign.hideSpinner('age-chart', 'showNoDataMsg');
          } else {
            genderChartCampaign.chart(datePeriod, campaignId, startEnd);
            ageChartCampaign.chart(datePeriod, campaignId, startEnd);
          }
          areaChartCampaign.chart(
            datePeriod,
            campaignId,
            startEnd,
            vm.activeAreaChart,
            vm.radioModel.toLowerCase()
          );
          topWebsiteChartCampaign.chart(datePeriod, campaignId, startEnd);
          // check if data exists or not, then call dependent charts as they are returning null when not checking data and hence making chart to broke
          uniqueChartCampaign
            .checkIfDataExists(campaignId, datePeriod, startEnd)
            .then(function(result) {
              if (result) {
                localVsInternationalChartCampaign.chart(
                  datePeriod,
                  campaignId,
                  startEnd
                );
                deviceChartCampaign.chart(datePeriod, campaignId, startEnd);
              } else {
                commonCampaign.hideSpinner(
                  'local-vs-international-chart',
                  'showNoDataMsg'
                );
                commonCampaign.hideSpinner('device-chart', 'showNoDataMsg');
              }
              vm.disableButtons = false;
            });
        });
    }

    /**
     * Change date using date picker
     * @param {string} datePeriod - day, week, month or all
     */
    function datePicker(datePeriod) {
      vm.dateOpen = false;
      var formats = ['dd MMM yyyy', 'MMMM yyyy'];
      var dateRanges = InsightsConfig.dateRanges;

      /**
       * Open date picker
       */
      vm.openDate = function() {
        vm.dateOpen = true;
      };

      vm.dateOptions = {
        startingDay: 1
      };

      /**
       * Highlight current week
       * @param {Date} date
       * @param {string} selectedDatePeriod
       * @returns {string}
       */
      vm.highlightWeek = function(date, selectedDatePeriod) {
        if (selectedDatePeriod === 'week') {
          var endOfWeek = moment(vm.datePicker).endOf('isoWeek');
          if (
            moment(date).isSame(moment(vm.datePicker), 'day') ||
            moment(date).isBetween(moment(vm.datePicker), endOfWeek, 'day') ||
            moment(date).isSame(endOfWeek, 'day')
          ) {
            return 'selected';
          }
        }
        return '';
      };

      // Remove date picker if all is selected
      vm.datePickerActive = datePeriod !== 'all';
      vm.datePickerWeekActive = datePeriod === 'week';
      vm.dateRangeVisible = datePeriod !== 'all';

      if (datePeriod === 'week') {
        vm.dateRanges = dateRanges.week;
        vm.activeDateRange = vm.dateRanges[0];
        vm.dateMode = 'day';

        // Disable all but Mondays
        vm.dateDisabled = function(date, mode) {
          return mode === 'day' && date.getDay() !== 1;
        };

        vm.format = formats[0];
        vm.minMode = 'day';
      } else if (datePeriod === 'month') {
        vm.dateRanges = dateRanges.month;
        vm.activeDateRange = vm.dateRanges[0];
        vm.dateDisabled = function() {
          return false;
        };

        vm.dateMode = 'month';
        vm.format = formats[1];
        vm.minMode = 'month';
      } else {
        if (datePeriod === 'all') {
          vm.dateRanges = dateRanges.all;
        } else {
          vm.dateRanges = dateRanges.day;
        }

        vm.activeDateRange = vm.dateRanges[0];
        vm.dateDisabled = function() {
          return false;
        };

        vm.dateMode = 'day';
        vm.format = formats[0];
        vm.minMode = 'day';
      }

      /**
       * Change charts based on selected date
       * @param {Date} date
       */
      vm.changeDatePicker = function(date) {
        // show spinners
        commonCampaign.showSpinner('cumulative-area-chart', 'hideChart');
        commonCampaign.showSpinner('unique-area-chart', 'hideChart');
        commonCampaign.showSpinner('helix-people-chart', 'hideChart');
        commonCampaign.showSpinner('helix-index-chart', 'hideChart');
        commonCampaign.hideNoData('premium-charts');
        commonCampaign.showSpinner('premium-people-chart', 'hideChart');
        commonCampaign.showSpinner('premium-index-chart', 'hideChart');
        commonCampaign.showSpinner('gender-chart', 'hideChart');
        commonCampaign.showSpinner('age-chart', 'hideChart');
        commonCampaign.showSpinner('area-chart', 'hideChart');
        commonCampaign.showSpinner('local-vs-international-chart', 'hideChart');
        commonCampaign.showSpinner('device-chart', 'hideChart');
        commonCampaign.showSpinner('website-chart', 'hideChart');

        vm.disableButtons = true;
        var dateRange = getDateRange();
        var variable = {
          variable: vm.activeVariable,
          file: selectedVariableFile,
          category: vm.selectedCategory
        };
        var startEnd = {
          start: moment(vm.campaign.startDate).format('YYYY-MM-DD'),
          end: moment(date).format('YYYY-MM-DD')
        };
        parseTotals(datePeriod, campaign, date);
        weekDatePicker(date, datePeriod);

        // get the current date to event log
        vm.currentDateToLog = date;

        //  logging dashboard data
        logEvents('Audiences dashboard render');

        cumulativeChartCampaign.changeDate(dateRange, datePeriod, date);
        uniqueChartCampaign.changeDate(dateRange, datePeriod, date);
        helixChartsCampaign.changeDate(
          campaign,
          datePeriod,
          startEnd,
          variable,
          vm.activePersonasChart,
          vm.yearSelected
        );
        premiumChartsCampaign.changeDate(
          campaign,
          datePeriod,
          startEnd,
          variable
        );
        areaChartCampaign.changeDate(
          campaign,
          datePeriod,
          startEnd,
          vm.activeAreaChart,
          vm.radioModel.toLowerCase(),
          variable
        );
        topWebsiteChartCampaign.changeDate(
          campaign,
          datePeriod,
          startEnd,
          variable
        );

        // update the charts if the category is ONLY selected to be as STATE and if no variables are there
        if (!variable.variable || vm.selectedCategory === 'STATE') {
          // check if the id is in array then dont show gender/age chart
          if (exclusionCheck) {
            commonCampaign.hideSpinner('gender-chart', 'showNoDataMsg');
            commonCampaign.hideSpinner('age-chart', 'showNoDataMsg');
          } else {
            genderChartCampaign.changeDate(
              campaign,
              startEnd,
              datePeriod,
              variable
            );
            ageChartCampaign.changeDate(
              campaign,
              startEnd,
              datePeriod,
              variable
            );
          }

          var localOverlay = $document[0].querySelector(
            '#local-vs-international-chart .overlay'
          );

          // Show the local chart
          if (vm.radioModel === 'Impressions') {
            angular.element(localOverlay).removeClass('active');
          } else {
            angular.element(localOverlay).addClass('active');
          }
          deviceChartCampaign.chart(datePeriod, campaign, startEnd, variable);

          // update the dates based on date picker
          startEnd = getStartEndDateOfSelectedPeriod(datePeriod);

          localVsInternationalChartCampaign.chart(
            datePeriod,
            campaign,
            startEnd,
            variable
          );
        }
        vm.disableButtons = false;
      };
    }

    /**
     * Change displayed date range
     * @param {any} event
     */
    function dateRangeClick(event) {
      vm.activeDateRange = event.target.text;
      var date = vm.datePicker;
      if (vm.datePeriod === 'month') {
        date = moment(date).toDate();
      } else if (vm.datePeriod === 'day') {
        date = moment(date).toDate();
      } else if (vm.datePeriod === 'week') {
        date = moment(date).toDate();
      }

      cumulativeChartCampaign.changeDate(
        event.target.text,
        vm.datePeriod,
        date
      );
      uniqueChartCampaign.changeDate(event.target.text, vm.datePeriod, date);
    }

    /**
     * Get the active date range if set.
     * @returns {string|boolean}
     */
    function getActiveDateRange() {
      if (vm.activeDateRange) {
        return vm.activeDateRange;
      }
      return false;
    }

    /**
     * Window resize
     */
    // @ts-ignore
    d3.select(window).on('resize', function() {
      vm.uaLabel = $window.innerWidth >= 1024 ? 'Unique Audience' : 'UA';
      vm.downloadLabel = $window.innerWidth >= 1500 ? 'Download Data' : '';
      vm.downloadText = $window.innerWidth >= 1200 ? 'Download' : '';
      vm.impressionLabel = $window.innerWidth >= 992 ? 'Impressions' : 'Imp';

      var navBar = angular.element(
        $document[0].querySelector('.navbar-collapse')
      );
      var sliding = navBar.hasClass('collapsing');

      if (sliding) {
        $timeout(resizeGraphs, 500);
      } else {
        resizeGraphs();
      }
    });

    /**
     * Resize graphs
     */
    function resizeGraphs() {
      var selectedRange = getDateRange();

      // check if the chart is loaded then resize
      if (
        checkIfChartIsloaded('helix-people-chart') &&
        checkIfChartIsloaded('helix-index-chart')
      ) {
        helixChartsCampaign.resize();
      }
      if (
        checkIfChartIsloaded('premium-people-chart') &&
        checkIfChartIsloaded('premium-index-chart')
      ) {
        premiumChartsCampaign.resize();
      }
      if (checkIfChartIsloaded('unique-area-chart')) {
        uniqueChartCampaign.resize(selectedRange, vm.datePeriod, vm.datePicker);
      }
      if (checkIfChartIsloaded('cumulative-area-chart')) {
        cumulativeChartCampaign.resize(
          selectedRange,
          vm.datePeriod,
          vm.datePicker
        );
      }
      if (checkIfChartIsloaded('area-chart')) {
        areaChartCampaign.resize();
      }
      if (checkIfChartIsloaded('website-chart')) {
        topWebsiteChartCampaign.resize();
      }
      if (checkIfChartIsloaded('device-chart')) {
        deviceChartCampaign.resize();
      }
      if (checkIfChartIsloaded('local-vs-international-chart')) {
        localVsInternationalChartCampaign.resize();
      }
      // check campaign exclusion
      if (exclusionCheck) {
        commonCampaign.hideSpinner('gender-chart', 'showNoDataMsg');
        commonCampaign.hideSpinner('age-chart', 'showNoDataMsg');
      } else {
        if (checkIfChartIsloaded('gender-chart')) {
          genderChartCampaign.resize();
        }
        if (checkIfChartIsloaded('age-chart')) {
          ageChartCampaign.resize();
        }
      }
    }

    /**
     * Get currently selected date range
     * @returns {string}
     */
    function getDateRange() {
      var activeDateRange = $document[0].getElementById('date-range-picker');
      if (!activeDateRange) {
        return null;
      }
      var selectedDate = activeDateRange.getElementsByClassName('selected');
      if (selectedDate) {
        return selectedDate[0].innerHTML;
      }
      return null;
    }

    /**
     * Download all data as zipped CSV's
     */
    function downloadAsCSV() {
      if (vm.downloading) {
        return;
      }

      $analytics.eventTrack('Click', {
        category: 'Campaigns',
        label: 'Download'
      });

      var variable = {
        variable: vm.activeVariable,
        file: selectedVariableFile,
        category: vm.selectedCategory
      };

      //  logging download data
      logEvents('Audiences dashboard download');

      if (vm.datePeriod === 'all') {
        downloadAsCSVAll(variable);
      } else if (vm.datePeriod === 'month') {
        downloadAsCSVMonth(variable);
      } else if (vm.datePeriod === 'week') {
        downloadAsCSVWeek(variable);
      } else if (vm.datePeriod === 'day') {
        downloadAsCSVDay(variable);
      }
    }

    /**
     * Convert object to CSV
     * @param {object} variable - JSON object
     * @return {void}
     */
    function downloadAsCSVAll(variable) {
      var spinner = $document[0].getElementById('download-icon');
      spinner.classList.add('fa-spinner');
      spinner.classList.add('fa-spin');
      vm.downloading = true;

      // @ts-ignore
      var zip = new JSZip();
      var helixDataAllPersonas;
      var helixDataAllCommunities;
      var helixDataAllPersonas2014;
      var helixDataAllCommunities2014;
      var ageDataAll;
      var deviceDataAll;
      var areaDataAll;
      var stateDataAll;
      var postcodeDataAllUA;
      var postcodeDataAllClicks;
      var postcodeDataAllImp;
      var topWebsitesDataAll;
      var cumulativeData;
      var statePop;
      var postcodePop;
      var premiumData;

      // find the first date and the last date
      var startEnd = {
        start: moment(vm.campaign.startDate).format('YYYY-MM-DD'),
        end: vm.datePicker ? moment(vm.datePicker).format('YYYY-MM-DD') : null
      };

      $q.all([
        cumulativeChartCampaign.getData(
          campaign,
          {
            start: moment(vm.campaign.startDate).format('YYYY-MM-DD'),
            end: startEnd.end
          },
          variable
        ),
        !vm.premiumAccess
          ? helixChartsCampaign.getData(
              campaign,
              'all',
              startEnd,
              variable,
              'Personas',
              '2014'
            )
          : null,
        !vm.premiumAccess
          ? helixChartsCampaign.getData(
              campaign,
              'all',
              startEnd,
              variable,
              'Personas',
              '2016'
            )
          : null,
        (!variable.variable || variable.file === 'STATE') && !exclusionCheck
          ? ageChartCampaign.getData(campaign, 'all', startEnd, variable)
          : null,
        areaChartCampaign.getData(campaign, 'all', startEnd, 'area', variable),
        areaChartCampaign.getData(campaign, 'all', startEnd, 'state', variable),
        !variable.variable || variable.file === 'STATE'
          ? deviceChartCampaign.getData(campaign, 'all', startEnd, variable)
          : null,
        !vm.premiumAccess
          ? helixChartsCampaign.getData(
              campaign,
              'all',
              startEnd,
              variable,
              'Communities',
              '2014'
            )
          : null,
        !vm.premiumAccess
          ? helixChartsCampaign.getData(
              campaign,
              'all',
              startEnd,
              variable,
              'Communities',
              '2016'
            )
          : null,
        areaChartCampaign.getData(
          campaign,
          'all',
          startEnd,
          'postcode',
          variable,
          'ua'
        ),
        areaChartCampaign.getData(
          campaign,
          'all',
          startEnd,
          'postcode',
          variable,
          'impressions'
        ),
        areaChartCampaign.getData(
          campaign,
          'all',
          startEnd,
          'postcode',
          variable,
          'clicks'
        ),
        topWebsiteChartCampaign.getData(campaign, 'all', startEnd, variable),
        areaChartCampaign.getData(
          campaign,
          'all',
          startEnd,
          'state',
          variable,
          'population'
        ),
        areaChartCampaign.getData(
          campaign,
          'all',
          startEnd,
          'postcode',
          variable,
          'population'
        ),
        vm.premiumAccess && (!variable.variable || variable.file === 'STATE')
          ? premiumChartsCampaign.getData(campaign, 'all', startEnd, variable)
          : null
      ]).then(function(result) {
        cumulativeData = result[0];
        helixDataAllPersonas2014 = result[1];
        helixDataAllPersonas = result[2];
        ageDataAll = result[3];
        areaDataAll = result[4];
        stateDataAll = result[5];
        deviceDataAll = result[6];
        helixDataAllCommunities2014 = result[7];
        helixDataAllCommunities = result[8];
        postcodeDataAllUA = result[9];
        postcodeDataAllImp = result[10];
        postcodeDataAllClicks = result[11];
        topWebsitesDataAll = result[12];
        statePop = result[13];
        postcodePop = result[14];
        premiumData = result[15];

        if (cumulativeData && cumulativeData.length > 0) {
          zip.file(
            'Audience_Total.csv',
            convertToCSV.convert(angular.toJson(cumulativeData))
          );
        }
        if (helixDataAllPersonas2014 && helixDataAllPersonas2014.length > 0) {
          zip.file(
            'HelixPersonas2014_Total.csv',
            convertToCSV.convert(angular.toJson(helixDataAllPersonas2014))
          );
        }
        if (helixDataAllPersonas && helixDataAllPersonas.length > 0) {
          zip.file(
            'HelixPersonas_Total.csv',
            convertToCSV.convert(angular.toJson(helixDataAllPersonas))
          );
        }
        if (ageDataAll && ageDataAll.length && !exclusionCheck) {
          zip.file(
            'AgeGender_Total.csv',
            convertToCSV.convert(angular.toJson(ageDataAll))
          );
        }
        if (areaDataAll && areaDataAll.length > 0) {
          zip.file(
            'Area_Total.csv',
            convertToCSV.convert(angular.toJson(areaDataAll))
          );
        }
        if (stateDataAll && stateDataAll.length > 0) {
          zip.file(
            'State_Total.csv',
            convertToCSV.convert(angular.toJson(stateDataAll))
          );
        }
        if (deviceDataAll && deviceDataAll.length > 0) {
          zip.file(
            'Device_Audience_Total.csv',
            convertToCSV.convert(angular.toJson(deviceDataAll))
          );
        }
        if (
          helixDataAllCommunities2014 &&
          helixDataAllCommunities2014.length > 0
        ) {
          zip.file(
            'HelixCommunity2014_Total.csv',
            convertToCSV.convert(angular.toJson(helixDataAllCommunities2014))
          );
        }
        if (helixDataAllCommunities && helixDataAllCommunities.length > 0) {
          zip.file(
            'HelixCommunity_Total.csv',
            convertToCSV.convert(angular.toJson(helixDataAllCommunities))
          );
        }
        if (postcodeDataAllUA && postcodeDataAllUA.length > 0) {
          zip.file(
            'Postcode_By_UA_Total.csv',
            convertToCSV.convert(angular.toJson(postcodeDataAllUA))
          );
        }
        if (postcodeDataAllImp && postcodeDataAllImp.length > 0) {
          zip.file(
            'Postcode_By_Impressions_Total.csv',
            convertToCSV.convert(angular.toJson(postcodeDataAllImp))
          );
        }
        if (postcodeDataAllClicks && postcodeDataAllClicks.length > 0) {
          zip.file(
            'Postcode_By_Clicks_Total.csv',
            convertToCSV.convert(angular.toJson(postcodeDataAllClicks))
          );
        }
        if (topWebsitesDataAll && topWebsitesDataAll.length > 0) {
          zip.file(
            'TopWebsites_By_UA_Total.csv',
            convertToCSV.convert(angular.toJson(topWebsitesDataAll))
          );
        }
        if (statePop && statePop.length > 0) {
          zip.file(
            'State_By_Population_Total.csv',
            convertToCSV.convert(angular.toJson(statePop))
          );
        }
        if (postcodePop && postcodePop.length > 0) {
          zip.file(
            'Postcode_by_Population_Share_Total.csv',
            convertToCSV.convert(angular.toJson(postcodePop))
          );
        }
        if (premiumData && premiumData.length > 0) {
          zip.file(
            'Premium_Audiences.csv',
            convertToCSV.convert(angular.toJson(premiumData))
          );
        }

        var content = zip.generate({
          type: 'blob'
        });

        // @ts-ignore
        saveAs(
          content,
          downloads.filename(
            vm.campaign.name,
            variable ? variable.variable : null,
            vm.activeDateRange,
            startEnd.end ||
              moment()
                .subtract(1, 'day')
                .format('YYYY-MM-DD')
          )
        );
        spinner.classList.remove('fa-spinner');
        spinner.classList.remove('fa-spin');
        vm.downloading = false;
      });
    }

    /**
     * Convert object to CSV
     * @param {object} variable - JSON object
     * @return {void}
     */
    function downloadAsCSVMonth(variable) {
      var spinner = $document[0].getElementById('download-icon');
      spinner.classList.add('fa-spinner');
      spinner.classList.add('fa-spin');
      vm.downloading = true;

      // @ts-ignore
      var zip = new JSZip();
      var uniqueDataMonth;
      var helixDataMonthPersonas;
      var helixDataMonthCommunities;
      var helixDataMonthPersonas2014;
      var helixDataMonthCommunities2014;
      var ageDataMonth;
      var deviceDataMonth;
      var areaDataMonth;
      var stateDataMonth;
      var postcodeDataMonthUA;
      var postcodeDataMonthClicks;
      var postcodeDataMonthImp;
      var topWebsitesDataMonth;
      var statePop;
      var postcodePop;
      var premiumData;

      // find the first date and the last date of month
      var startEnd = {
        start: moment(vm.datePicker)
          .startOf('month')
          .format('YYYY-MM-DD'),
        end: vm.datePicker
          ? moment(vm.datePicker)
              .endOf('month')
              .format('YYYY-MM-DD')
          : null
      };

      $q.all([
        uniqueChartCampaign.getData(
          campaign,
          'month',
          {
            start: downloads.downloadsStartDate(
              startEnd.end,
              vm.activeDateRange,
              moment(vm.campaign.startDate).format('YYYY-MM-DD')
            ),
            end: startEnd.end
          },
          variable
        ),
        !vm.premiumAccess
          ? helixChartsCampaign.getData(
              campaign,
              'month',
              startEnd,
              variable,
              'Personas',
              '2014'
            )
          : null,
        !vm.premiumAccess
          ? helixChartsCampaign.getData(
              campaign,
              'month',
              startEnd,
              variable,
              'Personas',
              '2016'
            )
          : null,
        (!variable.variable || variable.file === 'STATE') && !exclusionCheck
          ? ageChartCampaign.getData(campaign, 'month', startEnd, variable)
          : null,
        areaChartCampaign.getData(
          campaign,
          'month',
          startEnd,
          'area',
          variable
        ),
        areaChartCampaign.getData(
          campaign,
          'month',
          startEnd,
          'state',
          variable
        ),
        !variable.variable || variable.file === 'STATE'
          ? deviceChartCampaign.getData(campaign, 'month', startEnd, variable)
          : null,
        !vm.premiumAccess
          ? helixChartsCampaign.getData(
              campaign,
              'month',
              startEnd,
              variable,
              'Communities',
              '2014'
            )
          : null,
        !vm.premiumAccess
          ? helixChartsCampaign.getData(
              campaign,
              'month',
              startEnd,
              variable,
              'Communities',
              '2016'
            )
          : null,
        areaChartCampaign.getData(
          campaign,
          'month',
          startEnd,
          'postcode',
          variable,
          'ua'
        ),
        areaChartCampaign.getData(
          campaign,
          'month',
          startEnd,
          'postcode',
          variable,
          'impressions'
        ),
        areaChartCampaign.getData(
          campaign,
          'month',
          startEnd,
          'postcode',
          variable,
          'clicks'
        ),
        topWebsiteChartCampaign.getData(campaign, 'month', startEnd, variable),
        areaChartCampaign.getData(
          campaign,
          'month',
          startEnd,
          'state',
          variable,
          'population'
        ),
        areaChartCampaign.getData(
          campaign,
          'month',
          startEnd,
          'postcode',
          variable,
          'population'
        ),
        vm.premiumAccess && (!variable.variable || variable.file === 'STATE')
          ? premiumChartsCampaign.getData(campaign, 'month', startEnd, variable)
          : null
      ]).then(function(result) {
        uniqueDataMonth = result[0];
        helixDataMonthPersonas2014 = result[1];
        helixDataMonthPersonas = result[2];
        ageDataMonth = result[3];
        areaDataMonth = result[4];
        stateDataMonth = result[5];
        deviceDataMonth = result[6];
        helixDataMonthCommunities2014 = result[7];
        helixDataMonthCommunities = result[8];
        postcodeDataMonthUA = result[9];
        postcodeDataMonthImp = result[10];
        postcodeDataMonthClicks = result[11];
        topWebsitesDataMonth = result[12];
        statePop = result[13];
        postcodePop = result[14];
        premiumData = result[15];

        if (uniqueDataMonth && uniqueDataMonth.length > 0) {
          zip.file(
            'Audience_Monthly.csv',
            convertToCSV.convert(angular.toJson(uniqueDataMonth))
          );
        }
        if (
          helixDataMonthPersonas2014 &&
          helixDataMonthPersonas2014.length > 0
        ) {
          zip.file(
            'HelixPersonas2014_Monthly.csv',
            convertToCSV.convert(angular.toJson(helixDataMonthPersonas2014))
          );
        }
        if (helixDataMonthPersonas && helixDataMonthPersonas.length > 0) {
          zip.file(
            'HelixPersonas_Monthly.csv',
            convertToCSV.convert(angular.toJson(helixDataMonthPersonas))
          );
        }
        if (ageDataMonth && ageDataMonth.length && !exclusionCheck) {
          zip.file(
            'AgeGender_Monthly.csv',
            convertToCSV.convert(angular.toJson(ageDataMonth))
          );
        }
        if (areaDataMonth && areaDataMonth.length > 0) {
          zip.file(
            'Area_Monthly.csv',
            convertToCSV.convert(angular.toJson(areaDataMonth))
          );
        }
        if (stateDataMonth && stateDataMonth.length > 0) {
          zip.file(
            'State_Monthly.csv',
            convertToCSV.convert(angular.toJson(stateDataMonth))
          );
        }
        if (deviceDataMonth && deviceDataMonth.length > 0) {
          zip.file(
            'Device_Audience_Monthly.csv',
            convertToCSV.convert(angular.toJson(deviceDataMonth))
          );
        }
        if (
          helixDataMonthCommunities2014 &&
          helixDataMonthCommunities2014.length > 0
        ) {
          zip.file(
            'HelixCommunity2014_Monthly.csv',
            convertToCSV.convert(angular.toJson(helixDataMonthCommunities2014))
          );
        }
        if (helixDataMonthCommunities && helixDataMonthCommunities.length > 0) {
          zip.file(
            'HelixCommunity_Monthly.csv',
            convertToCSV.convert(angular.toJson(helixDataMonthCommunities))
          );
        }
        if (postcodeDataMonthUA && postcodeDataMonthUA.length > 0) {
          zip.file(
            'Postcode_By_UA_Monthly.csv',
            convertToCSV.convert(angular.toJson(postcodeDataMonthUA))
          );
        }
        if (postcodeDataMonthImp && postcodeDataMonthImp.length > 0) {
          zip.file(
            'Postcode_By_Impressions_Monthly.csv',
            convertToCSV.convert(angular.toJson(postcodeDataMonthImp))
          );
        }
        if (postcodeDataMonthClicks && postcodeDataMonthClicks.length > 0) {
          zip.file(
            'Postcode_By_Clicks_Monthly.csv',
            convertToCSV.convert(angular.toJson(postcodeDataMonthClicks))
          );
        }
        if (topWebsitesDataMonth && topWebsitesDataMonth.length > 0) {
          zip.file(
            'TopWebsites_By_UA_Monthly.csv',
            convertToCSV.convert(angular.toJson(topWebsitesDataMonth))
          );
        }
        if (statePop && statePop.length > 0) {
          zip.file(
            'State_By_Population_Monthly.csv',
            convertToCSV.convert(angular.toJson(statePop))
          );
        }
        if (postcodePop && postcodePop.length > 0) {
          zip.file(
            'Postcode_by_Population_Share_Monthly.csv',
            convertToCSV.convert(angular.toJson(postcodePop))
          );
        }
        if (premiumData && premiumData.length > 0) {
          zip.file(
            'Premium_Audiences_Monthly.csv',
            convertToCSV.convert(angular.toJson(premiumData))
          );
        }

        var content = zip.generate({
          type: 'blob'
        });
        // @ts-ignore
        saveAs(
          content,
          downloads.filename(
            vm.campaign.name,
            variable ? variable.variable : null,
            vm.activeDateRange,
            startEnd.end ||
              moment()
                .subtract(1, 'day')
                .format('YYYY-MM-DD')
          )
        );
        spinner.classList.remove('fa-spinner');
        spinner.classList.remove('fa-spin');
        vm.downloading = false;
      });
    }

    /**
     * Convert object to CSV
     * @param {object} variable - JSON object
     * @return {void}
     */
    function downloadAsCSVWeek(variable) {
      var spinner = $document[0].getElementById('download-icon');
      spinner.classList.add('fa-spinner');
      spinner.classList.add('fa-spin');
      vm.downloading = true;

      // @ts-ignore
      var zip = new JSZip();
      var uniqueDataWeek;
      var helixDataWeekPersonas;
      var helixDataWeekCommunities;
      var helixDataWeekPersonas2014;
      var helixDataWeekCommunities2014;
      var ageDataWeek;
      var deviceDataWeek;
      var areaDataWeek;
      var stateDataWeek;
      var postcodeDataWeekUA;
      var postcodeDataWeekClicks;
      var postcodeDataWeekImp;
      var topWebsitesDataWeek;
      var statePop;
      var postcodePop;
      var premiumData;

      // find the first date and the last date of week
      var startEnd = {
        start: moment(vm.datePicker)
          .startOf('isoWeek')
          .format('YYYY-MM-DD'),
        end: vm.datePicker
          ? moment(vm.datePicker)
              .endOf('isoWeek')
              .format('YYYY-MM-DD')
          : null
      };

      $q.all([
        uniqueChartCampaign.getData(
          campaign,
          'week',
          {
            start: downloads.downloadsStartDate(
              startEnd.end,
              vm.activeDateRange,
              moment(vm.campaign.startDate).format('YYYY-MM-DD')
            ),
            end: startEnd.end
          },
          variable
        ),
        !vm.premiumAccess
          ? helixChartsCampaign.getData(
              campaign,
              'week',
              startEnd,
              variable,
              'Personas',
              '2014'
            )
          : null,
        !vm.premiumAccess
          ? helixChartsCampaign.getData(
              campaign,
              'week',
              startEnd,
              variable,
              'Personas',
              '2016'
            )
          : null,
        (!variable.variable || variable.file === 'STATE') && !exclusionCheck
          ? ageChartCampaign.getData(campaign, 'week', startEnd, variable)
          : null,
        areaChartCampaign.getData(campaign, 'week', startEnd, 'area', variable),
        areaChartCampaign.getData(
          campaign,
          'week',
          startEnd,
          'state',
          variable
        ),
        !variable.variable || variable.file === 'STATE'
          ? deviceChartCampaign.getData(campaign, 'week', startEnd, variable)
          : null,
        !vm.premiumAccess
          ? helixChartsCampaign.getData(
              campaign,
              'week',
              startEnd,
              variable,
              'Communities',
              '2014'
            )
          : null,
        !vm.premiumAccess
          ? helixChartsCampaign.getData(
              campaign,
              'week',
              startEnd,
              variable,
              'Communities',
              '2016'
            )
          : null,
        areaChartCampaign.getData(
          campaign,
          'week',
          startEnd,
          'postcode',
          variable,
          'ua'
        ),
        areaChartCampaign.getData(
          campaign,
          'week',
          startEnd,
          'postcode',
          variable,
          'impressions'
        ),
        areaChartCampaign.getData(
          campaign,
          'week',
          startEnd,
          'postcode',
          variable,
          'clicks'
        ),
        topWebsiteChartCampaign.getData(campaign, 'week', startEnd, variable),
        areaChartCampaign.getData(
          campaign,
          'week',
          startEnd,
          'state',
          variable,
          'population'
        ),
        areaChartCampaign.getData(
          campaign,
          'week',
          startEnd,
          'postcode',
          variable,
          'population'
        ),
        vm.premiumAccess && (!variable.variable || variable.file === 'STATE')
          ? premiumChartsCampaign.getData(campaign, 'week', startEnd, variable)
          : null
      ]).then(function(result) {
        uniqueDataWeek = result[0];
        helixDataWeekPersonas2014 = result[1];
        helixDataWeekPersonas = result[2];
        ageDataWeek = result[3];
        areaDataWeek = result[4];
        stateDataWeek = result[5];
        deviceDataWeek = result[6];
        helixDataWeekCommunities2014 = result[7];
        helixDataWeekCommunities = result[8];
        postcodeDataWeekUA = result[9];
        postcodeDataWeekImp = result[10];
        postcodeDataWeekClicks = result[11];
        topWebsitesDataWeek = result[12];
        statePop = result[13];
        postcodePop = result[14];
        premiumData = result[15];

        if (uniqueDataWeek && uniqueDataWeek.length > 0) {
          zip.file(
            'Audience_Weekly.csv',
            convertToCSV.convert(angular.toJson(uniqueDataWeek))
          );
        }
        if (helixDataWeekPersonas2014 && helixDataWeekPersonas2014.length > 0) {
          zip.file(
            'HelixPersonas2014_Weekly.csv',
            convertToCSV.convert(angular.toJson(helixDataWeekPersonas2014))
          );
        }
        if (helixDataWeekPersonas && helixDataWeekPersonas.length > 0) {
          zip.file(
            'HelixPersonas_Weekly.csv',
            convertToCSV.convert(angular.toJson(helixDataWeekPersonas))
          );
        }
        if (ageDataWeek && ageDataWeek.length && !exclusionCheck) {
          zip.file(
            'AgeGender_Weekly.csv',
            convertToCSV.convert(angular.toJson(ageDataWeek))
          );
        }
        if (areaDataWeek && areaDataWeek.length > 0) {
          zip.file(
            'Area_Weekly.csv',
            convertToCSV.convert(angular.toJson(areaDataWeek))
          );
        }
        if (stateDataWeek && stateDataWeek.length > 0) {
          zip.file(
            'State_Weekly.csv',
            convertToCSV.convert(angular.toJson(stateDataWeek))
          );
        }
        if (deviceDataWeek && deviceDataWeek.length > 0) {
          zip.file(
            'Device_Audience_Weekly.csv',
            convertToCSV.convert(angular.toJson(deviceDataWeek))
          );
        }
        if (
          helixDataWeekCommunities2014 &&
          helixDataWeekCommunities2014.length > 0
        ) {
          zip.file(
            'HelixCommunity2014_Weekly.csv',
            convertToCSV.convert(angular.toJson(helixDataWeekCommunities2014))
          );
        }
        if (helixDataWeekCommunities && helixDataWeekCommunities.length > 0) {
          zip.file(
            'HelixCommunity_Weekly.csv',
            convertToCSV.convert(angular.toJson(helixDataWeekCommunities))
          );
        }
        if (postcodeDataWeekUA && postcodeDataWeekUA.length > 0) {
          zip.file(
            'Postcode_By_UA_Weekly.csv',
            convertToCSV.convert(angular.toJson(postcodeDataWeekUA))
          );
        }
        if (postcodeDataWeekImp && postcodeDataWeekImp.length > 0) {
          zip.file(
            'Postcode_By_Impressions_Weekly.csv',
            convertToCSV.convert(angular.toJson(postcodeDataWeekImp))
          );
        }
        if (postcodeDataWeekClicks && postcodeDataWeekClicks.length > 0) {
          zip.file(
            'Postcode_By_Clicks_Weekly.csv',
            convertToCSV.convert(angular.toJson(postcodeDataWeekClicks))
          );
        }
        if (topWebsitesDataWeek && topWebsitesDataWeek.length > 0) {
          zip.file(
            'TopWebsites_By_UA_Weekly.csv',
            convertToCSV.convert(angular.toJson(topWebsitesDataWeek))
          );
        }
        if (statePop && statePop.length > 0) {
          zip.file(
            'State_By_Population_Weekly.csv',
            convertToCSV.convert(angular.toJson(statePop))
          );
        }
        if (postcodePop && postcodePop.length > 0) {
          zip.file(
            'Postcode_by_Population_Share_Weekly.csv',
            convertToCSV.convert(angular.toJson(postcodePop))
          );
        }
        if (premiumData && premiumData.length > 0) {
          zip.file(
            'Premium_Audiences_Weekly.csv',
            convertToCSV.convert(angular.toJson(premiumData))
          );
        }

        var content = zip.generate({
          type: 'blob'
        });
        // @ts-ignore
        saveAs(
          content,
          downloads.filename(
            vm.campaign.name,
            variable ? variable.variable : null,
            vm.activeDateRange,
            startEnd.end ||
              moment()
                .subtract(1, 'day')
                .format('YYYY-MM-DD')
          )
        );
        spinner.classList.remove('fa-spinner');
        spinner.classList.remove('fa-spin');
        vm.downloading = false;
      });
    }

    /**
     * Convert object to CSV
     * @param {object} variable - JSON object
     * @return {void}
     */
    function downloadAsCSVDay(variable) {
      var spinner = $document[0].getElementById('download-icon');
      spinner.classList.add('fa-spinner');
      spinner.classList.add('fa-spin');
      vm.downloading = true;

      // @ts-ignore
      var zip = new JSZip();
      var uniqueDataDay;
      var helixDataDayPersonas;
      var helixDataDayCommunities;
      var helixDataDayPersonas2014;
      var helixDataDayCommunities2014;
      var ageDataDay;
      var deviceDataDay;
      var areaDataDay;
      var stateDataDay;
      var postcodeDataDayUA;
      var postcodeDataDayClicks;
      var postcodeDataDayImp;
      var topWebsitesDataDay;
      var localIntData;
      var statePop;
      var postcodePop;
      var premiumData;

      // find the first date and the last date
      var startEnd = {
        start: moment(vm.datePicker).format('YYYY-MM-DD'),
        end: vm.datePicker ? moment(vm.datePicker).format('YYYY-MM-DD') : null
      };

      $q.all([
        uniqueChartCampaign.getData(
          campaign,
          'day',
          {
            start: downloads.downloadsStartDate(
              startEnd.end,
              vm.activeDateRange,
              moment(vm.campaign.startDate).format('YYYY-MM-DD')
            ),
            end: startEnd.end
          },
          variable
        ),
        !vm.premiumAccess
          ? helixChartsCampaign.getData(
              campaign,
              'day',
              startEnd,
              variable,
              'Personas',
              '2014'
            )
          : null,
        !vm.premiumAccess
          ? helixChartsCampaign.getData(
              campaign,
              'day',
              startEnd,
              variable,
              'Personas',
              '2016'
            )
          : null,
        (!variable.variable || variable.file === 'STATE') && !exclusionCheck
          ? ageChartCampaign.getData(campaign, 'day', startEnd, variable)
          : null,
        areaChartCampaign.getData(campaign, 'day', startEnd, 'area', variable),
        areaChartCampaign.getData(campaign, 'day', startEnd, 'state', variable),
        !variable.variable || variable.file === 'STATE'
          ? deviceChartCampaign.getData(campaign, 'day', startEnd, variable)
          : null,
        localVsInternationalChartCampaign.getData(campaign, startEnd, variable),
        !vm.premiumAccess
          ? helixChartsCampaign.getData(
              campaign,
              'day',
              startEnd,
              variable,
              'Communities',
              '2014'
            )
          : null,
        !vm.premiumAccess
          ? helixChartsCampaign.getData(
              campaign,
              'day',
              startEnd,
              variable,
              'Communities',
              '2016'
            )
          : null,
        areaChartCampaign.getData(
          campaign,
          'day',
          startEnd,
          'postcode',
          variable,
          'ua'
        ),
        areaChartCampaign.getData(
          campaign,
          'day',
          startEnd,
          'postcode',
          variable,
          'impressions'
        ),
        areaChartCampaign.getData(
          campaign,
          'day',
          startEnd,
          'postcode',
          variable,
          'clicks'
        ),
        topWebsiteChartCampaign.getData(campaign, 'day', startEnd, variable),
        areaChartCampaign.getData(
          campaign,
          'day',
          startEnd,
          'state',
          variable,
          'population'
        ),
        areaChartCampaign.getData(
          campaign,
          'day',
          startEnd,
          'postcode',
          variable,
          'population'
        ),
        vm.premiumAccess && (!variable.variable || variable.file === 'STATE')
          ? premiumChartsCampaign.getData(campaign, 'day', startEnd, variable)
          : null
      ]).then(function(result) {
        uniqueDataDay = result[0];
        helixDataDayPersonas2014 = result[1];
        helixDataDayPersonas = result[2];
        ageDataDay = result[3];
        areaDataDay = result[4];
        stateDataDay = result[5];
        deviceDataDay = result[6];
        localIntData = result[7];
        helixDataDayCommunities2014 = result[8];
        helixDataDayCommunities = result[9];
        postcodeDataDayUA = result[10];
        postcodeDataDayImp = result[11];
        postcodeDataDayClicks = result[12];
        topWebsitesDataDay = result[13];
        statePop = result[14];
        postcodePop = result[15];
        premiumData = result[16];

        if (uniqueDataDay && uniqueDataDay.length > 0) {
          zip.file(
            'Audience_Daily.csv',
            convertToCSV.convert(angular.toJson(uniqueDataDay))
          );
        }
        if (helixDataDayPersonas2014 && helixDataDayPersonas2014.length > 0) {
          zip.file(
            'HelixPersonas2014_Daily.csv',
            convertToCSV.convert(angular.toJson(helixDataDayPersonas2014))
          );
        }
        if (helixDataDayPersonas && helixDataDayPersonas.length > 0) {
          zip.file(
            'HelixPersonas_Daily.csv',
            convertToCSV.convert(angular.toJson(helixDataDayPersonas))
          );
        }
        if (ageDataDay && ageDataDay.length && !exclusionCheck) {
          zip.file(
            'AgeGender_Daily.csv',
            convertToCSV.convert(angular.toJson(ageDataDay))
          );
        }
        if (areaDataDay && areaDataDay.length > 0) {
          zip.file(
            'Area_Daily.csv',
            convertToCSV.convert(angular.toJson(areaDataDay))
          );
        }
        if (stateDataDay && stateDataDay.length > 0) {
          zip.file(
            'State_Daily.csv',
            convertToCSV.convert(angular.toJson(stateDataDay))
          );
        }
        if (deviceDataDay && deviceDataDay.length > 0) {
          zip.file(
            'Device_Audience_Daily.csv',
            convertToCSV.convert(angular.toJson(deviceDataDay))
          );
        }
        if (localIntData && localIntData.length > 0) {
          zip.file(
            'International_Traffic_Daily.csv',
            convertToCSV.convert(angular.toJson(localIntData))
          );
        }
        if (
          helixDataDayCommunities2014 &&
          helixDataDayCommunities2014.length > 0
        ) {
          zip.file(
            'HelixCommunity2014_Daily.csv',
            convertToCSV.convert(angular.toJson(helixDataDayCommunities2014))
          );
        }
        if (helixDataDayCommunities && helixDataDayCommunities.length > 0) {
          zip.file(
            'HelixCommunity_Daily.csv',
            convertToCSV.convert(angular.toJson(helixDataDayCommunities))
          );
        }
        if (postcodeDataDayUA && postcodeDataDayUA.length > 0) {
          zip.file(
            'Postcode_By_UA_Daily.csv',
            convertToCSV.convert(angular.toJson(postcodeDataDayUA))
          );
        }
        if (postcodeDataDayImp && postcodeDataDayImp.length > 0) {
          zip.file(
            'Postcode_By_Impressions_Daily.csv',
            convertToCSV.convert(angular.toJson(postcodeDataDayImp))
          );
        }
        if (postcodeDataDayClicks && postcodeDataDayClicks.length > 0) {
          zip.file(
            'Postcode_By_Clicks_Daily.csv',
            convertToCSV.convert(angular.toJson(postcodeDataDayClicks))
          );
        }
        if (topWebsitesDataDay && topWebsitesDataDay.length > 0) {
          zip.file(
            'TopWebsites_By_UA_Daily.csv',
            convertToCSV.convert(angular.toJson(topWebsitesDataDay))
          );
        }
        if (statePop && statePop.length > 0) {
          zip.file(
            'State_By_Population_Daily.csv',
            convertToCSV.convert(angular.toJson(statePop))
          );
        }
        if (postcodePop && postcodePop.length > 0) {
          zip.file(
            'Postcode_by_Population_Share_Daily.csv',
            convertToCSV.convert(angular.toJson(postcodePop))
          );
        }
        if (premiumData && premiumData.length > 0) {
          zip.file(
            'Premium_Audiences_Daily.csv',
            convertToCSV.convert(angular.toJson(premiumData))
          );
        }

        var content = zip.generate({
          type: 'blob'
        });
        // @ts-ignore
        saveAs(
          content,
          downloads.filename(
            vm.campaign.name,
            variable ? variable.variable : null,
            vm.activeDateRange,
            startEnd.end ||
              moment()
                .subtract(1, 'day')
                .format('YYYY-MM-DD')
          )
        );
        spinner.classList.remove('fa-spinner');
        spinner.classList.remove('fa-spin');
        vm.downloading = false;
      });
    }

    /**
     * Toggle search box caret
     */
    function toggleCampaignCaret() {
      var myEl = angular.element($document[0].querySelector('#campaign-caret'));
      if (myEl.hasClass('fa-caret-down')) {
        myEl.removeClass('fa-caret-down');
        myEl.addClass('fa-caret-up');
      } else {
        myEl.addClass('fa-caret-down');
        myEl.removeClass('fa-caret-up');
      }
    }

    /**
     * Split string off numbers into 111,000 from 111000
     * @param {number} x - Number to split.
     * @param {string} [sep] - Separator.
     * @param {number} [grp] - Number to group by, must be an integer.
     * @return {string}
     */
    function localeString(x, sep, grp) {
      var sx = ('' + x).split('.');
      var s = '';
      var i;
      var j;

      sep = sep || (sep = ','); // default separator
      // @ts-ignore
      grp = grp || grp === 0 || (grp = 3); // default grouping
      i = sx[0].length;
      while (i > grp) {
        j = i - grp;
        s = sep + sx[0].slice(j, i) + s;
        i = j;
      }

      s = sx[0].slice(0, i) + s;
      sx[0] = s;
      return sx.join('.');
    }

    /**
     * Change Date Period
     * - Total -> Month -> Week -> Day
     * @param {string} period
     */
    function changePeriod(period) {
      // show spinners
      commonCampaign.showSpinner('cumulative-area-chart', 'hideChart');
      commonCampaign.showSpinner('unique-area-chart', 'hideChart');
      commonCampaign.showSpinner('helix-people-chart', 'hideChart');
      commonCampaign.showSpinner('helix-index-chart', 'hideChart');
      commonCampaign.hideNoData('premium-charts');
      commonCampaign.showSpinner('premium-people-chart', 'hideChart');
      commonCampaign.showSpinner('premium-index-chart', 'hideChart');
      commonCampaign.showSpinner('gender-chart', 'hideChart');
      commonCampaign.showSpinner('age-chart', 'hideChart');
      commonCampaign.showSpinner('area-chart', 'hideChart');
      commonCampaign.showSpinner('local-vs-international-chart', 'hideChart');
      commonCampaign.showSpinner('device-chart', 'hideChart');
      commonCampaign.showSpinner('website-chart', 'hideChart');

      vm.disableButtons = true;
      $analytics.eventTrack('Campaigns', {
        category: 'Reporting Timeframe Click',
        label: period
      });
      var variable = {
        variable: vm.activeVariable,
        file: selectedVariableFile,
        category: vm.selectedCategory
      };
      var startEnd = {
        start: moment(vm.campaign.startDate).format('YYYY-MM-DD'),
        end: vm.campaign.endDate
          ? moment(vm.campaign.endDate).format('YYYY-MM-DD')
          : null
      };

      if (period === 'month') {
        startEnd.start = moment(startEnd.start)
          .startOf('month')
          .format('YYYY-MM-DD');
      } else if (period === 'week') {
        startEnd.start = moment(startEnd.start)
          .startOf('isoWeek')
          .format('YYYY-MM-DD');
      }

      uniqueChartHeadings(period);
      datePicker(period);
      parseTotals(period, campaign);

      getLastDate
        .get(
          campaign,
          period,
          startEnd.start,
          startEnd.end ? startEnd.end : moment().format('YYYY-MM-DD'),
          'campaign'
        )
        .then(function(lastDateObj) {
          var lastDate;
          if (lastDateObj) {
            lastDate = lastDateObj.Period_End
              ? lastDateObj.Period_End
              : lastDateObj.Period_Start;
          } else {
            lastDate = startEnd.end;
          }

          startEnd.end = lastDate;

          vm.currentDateToLog = startEnd.end;

          cumulativeChartCampaign.changeDate(
            vm.activeDateRange,
            period,
            moment(startEnd.end).toDate()
          );
          //  logging dashboard data
          logEvents('Audiences dashboard render');
          uniqueChartCampaign.chart(
            period,
            campaign,
            vm.activeDateRange,
            startEnd,
            variable
          );
          helixChartsCampaign.chart(
            period,
            campaign,
            startEnd,
            variable,
            vm.activePersonasChart,
            vm.yearSelected
          );
          premiumChartsCampaign.chart(period, campaign, startEnd, variable);
          // update the charts if the category is ONLY selected to be as STATE and if no variables are there
          if (!variable.variable || vm.selectedCategory === 'STATE') {
            // check if the id is in array then dont show gender/age chart
            if (exclusionCheck) {
              commonCampaign.hideSpinner('gender-chart', 'showNoDataMsg');
              commonCampaign.hideSpinner('age-chart', 'showNoDataMsg');
            } else {
              genderChartCampaign.chart(period, campaign, startEnd, variable);
              ageChartCampaign.chart(period, campaign, startEnd, variable);
            }
          }
          areaChartCampaign.chart(
            period,
            campaign,
            startEnd,
            vm.activeAreaChart,
            vm.radioModel.toLowerCase(),
            variable
          );
          topWebsiteChartCampaign.chart(period, campaign, startEnd, variable);

          uniqueChartCampaign
            .checkIfDataExists(campaign, period, startEnd)
            .then(function(result) {
              if (result) {
                if (!variable.variable || vm.selectedCategory === 'STATE') {
                  deviceChartCampaign.chart(
                    period,
                    campaign,
                    startEnd,
                    variable
                  );

                  // get start date and end date shown in datepicker
                  startEnd = getStartEndDateOfSelectedPeriod(period);
                  localVsInternationalChartCampaign.chart(
                    period,
                    campaign,
                    startEnd,
                    variable
                  );
                }

                vm.disableDropdowns = false;
              } else {
                commonCampaign.hideSpinner(
                  'local-vs-international-chart',
                  'showNoDataMsg'
                );
                commonCampaign.hideSpinner('device-chart', 'showNoDataMsg');
                vm.disableDropdowns = true;
              }

              vm.disableButtons = false;
            });
        });
    }

    /**
     * Calculate Totals for Information Panel
     * @param {string} period - The date period
     * @param {string} campaignId
     * @param {Date} [date] - Optional Date
     */
    function parseTotals(period, campaignId, date) {
      clearTotals();
      switch (period) {
        case 'all':
          allTotals(campaignId);
          break;
        case 'month':
          monthlyTotals(campaignId, date);
          break;
        case 'week':
          weeklyTotals(campaignId, date);
          break;
        case 'day':
          dailyTotals(campaignId, date);
          break;
        default:
          $log.error('Campaign parseTotals default case');
      }
    }

    /**
     * Change unique chart headings based on selected period.
     * @param {string} period - The date period
     */
    function uniqueChartHeadings(period) {
      switch (period) {
        case 'all':
          vm.uniqueChartPeriodHeading = 'Daily Count';
          break;
        case 'month':
          vm.uniqueChartPeriodHeading = 'Monthly Count';
          break;
        case 'week':
          vm.uniqueChartPeriodHeading = 'Weekly Count';
          break;
        case 'day':
          vm.uniqueChartPeriodHeading = 'Daily Count';
          break;
        default:
          $log.error('Campaign uniqueChartHeadings default case');
      }
    }

    /**
     * Clear Information Panel Totals
     */
    function clearTotals() {
      var clearString = '-------------';
      vm.ua = clearString;
      vm.impressions = clearString;
      vm.clicks = clearString;
      vm.reach = clearString;
      vm.ctr = clearString;
      vm.frequency = clearString;
      vm.uaTotalPercent = '';
      vm.clkTotalPercent = '';
    }

    /**
     * Calculate Totals for Information Panel
     * @param {Array} totals
     */
    function calculateTotals(totals) {
      var ua = Math.floor(Number(totals[totals.length - 1].UA));
      if (isNaN(ua)) {
        ua = 0;
      }
      var variable = {
        variable: vm.activeVariable,
        file: selectedVariableFile,
        category: vm.selectedCategory
      };
      vm.ua = localeString(ua);

      var impressions = Math.floor(
        Number(totals[totals.length - 1].impressions)
      );
      if (isNaN(impressions)) {
        impressions = 0;
      }
      vm.impressions = localeString(impressions);

      var clicks = Math.floor(Number(totals[totals.length - 1].clicks));
      if (isNaN(clicks)) {
        clicks = 0;
      }
      vm.clicks = localeString(clicks);

      if (variable.file == 'STATE') {
        /**
         * get australia's state population from BQ
         */
        getAusStatePopulation.get(variable.variable).then(function(result) {
          console.log('2222222222222222222222');
          console.log(result);
          var ausPopulation = result.ABS_Pop_0;
          console.log(result);
          var reach = ua / ausPopulation;
          vm.reach = String((reach * 100).toFixed(2)) + '%';
        });
      } else {
        /**
         * get australia's population from BQ
         */
        getAusPopulation.get().then(function(result) {
          var ausPopulation = result.ABS_Pop_0;
          console.log(result);
          var reach = ua / ausPopulation;
          vm.reach = String((reach * 100).toFixed(2)) + '%';
        });
      }

      var ctr = clicks / impressions;
      if (isNaN(ctr)) {
        ctr = 0;
      }
      vm.ctr = String((ctr * 100).toFixed(2)) + '%';

      var frequency = impressions / ua;
      if (isNaN(frequency)) {
        frequency = 0;
      }
      vm.frequency = String(frequency.toFixed(2));
    }

    /**
     * Calculate percentage for filtered results.
     * - calculateTotals must have been run on filtered totals first.
     * @param {Array} totals
     * @param {Object} variable
     */
    function calculateFilteredTotals(totals, variable) {
      var idxObjects = filtersWithIdx.filter(function(f) {
        if (f.name === variable.variable) {
          return f.idx;
        }
        return undefined;
      });
      var idxValue = idxObjects[0].idx;
      var ua = Math.floor(Number(totals[totals.length - 1].UA));
      var clicks = Math.floor(Number(totals[totals.length - 1].clicks));

      if (ua > 0) {
        vm.uaTotalPercent = ' (';
        var rawUAPcnt = Number(vm.ua.split(',').join('')) / ua;
        rawUAPcnt *= 100;
        vm.uaTotalPercent += Math.floor(rawUAPcnt).toString() + '%, ';
        var ix = rawUAPcnt / idxValue;
        vm.uaTotalPercent += Math.floor(ix * 100).toString();
        vm.uaTotalPercent += 'ix)';
      }

      if (clicks > 0) {
        var clkPcnt =
          Math.floor(Number(vm.clicks.split(',').join(''))) / clicks;
        vm.clkTotalPercent = ' (' + Math.floor(clkPcnt * 100).toString() + '%)';
      }
    }

    /**
     * Campaign All Totals
     * @param {string} campaignId
     */
    function allTotals(campaignId) {
      var variable = {
        variable: vm.activeVariable,
        file: selectedVariableFile,
        category: vm.selectedCategory
      };
      var startEnd = {
        start: moment(vm.campaign.startDate).format('YYYY-MM-DD'),
        end: vm.campaign.endDate
          ? moment(vm.campaign.endDate).format('YYYY-MM-DD')
          : moment().format('YYYY-MM-DD')
      };
      cumulativeChartCampaign
        .allTotals(campaignId, startEnd.start, startEnd.end, variable)
        .then(
          function(result) {
            vm.disableButtons = false;
            calculateMaxMinDate(result);
            calculateTotals(result);
            if (variable.variable && variable.file !== 'STATE') {
              cumulativeChartCampaign
                .allTotals(campaignId, startEnd.start, startEnd.end, variable)
                .then(function(res) {
                  calculateFilteredTotals(res, variable);
                });
            }
          },
          function(reason) {
            vm.disableButtons = true;
            $log.error('campaigns-all-totals: Error');
            $log.error(reason);
          }
        );
    }

    /**
     * Campaign Daily Totals
     * @param {string} campaignId
     * @param {Date} [date] - Optional Date
     */
    function dailyTotals(campaignId, date) {
      var variable = {
        variable: vm.activeVariable,
        file: selectedVariableFile,
        category: vm.selectedCategory
      };
      var endDate = date || vm.campaign.endDate;
      endDate = moment(endDate || undefined).format('YYYY-MM-DD');
      var startDate = moment(vm.campaign.startDate).format('YYYY-MM-DD');

      uniqueChartCampaign
        .dailyTotals(campaignId, startDate, endDate, variable)
        .then(
          function(result) {
            // Calculate Max and Min if not changing date
            if (!date) {
              calculateMaxMinDate(result);
            }
            calculateTotals(result);
            if (variable.variable && variable.file !== 'STATE') {
              uniqueChartCampaign
                .dailyTotals(campaignId, startDate, endDate, variable)
                .then(function(res) {
                  calculateFilteredTotals(res, variable);
                });
            }
          },
          function(reason) {
            $log.error(reason);
          }
        );
    }

    /**
     * Campaign Weekly Totals
     * @param {string} campaignId
     * @param {Date} [date] - Optional Date
     */
    function weeklyTotals(campaignId, date) {
      var variable = {
        variable: vm.activeVariable,
        file: selectedVariableFile,
        category: vm.selectedCategory
      };
      var startDate = moment(vm.campaign.startDate).format('YYYY-MM-DD');
      var endDate = date || vm.campaign.endDate;
      endDate = moment(endDate || undefined).format('YYYY-MM-DD');
      uniqueChartCampaign
        .weeklyTotals(campaignId, startDate, endDate, variable)
        .then(
          function(result) {
            if (result.length > 0) {
              // Calculate Max and Min if not changing date
              if (!date) {
                calculateMaxMinDate(result);
              }
              calculateTotals(result);
              if (variable.variable && variable.file !== 'STATE') {
                uniqueChartCampaign
                  .weeklyTotals(campaignId, startDate, endDate, variable)
                  .then(function(res) {
                    calculateFilteredTotals(res, variable);
                  });
              }
            }
          },
          function(reason) {
            $log.error(reason);
          }
        );
    }

    /**
     * Campaign Monthly Totals
     * @param {string} campaignId
     * @param {Date} [date] - Optional Date
     */
    function monthlyTotals(campaignId, date) {
      var variable = {
        variable: vm.activeVariable,
        file: selectedVariableFile,
        category: vm.selectedCategory
      };
      var startDate = moment(vm.campaign.startDate).format('YYYY-MM-DD');
      var endDate = date || vm.campaign.endDate;
      endDate = moment(endDate || undefined).format('YYYY-MM-DD');

      uniqueChartCampaign
        .monthlyTotals(campaignId, startDate, endDate, variable)
        .then(
          function(result) {
            if (result.length > 0) {
              // Calculate Max and Min if not changing date
              if (!date) {
                calculateMaxMinDate(result);
              }
              calculateTotals(result);
              if (variable.variable && variable.file !== 'STATE') {
                uniqueChartCampaign
                  .monthlyTotals(campaignId, startDate, endDate, variable)
                  .then(function(res) {
                    calculateFilteredTotals(res, variable);
                  });
              }
            }
          },
          function(reason) {
            $log.error(reason);
          }
        );
    }

    /**
     * Calculate Max and Min Date for Date Picker
     * @param {Object[]} data
     * @param {string} [data[].Period_End]
     * @param {string} [data[].Period_Start]
     */
    function calculateMaxMinDate(data) {
      if (data[0].Period_End) {
        vm.maxDate = data[data.length - 1].Period_End;
        vm.minDate = data[0].Period_End;
      } else {
        vm.maxDate = data[data.length - 1].Period_Start;
        vm.minDate = data[0].Period_Start;
      }
      vm.datePicker = vm.maxDate;
      weekDatePicker(vm.maxDate, vm.datePeriod);
    }

    /**
     * Calculate value for week date picker
     * @param {string|Date} value - Current date
     * @param {string} datePeriod
     */
    function weekDatePicker(value, datePeriod) {
      if (datePeriod === 'week') {
        vm.datePickerWeek =
          moment(value)
            .startOf('isoWeek')
            .format('DD MMM YYYY') +
          ' - ' +
          moment(value)
            .endOf('isoWeek')
            .format('DD MMM YYYY');
      }
    }

    /**
     * Change selected variable.
     * @param {string} variable - Selected variable
     */
    function changeVariable(variable) {
      // show spinners
      commonCampaign.showSpinner('cumulative-area-chart', 'hideChart');
      commonCampaign.showSpinner('unique-area-chart', 'hideChart');
      commonCampaign.showSpinner('helix-people-chart', 'hideChart');
      commonCampaign.showSpinner('helix-index-chart', 'hideChart');
      commonCampaign.hideNoData('premium-charts');
      commonCampaign.showSpinner('premium-people-chart', 'hideChart');
      commonCampaign.showSpinner('premium-index-chart', 'hideChart');
      commonCampaign.showSpinner('gender-chart', 'hideChart');
      commonCampaign.showSpinner('age-chart', 'hideChart');
      commonCampaign.showSpinner('area-chart', 'hideChart');
      commonCampaign.showSpinner('local-vs-international-chart', 'hideChart');
      commonCampaign.showSpinner('device-chart', 'hideChart');
      commonCampaign.showSpinner('website-chart', 'hideChart');

      vm.disableButtons = true;
      $analytics.eventTrack('Audience Filter Subcategory', {
        category: 'Campaigns',
        label: variable
      });
      vm.processing = true;
      vm.selectedVariable = variable;

      var variableObj = {
        variable: variable,
        file: selectedVariableFile,
        category: vm.selectedCategory
      };
      var dateRange = getActiveDateRange();
      var subCatWrapper = angular.element(
        $document[0].querySelector('.sub-cat-wrapper .btn')
      );

      if (!subCatWrapper.hasClass('active')) {
        subCatWrapper.toggleClass('active');
      }
      var endDate = vm.datePicker || vm.campaign.endDate;

      var startEnd = {
        start: moment(vm.campaign.startDate).format('YYYY-MM-DD'),
        end: endDate ? moment(endDate).format('YYYY-MM-DD') : null
      };

      vm.activeVariable = variable;
      parseTotals(vm.datePeriod, campaign, vm.datePicker);

      //  logging dashboard data
      logEvents('Audiences dashboard render');

      cumulativeChartCampaign.chart(
        campaign,
        dateRange,
        vm.datePeriod,
        startEnd.start,
        startEnd.end,
        variableObj
      );
      uniqueChartCampaign.chart(
        vm.datePeriod,
        campaign,
        dateRange,
        startEnd,
        variableObj
      );
      helixChartsCampaign.chart(
        vm.datePeriod,
        campaign,
        startEnd,
        variableObj,
        vm.activePersonasChart,
        vm.yearSelected
      );
      premiumChartsCampaign.chart(
        vm.datePeriod,
        campaign,
        startEnd,
        variableObj
      );
      areaChartCampaign.chart(
        vm.datePeriod,
        campaign,
        startEnd,
        vm.activeAreaChart,
        vm.radioModel.toLowerCase(),
        variableObj
      );
      topWebsiteChartCampaign.chart(
        vm.datePeriod,
        campaign,
        startEnd,
        variableObj
      );
      var localOverlay = $document[0].querySelector(
        '#local-vs-international-chart .overlay'
      );
      // Show charts for state category and hide from others
      if (vm.selectedCategory === 'STATE') {
        // check if the id is in array then dont show gender/age chart
        if (exclusionCheck) {
          commonCampaign.hideSpinner('gender-chart', 'showNoDataMsg');
          commonCampaign.hideSpinner('age-chart', 'showNoDataMsg');
        } else {
          genderChartCampaign.chart(
            vm.datePeriod,
            campaign,
            startEnd,
            variableObj
          );
          ageChartCampaign.chart(
            vm.datePeriod,
            campaign,
            startEnd,
            variableObj
          );
        }

        // Show the local chart
        if (vm.radioModel === 'Impressions') {
          angular.element(localOverlay).removeClass('active');
        } else {
          angular.element(localOverlay).addClass('active');
        }
        localVsInternationalChartCampaign.chart(
          vm.datePeriod,
          campaign,
          startEnd,
          variableObj
        );
        deviceChartCampaign.chart(
          vm.datePeriod,
          campaign,
          startEnd,
          variableObj
        );
      } else {
        // Grey out unused charts.
        commonCampaign.hideSpinner('gender-chart', 'showNoDataMsg');
        commonCampaign.hideSpinner('age-chart', 'showNoDataMsg');
        commonCampaign.hideSpinner(
          'local-vs-international-chart',
          'showNoDataMsg'
        );
        commonCampaign.hideSpinner('device-chart', 'showNoDataMsg');
      }

      $timeout(clearSpinner, 2500);
      vm.disableButtons = false;
    }

    function clearSpinner() {
      vm.processing = false;
    }

    /**
     * Change Variable Category
     * @param {string} category - Selected category
     */
    function changeVariableCategory(category) {
      //  check if the selected category is state then disable state chart
      if (vm.selectedCategory === 'STATE') {
        vm.showAreaChart = ['area', 'postcode'];
        // if in area chart already state chart is displayed then show the area chart
        if (vm.activeAreaChart === 'state') {
          changeAreaChart('area');
        }
      } else {
        vm.showAreaChart = null;
      }

      vm.disableButtons = true;
      $analytics.eventTrack('Audience Filter', {
        category: 'Campaigns',
        label: category
      });

      vm.selectedVariable = null;

      var catWrapper = angular.element(
        $document[0].querySelector('.cat-wrapper .btn')
      );
      var subCatWrapper = angular.element(
        $document[0].querySelector('.sub-cat-wrapper .btn')
      );
      if (vm.variableCategories[0] !== 'Total Audience') {
        vm.variableCategories.unshift('Total Audience');
      }

      if (category === 'Total Audience') {
        // show spinners
        commonCampaign.showSpinner('cumulative-area-chart', 'hideChart');
        commonCampaign.showSpinner('unique-area-chart', 'hideChart');
        commonCampaign.showSpinner('helix-people-chart', 'hideChart');
        commonCampaign.showSpinner('helix-index-chart', 'hideChart');
        commonCampaign.hideNoData('premium-charts');
        commonCampaign.showSpinner('premium-people-chart', 'hideChart');
        commonCampaign.showSpinner('premium-index-chart', 'hideChart');
        commonCampaign.showSpinner('gender-chart', 'hideChart');
        commonCampaign.showSpinner('age-chart', 'hideChart');
        commonCampaign.showSpinner('area-chart', 'hideChart');
        commonCampaign.showSpinner('local-vs-international-chart', 'hideChart');
        commonCampaign.showSpinner('device-chart', 'hideChart');

        category = '';
        selectedVariableFile = null;
        vm.variableCategories.shift();
        vm.categorySelected = false;
        vm.selectedCategory = null;
        vm.variables = null;
        vm.activeVariable = null;
        var dateRange = getActiveDateRange();

        if (catWrapper.hasClass('active')) {
          catWrapper.toggleClass('active');
        }

        if (subCatWrapper.hasClass('active')) {
          subCatWrapper.toggleClass('active');
        }

        parseTotals(vm.datePeriod, campaign, vm.datePicker);
        var endDate = vm.datePicker || vm.campaign.endDate;

        var startEnd = {
          start: moment(vm.campaign.startDate).format('YYYY-MM-DD'),
          end: endDate ? moment(endDate).format('YYYY-MM-DD') : null
        };
        cumulativeChartCampaign.chart(
          campaign,
          dateRange,
          vm.datePeriod,
          startEnd.start,
          startEnd.end,
          vm.activeVariable
        );
        uniqueChartCampaign.chart(
          vm.datePeriod,
          campaign,
          dateRange,
          startEnd,
          vm.activeVariable
        );
        helixChartsCampaign.chart(
          vm.datePeriod,
          campaign,
          startEnd,
          vm.activeVariable,
          vm.activePersonasChart,
          vm.yearSelected
        );
        premiumChartsCampaign.chart(
          vm.datePeriod,
          campaign,
          startEnd,
          vm.activeVariable
        );

        // check if the id is in array then dont show gender/age chart
        if (exclusionCheck) {
          commonCampaign.hideSpinner('gender-chart', 'showNoDataMsg');
          commonCampaign.hideSpinner('age-chart', 'showNoDataMsg');
        } else {
          genderChartCampaign.chart(vm.datePeriod, campaign, startEnd);
          ageChartCampaign.chart(vm.datePeriod, campaign, startEnd);
        }

        areaChartCampaign.chart(
          vm.datePeriod,
          campaign,
          startEnd,
          vm.activeAreaChart,
          vm.radioModel.toLowerCase(),
          vm.activeVariable
        );

        var localOverlay = $document[0].querySelector(
          '#local-vs-international-chart .overlay'
        );
        // Show the local chart
        if (vm.radioModel === 'Impressions') {
          angular.element(localOverlay).removeClass('active');
        } else {
          angular.element(localOverlay).addClass('active');
        }
        localVsInternationalChartCampaign.chart(
          vm.datePeriod,
          campaign,
          startEnd
        );
        deviceChartCampaign.chart(vm.datePeriod, campaign, startEnd);
        topWebsiteChartCampaign.chart(
          vm.datePeriod,
          campaign,
          startEnd,
          vm.activeVariable
        );
        vm.disableButtons = false;
      } else if (category === 'STATE') {
        vm.selectedCategory = category;
        // get states as categories
        vm.variables = ['VIC', 'NSW', 'QLD', 'WA', 'SA', 'TAS', 'NT', 'ACT'];

        selectedVariableFile = 'STATE';

        if (!catWrapper.hasClass('active')) {
          catWrapper.toggleClass('active');
        }

        vm.categorySelected = true;
        vm.disableButtons = false;
      } else {
        if (!catWrapper.hasClass('active')) {
          catWrapper.toggleClass('active');
        }

        variableFiles.forEach(function(file) {
          if (file.name === category) {
            selectedVariableFile = file.refName;
          }
        });

        vm.selectedCategory = category;
        $http
          .post('/api/insights/variables/', {
            category: category,
            fileName: selectedVariableFile
          })
          .then(
            function(result) {
              filtersWithIdx = result.data;
              vm.variables = result.data.map(function(v) {
                return v.name;
              });
              vm.categorySelected = true;
              vm.disableButtons = false;
            },
            function(reason) {
              $log.error(reason.data);
            }
          );
      }
    }

    /**
     * Toggle between area charts.
     * @param {string} chart
     */
    function changeAreaChart(chart) {
      // show spinner on switching to different mode i.e area/state/postcode
      commonCampaign.showSpinner('area-chart', 'hideChart');

      vm.activeAreaChart = chart.toLowerCase();
      var endDate = vm.datePicker || vm.campaign.endDate;

      var startEnd = {
        start: moment(vm.campaign.startDate).format('YYYY-MM-DD'),
        end: endDate ? moment(endDate).format('YYYY-MM-DD') : null
      };

      var variable = {
        variable: vm.activeVariable,
        file: selectedVariableFile,
        category: vm.selectedCategory
      };

      areaChartCampaign.chart(
        vm.datePeriod,
        vm.campaign.id.toString(),
        startEnd,
        vm.activeAreaChart,
        vm.radioModel.toLowerCase(),
        variable
      );
    }

    function changeFocus() {
      //  logging dashboard data
      logEvents('Audiences dashboard render');

      if (vm.activeAreaChart === 'postcode') {
        changeAreaChart(vm.activeAreaChart);
      }
    }

    /**
     * Toggle between personas and communities on the Helix Personas chart.
     * @param {string} chart
     */
    function changePersonasChart(chart) {
      // showing spinner on getting data from server
      commonCampaign.showSpinner('helix-people-chart', 'hideChart');
      commonCampaign.showSpinner('helix-index-chart', 'hideChart');

      vm.activePersonasChart = chart;
      var endDate = vm.datePicker || vm.campaign.endDate;

      var startEnd = {
        start: moment(vm.campaign.startDate).format('YYYY-MM-DD'),
        end: endDate ? moment(endDate).format('YYYY-MM-DD') : null
      };

      var variable = {
        variable: vm.activeVariable,
        file: selectedVariableFile,
        category: vm.selectedCategory
      };

      helixChartsCampaign.chart(
        vm.datePeriod,
        vm.campaign.id.toString(),
        startEnd,
        variable,
        chart,
        vm.yearSelected
      );

      premiumChartsCampaign.chart(
        vm.datePeriod,
        vm.campaign.id.toString(),
        startEnd,
        variable
      );
    }

    /**
     * check if the chart is present or not
     * @param {string} wrapperName - chart Id
     */
    function checkIfChartIsloaded(wrapperName) {
      var wrapper = document.getElementById(wrapperName);
      return wrapper.getElementsByTagName('svg').length >= 1;
    }

    /**
     * get the dates for tables by period name
     * @param {string} period - month/week/day etc
     */
    function getStartEndDateOfSelectedPeriod(period) {
      var startEnd;
      // find the first date and the last date of month
      if (period === 'month') {
        startEnd = {
          start: moment(vm.datePicker)
            .startOf('month')
            .format('YYYY-MM-DD'),
          end: vm.datePicker
            ? moment(vm.datePicker)
                .endOf('month')
                .format('YYYY-MM-DD')
            : null
        };
      } else if (period === 'week') {
        startEnd = {
          start: moment(vm.datePicker)
            .startOf('isoWeek')
            .format('YYYY-MM-DD'),
          end: vm.datePicker
            ? moment(vm.datePicker)
                .endOf('isoWeek')
                .format('YYYY-MM-DD')
            : null
        };
      } else if (period === 'day') {
        startEnd = {
          start: moment(vm.datePicker).format('YYYY-MM-DD'),
          end: vm.datePicker ? moment(vm.datePicker).format('YYYY-MM-DD') : null
        };
      }
      return startEnd;
    }

    /**
     * Change Year to see data
     * - 2014 -> 2016
     * @param {string} year
     */
    function changeYear(year) {
      store.set('helixVersion', year);
      vm.yearSelected = year;

      // show spinners
      commonCampaign.showSpinner('helix-people-chart', 'hideChart');
      commonCampaign.showSpinner('helix-index-chart', 'hideChart');

      $analytics.eventTrack('Campaigns', {
        category: 'Year changed',
        label: year
      });
      var variableObj = {
        variable: vm.activeVariable,
        file: selectedVariableFile,
        category: vm.selectedCategory
      };
      getActiveDateRange();
      var endDate = vm.datePicker || vm.campaign.endDate;

      var startEnd = {
        start: moment(vm.campaign.startDate).format('YYYY-MM-DD'),
        end: endDate ? moment(endDate).format('YYYY-MM-DD') : null
      };

      helixChartsCampaign.chart(
        vm.datePeriod,
        vm.campaign.id.toString(),
        startEnd,
        variableObj,
        vm.activePersonasChart,
        vm.yearSelected
      );

      premiumChartsCampaign.chart(
        vm.datePeriod,
        vm.campaign.id.toString(),
        startEnd,
        variableObj
      );

      //  logging dashboard data
      logEvents('Audiences dashboard render');
    }

    /**
     * Log action and events
     * @param {string} action
     */
    function logEvents(action) {
      //  logging  data
      var timePeriod =
        vm.datePeriod === 'all' ? 'total' : vm.datePeriod.toLowerCase();
      var currentDate = null;
      if (timePeriod === 'month') {
        currentDate = moment(vm.currentDateToLog).format('MM-YYYY');
      } else if (timePeriod === 'day' || timePeriod === 'week') {
        currentDate = moment(vm.currentDateToLog).format('DD-MM-YYYY');
      }

      var activeCategory =
        vm.selectedCategory &&
        vm.selectedCategory.toLowerCase() !== 'total audience'
          ? vm.selectedCategory
          : 'null';
      var activeSubVariable = vm.selectedVariable
        ? vm.selectedVariable
        : 'null';

      var dataString =
        vm.campaign.id +
        '/period=' +
        timePeriod +
        '/period-value=' +
        currentDate +
        '/reporting-measure=' +
        vm.radioModel.toLowerCase() +
        '/variable-category=' +
        activeCategory.toLowerCase() +
        '/variable-subcategory=' +
        activeSubVariable.toLowerCase() +
        '/persona-type=' +
        store.get('helixVersion');

      $http.post(
        '/api/pixels/logevents',
        JSON.stringify({
          action: action,
          dataString: dataString
        })
      );
    }
  }
})();
