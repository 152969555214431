'use strict';

angular.module('upload').factory('SignedUrlHelper', [
  '$http',
  '$q',
  function($http, $q) {
    function getRequest(url) {
      var deferred = $q.defer();
      $http({ method: 'GET', url: url }).then(
        function(data) {
          deferred.resolve(data);
        },
        function(err) {
          deferred.reject(err);
        }
      );
      return deferred.promise;
    }

    return {
      getSignedUrlForDocument: function(document) {
        var url = 'api/upload/signeddocumenturl/' + document;
        return getRequest(url);
      } // ,
      //
      // getSignedUrlForImage: function(filename){
      //    var url = 'api/asteroidsearch/signedimageurl/' + filename;
      //    return getRequest(url);
      // }
    };
  }
]);
