'use strict';

// Start by defining the main module and adding the module dependencies
angular.module(
  ApplicationConfiguration.applicationModuleName,
  ApplicationConfiguration.applicationModuleVendorDependencies
);

// @ts-ignore
Raven.config(
  'https://ee59e675c61146ccabc4089871d4ccf9@o379043.ingest.sentry.io/5207411'
  )
  // @ts-ignore
  .addPlugin(Raven.Plugins.Angular)
  .install();

// Setting HTML5 Location Mode
angular.module(ApplicationConfiguration.applicationModuleName).config([
  '$locationProvider',
  function($locationProvider) {
    $locationProvider.html5Mode({
      enabled: true,
      requireBase: false
    });
  }
]);

// Then define the init function for starting up the application
angular.element(document).ready(function() {
  // Then init the app
  angular.bootstrap(document, [ApplicationConfiguration.applicationModuleName]);
});

angular
  .module(ApplicationConfiguration.applicationModuleName)
  .config(function($sceDelegateProvider) {
    $sceDelegateProvider.resourceUrlWhitelist([
      'self',
      'https://audienceshelpdesk.roymorgan.com/**'
    ]);
  });
