'use strict';

angular.module('insights').factory('HelixData', [
  function() {
    var chartData = [];

    return {
      getData: getData,
      setData: setData
    };

    /**
     * Set chart data
     * @param {Array} data
     */
    function setData(data) {
      chartData = data;
    }

    /**
     * Get chart data
     * @returns {Array}
     */
    function getData() {
      return chartData;
    }
  }
]);
