'use strict';

// Pixels controller
angular.module('pixels').controller('PixelsController', [
  '$scope',
  '$stateParams',
  '$location',
  'Authentication',
  'Pixels',
  function($scope, $stateParams, $location, Authentication, Pixels) {
    $scope.authentication = Authentication;

    // Update existing Pixel
    $scope.update = function() {
      var pixel = $scope.pixel;

      pixel.$update(
        function() {
          $location.path('pixels/' + pixel._id);
        },
        function(errorResponse) {
          $scope.error = errorResponse.data.message;
        }
      );
    };
  }
]);
