(function() {
  'use strict';

  // Noaccess controller
  angular.module('core').controller('NoaccessController', NoaccessController);

  NoaccessController.$inject = [
    'Authorization',
    'store',
    '$scope',
    '$window',
    'Authentication',
    '$filter',
    '$location',
    '$rootScope'
  ];

  function NoaccessController(
    Authorization,
    store,
    $scope,
    $window,
    Authentication,
    $filter,
    $location,
    $rootScope
  ) {
    Authorization.authorize().then(function() {
      initialise();
    });

    $scope.$on('loggedIn', function() {
      initialise();
    });

    // Expose view variables
    $scope.companiesOpen = false;
    $scope.teamsOpen = false;
    $scope.currentActiveCompany = 'Organisation';
    $scope.currentActiveTeam = 'Team';
    $scope.storeCurrentOrg = '';
    $scope.storeCurrentTeam = '';

    function initialise() {
      $scope.loggedIn = true;
      var allActiveUserCompaniesArr = store.get('companies');

      $scope.userAccessedOrg = [];

      if (store.get('currentCompany')) {
        $scope.currentCompanyWithNoAccess = store.get('currentCompany').name;
      }
      if (store.get('currentTeam')) {
        $scope.currentTeamWithNoAccess = store.get('currentTeam').name;
      }

      // current company product access
      if (store.get('productsAccess')) {
        $scope.productsAccess = store.get('productsAccess');
      }

      // get active organisations of active user with product id - For audience the product id is 2 (Live Audience Evaluation)
      Authentication.getActiveUserAccessedOrg('2').then(function(
        userAccessedOrgArr
      ) {
        $scope.userAccessedOrg = userAccessedOrgArr;
        var userAccessibleCompaniesArr = [];

        $scope.userAccessedOrg.forEach(function(orgArray) {
          // filter particular company from all company/organisation array in order to get the all required values needed for changing/setting organisation
          userAccessibleCompaniesArr.push(
            $filter('filter')(
              allActiveUserCompaniesArr,
              {
                id: orgArray.organisationId
              },
              true
            )[0]
          );
        });

        if (userAccessibleCompaniesArr.length) {
          $scope.companies = userAccessibleCompaniesArr;
          $scope.storeCurrentOrg = userAccessibleCompaniesArr[0];
          // current selected company in drop down
          $scope.currentSelectedCompany = userAccessibleCompaniesArr[0].name;
          // update Teams drop down with the array that user has accessed too
          $scope.updateUsersAccessibleTeamsDropDown($scope.storeCurrentOrg.id);
        } else {
          $scope.companies = '';
          $scope.currentSelectedCompany = '';
          $scope.storeCurrentOrg = '';
        }
      });
    }

    // change the organisation or company
    $scope.changeCompany = function(organisation) {
      $scope.companiesOpen = false;
      $scope.currentSelectedCompany = organisation.name;
      $scope.storeCurrentOrg = organisation;
      // update Teams drop down with the array that user has accessed too
      $scope.updateUsersAccessibleTeamsDropDown(organisation.id);
    };

    $scope.changeTeam = function(team) {
      $scope.teamsOpen = false;
      $scope.storeCurrentTeam = team;
      $scope.currentSelectedTeam = team.name;
    };

    $scope.setCompanyAndTeam = function() {
      Authentication.updateStorageValue(
        'currentCompany',
        $scope.storeCurrentOrg
      );

      if ($scope.teams.length) {
        var team = $scope.storeCurrentTeam;
        $scope.teamsOpen = false;

        Authentication.updateStorageValue('currentTeam', team);
        Authentication.updateStorageValue(
          'teams',
          $scope.allTeamsRegardlessOfRole
        );

        Authentication.getActiveUseFeatureAccessInTeam(team).then(function(
          permissions
        ) {
          if (permissions.length && permissions) {
            var currentCompany = store.get('currentCompany');
            Authentication.updateStorageValue('permissionSet', permissions);
            Authentication.updateStorageValue('collections', {});
            var permissionList = Authentication.convertPermissionSetsToPermissions(
              permissions
            );
            Authentication.getCollections(
              currentCompany.id,
              team.id,
              permissionList
            ).then(function() {
              $rootScope.$broadcast('getcollections');
              $window.location.href = '/';
            });
          } else {
            store.remove('collections');
            store.remove('permissionSet');
            store.remove('permission');
            console.error('Looks like you do not have permissions.');
            $rootScope.$broadcast('This team have no permissions');
            $window.location.href = '/';
          }
        });
      } else {
        // company without teams
        store.remove('teams');
        store.remove('currentTeam');
        var organisation = $scope.storeCurrentOrg;
        Authentication.getActiveUserNineDotMenuProductAccess(
          organisation.id,
          ''
        ).then(function(productsAccess) {
          Authentication.updateStorageValue('productsAccess', productsAccess);

          // get user permissions
          Authentication.getActiveUserFeatureAccessInOrg(organisation.id).then(
            function(permissions) {
              if (permissions.length && permissions) {
                Authentication.updateStorageValue('collections', {});
                Authentication.updateStorageValue('permissionSet', permissions);
                var permissionList = Authentication.convertPermissionSetsToPermissions(
                  permissions
                );
                Authentication.getCollections(
                  organisation.id,
                  '',
                  permissionList
                ).then(function() {
                  $rootScope.$broadcast('Get collections');
                  $window.location.href = '/';
                });
              } else {
                store.remove('permissionSet');
                store.remove('permission');
                store.remove('collections');
                console.error('Looks like you do not have permissions.');
                $rootScope.$broadcast('This organisation have no permissions');
                $window.location.href = '/';
              }
            }
          );
        });
      }
    };

    /**
     * Update the teams drop down with the users accessible teams
     * @PARAM organisation id (integer) - orgId
     */
    $scope.updateUsersAccessibleTeamsDropDown = function(orgId) {
      $scope.teamsOpen = false;

      // get accessible TEAMS of active organisation with product id and organisation id - For audience the product id is 2 (Live Audience Evaluation)
      Authentication.getActiveUserAccessibleTeams(
        '2',
        $scope.storeCurrentOrg.id
      ).then(function(accessibleTeamsArr) {
        if (accessibleTeamsArr.length && accessibleTeamsArr[0]) {
          // get all the teams of the selected organisation regardless user have access or not
          Authentication.getActiveUsersOrganisationsTeams(orgId).then(function(
            allTeamsRegardlessOfRole
          ) {
            var finalFilteredTeamsArr = [];
            accessibleTeamsArr.forEach(function(currentOrgAccessibleTeam) {
              finalFilteredTeamsArr.push(
                $filter('filter')(
                  allTeamsRegardlessOfRole,
                  {
                    id: currentOrgAccessibleTeam.teamId
                  },
                  true
                )[0]
              );
            });
            $scope.allTeamsRegardlessOfRole = allTeamsRegardlessOfRole;
            $scope.teams = finalFilteredTeamsArr;
            $scope.storeCurrentTeam = finalFilteredTeamsArr[0];
            $scope.currentSelectedTeam = finalFilteredTeamsArr[0].name;
          });
        } else {
          $scope.teams = '';
          $scope.storeCurrentTeam = '';
          $scope.currentSelectedTeam = '';
          $scope.allTeamsRegardlessOfRole = '';
        }
      });
    };
  }
})();
