'use strict';

angular.module('websites').directive('websites', [
  function() {
    return {
      template: '<div></div>',
      restrict: 'E',
      link: function postLink(scope, element, attrs) {
        // Websites directive logic
        // ...

        element.text('this is the websites directive');
      }
    };
  }
]);
