(function() {
  'use strict';

  angular.module('insights').factory('downloads', downloads);

  function downloads() {
    return {
      filename: filename,
      renameHeading: renameHeading,
      removeHeading: removeHeading,
      addHeading: addHeading,
      orderFields: orderFields,
      downloadsStartDate: downloadsStartDate
    };

    /**
     * Generate file name for download file.
     * @param {string} insightName
     * @param {string} variableName
     * @param {string} dateRange
     * @param {string} date
     */
    function filename(insightName, variableName, dateRange, date) {
      if (!variableName) {
        variableName = '';
      }

      return (
        insightName +
        variableName +
        'Audience_Downloads_' +
        dateRange +
        '_' +
        date +
        '.zip'
      );
    }

    /**
     * Rename Unique download headings.
     *
     * @param {Array<Object>} data
     * @param {string} oldFieldName
     * @param {string} newFieldName
     */
    function renameHeading(data, oldFieldName, newFieldName) {
      return data.map(function(datum) {
        datum[newFieldName] = datum[oldFieldName];
        delete datum[oldFieldName];
        return datum;
      });
    }

    /**
     * Rename Unique download headings.
     *
     * @param {Array<Object>} data
     * @param {string} heading
     */
    function removeHeading(data, heading) {
      return data.map(function(datum) {
        delete datum[heading];
        return datum;
      });
    }

    /**
     * Add heading with data.
     *
     * @param {Array<Object>} data
     * @param {string} heading
     * @param {string} columnData
     */
    function addHeading(data, heading, columnData) {
      return data.map(function(datum) {
        datum[heading] = columnData;
        return datum;
      });
    }

    /**
     * Generate date range for downloads.
     *
     * @param {?string} endDate
     * @param {string} range
     * @param {string} startDate
     * @return {string}
     */
    function downloadsStartDate(endDate, range, startDate) {
      if (!endDate) {
        endDate = moment().format('YYYY-MM-DD');
      }
      switch (range.toLowerCase()) {
        case '7 days':
          return moment(endDate)
            .subtract(6, 'days')
            .format('YYYY-MM-DD');
        case '15 days':
          return moment(endDate)
            .subtract(14, 'days')
            .format('YYYY-MM-DD');
        case '30 days':
          return moment(endDate)
            .subtract(29, 'days')
            .format('YYYY-MM-DD');
        case '90 days':
          return moment(endDate)
            .subtract(89, 'days')
            .format('YYYY-MM-DD');
        case '365 days':
          return moment(endDate)
            .subtract(364, 'days')
            .format('YYYY-MM-DD');

        case '3 months':
          return moment(endDate)
            .subtract(2, 'months')
            .format('YYYY-MM-DD');
        case '6 months':
          return moment(endDate)
            .subtract(5, 'months')
            .format('YYYY-MM-DD');
        case '9 months':
          return moment(endDate)
            .subtract(8, 'months')
            .format('YYYY-MM-DD');
        case '12 months':
          return moment(endDate)
            .subtract(11, 'months')
            .format('YYYY-MM-DD');

        case '4 weeks':
          return moment(endDate)
            .subtract(3, 'weeks')
            .format('YYYY-MM-DD');
        case '12 weeks':
          return moment(endDate)
            .subtract(11, 'weeks')
            .format('YYYY-MM-DD');
        case '24 weeks':
          return moment(endDate)
            .subtract(23, 'weeks')
            .format('YYYY-MM-DD');
        case '52 weeks':
          return moment(endDate)
            .subtract(51, 'weeks')
            .format('YYYY-MM-DD');

        case 'all':
          return moment(startDate).format('YYYY-MM-DD');
        default:
          return endDate;
      }
    }

    /**
     * Re-order download data fields
     *
     * @param {Array<Object>} data
     * @param {Array<string>} orderedHeadings
     *
     * @returns {Array<Map>}
     */
    function orderFields(data, orderedHeadings) {
      var newData = data.map(function(datum) {
        var map = new Map();
        orderedHeadings.forEach(function(heading) {
          map[heading] = datum[heading];
        });
        return map;
      });
      return newData;
    }
  }
})();
