'use strict';

angular.module('insights').factory('InsightsAgeConfig', [
  'InsightsConfig',
  function(InsightsConfig) {
    return {
      chartId: '#age-chart',
      margins: {
        top: 80,
        left: 48,
        right: 24,
        bottom: 100
      },
      legendPanel: {
        height: 100
      },
      noInsights: InsightsConfig.noInsights,

      width: function() {
        return (
          d3
            .select(this.chartId)
            .node()
            .getBoundingClientRect().width -
          this.margins.left -
          this.margins.right
        );
      },

      height: function() {
        return 420 - this.margins.top - this.margins.bottom;
      }
    };
  }
]);
