'use strict';

angular.module('insights').factory('PremiumPeopleConfig', [
  'InsightsConfig',
  function(InsightsConfig) {
    return {
      chartId: function() {
        return InsightsConfig.premiumPeopleChart;
      },

      width: function() {
        var width = d3
          .select(this.chartId())
          .node()
          .getBoundingClientRect().width;
        width = width - this.margin.left - this.margin.right;
        return width;
      },

      margin: {
        top: 30,
        right: 20,
        bottom: 0,
        left: 20
      },
      widthPercent: function() {
        var widthPercent;
        if (this.measuredWidth() < 400) {
          widthPercent = ((this.width() - 150) / this.width()) * 100;
          angular
            .element(document.querySelector('#premium-charts'))
            .toggleClass('small-chart');
        } else {
          widthPercent =
            ((this.width() - InsightsConfig.premiumPeopleYLabelOffset) /
              this.width()) *
            100;
        }

        return widthPercent;
      },

      measuredWidth: function() {
        return d3
          .select(this.chartId())
          .node()
          .getBoundingClientRect().width;
      },

      barHeight: function() {
        return InsightsConfig.barHeight;
      },

      spacing: 3,
      premiumPeopleYLabelOffset: function() {
        return InsightsConfig.premiumPeopleYLabelOffset;
      },

      doubleHeight: function() {
        return InsightsConfig.doubleHeight;
      }
    };
  }
]);
