'use strict';

angular.module('resources').directive('cayzu', [
  '$http',
  function($http) {
    return {
      template: '<iframe height="100%" width="100%" frameborder="0"></iframe>',
      restrict: 'E',
      transclude: true,
      scope: {
        src: '@',
        srcToken: '@'
      },
      replace: true,
      link: {
        pre: function(scope, element) {
          $http.post('/api/resources/cayzu/get-token').then(function(result) {
            if (
              result.data.success &&
              result.data.token &&
              result.data.token.length > 0
            ) {
              element.attr('src', (scope.srcToken += result.data.token));
            }
          });
        }
      }
    };
  }
]);
