(function() {
  'use strict';

  // Campaigns service used to communicate Campaigns REST endpoints
  angular
    .module('campaigns')
    .factory('CampaignsTeamAccess', CampaignsTeamAccess);

  CampaignsTeamAccess.$inject = ['AudiencesApi'];

  function CampaignsTeamAccess(audiencesApi) {
    return audiencesApi.resource(
      '/team-access/campaigns/:campaignId',
      {
        campaignId: '@campaignId'
      },
      {
        update: {
          method: 'PUT'
        }
      }
    );
  }
})();
