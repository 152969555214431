'use strict';

// Add token to all http requests
angular
  .module('users')
  .factory('verificationInterceptor', [
    'Authentication',
    '$q',
    '$location',
    '$window',
    'store',
    function(Authentication, $q, $location, $window, store) {
      return {
        responseError: function(rejection) {
          if (
            rejection.status === 403 &&
            $location.url() !== '/no-access' &&
            store.get('permissions').length === 0
          ) {
            $window.location.href = '/no-access';
          }
          return $q.reject(rejection);
        }
      };
    }
  ])
  .config(function($httpProvider) {
    $httpProvider.interceptors.push('verificationInterceptor');
  });
