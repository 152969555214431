(function() {
  'use strict';

  angular
    .module('settings')
    .controller('globalsettingsController', globalsettingsController);

  globalsettingsController.$inject = [
    '$rootScope',
    '$scope',
    'Authorization',
    'store',
    '$http',
    'RmrAuthenticationService'
  ];
  function globalsettingsController(
    $rootScope,
    $scope,
    Authorization,
    store,
    $http,
    RmrAuthenticationService
  ) {
    $rootScope.title = 'Settings';
    var vm = this;
    var userManagementURL = RmrAuthenticationService.getUserManagementUrl();
    var token = RmrAuthenticationService.getToken();
    vm.updatedetails = updatedetails;

    Authorization.authorize(true).then(function(isAuth) {
      if (isAuth) {
        initialise();
      }
    });

    function initialise() {
      $scope.user = store.get('client');
      // get current user details
      $http
        .get(userManagementURL + '/active-user', {
          headers: {
            token: token
          }
        })
        .then(function(result, err) {
          if (err) {
            console.error('Get current active users details');
          } else {
            var dateNow = new Date();
            var dateNowString = dateNow.toISOString();
            vm.name = result.data.name;
            vm.phone = result.data.phone;
            vm.dateUpdated = dateNowString;
          }
        });
    }

    // Update user settings
    function updatedetails() {
      vm.processing = true;
      var spinner = document.getElementById('create-spinner-button');
      spinner.classList.remove('hidden');

      // get current user details
      $http
        .put(
          userManagementURL + '/active-user',
          {
            name: vm.name,
            phone: vm.phone,
            dateUpdated: vm.dateUpdated
          },
          {
            headers: {
              token: token,
              'Content-Type': 'application/json'
            }
          }
        )
        .then(
          function(result, err) {
            var msgError;
            if (err) {
              vm.message =
                'Error: make sure you are submitting form with right details.';
              msgError = angular.element(
                document.querySelector('.show-settings-message')
              );
              msgError.removeClass('success').addClass('error');
            } else {
              vm.message = 'Success: User details updated successfully';
              msgError = angular.element(
                document.querySelector('.show-settings-message')
              );
              msgError.removeClass('error').addClass('success');
            }
            spinner.classList.add('hidden');
          },
          function(error) {
            vm.message = 'Error: ' + error.data.errors[0].message;
            var msgError = angular.element(
              document.querySelector('.show-settings-message')
            );
            msgError.removeClass('success').addClass('error');
            spinner.classList.add('hidden');
          }
        );
    }
  }
})();
