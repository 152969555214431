'use strict';

// Configuring the Articles module
angular.module('pixels').run([
  'Menus',
  function(Menus) {
    // Set top bar menu items
    Menus.addMenuItem(
      'topbar',
      'Pixels',
      'pixels',
      'dropdown',
      '/pixels(/create)?'
    );
    Menus.addSubMenuItem('topbar', 'pixels', 'List Pixels', 'pixels');
    Menus.addSubMenuItem('topbar', 'pixels', 'New Pixel', 'pixels/create');
  }
]);
