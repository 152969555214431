(function() {
  'use strict';

  // Campaigns service used to communicate Campaigns REST endpoints
  angular.module('campaigns').factory('WebsitesTeamAccess', WebsitesTeamAccess);

  WebsitesTeamAccess.$inject = ['AudiencesApi'];

  function WebsitesTeamAccess(audiencesApi) {
    return audiencesApi.resource(
      '/team-access/websites/:websiteId',
      {
        websiteId: '@websiteId'
      },
      {
        update: {
          method: 'PUT'
        }
      }
    );
  }
})();
