(function() {
  'use strict';

  // Verification controller
  angular
    .module('core')
    .controller('VerificationController', VerificationController);

  VerificationController.$inject = ['Authorization', 'store'];

  function VerificationController(Authorization, store) {
    var vm = this;
    Authorization.authorize().then(function() {
      vm.productsAccess = store.get('productsAccess');
    });
  }
})();
