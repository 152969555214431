(function() {
  'use strict';

  angular.module('insights').factory('InsightsDevice', InsightsDevice);

  InsightsDevice.$inject = ['InsightsDeviceConfig', 'commonWebsite'];

  function InsightsDevice(InsightsDeviceConfig, commonWebsite) {
    var typeOfData;
    var globalData = [];
    var noInsights = '<div class="no-insights">Data Unavailable</div>';

    return {
      createChart: createChart,
      resize: resize,
      changeDate: changeDate
    };

    /**
     * Cast data points into Number
     * to remove 1.111E7 for large numbers.
     * @param  {Array} data
     * @return {Array}
     */
    function castToNumber(data) {
      for (var i = 0; i < data.length; i++) {
        data[i].clicks_d = parseInt(Number(data[i].clicks_d), 10);
        data[i].clicks_m = parseInt(Number(data[i].clicks_m), 10);
        data[i].clicks_t = parseInt(Number(data[i].clicks_t), 10);
        data[i].imp_d = parseInt(Number(data[i].imp_d), 10);
        data[i].imp_m = parseInt(Number(data[i].imp_m), 10);
        data[i].imp_t = parseInt(Number(data[i].imp_t), 10);
        data[i].ua_d = parseInt(Number(data[i].ua_d), 10);
        data[i].ua_m = parseInt(Number(data[i].ua_m), 10);
        data[i].ua_t = parseInt(Number(data[i].ua_t), 10);
        data[i].ua_dm = parseInt(Number(data[i].ua_dm), 10);
        data[i].ua_mt = parseInt(Number(data[i].ua_mt), 10);
        data[i].ua_dt = parseInt(Number(data[i].ua_dt), 10);
        data[i].ua_dmt = parseInt(Number(data[i].ua_dmt), 10);
      }

      return data;
    }

    /**
     * Calculate Percentage for Tooltip
     * @param {string} value
     * @param {string} total
     * @return {string}
     */
    function calculateTooltipPercent(value, total) {
      var percent = value / total * 100;
      return Math.round(percent * 10) / 10 + '%';
    }

    /**
     * Create device venn diagram chart
     * @param {Array} data
     */
    function createChart(data) {
      // Remove tooltip so can be reinitialised.
      angular.element(document.querySelector('.venntooltip')).remove();

      if (data) {
        data = castToNumber(data);
        globalData = data;
      }

      typeOfData = angular
        .element(document.querySelector('.switch.active'))
        .attr('value');

      var chartId = InsightsDeviceConfig.chartId;
      var container = d3.select(chartId);

      var initialData = globalData[0];
      var sets;
      var overlay = document.querySelector(
        InsightsDeviceConfig.chartId + ' .overlay'
      );
      var variable = document
        .querySelector('.variable-dropdown .ng-scope')
        .innerHTML.trim();
      var moveTabletLabel;
      var moveMobileLabel;
      var total;

      // check if the variable is not state as if it is state we are showing the device chart but not for other categories
      if (variable.length > 0 && variable != 'STATE') {
        return;
      }

      d3.selectAll('.switch').on('click.device', createChart);

      if (typeOfData === 'Impressions') {
        if (
          initialData &&
          (initialData.imp_d > 0 ||
            initialData.imp_m > 0 ||
            initialData.imp_t > 0)
        ) {
          angular.element(overlay).removeClass('active');

          total = initialData.imp_d + initialData.imp_m + initialData.imp_t;

          sets = [
            {
              sets: ['Desktop'],
              size: initialData.imp_d,
              percent: calculateTooltipPercent(initialData.imp_d, total)
            },
            {
              sets: ['Mobile'],
              size: initialData.imp_m,
              percent: calculateTooltipPercent(initialData.imp_m, total)
            },
            {
              sets: ['Tablet'],
              size: initialData.imp_t,
              percent: calculateTooltipPercent(initialData.imp_t, total)
            }
          ];
        } else {
          commonWebsite.hideSpinner('device-chart', 'showNoDataMsg');
          return;
        }
      } else if (typeOfData === 'Clicks') {
        if (
          initialData &&
          (initialData.clicks_d > 0 ||
            initialData.clicks_m > 0 ||
            initialData.clicks_t > 0)
        ) {
          angular.element(overlay).removeClass('active');

          total =
            initialData.clicks_d + initialData.clicks_m + initialData.clicks_t;

          sets = [
            {
              sets: ['Desktop'],
              size: initialData.clicks_d,
              percent: calculateTooltipPercent(initialData.clicks_d, total)
            },
            {
              sets: ['Mobile'],
              size: initialData.clicks_m,
              percent: calculateTooltipPercent(initialData.clicks_m, total)
            },
            {
              sets: ['Tablet'],
              size: initialData.clicks_t,
              percent: calculateTooltipPercent(initialData.clicks_t, total)
            }
          ];
        } else {
          commonWebsite.hideSpinner('device-chart', 'showNoDataMsg');
          return;
        }
      } else if (typeOfData === 'UA') {
        if (
          initialData &&
          (initialData.ua_d > 0 || initialData.ua_m > 0 || initialData.ua_t > 0)
        ) {
          angular.element(overlay).removeClass('active');

          total = initialData.ua_d + initialData.ua_m + initialData.ua_t;
          total =
            total - initialData.ua_dm - initialData.ua_dt - initialData.ua_mt;
          total += initialData.ua_dmt;

          sets = [
            {
              sets: ['Desktop'],
              size: initialData.ua_d,
              totalPercent: calculateTooltipPercent(initialData.ua_d, total),
              singleSize:
                initialData.ua_d -
                initialData.ua_dt -
                initialData.ua_dm +
                initialData.ua_dmt,
              singlePercent: calculateTooltipPercent(
                initialData.ua_d -
                  initialData.ua_dt -
                  initialData.ua_dm +
                  initialData.ua_dmt,
                total
              )
            },
            {
              sets: ['Mobile'],
              size: initialData.ua_m,
              totalPercent: calculateTooltipPercent(initialData.ua_m, total),
              singleSize:
                initialData.ua_m -
                initialData.ua_mt -
                initialData.ua_dm +
                initialData.ua_dmt,
              singlePercent: calculateTooltipPercent(
                initialData.ua_m -
                  initialData.ua_mt -
                  initialData.ua_dm +
                  initialData.ua_dmt,
                total
              )
            },
            {
              sets: ['Tablet'],
              size: initialData.ua_t,
              totalPercent: calculateTooltipPercent(initialData.ua_t, total),
              singleSize:
                initialData.ua_t -
                initialData.ua_dt -
                initialData.ua_mt +
                initialData.ua_dmt,
              singlePercent: calculateTooltipPercent(
                initialData.ua_t -
                  initialData.ua_dt -
                  initialData.ua_mt +
                  initialData.ua_dmt,
                total
              )
            },
            {
              sets: ['Desktop', 'Mobile'],
              size: initialData.ua_dm,
              percent: calculateTooltipPercent(initialData.ua_dm, total)
            },
            {
              sets: ['Mobile', 'Tablet'],
              size: initialData.ua_mt,
              percent: calculateTooltipPercent(initialData.ua_mt, total)
            },
            {
              sets: ['Desktop', 'Tablet'],
              size: initialData.ua_dt,
              percent: calculateTooltipPercent(initialData.ua_dt, total)
            },
            {
              sets: ['Desktop', 'Mobile', 'Tablet'],
              size: initialData.ua_dmt,
              weight: 10000,
              percent: calculateTooltipPercent(initialData.ua_dmt, total)
            }
          ];

          if (initialData.ua_m / initialData.ua_d * 100 < 1) {
            moveMobileLabel = true;
          }

          if (initialData.ua_t / initialData.ua_d * 100 < 1) {
            moveTabletLabel = true;
          }
        } else {
          commonWebsite.hideSpinner('device-chart', 'showNoDataMsg');
          return;
        }
      }

      var width = InsightsDeviceConfig.width();
      var height = InsightsDeviceConfig.height;
      var chart = venn
        .VennDiagram()
        .width(width)
        .height(height)
        .styled(false);
      container.datum(sets).call(chart);
      commonWebsite.hideSpinner('device-chart');

      // Move labels so they don't overlap
      if (moveMobileLabel) {
        container.selectAll('tspan')[0].forEach(function(tspan) {
          if (tspan.innerHTML === 'Mobile') {
            tspan.setAttribute('y', Number(tspan.getAttribute('y')) + 15);
          }
        });
      }

      if (moveTabletLabel) {
        container.selectAll('tspan')[0].forEach(function(tspan) {
          if (tspan.innerHTML === 'Tablet') {
            tspan.setAttribute('y', Number(tspan.getAttribute('y')) - 15);
          }
        });
      }

      if (!document.querySelector('.venntooltip')) {
        // add a tooltip
        var tooltip = d3
          .select('body')
          .append('div')
          .attr('class', 'venntooltip');
        tooltip.append('p').attr('id', 'device-total');
        tooltip
          .select('#device-total')
          .append('div')
          .attr('class', 'tlt');
        tooltip
          .select('#device-total')
          .append('div')
          .attr('class', 'single');
        tooltip
          .select('#device-total .tlt')
          .append('span')
          .attr('class', 'lbl');
        tooltip
          .select('#device-total .tlt')
          .append('span')
          .attr('class', 'total');
        tooltip
          .select('#device-total .tlt')
          .append('span')
          .attr('class', 'pcnt');
        tooltip
          .select('#device-total .single')
          .append('span')
          .attr('class', 'lbl');
        tooltip
          .select('#device-total .single')
          .append('span')
          .attr('class', 'total');
        tooltip
          .select('#device-total .single')
          .append('span')
          .attr('class', 'pcnt');

        // add listeners to all the groups to display tooltip on mouseover
        container
          .selectAll('g')
          .on('mouseover', function(d) {
            // sort all the areas relative to the current item
            venn.sortAreas(container, d);

            // Display a tooltip with the current size
            tooltip
              .transition()
              .duration(400)
              .style('opacity', 1);
            if (typeOfData === 'UA') {
              if (d.sets.length === 1) {
                tooltip
                  .select('#device-total .tlt .lbl')
                  .text('Total ' + d.sets + ': ');
                tooltip
                  .select('#device-total .tlt .total')
                  .text(d3.format(',d')(d.size));
                tooltip
                  .select('#device-total .tlt .pcnt')
                  .text('(' + d.totalPercent + ')');
                tooltip
                  .select('#device-total .single .lbl')
                  .text(d.sets + ' Only: ');
                tooltip
                  .select('#device-total .single .total')
                  .text(d3.format(',d')(d.singleSize));
                tooltip
                  .select('#device-total .single .pcnt')
                  .text('(' + d.singlePercent + ')');
              } else {
                tooltip
                  .select('#device-total .tlt .lbl')
                  .text('Total ' + d.sets.join(' & ') + ': ');
                tooltip
                  .select('#device-total .tlt .total')
                  .text(d3.format(',d')(d.size));
                tooltip
                  .select('#device-total .tlt .pcnt')
                  .text('(' + d.percent + ')');
                tooltip.select('#device-total .single .lbl').text('');
                tooltip.select('#device-total .single .total').text('');
                tooltip.select('#device-total .single .pcnt').text('');
              }
            } else if (d.sets.length === 1) {
              tooltip.select('#device-total .tlt .lbl').text(d.sets + ': ');
              tooltip
                .select('#device-total .tlt .total')
                .text(d3.format(',d')(d.size));
              tooltip
                .select('#device-total .tlt .pcnt')
                .text('(' + d.percent + ')');
              tooltip.select('#device-total .single .lbl').text('');
              tooltip.select('#device-total .single .total').text('');
              tooltip.select('#device-total .single .pcnt').text('');
            } else {
              tooltip
                .select('#device-total .tlt .lbl')
                .text(d.sets.join(' & ') + ': ');
              tooltip
                .select('#device-total .tlt .total')
                .text(d3.format(',d')(d.size));
              tooltip
                .select('#device-total .tlt .pcnt')
                .text('(' + d.percent + ')');
              tooltip.select('#device-total .single .lbl').text('');
              tooltip.select('#device-total .single .total').text('');
              tooltip.select('#device-total .single .pcnt').text('');
            }

            // highlight the current path
            var selection = d3
              .select(this)
              .transition('tooltip')
              .duration(400);
            selection
              .select('path')
              .style('stroke-width', 3)
              .style('fill-opacity', d.sets.length === 1 ? 0.85 : 0.1)
              .style('stroke-opacity', 1);
          })
          .on('mousemove', function() {
            tooltip
              .style('left', d3.event.pageX + 'px')
              .style('top', d3.event.pageY - 28 + 'px');
          })
          .on('mouseout', function(d) {
            tooltip
              .transition()
              .duration(400)
              .style('opacity', 0);
            var selection = d3
              .select(this)
              .transition('tooltip')
              .duration(400);
            selection
              .select('path')
              .style('stroke-width', 0)
              .style('fill-opacity', d.sets.length === 1 ? 0.75 : 0.0)
              .style('stroke-opacity', 0);
          });
      }
    }

    function changeDate(data) {
      createChart(data);
    }

    function resize() {
      var chartId = InsightsDeviceConfig.chartId;
      var chart = d3.select(chartId);
      chart.selectAll('svg').remove();
      return createChart(globalData);
    }
  }
})();
