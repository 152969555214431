'use strict';

angular.module('core').controller('TopHeaderController', [
  '$scope',
  'Authorization',
  'Menus',
  '$rootScope',
  'store',
  '$window',
  '$location',
  '$cookies',
  'Authentication',
  'RmrAuthenticationService',
  'Settings',
  '$state',
  'Teams',
  'AdminSettings',
  '$http',
  '$filter',
  function(
    $scope,
    Authorization,
    Menus,
    $rootScope,
    store,
    $window,
    $location,
    $cookies,
    Authentication,
    RmrAuthenticationService,
    Settings,
    $state,
    Teams,
    AdminSettings,
    $http,
    $filter
  ) {
    $scope.loggedIn = false;
    $scope.companiesOpen = false;
    $scope.teamsOpen = false;

    Authorization.authorize(true).then(function(isAuth) {
      if (isAuth) {
        initialise();
      }
    });

    $scope.$on('loggedIn', function() {
      initialise();
    });

    $scope.isCollapsed = false;
    $scope.menu = Menus.getMenu('topbar');
    $scope.headerOpen = false;

    $scope.changePassword = function() {
      $scope.showChangePasswordDialog = true;
    };

    $scope.signOut = function() {
      Authentication.logout();

      if ($cookies.get('SSOAUTH')) {
        var ssoauthCookie = JSON.parse($cookies.get('SSOAUTH'));
        var ssoauthStrategyUrl = JSON.stringify(ssoauthCookie.strategy);
        $window.location.href =
          RmrAuthenticationService.getUserManagementUrl() +
          '/api/auth/signout?ssostrategy=' +
          ssoauthStrategyUrl;
        $cookies.remove('SSOAUTH');
      } else {
        $window.location.href = '/';
      }
    };

    $scope.setTeam = function(team) {
      $scope.teamsOpen = false;
      $scope.currentTeam = team;
      Authentication.updateStorageValue('currentTeam', team);

      Authentication.getActiveUseFeatureAccessInTeam(team).then(function(
        permissions
      ) {
        if (permissions.length && permissions) {
          var currentCompany = store.get('currentCompany');
          store.set('permissionSet', permissions);
          store.set('collections', {});
          var permissionList = Authentication.convertPermissionSetsToPermissions(
            permissions
          );
          Authentication.getCollections(
            currentCompany.id,
            team.id,
            permissionList
          ).then(function() {
            $rootScope.$broadcast('teamChanged');
            Authorization.checkPerms();
          });
        } else {
          store.remove('collections');
          store.remove('permissionSet');
          store.remove('permission');
          console.error('Looks like you do not have permissions.');
          $rootScope.$broadcast('teamChanged');
          Authorization.checkPerms();
        }
      });
    };

    $scope.setCompany = function(company) {
      $scope.companiesOpen = false;
      // call the function to get teams.
      Authentication.getTeamsAndPermissions(company).then(function() {
        $rootScope.$broadcast('teamChanged');
        Authorization.checkPerms();
      });
    };

    $scope.getProductImageSource = function(productAccess) {
      // include query string to differentiate html image requests from xhr requests in ReactJS apps
      // reason: xhr requests are using cached responses from html request which causes issues in Chrome
      // see https://serverfault.com/questions/856904/chrome-s3-cloudfront-no-access-control-allow-origin-header-on-initial-xhr-req/856948#856948
      return productAccess.iconLogoLocation + '?x-request=html';
    };

    $scope.switchBack = function() {
      Authorization.switchBack();
    };

    function initialise() {
      Settings.checkNotification();
      $scope.loggedIn = true;
      $scope.user = store.get('client');
      $scope.companies = store.get('companies');
      $scope.canShowSwitchBack = Authorization.isUserSwitched();
      $scope.orgIdAcrossProducts = Authentication.getCookie(
        'currentOrganisationId'
      );
      //  check if the cookie company id is different, then take the cookie id
      if (store.get('currentCompany').id !== $scope.orgIdAcrossProducts) {
        $scope.currentCompany = $filter('filter')(
          $scope.companies,
          {
            id: parseInt($scope.orgIdAcrossProducts)
          },
          true
        )[0];
        // call the function to get teams.
        Authentication.getTeamsAndPermissions($scope.currentCompany).then(
          function() {
            $scope.permissionSet = store.get('permissionSet');
            $scope.currentTeam = store.get('currentTeam');
            $scope.productsAccess = store.get('productsAccess');
            $scope.teams = Teams.getTeamsForTopHeader(
              $scope.user,
              $scope.permissionSet,
              $scope.currentCompany
            );
            $scope.currentTeam = Teams.getCurrentTeam($scope.teams);
            if (
              (!store.get('permissions') ||
                store.get('permissions').length == 0) &&
              $location.url() !== '/no-access'
            ) {
              $window.location.href = '/no-access';
            }
          }
        );
      } else {
        $scope.currentCompany = store.get('currentCompany');
        $scope.permissionSet = store.get('permissionSet');
        $scope.currentTeam = store.get('currentTeam');

        $scope.productsAccess = store.get('productsAccess');
        $scope.teams = Teams.getTeamsForTopHeader(
          $scope.user,
          $scope.permissionSet,
          $scope.currentCompany
        );
        $scope.currentTeam = Teams.getCurrentTeam($scope.teams);
      }
      checkFlag();
    }

    function checkFlag() {
      $http.get('/api/settings/admin/yesterday').then(function(data) {
        if (data && data.data === 'error') {
          console.error(
            'There was an error checking to see if there was valid data from yesterday'
          );
        } else {
          var flag = data.data;
          var timeNow = moment(new Date());
          var fileTime = moment(flag.updated);
          var diff = timeNow.diff(fileTime, 'minutes');
          var minutesBetweenCheck = 30;
          if (diff > minutesBetweenCheck) {
            updateFlag();
          }
        }
      });
    }

    function updateFlag() {
      $http.get('/api/settings/check-data').then(function(result) {
        var count = result.data;
        var dataAvailable = count > 0;

        // update the file
        var data = {
          dataAvailable: dataAvailable,
          updated: new Date()
        };
        $http.post('/api/settings/admin/yesterday', data);

        // update notification
        if (dataAvailable) {
          AdminSettings.update({
            dashboardNotificationOn: false
          });
        } else {
          AdminSettings.update({
            dashboardNotificationOn: true
          });
        }
      });
    }
  }
]);
