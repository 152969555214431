(function() {
  'use strict';

  angular
    .module('websites')
    .factory('browserChartWebsite', browserChartWebsite);

  browserChartWebsite.$inject = [
    'commonWebsite',
    '$http',
    'InsightsBrowser',
    '$q',
    '$log',
    'downloads'
  ];
  function browserChartWebsite(
    commonWebsite,
    $http,
    InsightsBrowser,
    $q,
    $log,
    downloads
  ) {
    return {
      chart: chart,
      resize: resize,
      getData: getData
    };

    /**
     * StartEnd type
     * @typedef {Object} StartEnd
     * @property {string} start Campaign start date
     * @property {?string} end Campaign end date or selected date.
     */

    /**
     * Browser chart based on date period
     * @param {string} period
     * @param {string} websiteId
     * @param {StartEnd} startEnd - Insight start and end dates
     * @param {Object} variable
     */
    function chart(period, websiteId, startEnd, variable) {
      var companyUID = commonWebsite.getUID();
      if (period === 'month') {
        timeChart(
          websiteId,
          companyUID,
          startEnd.start,
          startEnd.end,
          period,
          variable
        );
      } else if (period === 'week') {
        timeChart(
          websiteId,
          companyUID,
          startEnd.start,
          startEnd.end,
          period,
          variable
        );
      } else if (period === 'day') {
        timeChart(
          websiteId,
          companyUID,
          startEnd.start,
          startEnd.end,
          period,
          variable
        );
      }
    }

    /**
     * Browser Chart for time period
     * @param {string} websiteId
     * @param {string} startDate - Campaign Start Date YYYY-MM-DD
     * @param {?string} endDate - Campaign End Date YYYY-MM-DD
     * @param {string} period
     * @param {Object} variable
     */
    function timeChart(
      websiteId,
      companyUID,
      startDate,
      endDate,
      period,
      variable
    ) {
      var yesterday = commonWebsite.formatEndDate();
      var adjustedStart;
      if (period === 'month') {
        adjustedStart = moment(startDate)
          .startOf('month')
          .format('YYYY-MM-DD');
      } else if (period === 'week') {
        adjustedStart = moment(startDate)
          .startOf('isoWeek')
          .format('YYYY-MM-DD');
      }

      $http
        .post('/api/insights/website', {
          campaign: websiteId,
          companyUID: companyUID,
          startDate: adjustedStart || startDate,
          endDate: endDate || yesterday,
          chart: 'browser',
          period: 'time',
          type: 'website',
          variable: variable
        })
        .then(
          function(result) {
            if (result.data.insights.length > 0) {
              InsightsBrowser.checkService(angular.copy(result.data.insights));
            } else {
              commonWebsite.hideSpinner('browser-chart', 'showNoDataMsg');
            }
          },
          function(reason) {
            commonWebsite.hideSpinner('browser-chart', 'showNoDataMsg');
            $log.error('Error: website-browser-time');
            $log.error(reason);
          }
        );
    }

    /**
     * Resize chart
     */
    function resize() {
      InsightsBrowser.resize();
    }

    /**
     * Download all data
     * @param {string} websiteId
     * @param {StartEnd} startEnd - Insight start and end dates
     * @param {Object} variable
     * @param {string} type - day, week, month.
     */
    function getData(websiteId, startEnd, variable, type) {
      var companyUID = commonWebsite.getUID();
      var yesterday = commonWebsite.formatEndDate();
      var startDate = startEnd.start;
      var endDate = startEnd.end || yesterday;

      return $q(function(resolve, reject) {
        $http
          .post('/api/insights/website', {
            campaign: websiteId,
            companyUID: companyUID,
            endDate: endDate,
            chart: 'browser',
            period: 'day',
            type: 'website',
            startDate: startDate,
            variable: variable
          })
          .then(
            function(result) {
              var data = result.data.insights;
              if (type === 'month' || type === 'week') {
                data = aggregateDownload(data);
              }
              var dateHeading;
              if (type === 'day') {
                dateHeading = 'Date';
              } else if (type === 'month') {
                dateHeading = 'Start_Date_of_Month';
              } else if (type === 'week') {
                dateHeading = 'Start_Date_of_Week';
              }
              data = formatDownloadData(data, dateHeading);

              resolve(data);
            },
            function(reason) {
              $log.error('Error: website-browser-data');
              $log.error(reason);
              reject();
            }
          );
      });
    }

    function formatDownloadData(data, dateHeading) {
      var newData = downloads.renameHeading(data, 'Period_Start', dateHeading);
      newData = downloads.renameHeading(newData, 'browser_type', 'Browser');
      newData = downloads.renameHeading(newData, 'count', 'Impressions');

      return newData;
    }

    function aggregateDownload(data) {
      return data.reduce(function(acc, d) {
        var found = acc.find(function(a) {
          return a.browser_type === d.browser_type;
        });
        d.count = Number(d.count);
        if (!found) {
          acc.push(d);
        } else {
          found.count += d.count;
        }

        return acc;
      }, []);
    }
  }
})();
