'use strict';

// Setting up route
angular.module('resources').config([
  '$stateProvider',
  function($stateProvider) {
    // Resources state routing
    $stateProvider.state('resourcesSupport', {
      url: '/resources/support',
      templateUrl: 'modules/resources/views/resources-support.client.view.html'
    });
  }
]);
