'use strict';

angular.module('insights').factory('HelixIndexConfig', [
  'InsightsConfig',
  function(InsightsConfig) {
    return {
      chartId: function() {
        return InsightsConfig.helixIndexChart;
      },

      width: function() {
        return d3
          .select(this.chartId())
          .node()
          .getBoundingClientRect().width;
      },

      height: function() {
        return InsightsConfig.doubleHeight;
      },

      margin: {
        top: 30,
        right: 10,
        bottom: 0,
        left: 0
      },
      widthPercent: function() {
        return (
          (this.width() - this.margin.left - this.margin.right) /
          this.width() *
          100
        );
      },

      barHeight: function() {
        return InsightsConfig.barHeight;
      }
    };
  }
]);
