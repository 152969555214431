'use strict';

angular.module('insights').factory('Insights', [
  '$resource',
  function($resource) {
    // Validate menu existence

    return $resource(
      'insights/:insightId',
      {
        insightId: '@_id'
      },
      {
        update: {
          method: 'PUT'
        }
      }
    );
  }
]);
