'use strict';

angular
  .module('insights')
  .factory('PremiumIndex', function(
    $q,
    PremiumIndexConfig,
    PremiumPeople,
    $document,
    $injector,
    commonWebsite,
    store
  ) {
    var y;
    var noInsights = '<div class="no-insights">Data Unavailable</div>';
    var Premium;
    var prevChartType;
    function noData() {
      var chartWrapper = $document[0].getElementById('premium-charts');
      chartWrapper.querySelector('.overlay').classList.add('active');
      // Display message 'No Data Currently'.
      angular.element(chartWrapper).prepend(noInsights);
    }

    function removeNoData() {
      var chartWrapper = $document[0].getElementById('premium-charts');
      chartWrapper.querySelector('.overlay').classList.remove('active');
      var noInsights = chartWrapper.querySelector('.no-insights');
      if (noInsights) {
        angular.element(chartWrapper.querySelector('.no-insights')).remove();
      }
    }

    /**
     * Gets the currently showed data type. E.g. UA, Impressions or Clicks.
     * @returns {string} - Type of data shown
     */
    function getTypeOfData() {
      var data = angular
        .element($document[0].querySelector('.switch.active'))
        .attr('value');

      if (data === 'UA') {
        data = 'ua';
      } else if (data === 'Impressions') {
        data = 'impressions';
      } else if (data === 'Clicks') {
        data = 'clicks';
      }

      return data;
    }

    function sortData(sortable, sortOrder) {
      var chartId = PremiumIndexConfig.chartId();
      var chart = d3.select(chartId);
      chart
        .selectAll('.bar')
        .sort(function(a, b) {
          if (sortOrder) {
            return d3.ascending(a[sortable], b[sortable]);
          }
          return d3.descending(a[sortable], b[sortable]);
        })
        .transition()
        .delay(function(d, i) {
          return i * 25;
        })
        .duration(750)
        .attr('transform', function(d, i) {
          return 'translate(0,' + y(i) + ')';
        });
    }

    function createChart(sortedData) {
      var dataType = 'index'; // default datatype set to index as for Unique audience
      var typeOfData = getTypeOfData();
      if (!Premium) {
        Premium = $injector.get('Premium');
      }
      // check if it is impressions then make datatype frequency and for clicks is ctr
      if (typeOfData == 'impressions') {
        dataType = 'freq';
      } else if (typeOfData == 'clicks') {
        dataType = 'ctr';
      }

      // Get the max index so our table doesn't go outside the bounds
      var max = Math.max.apply(
        Math,
        sortedData.map(function(o) {
          return o[dataType];
        })
      );

      var margin = PremiumIndexConfig.margin;
      var width = PremiumIndexConfig.width();
      var barHeight = PremiumIndexConfig.barHeight();
      var height;
      var widthPercent = PremiumIndexConfig.widthPercent();

      var x = d3.scale
        .linear()
        .range([0, width])
        .domain([0, max]);

      y = d3.scale.ordinal();

      var xAxis = d3.svg
        .axis()
        .ticks(4)
        .tickFormat(function(d) {
          var prefix = d3.formatPrefix(d);
          return prefix.scale(d) + prefix.symbol;
        })
        .scale(x);

      var yAxis = d3.svg
        .axis()
        .scale(y)
        .tickFormat('');

      var chartId = PremiumIndexConfig.chartId();

      // Create the chart
      var chart = d3
        .select(chartId)
        .append('svg')
        .style('width', width + margin.left + margin.right + 'px')
        .append('g')
        .attr('transform', 'translate(' + [0, 0] + ')');

      // Set y domain
      y.domain(d3.range(sortedData.length)).rangeBands([
        5,
        sortedData.length * barHeight
      ]);

      // Set height based on data
      height = y.rangeExtent()[1];
      d3.select(chart.node().parentNode).style(
        'height',
        height + margin.top + margin.bottom + 'px'
      );

      // Add top and bottom axes
      chart
        .append('g')
        .attr('class', 'x axis top')
        .call(yAxis.orient('left'));

      chart
        .append('g')
        .attr('class', 'x axis bottom')
        .attr('transform', 'translate(0,' + height + ')')
        .call(xAxis.orient('bottom'));

      var bars = chart
        .selectAll('.bar')
        .data(sortedData)
        .enter()
        .append('g')
        .attr('class', 'bar')
        .attr('transform', function(d, i) {
          return 'translate(0,' + y(i) + ')';
        });

      bars
        .append('rect')
        .attr('class', function(d) {
          return 'percent _' + d.className;
        })
        .attr('height', y.rangeBand() - 8)
        .attr('width', function(d) {
          return (d[dataType] / max) * widthPercent + '%';
        });

      bars
        .append('rect')
        .attr('class', function() {
          return 'gray-bar';
        })
        .attr('y', -2)
        .attr('height', y.rangeBand() - 4)
        .attr('width', function() {
          return (100 / max) * widthPercent + '%';
        });

      bars
        .append('text')
        .text(function(d) {
          // check if the number is decimal or integer, go to else if the number is not decimal
          var data = d[dataType];
          if (Number(data) === data && data % 1 !== 0) {
            // if the data is 0.005, then print < 0.01
            if (data > 0 && data < 0.005) {
              data = '< 0.01';
            } else {
              data = data.toFixed(2);
            }
            // if the data type is for clicks then concatenate the % sign
            if (typeOfData == 'clicks') {
              return data + '%';
            }
            return data;
          }
          // if the data type is for clicks then concatenate the % sign
          if (typeOfData == 'clicks') {
            return data + '%';
          }
          return data;
        })
        .attr('class', 'number')
        .attr('y', y.rangeBand() - 10)
        .attr('x', 0)
        .attr('transform', 'translate(' + [10, -5] + ')');

      function changePremiumIndex() {
        if (!Premium) {
          Premium = $injector.get('Premium');
        }
        var previousTypeOfData = typeOfData;
        typeOfData = getTypeOfData();
        if (previousTypeOfData === typeOfData) {
          return;
        }
        var t0;
        var toggleIndex = $document[0].getElementById('toggle-premium-index');
        var indexCaretStr = '<i class="fa" id="premium-index-caret"></i>';

        if (typeOfData === 'impressions') {
          // Display Frequency
          // Change Label
          $document[0].querySelector('.premium-index').innerHTML =
            'Frequency' + indexCaretStr;

          // Add Click event for caret
          toggleIndex.removeEventListener('click', Premium.indexOnClick, false);
          toggleIndex.removeEventListener('click', Premium.ctrOnClick, false);
          toggleIndex.addEventListener('click', Premium.freqOnClick, false);
          // Display Frequency Data
          // Display CTR Data
          var maxFreq = Math.max.apply(
            Math,
            sortedData.map(function(o) {
              return o.freq;
            })
          );

          if (maxFreq > 0) {
            removeNoData();
            t0 = d3
              .select('#premium-index-chart svg')
              .transition()
              .duration(750);
            t0.selectAll('rect.percent')
              .attr('height', y.rangeBand() - 8)
              .attr('width', function(d) {
                return (d.freq / maxFreq) * widthPercent + '%';
              });

            t0.selectAll('.number')
              .text(function(d) {
                // check if the number is decimal
                if (Number(d.freq) === d.freq && d.freq % 1 !== 0) {
                  return String(d.freq.toFixed(2));
                }
                return d.freq;
              })
              .attr('class', 'number')
              .attr('y', y.rangeBand() - 10)
              .attr('x', 0)
              .attr('transform', 'translate(' + [10, -5] + ')');

            x = d3.scale
              .linear()
              .range([0, width])
              .domain([0, maxFreq]);

            y = d3.scale.ordinal();
            y.domain(d3.range(sortedData.length)).rangeBands([
              5,
              sortedData.length * barHeight
            ]);

            xAxis = d3.svg
              .axis()
              .ticks(4)
              .tickFormat(function(d) {
                var prefix = d3.formatPrefix(d);
                return prefix.scale(d) + prefix.symbol;
              })
              .scale(x);

            t0.selectAll('.x.axis.bottom').call(xAxis.orient('bottom'));
          } else {
            noData();
          }
        } else if (typeOfData === 'clicks') {
          // Display CTR
          // Change Label
          $document[0].querySelector('.premium-index').innerHTML =
            'CTR' + indexCaretStr;

          // Add Click event for caret
          toggleIndex.removeEventListener('click', Premium.indexOnClick, false);
          toggleIndex.removeEventListener('click', Premium.freqOnClick, false);
          toggleIndex.addEventListener('click', Premium.ctrOnClick, false);

          // Display CTR Data
          var maxCTR = Math.max.apply(
            Math,
            sortedData.map(function(o) {
              return o.ctr;
            })
          );

          if (maxCTR > 0) {
            removeNoData();
            t0 = d3
              .select('#premium-index-chart svg')
              .transition()
              .duration(750);
            t0.selectAll('rect.percent')
              .attr('height', y.rangeBand() - 8)
              .attr('width', function(d) {
                return (d.ctr / maxCTR) * widthPercent + '%';
              });

            t0.selectAll('.number')
              .text(function(d) {
                // if the data is 0.005, then print < 0.01
                if (d.ctr > 0 && d.ctr < 0.005) {
                  d.ctr = '< 0.1';
                } else {
                  d.ctr = d.ctr.toFixed(2);
                }

                return d.ctr + '%';
              })
              .attr('class', 'number')
              .attr('y', y.rangeBand() - 10)
              .attr('x', 0)
              .attr('transform', 'translate(' + [10, -5] + ')');

            x = d3.scale
              .linear()
              .range([0, width])
              .domain([0, maxCTR]);

            y = d3.scale.ordinal();
            y.domain(d3.range(sortedData.length)).rangeBands([
              5,
              sortedData.length * barHeight
            ]);

            xAxis = d3.svg
              .axis()
              .ticks(4)
              .scale(x);

            t0.selectAll('.x.axis.bottom').call(xAxis.orient('bottom'));
          } else {
            noData();
          }
        } else if (typeOfData === 'ua') {
          removeNoData();

          // Display CTR Data
          var maxIndex = Math.max.apply(
            Math,
            sortedData.map(function(o) {
              return o.index;
            })
          );

          // Change Label
          $document[0].querySelector('.premium-index').innerHTML =
            'Index' + indexCaretStr;

          // Add Click event for caret
          toggleIndex.removeEventListener('click', Premium.ctrOnClick, false);
          toggleIndex.removeEventListener('click', Premium.freqOnClick, false);
          toggleIndex.addEventListener('click', Premium.indexOnClick, false);

          // Display Index Data
          t0 = d3
            .select('#premium-index-chart svg')
            .transition()
            .duration(750);
          t0.selectAll('rect.percent')
            .attr('height', y.rangeBand() - 8)
            .attr('width', function(d) {
              return (d.index / maxIndex) * widthPercent + '%';
            });

          t0.selectAll('.number')
            .text(function(d) {
              return d3.format(',d')(d.index);
            })
            .attr('class', 'number')
            .attr('y', y.rangeBand() - 10)
            .attr('x', 0)
            .attr('transform', 'translate(' + [10, -5] + ')');

          x = d3.scale
            .linear()
            .range([0, width])
            .domain([0, maxIndex]);

          y = d3.scale.ordinal();
          y.domain(d3.range(sortedData.length)).rangeBands([
            5,
            sortedData.length * barHeight
          ]);

          xAxis = d3.svg
            .axis()
            .ticks(4)
            .tickFormat(function(d) {
              var prefix = d3.formatPrefix(d);
              return prefix.scale(d) + prefix.symbol;
            })
            .scale(x);

          t0.selectAll('.x.axis.bottom').call(xAxis.orient('bottom'));
        }

        chart
          .selectAll('.bar')
          .sort(function(a, b) {
            return d3.descending(a[typeOfData], b[typeOfData]);
          })
          .transition()
          .delay(function(d, i) {
            return i * 25;
          })
          .duration(750)
          .attr('transform', function(d, i) {
            return 'translate(0,' + y(i) + ')';
          });
      }

      var metricSwitch = $document[0].getElementsByClassName('switch');

      for (var i = 0; i < metricSwitch.length; i++) {
        metricSwitch[i].addEventListener('click', changePremiumIndex);
      }

      var toggleIndex = $document[0].getElementById('toggle-premium-index');

      toggleIndex.addEventListener('click', Premium.indexOnClick, false);
      commonWebsite.hideSpinner('premium-index-chart', 'hideNoDataMsg');
    }

    function resize() {
      var chartId = PremiumIndexConfig.chartId();
      var chart = d3.select(chartId);
      var margin = PremiumIndexConfig.margin;
      var data = chart.selectAll('.bar').data();
      var barHeight = PremiumIndexConfig.barHeight();

      // update width
      var width = chart.node().getBoundingClientRect().width;

      // Get the max index so our table doesn't go outside the bounds
      var maxIndex = Math.max.apply(
        Math,
        data.map(function(o) {
          return o.index;
        })
      );

      var x = d3.scale
        .linear()
        .range([0, width])
        .domain([0, maxIndex]);

      var y = d3.scale.ordinal();

      y.domain(d3.range(data.length)).rangeBands([5, data.length * barHeight]);

      var height = y.rangeExtent()[1];

      var xAxis = d3.svg
        .axis()
        .ticks(4)
        .tickFormat(function(d) {
          var prefix = d3.formatPrefix(d);
          return prefix.scale(d) + prefix.symbol;
        })
        .scale(x);

      chart
        .selectAll('svg')
        .style('width', width + margin.left + margin.right + 'px');

      chart.selectAll('rect.background').attr('width', width);

      chart
        .selectAll('.x.axis.bottom')
        .attr('width', width)
        .attr('transform', 'translate(' + [0, height] + ')')
        .call(xAxis.orient('bottom'));
    }

    /**
     * Change date period
     * @param {Array} data
     */
    function changeDate(data) {
      var chartId = PremiumIndexConfig.chartId();
      var t0;
      var typeOfData = getTypeOfData();
      var toggleIndex = $document[0].getElementById('toggle-premium-index');
      var indexCaretStr = '<i class="fa" id="premium-index-caret"></i>';
      var x;
      var xAxis;
      if (!Premium) {
        Premium = $injector.get('Premium');
      }
      var width = PremiumIndexConfig.width();
      var barHeight = PremiumIndexConfig.barHeight();
      var widthPercent = PremiumIndexConfig.widthPercent();
      var chart = d3.select(chartId);

      // Get Old Data.
      var oldData = chart.selectAll('.bar').data();

      // Put New Data in Same order as Old Data.
      var newData = [];
      oldData.forEach(function(key) {
        var found = false;
        data = data.filter(function(item) {
          if (!found && item.name === key.name) {
            newData.push(item);
            found = true;
            return false;
          }
          return true;
        });
      });

      data = newData;

      // Replace existing data with new data.
      chart.selectAll('.bar').data(data);
      chart.selectAll('rect.percent').data(data);
      chart.selectAll('.number').data(data);

      if (typeOfData === 'impressions') {
        // Display Frequency
        // Change Label
        $document[0].querySelector('.premium-index').innerHTML =
          'Frequency' + indexCaretStr;

        // Add Click event for caret
        toggleIndex.removeEventListener('click', Premium.indexOnClick, false);
        toggleIndex.removeEventListener('click', Premium.ctrOnClick, false);
        toggleIndex.addEventListener('click', Premium.freqOnClick, false);

        // Display Frequency Data
        // Display CTR Data
        var maxFreq = Math.max.apply(
          Math,
          data.map(function(o) {
            return o.freq;
          })
        );

        t0 = d3
          .select('#premium-index-chart svg')
          .transition()
          .duration(750);
        t0.selectAll('rect.percent')
          .attr('height', y.rangeBand() - 8)
          .attr('width', function(d) {
            return (d.freq / maxFreq) * widthPercent + '%';
          });

        t0.selectAll('.number')
          .text(function(d) {
            return String(d.freq.toFixed(2));
          })
          .attr('class', 'number')
          .attr('y', y.rangeBand() - 10)
          .attr('x', 0)
          .attr('transform', 'translate(' + [10, -5] + ')');

        x = d3.scale
          .linear()
          .range([0, width])
          .domain([0, maxFreq]);

        y = d3.scale.ordinal();
        y.domain(d3.range(data.length)).rangeBands([
          5,
          data.length * barHeight
        ]);

        xAxis = d3.svg
          .axis()
          .ticks(4)
          .tickFormat(function(d) {
            var prefix = d3.formatPrefix(d);
            return prefix.scale(d) + prefix.symbol;
          })
          .scale(x);

        t0.selectAll('.x.axis.bottom').call(xAxis.orient('bottom'));
      } else if (typeOfData === 'clicks') {
        // Display CTR
        // Change Label
        $document[0].querySelector('.premium-index').innerHTML =
          'CTR' + indexCaretStr;

        // Add Click event for caret
        toggleIndex.removeEventListener('click', Premium.indexOnClick, false);
        toggleIndex.removeEventListener('click', Premium.freqOnClick, false);
        toggleIndex.addEventListener('click', Premium.ctrOnClick, false);

        // Display CTR Data
        var maxCTR = Math.max.apply(
          Math,
          data.map(function(o) {
            return o.ctr;
          })
        );

        t0 = d3
          .select('#premium-index-chart svg')
          .transition()
          .duration(750);
        t0.selectAll('rect.percent')
          .attr('height', y.rangeBand() - 8)
          .attr('width', function(d) {
            return d.ctr * widthPercent + '%';
          });

        t0.selectAll('.number')
          .text(function(d) {
            return String(d.ctr.toFixed(2)) + '%';
          })
          .attr('class', 'number')
          .attr('y', y.rangeBand() - 10)
          .attr('x', 0)
          .attr('transform', 'translate(' + [10, -5] + ')');

        x = d3.scale
          .linear()
          .range([0, width])
          .domain([0, maxCTR]);

        y = d3.scale.ordinal();
        y.domain(d3.range(data.length)).rangeBands([
          5,
          data.length * barHeight
        ]);

        xAxis = d3.svg
          .axis()
          .ticks(4)
          .scale(x);

        t0.selectAll('.x.axis.bottom').call(xAxis.orient('bottom'));
      } else if (typeOfData === 'ua') {
        // Get the max index so our table doesn't go outside the bounds
        var maxIndex = Math.max.apply(
          Math,
          data.map(function(o) {
            return o.index;
          })
        );

        t0 = chart
          .select('svg')
          .transition()
          .duration(750);

        t0.selectAll('.bar').attr('transform', function(d, i) {
          return 'translate(0,' + y(i) + ')';
        });

        t0.selectAll('rect.percent')
          .attr('height', y.rangeBand() - 8)
          .attr('width', function(d) {
            return (d.index / maxIndex) * widthPercent + '%';
          });

        t0.selectAll('rect.gray-bar')
          .attr('y', -2)
          .attr('height', y.rangeBand() - 4)
          .attr('width', function() {
            return (100 / maxIndex) * widthPercent + '%';
          });

        t0.selectAll('.number')
          .attr('y', y.rangeBand() - 10)
          .attr('x', 0)
          .text(function(d) {
            return d3.format(',d')(d.index);
          })
          .attr('transform', 'translate(' + [10, -5] + ')');

        x = d3.scale
          .linear()
          .range([0, width])
          .domain([0, maxIndex]);

        y = d3.scale.ordinal();
        y.domain(d3.range(data.length)).rangeBands([
          0,
          data.length * barHeight
        ]);

        xAxis = d3.svg
          .axis()
          .ticks(4)
          .tickFormat(function(d) {
            var prefix = d3.formatPrefix(d);
            return prefix.scale(d) + prefix.symbol;
          })
          .scale(x);

        t0.selectAll('.x.axis.bottom').call(xAxis.orient('bottom'));
      }

      chart
        .selectAll('.bar')
        .sort(function(a, b) {
          return d3.descending(a[typeOfData], b[typeOfData]);
        })
        .transition()
        .delay(function(d, i) {
          return i * 25;
        })
        .duration(750)
        .attr('transform', function(d, i) {
          return 'translate(0,' + y(i) + ')';
        });
    }

    /**
     * Check if graph has been created
     * @returns {boolean}
     */
    function graphInitialised() {
      var chart = $document[0].getElementById('premium-index-chart');
      var svg = chart.getElementsByTagName('svg');
      return svg.length > 0;
    }

    /**
     * Remove chart
     * - Used when transitioning between chart types.
     */
    function removeChart() {
      var chart = d3.select('#premium-index-chart');
      chart.selectAll('svg').remove();
    }

    /**
     * Create chart or change date
     * @param {Array} jsonData
     */
    function createOrChange(jsonData, chartType) {
      removeChart();

      var overlay = $document[0].querySelector(
        PremiumIndexConfig.chartId() + ' .overlay'
      );
      var variable = $document[0]
        .querySelector('.variable-dropdown .ng-scope')
        .innerHTML.trim();

      if (variable.length > 0 && variable != 'STATE') {
        angular.element(overlay).addClass('active');
      } else {
        angular.element(overlay).removeClass('active');
      }

      if (!graphInitialised()) {
        return createChart(jsonData);
      }

      return changeDate(jsonData);
    }

    return {
      createOrChange: createOrChange,
      resize: resize,
      sortData: sortData
    };
  });
