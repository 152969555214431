(function() {
  'use strict';

  angular
    .module('insights')
    .factory('ageGenderDisclaimer', ageGenderDisclaimer);

  ageGenderDisclaimer.$inject = ['$http', '$q', '$log', 'Utility'];

  function ageGenderDisclaimer($http, $q, $log, Utility) {
    return {
      check: check
    };

    function check(insightId) {
      var companyUID = Utility.getUID();
      return $q(function(resolve, reject) {
        $http
          .get('/api/insights/ageGenderDisclaimer', {
            params: {
              insightId: insightId,
              companyUID: companyUID
            }
          })
          .then(function(response) {
            resolve(response.data.show);
          })
          .catch(function(err) {
            console.log(err);
          });
      });
    }
  }
})();
