(function() {
  'use strict';

  angular
    .module('campaigns')
    .factory('uniqueChartCampaign', uniqueChartCampaign);

  uniqueChartCampaign.$inject = [
    '$http',
    'InsightsDailyUnique',
    'commonCampaign',
    '$q',
    '$log',
    '$document',
    'downloads'
  ];

  function uniqueChartCampaign(
    $http,
    InsightsDailyUnique,
    commonCampaign,
    $q,
    $log,
    $document,
    downloads
  ) {
    return {
      chart: chart,
      resize: resize,
      monthlyTotals: monthlyTotals,
      weeklyTotals: weeklyTotals,
      dailyTotals: dailyTotals,
      changeDate: changeDate,
      getData: getData,
      checkIfDataExists: checkIfDataExists
    };

    /**
     * Unique Chart based on date period
     * @param {string} period - Date period; all, month, day etc.
     * @param {string} campaignId
     * @param {string} dateRange
     * @param {Object} startEnd - Insight start and end dates
     * @param {Object=} variable
     */
    function chart(period, campaignId, dateRange, startEnd, variable) {
      var companyUID = commonCampaign.getUID();

      if (period === 'all') {
        // calling dayChart is right for the "all" period because in
        // particularly unique chart we have to show results day by day as total
        // (summed results) are shown in cumulative
        dayChart(
          campaignId,
          companyUID,
          startEnd.start,
          startEnd.end,
          dateRange,
          variable
        );
      } else if (period === 'month') {
        monthChart(
          campaignId,
          companyUID,
          startEnd.start,
          startEnd.end,
          dateRange,
          variable
        );
      } else if (period === 'week') {
        weekChart(
          campaignId,
          companyUID,
          startEnd.start,
          startEnd.end,
          dateRange,
          variable
        );
      } else if (period === 'day') {
        dayChart(
          campaignId,
          companyUID,
          startEnd.start,
          startEnd.end,
          dateRange,
          variable
        );
      }
    }

    /**
     * Calculate Date Ranges
     * @param {string} dateRange - Date range e.g. 4 Weeks
     * @param {string} datePeriod - day, week, month
     * @param {Date} [date] - Optional date
     * @returns {Object} startEnd
     */
    function calculateDateRange(dateRange, datePeriod, date) {
      var startEnd;
      var dateRangeNumber = dateRange.split(' ')[0];

      // Check dateRangeNumber is a number
      if (Number(dateRangeNumber) === parseInt(dateRangeNumber, 10)) {
        // If no date then use today
        if (!date) {
          // TODO: Fix this.
          /* eslint-disable no-param-reassign */
          date = new Date();

          // this gets called when Month or week is first selected and you need previous
          // week or months data
          if (datePeriod === 'week') {
            date = moment(date)
              .subtract(1, datePeriod)
              .endOf('isoWeek')
              .toDate();
          } else if (datePeriod === 'day') {
            date = moment(date)
              .subtract(1, 'day')
              .toDate();
          } else if (datePeriod === 'month') {
            date = moment(date)
              .subtract(1, datePeriod)
              .endOf('month')
              .toDate();
          }
        }

        if (datePeriod === 'day') {
          if (dateRange !== '365 Days') {
            // @ts-ignore
            dateRangeNumber -= 1;
          }
        } else if (datePeriod === 'week') {
          if (dateRange === '52 Weeks') {
            dateRangeNumber += 1;
          }

          date = moment(date).toDate();
        } else if (datePeriod === 'month') {
          // @ts-ignore
          dateRangeNumber -= 1;
        }
        /* eslint-enable no-param-reassign */

        startEnd = commonCampaign.getLastDate(
          date,
          dateRangeNumber,
          datePeriod + 's'
        );
      } else {
        startEnd = {
          start: InsightsDailyUnique.getEarliestDate(),
          end: moment().format('YYYY-MM-DD')
        };
      }

      return startEnd;
    }

    /**
     * Change Date
     * @param {string} range
     * @param {string} datePeriod
     * @param {Date} [date] - Optional date
     */
    function changeDate(range, datePeriod, date) {
      var startEnd = calculateDateRange(range, datePeriod, date);
      InsightsDailyUnique.createChart(null, range, datePeriod, startEnd);
    }

    /**
     * Resize chart
     * @param {string} range
     * @param {string} datePeriod
     * @param {Date} date
     */
    function resize(range, datePeriod, date) {
      var startEnd = calculateDateRange(range, datePeriod, date);
      InsightsDailyUnique.createChart(null, range, datePeriod, startEnd);
    }

    /**
     * Check if data exists for time periods
     * @param {string} campaignId
     * @param {string} period
     * @param {Object} startEnd - Insight start and end dates
     * @return {Promise} Promise
     */
    function checkIfDataExists(campaignId, period, startEnd) {
      var companyUID = commonCampaign.getUID();

      return $q(function(resolve, reject) {
        if (period === 'month') {
          monthlyData(
            campaignId,
            companyUID,
            startEnd.start,
            startEnd.end
          ).then(
            function(result) {
              resolve(result);
            },
            function(reason) {
              reject(reason);
            }
          );
        } else if (period === 'week') {
          weeklyData(campaignId, companyUID, startEnd.start, startEnd.end).then(
            function(result) {
              resolve(result);
            },
            function(reason) {
              reject(reason);
            }
          );
        } else if (period === 'day' || period === 'all') {
          // calling dailyData is right for the "all" period because in
          // particularly unique chart we have to show results day by day as
          // total (summed results) are shown in cumulative
          dailyData(campaignId, companyUID, startEnd.start, startEnd.end).then(
            function(result) {
              resolve(result);
            },
            function(reason) {
              reject(reason);
            }
          );
        } else {
          resolve(true);
        }
      });
    }

    /**
     * Check if monthly data is present
     * @param {string} campaignId
     * @param {string} companyUID - The unique id of the Company/Organisation
     * @param {string} startDate - Campaign Start Date YYYY-MM-DD
     * @param {?string} endDate - Campaign End Date YYYY-MM-DD
     * @return {Promise}
     */
    function monthlyData(campaignId, companyUID, startDate, endDate) {
      return $q(function(resolve, reject) {
        var yesterday = commonCampaign.formatEndDate();
        var startOfMonth = moment(startDate)
          .startOf('month')
          .format('YYYY-MM-DD');
        $http
          .post('/api/insights/campaign', {
            campaign: campaignId,
            companyUID: companyUID,
            endDate: endDate || yesterday,
            chart: 'unique',
            period: 'month',
            type: 'campaign',
            startDate: startOfMonth
          })
          .then(
            function(result) {
              if (result.data.insights.length > 0) {
                resolve(true);
              } else {
                resolve(false);
              }
            },
            function(reason) {
              $log.error('Error: ' + reason);
              reject();
            }
          );
      });
    }

    /**
     * Check if weekly data is present
     * @param {string} campaignId
     * @param {string} startDate - Campaign Start Date YYYY-MM-DD
     * @param {?string} endDate - Campaign End Date YYYY-MM-DD
     * @return {Promise}
     */
    function weeklyData(campaignId, companyUID, startDate, endDate) {
      return $q(function(resolve, reject) {
        var yesterday = commonCampaign.formatEndDate();
        var startOfWeek = moment(startDate)
          .startOf('isoWeek')
          .format('YYYY-MM-DD');
        $http
          .post('/api/insights/campaign', {
            campaign: campaignId,
            companyUID: companyUID,
            endDate: endDate || yesterday,
            chart: 'unique',
            period: 'week',
            type: 'campaign',
            startDate: startOfWeek
          })
          .then(
            function(result) {
              if (result.data.insights.length > 0) {
                resolve(true);
              } else {
                resolve(false);
              }
            },
            function(reason) {
              $log.error('Error: ' + reason);
              reject();
            }
          );
      });
    }

    /**
     * Check if daily data is present
     * @param {string} campaignId
     * @param {string} startDate - Campaign Start Date YYYY-MM-DD
     * @param {?string} endDate - Campaign End Date YYYY-MM-DD
     * @return {Promise}
     */
    function dailyData(campaignId, companyUID, startDate, endDate) {
      return $q(function(resolve, reject) {
        var yesterday = commonCampaign.formatEndDate();
        $http
          .post('/api/insights/campaign', {
            campaign: campaignId,
            companyUID: companyUID,
            endDate: endDate || yesterday,
            chart: 'unique',
            period: 'day',
            type: 'campaign',
            startDate: startDate
          })
          .then(
            function(result) {
              if (result.data.insights.length > 0) {
                resolve(true);
              } else {
                resolve(false);
              }
            },
            function(reason) {
              $log.error('Error: ' + reason);
              reject();
            }
          );
      });
    }

    /**
     * Monthly Unique count chart.
     * @param {string} campaignId
     * @param {string} startDate - Campaign Start Date YYYY-MM-DD
     * @param {?string} endDate - Campaign End Date YYYY-MM-DD
     * @param {string} dateRange
     * @param {Object=} variable
     */
    function monthChart(
      campaignId,
      companyUID,
      startDate,
      endDate,
      dateRange,
      variable
    ) {
      var yesterday = commonCampaign.formatEndDate();
      var startOfMonth = moment(startDate)
        .startOf('month')
        .format('YYYY-MM-DD');
      $http
        .post('/api/insights/campaign', {
          campaign: campaignId,
          companyUID: companyUID,
          endDate: endDate || yesterday,
          chart: 'unique',
          period: 'month',
          type: 'campaign',
          variable: variable,
          startDate: startOfMonth
        })
        .then(
          function(result) {
            if (result.data.insights.length > 0) {
              var insights = result.data.insights;
              InsightsDailyUnique.createChart(insights, dateRange, 'month', {
                start: startOfMonth,
                end: endDate
              });

              var noInsightsMessage = $document[0]
                .getElementById('unique-area-chart')
                .getElementsByClassName('no-insights');
              if (noInsightsMessage) {
                angular.element(noInsightsMessage).remove();
              }
            } else {
              commonCampaign.hideSpinner('unique-area-chart', 'showNoDataMsg');
            }
          },
          function(reason) {
            commonCampaign.hideSpinner('unique-area-chart', 'showNoDataMsg');
            $log.error('Error: ' + reason);
          }
        );
    }

    /**
     * Daily Unique count chart.
     * @param {string} campaignId
     * @param {string} startDate - Campaign Start Date YYYY-MM-DD
     * @param {?string} endDate - Campaign End Date YYYY-MM-DD
     * @param {string} dateRange
     * @param {Object=} variable
     */
    function dayChart(
      campaignId,
      companyUID,
      startDate,
      endDate,
      dateRange,
      variable
    ) {
      var yesterday = commonCampaign.formatEndDate();
      // Check if chart was created today.
      if (yesterday <= startDate) {
        commonCampaign.hideSpinner('unique-area-chart', 'showNoDataMsg');
      }
      return $http
        .post('/api/insights/campaign', {
          campaign: campaignId,
          companyUID: companyUID,
          endDate: endDate || yesterday,
          chart: 'unique',
          period: 'day',
          type: 'campaign',
          variable: variable,
          startDate: startDate
        })
        .then(
          function(result) {
            if (result.data.insights.length > 0) {
              var insights = result.data.insights;

              // If data from today is included remove it.
              if (
                new Date(insights[insights.length - 1].Period_Start) >=
                new Date(commonCampaign.todayDate())
              ) {
                insights.pop();
              }

              InsightsDailyUnique.createChart(insights, dateRange, 'day', {
                start: startDate,
                end: endDate || yesterday
              });
            } else {
              commonCampaign.hideSpinner('unique-area-chart', 'showNoDataMsg');
            }
          },
          function(reason) {
            $log.error('Error: Campaigns UniqueChart');
            $log.error(reason);
            commonCampaign.hideSpinner('unique-area-chart', 'showNoDataMsg');
          }
        );
    }

    /**
     * Weekly Unique count chart.
     * @param {string} campaignId
     * @param {string} startDate - Campaign Start Date YYYY-MM-DD
     * @param {?string} endDate - Campaign End Date YYYY-MM-DD
     * @param {string} dateRange
     * @param {Object=} variable
     */
    function weekChart(
      campaignId,
      companyUID,
      startDate,
      endDate,
      dateRange,
      variable
    ) {
      var yesterday = commonCampaign.formatEndDate();
      var startOfWeek = moment(startDate)
        .startOf('isoWeek')
        .format('YYYY-MM-DD');
      $http
        .post('/api/insights/campaign', {
          campaign: campaignId,
          companyUID: companyUID,
          endDate: endDate || yesterday,
          chart: 'unique',
          period: 'week',
          type: 'campaign',
          variable: variable,
          startDate: startOfWeek
        })
        .then(
          function(result) {
            if (result.data.insights.length > 0) {
              var insights = result.data.insights;
              InsightsDailyUnique.createChart(insights, dateRange, 'week', {
                start: startOfWeek,
                end: endDate
              });
            } else {
              commonCampaign.hideSpinner('unique-area-chart', 'showNoDataMsg');
            }
          },
          function(reason) {
            commonCampaign.hideSpinner('unique-area-chart', 'showNoDataMsg');

            $log.error('Error: ' + reason);
          }
        );
    }

    /**
     * Which data to download
     * @param {string} campaignId
     * @param {string} type
     * @param {Object} startEnd - Insight start and end dates
     * @param {Object=} variable
     * @returns {Promise} Promise
     */
    function getData(campaignId, type, startEnd, variable) {
      if (type === 'day') {
        return getDataDay(campaignId, startEnd, variable);
      }
      if (type === 'week') {
        return getDataWeek(campaignId, startEnd, variable);
      }
      if (type === 'all') {
        return getDataAll(campaignId, startEnd, variable);
      }
      return getDataMonth(campaignId, startEnd, variable);
    }

    /**
     * Get All Data for Download
     * @param {string} campaignId
     * @param {Object} startEnd - Insight start and end dates
     * @param {Object=} variable
     * @returns {Promise} Promise
     */
    function getDataAll(campaignId, startEnd, variable) {
      return $q(function(resolve, reject) {
        allTotals(campaignId, startEnd.start, startEnd.end, variable).then(
          function(result) {
            resolve(InsightsDailyUnique.roundData(result));
          },
          function(reason) {
            $log.error('Error: ' + reason);
            reject();
          }
        );
      });
    }

    /**
     * Get Day Data for Download
     * @param {string} campaignId
     * @param {Object} startEnd - Insight start and end dates
     * @param {Object=} variable
     * @returns {Promise} Promise
     */
    function getDataDay(campaignId, startEnd, variable) {
      return $q(function(resolve, reject) {
        dailyTotals(campaignId, startEnd.start, startEnd.end, variable).then(
          function(result) {
            var data = InsightsDailyUnique.roundData(result);
            data = downloads.renameHeading(data, 'Period_Start', 'Date');
            data = downloads.orderFields(data, [
              'Date',
              'UA',
              'Impressions',
              'Clicks'
            ]);
            resolve(data);
          },
          function(reason) {
            $log.error('Error: ' + reason);
            reject();
          }
        );
      });
    }

    /**
     * Get Week Data for Download
     * @param {string} campaignId
     * @param {Object} startEnd - Insight start and end dates
     * @param {Object=} variable
     * @returns {Promise} Promise
     */
    function getDataWeek(campaignId, startEnd, variable) {
      return $q(function(resolve, reject) {
        weeklyTotals(campaignId, startEnd.start, startEnd.end, variable).then(
          function(result) {
            var data = InsightsDailyUnique.roundData(result);
            data = downloads.renameHeading(
              data,
              'Period_End',
              'Start_Date_of_Week'
            );
            data = downloads.orderFields(data, [
              'Start_Date_of_Week',
              'UA',
              'Impressions',
              'Clicks'
            ]);
            resolve(data);
          },
          function(reason) {
            $log.error('Error: ' + reason);
            reject();
          }
        );
      });
    }

    /**
     * Get Month Data for Download
     * @param {string} campaignId
     * @param {Object} startEnd - Insight start and end dates
     * @param {Object=} variable
     * @returns {Promise} Promise
     */
    function getDataMonth(campaignId, startEnd, variable) {
      return $q(function(resolve, reject) {
        monthlyTotals(campaignId, startEnd.start, startEnd.end, variable).then(
          function(result) {
            var data = InsightsDailyUnique.roundData(result);
            data = downloads.renameHeading(
              data,
              'Period_End',
              'Start_Date_of_Month'
            );
            data = downloads.orderFields(data, [
              'Start_Date_of_Month',
              'UA',
              'Impressions',
              'Clicks'
            ]);
            resolve(data);
          },
          function(reason) {
            $log.error('Error: ' + reason);
            reject();
          }
        );
      });
    }

    /**
     * Monthly Totals
     * @param {string} campaignId
     * @param {string} startDate - Campaign Start Date YYYY-MM-DD
     * @param {?string} endDate - Campaign End Date YYYY-MM-DD
     * @param {Object=} variable
     * @returns {Promise} Promise
     */
    function monthlyTotals(campaignId, startDate, endDate, variable) {
      var yesterday = commonCampaign.formatEndDate();
      var companyUID = commonCampaign.getUID();
      var startOfMonth = moment(startDate)
        .startOf('month')
        .format('YYYY-MM-DD');
      return $q(function(resolve, reject) {
        $http
          .post('/api/insights/campaign', {
            campaign: campaignId,
            companyUID: companyUID,
            endDate: endDate || yesterday,
            chart: 'unique',
            period: 'month',
            type: 'campaign',
            variable: variable,
            startDate: startOfMonth
          })
          .then(function(result) {
            if (result.data.insights) {
              var insights = result.data.insights;
              resolve(insights);
            } else {
              reject('ERROR');
            }
          });
      });
    }

    /**
     * Weekly Totals
     * @param {string} campaignId
     * @param {string} startDate - Campaign Start Date YYYY-MM-DD
     * @param {?string} endDate - Campaign End Date YYYY-MM-DD
     * @param {Object=} variable
     * @returns {Promise} Promise
     */
    function weeklyTotals(campaignId, startDate, endDate, variable) {
      var yesterday = commonCampaign.formatEndDate();
      var companyUID = commonCampaign.getUID();
      var startOfWeek = moment(startDate)
        .startOf('isoWeek')
        .format('YYYY-MM-DD');
      return $q(function(resolve, reject) {
        $http
          .post('/api/insights/campaign', {
            campaign: campaignId,
            companyUID: companyUID,
            endDate: endDate || yesterday,
            chart: 'unique',
            period: 'week',
            type: 'campaign',
            variable: variable,
            startDate: startOfWeek
          })
          .then(function(result) {
            if (result.data.insights) {
              var insights = result.data.insights;
              resolve(insights);
            } else {
              reject('ERROR');
            }
          });
      });
    }

    /**
     * Daily Totals
     * @param {string} campaignId
     * @param {string} startDate - Campaign Start Date YYYY-MM-DD
     * @param {?string} endDate - Campaign End Date YYYY-MM-DD
     * @param {Object=} variable
     * @returns {Promise} Promise
     */
    function dailyTotals(campaignId, startDate, endDate, variable) {
      var yesterday = commonCampaign.formatEndDate();
      var companyUID = commonCampaign.getUID();
      return $q(function(resolve, reject) {
        $http
          .post('/api/insights/campaign', {
            campaign: campaignId,
            companyUID: companyUID,
            endDate: endDate || yesterday,
            chart: 'unique',
            period: 'day',
            type: 'campaign',
            variable: variable,
            startDate: startDate
          })
          .then(function(result) {
            if (result.data.insights) {
              var insights = result.data.insights;
              resolve(insights);
            } else {
              reject('ERROR');
            }
          });
      });
    }

    /**
     * All Totals
     * @param {string} campaignId
     * @param {string} startDate - Campaign Start Date YYYY-MM-DD
     * @param {?string} endDate - Campaign End Date YYYY-MM-DD
     * @param {Object=} variable
     * @returns {Promise} Promise
     */
    function allTotals(campaignId, startDate, endDate, variable) {
      var yesterday = commonCampaign.formatEndDate();
      var companyUID = commonCampaign.getUID();
      return $q(function(resolve, reject) {
        $http
          .post('/api/insights/campaign', {
            campaign: campaignId,
            companyUID: companyUID,
            endDate: endDate || yesterday,
            chart: 'unique',
            period: 'all',
            type: 'campaign',
            variable: variable,
            startDate: startDate
          })
          .then(function(result) {
            if (result.data.insights) {
              var insights = result.data.insights;
              resolve(insights);
            } else {
              reject('ERROR');
            }
          });
      });
    }
  }
})();
