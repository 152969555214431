(function() {
  'use strict';

  angular
    .module('campaigns')
    .factory(
      'localVsInternationalChartCampaign',
      localVsInternationalChartCampaign
    );

  localVsInternationalChartCampaign.$inject = [
    'commonCampaign',
    '$http',
    'InsightsLocalVsInternational',
    '$log',
    '$q',
    'downloads'
  ];

  function localVsInternationalChartCampaign(
    commonCampaign,
    $http,
    InsightsLocalVsInternational,
    $log,
    $q,
    downloads
  ) {
    return {
      chart: chart,
      resize: resize,
      getData: getData
    };

    /**
     * StartEnd type
     * @typedef {Object} StartEnd
     * @property {string} start Campaign start date
     * @property {?string} end Campaign end date or selected date.
     */

    /**
     * Parse which Local vs International Chart
     * @param {string} period
     * @param {string} campaignId
     * @param {StartEnd} startEnd - Insight start and end dates
     * @param {Object} variable
     */
    function chart(period, campaignId, startEnd, variable) {
      var companyUID = commonCampaign.getUID();
      if (period === 'all') {
        allChart(
          campaignId,
          companyUID,
          startEnd.start,
          startEnd.end,
          variable
        );
      } else if (period === 'month') {
        timeChart(
          campaignId,
          companyUID,
          startEnd.start,
          startEnd.end,
          period,
          variable
        );
      } else if (period === 'week') {
        timeChart(
          campaignId,
          companyUID,
          startEnd.start,
          startEnd.end,
          period,
          variable
        );
      } else if (period === 'day') {
        timeChart(
          campaignId,
          companyUID,
          startEnd.start,
          startEnd.end,
          period,
          variable
        );
      }
    }

    /**
     * Local vs International Chart for All Time
     * @param {string} campaignId
     * @param {string} startDate - Campaign Start Date YYYY-MM-DD
     * @param {?string} endDate - Campaign End Date YYYY-MM-DD
     * @param {Object} variable
     */
    function allChart(campaignId, companyUID, startDate, endDate, variable) {
      var yesterday = commonCampaign.formatEndDate();
      $http
        .post('/api/insights/campaign', {
          campaign: campaignId,
          companyUID: companyUID,
          endDate: endDate || yesterday,
          chart: 'local',
          period: 'all',
          type: 'campaign',
          startDate: startDate,
          variable: variable
        })
        .then(
          function(result) {
            if (result.data.insights.length > 0) {
              InsightsLocalVsInternational.checkService(
                angular.copy(result.data.insights)
              );
            } else {
              commonCampaign.hideSpinner(
                'local-vs-international-chart',
                'showNoDataMsg'
              );
            }
          },
          function(reason) {
            commonCampaign.hideSpinner(
              'local-vs-international-chart',
              'showNoDataMsg'
            );
            $log.error('Error: campaign-local-all');
            $log.error(reason);
          }
        );
    }

    /**
     * Local vs International Chart for a Time Period
     * @param {string} campaignId
     * @param {string} startDate - Campaign Start Date YYYY-MM-DD
     * @param {?string} endDate - Campaign End Date YYYY-MM-DD
     * @param {Object} variable
     * @param {string} period
     */
    function timeChart(
      campaignId,
      companyUID,
      startDate,
      endDate,
      period,
      variable
    ) {
      var yesterday = commonCampaign.formatEndDate();
      var adjustedStart;
      if (period === 'month') {
        adjustedStart = moment(startDate)
          .startOf('month')
          .format('YYYY-MM-DD');
      } else if (period === 'week') {
        adjustedStart = moment(startDate)
          .startOf('isoWeek')
          .format('YYYY-MM-DD');
      }

      $http
        .post('/api/insights/campaign', {
          campaign: campaignId,
          companyUID: companyUID,
          startDate: adjustedStart || startDate,
          endDate: endDate || yesterday,
          chart: 'local',
          period: 'time',
          type: 'campaign',
          variable: variable
        })
        .then(
          function(result) {
            if (result.data.insights.length > 0) {
              InsightsLocalVsInternational.checkService(
                angular.copy(result.data.insights)
              );
            } else {
              commonCampaign.hideSpinner(
                'local-vs-international-chart',
                'showNoDataMsg'
              );
            }
          },
          function(reason) {
            commonCampaign.hideSpinner(
              'local-vs-international-chart',
              'showNoDataMsg'
            );
            $log.error('Error: campaign-local-time');
            $log.error(reason);
          }
        );
    }

    /**
     * Resize chart
     */
    function resize() {
      InsightsLocalVsInternational.resize();
    }

    /**
     * Get data for download.
     *
     * @param {string} campaignId
     * @param {Object} startEnd - Insight start and end dates
     * @param {Object} [variable]
     */
    function getData(campaignId, companyUID, startEnd, variable) {
      var yesterday = commonCampaign.formatEndDate();

      return $q(function(resolve, reject) {
        $http
          .post('/api/insights/campaign', {
            campaign: campaignId,
            companyUID: companyUID,
            startDate: startEnd.start,
            endDate: startEnd.end || yesterday,
            chart: 'local',
            period: 'time',
            type: 'campaign',
            variable: variable
          })
          .then(
            function(result) {
              var data = formatDownload(
                result.data.insights,
                'Date',
                startEnd.end || yesterday
              );
              resolve(data);
            },
            function(reason) {
              $log.error('Error: Campaign-local-download');
              $log.error(reason);
              reject();
            }
          );
      });
    }

    function formatDownload(rawData, dateHeading, date) {
      var data = downloads.addHeading(rawData, dateHeading, date);
      data = downloads.renameHeading(
        data,
        'impressions_local',
        'AU_Impressions'
      );
      data = downloads.renameHeading(
        data,
        'impressions_int',
        'International_Impressions'
      );
      data = downloads.renameHeading(data, 'clicks_local', 'AU_Clicks');
      data = downloads.renameHeading(
        data,
        'clicks_int',
        'International_Clicks'
      );
      data = downloads.orderFields(data, [
        'Date',
        'AU_Impressions',
        'International_Impressions',
        'AU_Clicks',
        'International_Clicks'
      ]);
      return data;
    }
  }
})();
