(function() {
  'use strict';

  angular
    .module('settings')
    .controller('CampaignTeamsController', CampaignTeamsController);

  CampaignTeamsController.$inject = [
    '$rootScope',
    'Campaigns',
    'CampaignsTeamAccess',
    'Teams',
    '$log',
    'Authorization',
    'store'
  ];

  function CampaignTeamsController(
    $rootScope,
    Campaigns,
    CampaignsTeamAccess,
    Teams,
    $log,
    Authorization,
    store
  ) {
    var vm = this;
    Authorization.authorize().then(function() {
      vm.user = store.get('client');
      vm.permissions = Authorization.permissions();
      vm.permissionSet = Authorization.permissionSet();
      vm.currentCompany = store.get('currentCompany');
      vm.teams = Teams.getTeams(vm.user, vm.permissionSet, vm.currentCompany);
      getCampaigns();
    });
    $rootScope.title = 'Campaign Teams';
    vm.toggleArchiveFilter = toggleArchiveFilter;
    vm.noToShowOptions = [
      { name: 10, value: 10 },
      { name: 20, value: 20 },
      { name: 'All', value: 0 }
    ];
    vm.noToShow = vm.noToShowOptions[0].value;
    vm.archive = 'Active';
    vm.orderByField = 'created';
    vm.reverseSort = true;
    vm.setTeam = setTeam;

    /**
     * Toggle displaying archived insights.
     */
    function toggleArchiveFilter() {
      if (vm.archive === '') {
        vm.archive = 'Active';
      } else {
        vm.archive = '';
      }
    }

    function getCampaigns() {
      vm.campaigns = [];
      CampaignsTeamAccess.query(function(campaigns) {
        campaigns.forEach(function(campaign) {
          addTeamNameToCampaign(campaign);
        });
      });
    }

    function addTeamNameToCampaign(campaign) {
      if (campaign.teamId) {
        // Got team id, now get the team name
        getTeamName(campaign, campaign.teamId);
      } else {
        $log.warn('No team found for ' + campaign.id);
        vm.campaigns.push(campaign);
      }
    }

    function getTeamName(campaign, teamId) {
      Teams.getTeamName(vm.teams, teamId).then(function(teamName) {
        campaign.teamName = teamName;
        vm.campaigns.push(campaign);
      });
    }

    /**
     * Handle changing team for campaign.
     * @param {Object} team
     * @param {Object} campaign
     */
    function setTeam(team, campaign) {
      CampaignsTeamAccess.update(
        {
          campaignId: campaign.id
        },
        {
          teamId: team.id
        },
        function() {
          Teams.getTeamName(vm.teams, team.id).then(function(name) {
            campaign.teamName = name;
          });
        },
        function(err) {
          $log.error(err);
        }
      );
    }
  }
})();
