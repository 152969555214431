(function() {
  'use strict';

  angular
    .module('websites')
    .factory('listingTotalsWebsite', listingTotalsWebsite);

  listingTotalsWebsite.$inject = ['$http', '$q', '$log', 'commonWebsite'];

  function listingTotalsWebsite($http, $q, $log, commonWebsite) {
    return {
      total: total
    };

    function total(websiteId, startDate, endDate) {
      var yesterday = commonWebsite.formatEndDate();
      var companyUID = commonWebsite.getUID();

      return $q(function(resolve, reject) {
        $http
          .post('/api/insights/website/totals', {
            campaign: websiteId.toString(),
            companyUID: companyUID,
            endDate: endDate || yesterday,
            startDate: startDate
          })
          .then(function(result) {
            resolve(result.data);
          })
          .catch(function(reason) {
            $log.error(reason);
            reject(reason);
          });
      });
    }
  }
})();
