'use strict';

/* eslint angular/di: [2,"array"] */
// Setting up route
angular.module('settings').config([
  '$stateProvider',
  function($stateProvider) {
    // Resources state routing
    $stateProvider
      .state('settingsSupport', {
        url: '/settings/admin',
        templateUrl: 'modules/settings/views/admin.client.view.html'
      })
      .state('global-settings', {
        url: '/global-settings',
        templateUrl: 'modules/settings/views/globalsettings.client.view.html'
      });
  }
]);
