'use strict';

// Setting up route
angular.module('pixels').config([
  '$stateProvider',
  function($stateProvider) {
    // Pixels state routing
    $stateProvider
      .state('listPixels', {
        url: '/pixels',
        templateUrl: 'modules/pixels/views/list-pixels.client.view.html'
      })
      .state('createPixel', {
        url: '/pixels/create',
        templateUrl: 'modules/pixels/views/create-pixel.client.view.html'
      })
      .state('viewPixel', {
        url: '/pixels/:pixelId',
        templateUrl: 'modules/pixels/views/view-pixel.client.view.html'
      })
      .state('editPixel', {
        url: '/pixels/:pixelId/edit',
        templateUrl: 'modules/pixels/views/edit-pixel.client.view.html'
      });
  }
]);
