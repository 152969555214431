(function() {
  'use strict';

  angular
    .module('campaigns')
    .factory('cumulativeChartCampaign', cumulativeChartCampaign);

  cumulativeChartCampaign.$inject = [
    '$http',
    'commonCampaign',
    'InsightsCumulative',
    '$q',
    '$log',
    '$document',
    'downloads'
  ];
  function cumulativeChartCampaign(
    $http,
    commonCampaign,
    InsightsCumulative,
    $q,
    $log,
    $document,
    downloads
  ) {
    return {
      chart: chart,
      resize: resize,
      allTotals: allTotals,
      changeDate: changeDate,
      getData: getData
    };

    /**
     * StartEnd type
     * @typedef {Object} StartEnd
     * @property {string} start Campaign start date
     * @property {?string} end Campaign end date or selected date.
     */

    /**
     * Variable type
     * @typedef {Object} Variable
     * @property {string} variable Variable name
     * @property {string} file Variable filename
     */

    /**
     * Campaign Cumulative Chart
     * @param {string} campaignId
     * @param {string} dateRange
     * @param {string} datePeriod
     * @param {string} startDate - Campaign Start Date YYYY-MM-DD
     * @param {?string} endDate - Campaign End Date YYYY-MM-DD
     * @param {Variable} [variable] - Optional variable
     */
    function chart(
      campaignId,
      dateRange,
      datePeriod,
      startDate,
      endDate,
      variable
    ) {
      var yesterday = commonCampaign.formatEndDate();
      var companyUID = commonCampaign.getUID();
      $http
        .post('/api/insights/campaign', {
          campaign: campaignId,
          companyUID: companyUID,
          endDate: endDate || yesterday,
          chart: 'cumulative',
          period: 'all',
          type: 'campaign',
          variable: variable,
          startDate: startDate
        })
        .then(
          function(result) {
            if (result.data.insights.length > 0) {
              var insights = result.data.insights;

              // If data from today is included remove it.
              if (
                new Date(insights[insights.length - 1].Period_End) >=
                new Date(commonCampaign.todayDate())
              ) {
                insights.pop();
              }

              var filteredRange = calculateDateRange(
                dateRange,
                datePeriod,
                endDate || yesterday
              );
              InsightsCumulative.createChart(
                insights,
                dateRange,
                filteredRange
              );
              var noInsightsMessage = $document[0]
                .getElementById('cumulative-area-chart')
                .getElementsByClassName('no-insights');
              if (noInsightsMessage) {
                angular.element(noInsightsMessage).remove();
              }
            } else {
              commonCampaign.hideSpinner(
                'cumulative-area-chart',
                'showNoDataMsg'
              );
            }
          },
          function(reason) {
            commonCampaign.hideSpinner(
              'cumulative-area-chart',
              'showNoDataMsg'
            );
            $log.error('Error: campaign-cumulative-chart');
            $log.error(reason.data);
          }
        );
    }

    /**
     * Calculate Date Ranges
     * @param {string} dateRange - Date range e.g. 4 Weeks
     * @param {string} datePeriod - day, week, month
     * @param {Date} date - Date
     * @returns {?StartEnd} startEnd - Object containing start and end dates
     */
    function calculateDateRange(dateRange, datePeriod, date) {
      var startEnd;
      if (
        dateRange.toLowerCase() === 'all' &&
        datePeriod.toLowerCase() === 'all'
      ) {
        return null;
      }

      var dateRangePart = dateRange.split(' ')[0];
      var yesterday = moment()
        .subtract(1, 'day')
        .toDate();
      if (Number(dateRangePart) === parseInt(dateRangePart, 10)) {
        var dateRangeNumber = Number(dateRangePart);
        // If no date then use today
        if (!date) {
          // TODO: Fix this.
          /* eslint-disable no-param-reassign */
          date = new Date();

          // this gets called when Month or week is first selected and you need previous
          // week or months data
          if (datePeriod === 'month') {
            date = moment(date)
              .subtract(1, datePeriod)
              .toDate();
          } else if (datePeriod === 'week') {
            date = moment(date)
              .subtract(1, datePeriod)
              .endOf('isoWeek')
              .toDate();
          } else if (datePeriod === 'day') {
            date = moment(date)
              .subtract(1, 'day')
              .toDate();
          }
        }

        if (datePeriod === 'month') {
          date = moment(date).toDate();
          dateRangeNumber -= 1;
        } else if (datePeriod === 'week') {
          if (dateRange === '52 Weeks') {
            dateRangeNumber += 1;
          }

          date = moment(date).toDate();
        } else if (datePeriod === 'day') {
          if (dateRange !== '365 Days') {
            dateRangeNumber -= 1;
          }
        }
        /* eslint-enable no-param-reassign */

        startEnd = commonCampaign.getLastDate(
          date,
          dateRangeNumber,
          datePeriod + 's'
        );
      } else {
        startEnd = {
          start: InsightsCumulative.getEarliestDate(),
          end: moment(yesterday).format('YYYY-MM-DD')
        };
      }

      return startEnd;
    }

    /**
     * Resize chart
     * @param {string} range - Date Range e.g. 7 days, all
     * @param {string} datePeriod - week, month, day or all
     * @param {Date} date
     */
    function resize(range, datePeriod, date) {
      var startEnd = calculateDateRange(range, datePeriod, date);
      InsightsCumulative.createChart(null, range, startEnd);
    }

    /**
     * Change Date
     * @param {string} range - Date Range e.g. 7 days, all
     * @param {string} datePeriod - week, month, day or all
     * @param {Date} [date] - Optional date
     */
    function changeDate(range, datePeriod, date) {
      var startEnd = calculateDateRange(range, datePeriod, date);
      InsightsCumulative.createChart(null, range, startEnd);
    }

    /**
     * Get Data
     * @param {string} campaignId
     * @param {StartEnd} startEnd - Object containing start and end dates
     * @param {?Variable} variable
     * @returns {Promise<Object[]>}
     */
    function getData(campaignId, startEnd, variable) {
      var yesterday = commonCampaign.formatEndDate();
      var companyUID = commonCampaign.getUID();
      return $q(function(resolve, reject) {
        $http
          .post('/api/insights/campaign', {
            campaign: campaignId,
            companyUID: companyUID,
            endDate: startEnd.end || yesterday,
            chart: 'cumulative',
            period: 'all',
            type: 'campaign',
            variable: variable,
            startDate: startEnd.start
          })
          .then(function(result) {
            if (result.data.insights.length > 0) {
              var data = result.data.insights;
              data = downloads.renameHeading(
                data,
                'Period_End',
                'Period_End_Date'
              );
              data = downloads.removeHeading(data, 'clicks');
              data = downloads.removeHeading(data, 'impressions');
              data = downloads.removeHeading(data, 'ua');
              data = downloads.orderFields(data, [
                'Period_End_Date',
                'UA',
                'Impressions',
                'Clicks'
              ]);

              resolve(data);
            }
          })
          .catch(function(error) {
            $log.error('Error: Campaigns cumulative charts getData');
            $log.error(error);
            reject();
          });
      });
    }

    /**
     * All Totals
     * @param {string} campaignId
     * @param {string} startDate - Campaign Start Date YYYY-MM-DD
     * @param {?string} endDate - Campaign End Date YYYY-MM-DD
     * @param {Variable} [variable] - Optional variable
     * @returns {Promise} Promise
     */
    function allTotals(campaignId, startDate, endDate, variable) {
      var yesterday = commonCampaign.formatEndDate();
      var companyUID = commonCampaign.getUID();
      return $q(function(resolve, reject) {
        $http
          .post('/api/insights/campaign', {
            campaign: campaignId,
            companyUID: companyUID,
            endDate: endDate || yesterday,
            chart: 'cumulative',
            period: 'all',
            type: 'campaign',
            variable: variable,
            startDate: startDate
          })
          .then(
            function(result) {
              if (result.data.insights.length > 0) {
                var insights = result.data.insights;

                // If data from today is included remove it.
                if (
                  new Date(insights[insights.length - 1].Period_End) >=
                  new Date(commonCampaign.todayDate())
                ) {
                  insights.pop();
                }
                resolve(insights);
              } else {
                resolve('false');
              }
            },
            function(reason) {
              $log.error('Error: Cumulative all totals:');
              $log.error(reason);
              reject();
            }
          );
      });
    }
  }
})();
