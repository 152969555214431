'use strict';

angular
  .module('insights')
  .factory('InsightsGender', function(InsightsGenderConfig, commonWebsite) {
    var path = [];
    var typeOfData = 'ua';
    var width = 0;
    var globalData = [];
    var completeData = [];

    /**
     * Returns today's date in YYYY-MM-DD format
     */
    function todayDate() {
      return moment().format('YYYY-MM-DD');
    }

    /**
     * Remove null data
     * @param {Array} data
     * @returns {Array}
     */
    function removeNullData(data) {
      var tempArray = [];
      if (data && data.length > 0) {
        for (var i = 0; i < data.length; i++) {
          if (data[i].gender !== null) {
            tempArray.push(data[i]);
          }
        }
      }

      return tempArray;
    }

    /**
     * Get data for only most recent date and remove null data.
     * @param {Array} data
     * @returns {Array}
     */
    function parseData(data) {
      data = removeNullData(data);

      // Proceed only if there is data.
      if (data.length < 1) {
        return data;
      }

      var lastDate;
      var relevantData;
      if (data[0].Period_End) {
        lastDate = data[0].Period_End;
        relevantData = [];
        for (var j = 0; j < data.length; j++) {
          if (data[j].Period_End === lastDate) {
            relevantData.push(data[j]);
          } else {
            break;
          }
        }
      } else {
        lastDate = data[0].Period_Start;
        relevantData = [];
        for (var n = 0; n < data.length; n++) {
          if (data[n].Period_Start === lastDate) {
            relevantData.push(data[n]);
          } else {
            break;
          }
        }
      }

      relevantData.forEach(function(data) {
        data.impressions = parseInt(Number(data.impressions), 10);
        data.ua = parseInt(Number(data.ua), 10);
        data.clicks = parseInt(Number(data.clicks), 10);
      });

      return relevantData;
    }

    /**
     * Check if Data is null
     * @param {Array} data
     * @returns {boolean}
     */
    function checkIfData(data) {
      if (!data || data.length < 1) {
        return false;
      }

      var hasData = true;
      if (data[0].gender === null) {
        hasData = false;
      }

      return hasData;
    }

    var readData = function(data) {
      globalData = data;
      data = parseData(data);
      var containsData = checkIfData(globalData);
      if (!containsData) {
        commonWebsite.hideSpinner('gender-chart', 'showNoDataMsg');
        return;
      }
      width = InsightsGenderConfig.width();

      var height = d3
        .select(InsightsGenderConfig.chartId)
        .node()
        .getBoundingClientRect().height;
      var radius = Math.min(width, height) / 2.3;
      var labelRadius = radius;
      var arc = d3.svg
        .arc()
        .outerRadius(radius - InsightsGenderConfig.outerRadius)
        .innerRadius(radius - InsightsGenderConfig.innerRadius);

      var pie = d3.layout
        .pie()
        .sort(null)
        .value(function(d) {
          return d[typeOfData];
        });

      var svg = d3
        .select(InsightsGenderConfig.chartId)
        .append('svg')
        .attr('width', '100%')
        .attr('height', '100%')
        .style('position', 'relative')
        .style('z-index', '10')
        .attr(
          'viewBox',
          '0 0 ' + Math.min(width, height) + ' ' + Math.min(width, height)
        )
        .attr('preserveAspectRatio', 'xMinYMin meet')
        .append('g')
        .attr(
          'transform',
          'translate(' +
            width / 2 +
            ',' +
            (height / 2 - 30 + InsightsGenderConfig.margin.top) +
            ')'
        );

      path = svg
        .datum(data)
        .selectAll('path')
        .data(pie(data))
        .enter()
        .append('path');

      path
        .attr('class', function(d) {
          return d.data.gender.toLowerCase();
        })
        .transition()
        .delay(function(d, i) {
          return i * 500;
        })
        .duration(500)
        .attrTween('d', function(d) {
          var i = d3.interpolate(d.startAngle + 0.1, d.endAngle);
          return function(t) {
            d.endAngle = i(t);
            return arc(d);
          };
        });

      // Labels
      svg.append('g').attr('class', 'labels');
      var labels = d3.select(InsightsGenderConfig.chartId + ' .labels');
      var enteringLabels = labels
        .selectAll('.label')
        .data(pie(data))
        .enter();
      var labelGroups = enteringLabels.append('g').attr('class', 'label');
      labelGroups.append('circle').attr({
        x: 0,
        y: 0,
        r: 2,
        fill: '#000',
        transform: function(d) {
          return 'translate(' + arc.centroid(d) + ')';
        },

        class: 'label-circle'
      });

      var textLines = labelGroups.append('line').attr({
        x1: function(d) {
          return arc.centroid(d)[0];
        },

        y1: function(d) {
          return arc.centroid(d)[1];
        },

        x2: function(d) {
          return arc.centroid(d)[0];
        },

        y2: function(d) {
          var centroid = arc.centroid(d);
          var midAngle = Math.atan2(centroid[1], centroid[0]);
          return Math.sin(midAngle) * labelRadius;
        },

        class: 'label-line'
      });

      var textLabels = labelGroups
        .append('text')
        .attr({
          x: function(d) {
            var centroid = arc.centroid(d);
            return centroid[0] + 3;
          },

          y: function(d) {
            var centroid = arc.centroid(d);
            var midAngle = Math.atan2(centroid[1], centroid[0]);
            return Math.sin(midAngle) * (labelRadius + 5);
          },

          'text-anchor': function(d) {
            var centroid = arc.centroid(d);
            var midAngle = Math.atan2(centroid[1], centroid[0]);
            var x = Math.cos(midAngle) * (labelRadius + 5);
            return x > 0 ? 'start' : 'end';
          },

          class: 'label-text'
        })
        .text(function(d) {
          var label = d.data.gender;
          var total = 0;
          for (var i = 0; i < data.length; i++) {
            total += parseInt(Number(data[i][typeOfData]));
          }

          var percentage =
            Math.round((parseInt(Number(d.value)) / total) * 100 * 10) / 10;
          return label + ' ' + percentage + '%';
        });

      var alpha = 0.5;
      var spacing = 12;

      function relax() {
        var again = false;
        textLabels.each(function() {
          var a = this; // jscs:ignore safeContextKeyword
          var da = d3.select(a);
          var y1 = da.attr('y');
          textLabels.each(function() {
            var b = this; // jscs:ignore safeContextKeyword

            // a & b are the same element and don't collide
            if (a === b) return;
            var db = d3.select(b);

            // a & b are on opposite sides of the chart and
            // don't collide
            if (da.attr('text-anchor') !== db.attr('text-anchor')) return;

            // Now let's calculate the distance between
            // these elements
            var y2 = db.attr('y');
            var deltaY = y1 - y2;

            // If spacing is greater than our specified spacing,
            // they don't collide
            if (Math.abs(deltaY) > spacing) return;

            // If the labels collide, we'll push each
            // of the two labels up and down a little bit.
            again = true;
            var sign = deltaY > 0 ? 1 : -1;
            var adjust = sign * alpha;
            da.attr('y', +y1 + adjust);
            db.attr('y', +y2 - adjust);
          });
        });

        // Adjust our line leaders here
        // so that they follow the labels.
        if (again) {
          var labelElements = textLabels[0];
          textLines.attr('y2', function(d, i) {
            var labelForLine = d3.select(labelElements[i]);
            return labelForLine.attr('y');
          });

          setTimeout(relax, 20);
        }
      }

      commonWebsite.hideSpinner('gender-chart');

      var switches = document.getElementsByClassName('switch');
      for (var i = 0; i < switches.length; i++) {
        switches[i].addEventListener('click', changeGender);
      }
    };

    function changeGender() {
      typeOfData = angular
        .element(document.querySelector('.switch.active'))
        .attr('value');
      var chartId = InsightsGenderConfig.chartId;
      var chart = d3.select(chartId);
      var overlay = document.querySelector(
        InsightsGenderConfig.chartId + ' .overlay'
      );
      var variable = document
        .querySelector('.variable-dropdown .ng-scope')
        .innerHTML.trim();

      // check if the variable is not state as if it is state we are showing the gender chart but not for other categories
      if (variable.length > 0 && variable != 'STATE') {
        return;
      }

      if (typeOfData === 'Impressions') {
        angular.element(overlay).removeClass('active');
        typeOfData = 'impressions';
        chart.selectAll('svg').remove();
        if (globalData) {
          readData(globalData);
        }
      } else if (typeOfData === 'Clicks') {
        typeOfData = 'clicks';
        angular.element(overlay).addClass('active');
      } else if (typeOfData === 'UA') {
        angular.element(overlay).removeClass('active');
        typeOfData = 'ua';
        chart.selectAll('svg').remove();
        if (globalData) {
          readData(globalData);
        }
      }
    }

    function roundFinalData(dataToRound) {
      // If data from today is included remove it.
      var currentIndex;
      for (var i = dataToRound.length - 1; i >= 0; i--) {
        if (new Date(dataToRound[i].Period_End) < new Date(todayDate())) {
          currentIndex = i;
          break;
        }
      }

      if (currentIndex) {
        if (currentIndex <= dataToRound.length) {
          dataToRound.splice(
            currentIndex + 1,
            dataToRound.length - currentIndex + 1
          );
        }
      }

      dataToRound = dataToRound.filter(function(d) {
        return !!('ua' in d && !isNaN(d.ua) && d.ua !== null);
      });

      dataToRound = dataToRound.map(function(d) {
        return {
          Period_Start: d.Period_Start,
          Period_End: d.Period_End,
          gender: d.gender,
          ua: Math.round(d.ua),
          impressions: Math.round(d.impressions)
        };
      });

      return dataToRound;
    }

    /**
     * Removes chart
     */
    function removeChart() {
      var chartId = InsightsGenderConfig.chartId;
      var chart = d3.select(chartId);
      chart.selectAll('svg').remove();
    }

    /**
     * Check if Data is using Period_Start or Period_End
     * @param {Array} data
     * @returns {string}
     */
    function getDatePeriodType(data) {
      if (data[0].Period_Start) {
        return 'Period_Start';
      }
      return 'Period_End';
    }

    return {
      roundData: roundFinalData,
      checkService: function(jsonData) {
        jsonData = removeNullData(jsonData);
        completeData = angular.copy(jsonData);
        globalData = angular.copy(jsonData);
        return changeGender();
      },

      resize: function() {
        removeChart();
        return readData(globalData);
      },

      clearData: function() {
        globalData = null;
      }
    };
  });
