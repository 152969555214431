(function() {
  'use strict';

  angular
    .module('websites')
    .controller('WebsitesPixelController', WebsitesPixelController);

  WebsitesPixelController.$inject = [
    '$rootScope',
    '$stateParams',
    'PixelsWebsite',
    '$http',
    'Websites',
    'commonWebsite',
    'collectLinksWebsite',
    '$location',
    'Authorization',
    'Authentication',
    '$timeout',
    'store',
    'Settings',
    '$resource',
    '$window',
    '$log',
    '$document'
  ];

  function WebsitesPixelController(
    $rootScope,
    $stateParams,
    PixelsWebsite,
    $http,
    Websites,
    commonWebsite,
    collectLinksWebsite,
    $location,
    Authorization,
    Authentication,
    $timeout,
    store,
    Settings,
    $resource,
    $window,
    $log,
    $document
  ) {
    var vm = this;
    var websiteName;
    vm.env = Authentication.getEnv();
    Authorization.authorize().then(function() {
      vm.user = store.get('client');
      vm.permissionSet = store.get('permissionSet');
      vm.companies = store.get('companies');
      vm.currentCompany = store.get('currentCompany');
    });

    Settings.checkNotification();

    vm.archive = '';
    vm.orderByField = 'pixelNum';
    $rootScope.title = 'Website Evaluation';
    vm.pixels = PixelsWebsite.query({
      websiteId: $stateParams.websiteId
    });

    vm.website = {
      id: '',
      randAdvertiserId: ''
    };
    Websites.get({
      id: $stateParams.websiteId
    }).$promise.then(function(result) {
      vm.website = result;
      websiteName = vm.website.name ? vm.website.name : '';
    });

    vm.websites = Websites.query();
    vm.toggleArchiveFilter = toggleArchiveFilter;
    vm.archiveInsight = archiveInsight;
    vm.copyUrl = copyUrl;
    vm.collectPixelLinks = collectPixelLinks;
    vm.getPixelUrl = getPixelUrl;
    vm.activateInsight = activateInsight;
    vm.toggleWebsiteCaret = toggleWebsiteCaret;
    vm.nameEdit = false;
    vm.updateName = updateName;
    vm.showNewPixel = false;

    function toggleArchiveFilter() {
      if (vm.archive === '') {
        vm.archive = 'Active';
      } else {
        vm.archive = '';
      }
    }

    function toggleWebsiteCaret() {
      var myEl = angular.element($document[0].querySelector('#website-caret'));
      if (myEl.hasClass('fa-caret-down')) {
        myEl.removeClass('fa-caret-down');
        myEl.addClass('fa-caret-up');
      } else {
        myEl.addClass('fa-caret-down');
        myEl.removeClass('fa-caret-up');
      }
    }

    // Archive Website
    function archiveInsight() {
      var website = vm.website;

      Websites.update(
        {
          id: website.id
        },
        {
          isArchived: true
        },
        function(result) {
          website.status = result.status;

          $http.post(
            '/api/pixels/logevents',
            JSON.stringify({
              action: 'Pixel archived',
              dataString: website.id.toString()
            })
          );
          $log.log(result);
        },
        function(errorResponse) {
          vm.error = errorResponse.data.errors[0].message;
        }
      );
    }

    // Activate Website
    function activateInsight() {
      var website = vm.website;

      Websites.update(
        {
          id: website.id
        },
        {
          isArchived: false
        },
        function(result) {
          website.status = result.status;
          $log.log(result);
        },
        function(errorResponse) {
          vm.error = errorResponse.data.errors[0].message;
        }
      );
    }

    function copyUrl() {
      var copyTextArea = $document[0].querySelector('#url');
      copyTextArea.select();

      try {
        $document[0].execCommand('copy');
        $document[0].getSelection().removeAllRanges();
      } catch (err) {
        // eslint-disable-next-line no-alert
        $window.prompt('Copy to clipboard: Ctrl+C', copyTextArea.value);
      }
    }

    function collectPixelLinks() {
      var pixelUrl = getPixelUrl(vm.website);
      var username = store.get('client').userName;
      $log.log(vm.website.websiteUrl);
      $log.log(pixelUrl);
      $log.log(username);
      collectLinksWebsite.collectLinks(
        {
          website: vm.website.websiteUrl,
          pixel: pixelUrl,
          username: username
        },
        function(result) {
          showInfoMessage(result.message);
        },
        function(errorResponse) {
          vm.error = errorResponse.data.message;
        }
      );
    }

    /**
     * Create Website Pixel URL
     * @param {Object} website
     * @param {Object} [pixel]
     * @returns {string}
     */
    function getPixelUrl(website, pixel) {
      var host;
      if (vm.env === 'production') {
        host = 'https://pixel.roymorgan.com';
      } else {
        host = 'https://pixel.roymorganuat.com';
      }

      var url =
        host +
        '/stats_v2/Tress.php?u=' +
        commonWebsite.getUID() +
        '&ca=' +
        website.id +
        '&a=' +
        website.randAdvertiserId;

      return url;
    }

    function showInfoMessage(message) {
      vm.info = message;
      $timeout(function() {
        vm.info = null;
      }, 5000);
    }

    function updateName(newName) {
      var website = vm.website;

      if (newName !== websiteName) {
        Websites.update(
          {
            id: website.id
          },
          {
            name: website.name
          },
          function(result) {
            websiteName = result.name;
          },
          function(errorResponse) {
            $log.error(errorResponse.data.errors[0].message);
            vm.error = errorResponse.data.errors[0].message;
          }
        );
      }
    }

    vm.hasWritePermission = function() {
      return Authorization.hasPermission(['Websites']);
    };
  }
})();
