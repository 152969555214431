'use strict';

// Databases service used for communicating with the articles REST endpoints
angular.module('upload').factory('Documents', [
  '$resource',
  function($resource) {
    return $resource(
      'api/upload/documents/:document',
      {
        document: '@document'
      },
      {
        remove: {
          method: 'DELETE',
          params: { document: '@document' }
        },
        exists: {
          method: 'GET',
          params: { document: '@document' },
          url: 'api/upload/documents/exists/:document'
        }
      }
    );
  }
]);
