'use strict';

// Websites service used to communicate Websites REST endpoints
angular.module('websites').factory('collectLinksWebsite', [
  '$resource',
  function($resource) {
    return $resource(
      '/api/websites/collect-links',
      {},
      {
        collectLinks: {
          method: 'POST'
        }
      }
    );
  }
]);
