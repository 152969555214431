(function() {
  'use strict';

  // Common Team functions
  angular.module('users').factory('Teams', Teams);

  Teams.$inject = ['store', '$http', '$q'];

  function Teams(store, $http, $q) {
    return {
      getTeams: getTeams,
      getCurrentTeam: getCurrentTeam,
      getUserManagementUrl: getUserManagementUrl,
      getTeamName: getTeamName,
      getTeamsForTopHeader: getTeamsForTopHeader
    };

    function getTeams() {
      return store.get('teams');
    }

    function getTeamsForTopHeader(user, permissionSet, currentCompany) {
      return angular.copy(getTeams(user, permissionSet, currentCompany));
    }

    function getCurrentTeam(teams) {
      var currentTeam = store.get('currentTeam');
      if (currentTeam) {
        return currentTeam;
      }
      return getDefaultTeam(teams);
    }

    function getDefaultTeam(teams) {
      if (teams && teams.length) {
        return teams[0];
      }
      return false;
    }

    function getUserManagementUrl() {
      switch (document.location.hostname) {
        case 'localhost':
        case 'audiences.roymorgandev.com':
          return 'https://liveapi.roymorgandev.com/management/v2';
        case 'audiences.roymorganuat.com':
          return 'https://liveapi.roymorganuat.com/management/v2';
        default:
          return 'https://liveapi.roymorgan.com/management/v2';
      }
    }

    function getTeamName(teams, teamId) {
      return $q(function(resolve) {
        teams.forEach(function(team) {
          if (team.id === parseInt(teamId, 10)) {
            resolve(team.name);
          }
        });
        resolve(null);
      });
    }
  }
})();
