(function() {
  'use strict';

  angular
    .module('insights')
    .factory('getAusStatePopulation', getAusStatePopulation);

  getAusStatePopulation.$inject = ['$http', '$q', '$log'];

  function getAusStatePopulation($http, $q, $log) {
    console.log('7777777777');
    return {
      get: get
    };

    /**
     * get the Australia population from BQ
     * @returns {Promise<object>}
     */
    function get(state) {
      console.log('8888888888888888');
      console.log(state);
      return $q(function(resolve, reject) {
        $http
          .get('/api/insights/getAusStatePopulation', {
            params: {
              state: state
            }
          })
          .then(function(result) {
            console.log('999999999999');
            console.log(result.data);
            resolve(result.data);
          })
          .catch(function(reason) {
            console.log('10101011');
            $log.error('Error: ', reason);
            reject(reason);
          });
      });
    }
  }
})();
