'use strict';

// Add token to all http requests
angular
  .module('users')
  .factory('permissionsInterceptor', [
    'Authentication',
    'store',
    function(Authentication, store) {
      return {
        request: function(config) {
          var permissions = store.get('permissions');
          var currentTeam = store.get('currentTeam');

          if (permissions) {
            config.headers.permissions = angular.toJson(permissions);
          }
          if (currentTeam) {
            // NEW API
            // config.headers.TeamId = currentTeam.teamId;
            config.headers.TeamId = currentTeam.id;
          }

          return config;
        }
      };
    }
  ])
  .config(function($httpProvider) {
    $httpProvider.interceptors.push('permissionsInterceptor');
  });
