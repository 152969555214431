'use strict';

angular.module('insights').factory('InsightsBrowserConfig', [
  function() {
    return {
      margin: {
        top: 30,
        right: 100,
        bottom: 50,
        left: 100
      },
      colors: d3.scale.ordinal().range(['#00AEC7', '#456E7E', '#6699CC']),
      outerRadius: 10,
      innerRadius: 70,
      width: function() {
        return d3
          .select(this.chartId)
          .node()
          .getBoundingClientRect().width;
      },

      actualWidth: function() {
        return parseInt(d3.select(this.chartId).style('width'), 10);
      },

      widthPercent: function() {
        return (
          (this.width() - this.margin.left - this.margin.right) /
          this.width() *
          100
        );
      },

      chartId: '#browser-chart',
      height: 420,
      barHeight: 30,
      spacing: 3,
      percent: function() {
        return d3.format('%');
      }
    };
  }
]);
