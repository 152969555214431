'use strict';

// Pixel Modal Controller
angular
  .module('campaigns')
  .controller('bulkPixelModalCtrl', function(
    $scope,
    $uibModalInstance,
    Upload,
    $timeout,
    pixels,
    campaignId,
    spinnerService
  ) {
    $scope.ok = function() {
      $uibModalInstance.close(pixels);
    };

    $scope.cancel = function() {
      $uibModalInstance.dismiss('cancel');
    };

    $scope.uploadFiles = function(file) {
      $scope.f = file;

      $scope.upload = function() {
        spinnerService.show('uploadSpinner');
        if (file && !file.$error) {
          file.upload = Upload.upload({
            url: '/api/pixels/campaignUpload',
            file: file,
            fields: {
              campaignId: campaignId
            }
          });

          file.upload.then(
            function(response) {
              $timeout(function() {
                file.result = response.data;
                $scope.success = response.message;
                response.data.forEach(function(pixel) {
                  pixels.push(pixel);
                });

                spinnerService.hide('uploadSpinner');
              });
            },
            function(response) {
              if (response.status > 0) {
                $scope.errorMsg = response.status + ': ' + response.data;
              }
            }
          );

          file.upload.progress(function(evt) {
            file.progress = Math.min(
              100,
              parseInt(100.0 * evt.loaded / evt.total)
            );
          });
        }
      };
    };
  });
