'use strict';

// Add token to all http requests
angular
  .module('users')
  .factory('tokenInterceptor', [
    'Authentication',
    '$location',
    function(Authentication, $location) {
      return {
        request: function(config) {
          config.headers.Authorization = Authentication.getValidToken(); // auth for Pixel MeanJS API
          config.headers.token = config.headers.Authorization; // auth for Live Audiences API

          if (
            config.url.indexOf('http') === 0 &&
            config.url.indexOf('//') > -1
          ) {
            var startUrlUpper = angular.uppercase(
              $location.protocol() +
                '://' +
                $location.host() +
                ':' +
                $location.port()
            );

            var urlUpper = angular.uppercase(config.url);
            if (urlUpper.indexOf(startUrlUpper) !== 0) {
              config.headers.Authorization = undefined;
            }
          }
          return config;
        }
      };
    }
  ])

  .config(function($httpProvider) {
    $httpProvider.interceptors.push('tokenInterceptor');
  });
