(function() {
  'use strict';

  angular.module('campaigns').factory('areaChartCampaign', areaChartCampaign);

  areaChartCampaign.$inject = [
    '$http',
    'commonCampaign',
    'InsightsArea',
    '$q',
    '$log',
    'downloads'
  ];

  function areaChartCampaign(
    $http,
    commonCampaign,
    InsightsArea,
    $q,
    $log,
    downloads
  ) {
    return {
      chart: chart,
      resize: resize,
      changeDate: changeDate,
      getData: getData
    };

    /**
     * Area Chart based on date period
     * @param {string} period
     * @param {string} campaignId
     * @param {Object} startEnd - Insight start and end dates
     * @param {string} chartType - area, state or postcode.
     * @param {string} typeOfData - ua, impressions or clicks.
     * @param {Object=} variable
     */
    function chart(
      period,
      campaignId,
      startEnd,
      chartType,
      typeOfData,
      variable
    ) {
      var companyUID = commonCampaign.getUID();
      if (period === 'all') {
        allChart(
          campaignId,
          companyUID,
          startEnd.end,
          chartType,
          typeOfData,
          variable
        );
      } else if (period === 'month') {
        monthChart(
          campaignId,
          companyUID,
          startEnd.end,
          chartType,
          typeOfData,
          variable
        );
      } else if (period === 'week') {
        weekChart(
          campaignId,
          companyUID,
          startEnd.end,
          chartType,
          typeOfData,
          variable
        );
      } else if (period === 'day') {
        dayChart(
          campaignId,
          companyUID,
          startEnd.end,
          chartType,
          typeOfData,
          variable
        );
      }
    }

    /**
     * All Time Area Chart
     * @param {string} campaignId
     * @param {?string} endDate - Campaign End Date YYYY-MM-DD
     * @param {string} chartType - area, state or postcode.
     * @param {string} typeOfData - ua, impressions or clicks.
     * @param {Object=} variable
     */
    function allChart(
      campaignId,
      companyUID,
      endDate,
      chartType,
      typeOfData,
      variable
    ) {
      var yesterday = commonCampaign.formatEndDate();

      $http
        .post('/api/insights/campaign', {
          campaign: campaignId,
          companyUID: companyUID,
          endDate: endDate || yesterday,
          chart: chartType,
          period: 'all',
          type: 'campaign',
          variable: variable,
          focus: typeOfData
        })
        .then(
          function(result) {
            if (result.data.insights.length > 0) {
              var insights = result.data.insights;

              InsightsArea.checkService(insights, chartType);
            } else {
              commonCampaign.hideSpinner('area-chart', 'showNoDataMsg');
            }
          },
          function(reason) {
            commonCampaign.hideSpinner('area-chart', 'showNoDataMsg');
            $log.error('Error: campaign-area-all');
            $log.error(reason);
          }
        );
    }

    /**
     * Month Area Chart
     * @param {string} campaignId
     * @param {?string} endDate - Campaign End Date YYYY-MM-DD
     * @param {string} chartType - area, state or postcode.
     * @param {string} typeOfData - ua, impressions or clicks.
     * @param {Object=} variable
     */
    function monthChart(
      campaignId,
      companyUID,
      endDate,
      chartType,
      typeOfData,
      variable
    ) {
      var startOfMonth = moment(endDate || undefined)
        .startOf('month')
        .format('YYYY-MM-DD');

      $http
        .post('/api/insights/campaign', {
          campaign: campaignId,
          companyUID: companyUID,
          endDate: startOfMonth,
          chart: chartType,
          period: 'month',
          type: 'campaign',
          variable: variable,
          focus: typeOfData
        })
        .then(
          function(result) {
            if (result.data.insights.length > 0) {
              var insights = result.data.insights;

              InsightsArea.checkService(insights, chartType);
            } else {
              commonCampaign.hideSpinner('area-chart', 'showNoDataMsg');
            }
          },
          function(reason) {
            commonCampaign.hideSpinner('area-chart', 'showNoDataMsg');
            $log.error('Error: campaign-gender-month');
            $log.error(reason);
          }
        );
    }

    /**
     * Week Area Chart
     * @param {string} campaignId
     * @param {?string} endDate - Campaign End Date YYYY-MM-DD
     * @param {string} chartType - area, state or postcode.
     * @param {string} typeOfData - ua, impressions or clicks.
     * @param {Object=} variable
     */
    function weekChart(
      campaignId,
      companyUID,
      endDate,
      chartType,
      typeOfData,
      variable
    ) {
      var startOfWeek = moment(endDate || undefined)
        .startOf('isoWeek')
        .format('YYYY-MM-DD');

      $http
        .post('/api/insights/campaign', {
          campaign: campaignId,
          companyUID: companyUID,
          endDate: startOfWeek,
          chart: chartType,
          period: 'week',
          type: 'campaign',
          variable: variable,
          focus: typeOfData
        })
        .then(
          function(result) {
            if (result.data.insights.length > 0) {
              var insights = result.data.insights;

              InsightsArea.checkService(insights, chartType);
            } else {
              commonCampaign.hideSpinner('area-chart', 'showNoDataMsg');
            }
          },
          function(reason) {
            commonCampaign.hideSpinner('area-chart', 'showNoDataMsg');
            $log.error('Error: campaign-gender-week');
            $log.error(reason);
          }
        );
    }

    /**
     * Day Area Chart
     * @param {string} campaignId
     * @param {?string} endDate - Campaign End Date YYYY-MM-DD
     * @param {string} chartType - area, state or postcode.
     * @param {string} typeOfData - ua, impressions or clicks.
     * @param {Object=} variable
     */
    function dayChart(
      campaignId,
      companyUID,
      endDate,
      chartType,
      typeOfData,
      variable
    ) {
      var yesterday = commonCampaign.formatEndDate();

      $http
        .post('/api/insights/campaign', {
          campaign: campaignId,
          companyUID: companyUID,
          endDate: endDate || yesterday,
          chart: chartType,
          period: 'day',
          type: 'campaign',
          variable: variable,
          focus: typeOfData
        })
        .then(
          function(result) {
            if (result.data.insights.length > 0) {
              var insights = result.data.insights;

              InsightsArea.checkService(insights, chartType);
            } else {
              commonCampaign.hideSpinner('area-chart', 'showNoDataMsg');
            }
          },
          function(reason) {
            commonCampaign.hideSpinner('area-chart', 'showNoDataMsg');
            $log.error('Error: campaign-gender-day');
            $log.error(reason);
          }
        );
    }

    /**
     * Resize chart
     */
    function resize() {
      InsightsArea.resize();
    }

    /**
     * Change Date
     * @param {string} campaignId
     * @param {string} period - Date period
     * @param {Object} startEnd - Insight start and end dates
     * @param {string} chartType - area, state or postcode.
     * @param {string} typeOfData - ua, impressions or clicks.
     * @param {Object=} variable
     */
    function changeDate(
      campaignId,
      period,
      startEnd,
      chartType,
      typeOfData,
      variable
    ) {
      var companyUID = commonCampaign.getUID();
      if (period === 'month') {
        monthChart(
          campaignId,
          companyUID,
          startEnd.end,
          chartType,
          typeOfData,
          variable
        );
      } else if (period === 'week') {
        weekChart(
          campaignId,
          companyUID,
          startEnd.end,
          chartType,
          typeOfData,
          variable
        );
      } else if (period === 'day') {
        dayChart(
          campaignId,
          companyUID,
          startEnd.end,
          chartType,
          typeOfData,
          variable
        );
      }
    }

    /**
     * Which data to download
     * @param {string} campaignId
     * @param {string} period - Date period
     * @param {Object} startEnd - Insight start and end dates
     * @param {string} chartType - area, state or postcode.
     * @param {Object=} variable
     * @param {string} [typeOfData] - ua, impressions, clicks, population.
     * @returns {Promise} Promise
     */
    function getData(
      campaignId,
      period,
      startEnd,
      chartType,
      variable,
      typeOfData
    ) {
      var companyUID = commonCampaign.getUID();
      if (period === 'day') {
        return getDataDay(
          campaignId,
          companyUID,
          startEnd.end,
          chartType,
          typeOfData,
          variable
        );
      }
      if (period === 'week') {
        return getDataWeek(
          campaignId,
          companyUID,
          startEnd.end,
          chartType,
          typeOfData,
          variable
        );
      }
      if (period === 'all') {
        return getDataAll(
          campaignId,
          companyUID,
          startEnd.end,
          chartType,
          typeOfData,
          variable
        );
      }
      return getDataMonth(
        campaignId,
        companyUID,
        startEnd.end,
        chartType,
        typeOfData,
        variable
      );
    }

    /**
     * Get Day Data for Download
     * @param {string} campaignId
     * @param {?string} endDate - Campaign End Date YYYY-MM-DD
     * @param {string} chartType - area, state or postcode.
     * @param {?string} typeOfData - ua, impressions, clicks or population.
     * @param {Object=} variable
     * @returns {Promise} Promise
     */
    function getDataDay(
      campaignId,
      companyUID,
      endDate,
      chartType,
      typeOfData,
      variable
    ) {
      var yesterday = commonCampaign.formatEndDate();
      return $q(function(resolve, reject) {
        $http
          .post('/api/insights/campaign', {
            campaign: campaignId,
            companyUID: companyUID,
            endDate: endDate || yesterday,
            chart: chartType,
            period: 'day',
            type: 'campaign',
            variable: variable,
            focus: typeOfData === 'population' ? 'ua' : typeOfData
          })
          .then(
            function(result) {
              InsightsArea.roundData(result.data.insights, chartType).then(
                function(res) {
                  var data = formatDownloads(
                    res,
                    'Date',
                    endDate || yesterday,
                    chartType,
                    typeOfData
                  );
                  resolve(data);
                }
              );
            },
            function(reason) {
              $log.error('Error: campaign-gender-data-day');
              $log.error(reason);
              reject();
            }
          );
      });
    }

    /**
     * Get all Data for Download
     * @param {string} campaignId
     * @param {?string} endDate - Campaign End Date YYYY-MM-DD
     * @param {string} chartType - area, state or postcode.
     * @param {?string} typeOfData - ua, impressions, clicks or population.
     * @param {Object=} variable
     * @returns {Promise} Promise
     */
    function getDataAll(
      campaignId,
      companyUID,
      endDate,
      chartType,
      typeOfData,
      variable
    ) {
      var yesterday = commonCampaign.formatEndDate();
      return $q(function(resolve, reject) {
        $http
          .post('/api/insights/campaign', {
            campaign: campaignId,
            companyUID: companyUID,
            endDate: endDate || yesterday,
            chart: chartType,
            period: 'all',
            type: 'campaign',
            variable: variable,
            focus: typeOfData === 'population' ? 'ua' : typeOfData
          })
          .then(
            function(result) {
              InsightsArea.roundData(result.data.insights, chartType).then(
                function(res) {
                  var data = formatDownloads(
                    res,
                    'Period_End_Date',
                    endDate || yesterday,
                    chartType,
                    typeOfData
                  );
                  resolve(data);
                }
              );
            },
            function(reason) {
              $log.error('Error: campaign-gender-data-day');
              $log.error(reason);
              reject();
            }
          );
      });
    }

    /**
     * Get Week Data for Download
     * @param {string} campaignId
     * @param {?string} endDate - Campaign End Date YYYY-MM-DD
     * @param {string} chartType - area, state or postcode.
     * @param {?string} typeOfData - ua, impressions, clicks or population.
     * @param {Object=} variable
     * @returns {Promise} Promise
     */
    function getDataWeek(
      campaignId,
      companyUID,
      endDate,
      chartType,
      typeOfData,
      variable
    ) {
      var startOfWeek = moment(endDate || undefined)
        .startOf('isoWeek')
        .format('YYYY-MM-DD');
      return $q(function(resolve, reject) {
        $http
          .post('/api/insights/campaign', {
            campaign: campaignId,
            companyUID: companyUID,
            endDate: startOfWeek,
            chart: chartType,
            period: 'week',
            type: 'campaign',
            variable: variable,
            focus: typeOfData === 'population' ? 'ua' : typeOfData
          })
          .then(
            function(result) {
              InsightsArea.roundData(result.data.insights, chartType).then(
                function(res) {
                  var data = formatDownloads(
                    res,
                    'Start_Date_of_Week',
                    startOfWeek,
                    chartType,
                    typeOfData
                  );
                  resolve(data);
                }
              );
            },
            function(reason) {
              $log.error('Error: campaign-gender-data-week');
              $log.error(reason);
              reject();
            }
          );
      });
    }

    /**
     * Get Month Data for Download
     * @param {string} campaignId
     * @param {?string} endDate - Campaign End Date YYYY-MM-DD
     * @param {string} chartType - area, state or postcode.
     * @param {?string} typeOfData - ua, impressions, clicks or population.
     * @param {Object=} variable
     * @returns {Promise} Promise
     */
    function getDataMonth(
      campaignId,
      companyUID,
      endDate,
      chartType,
      typeOfData,
      variable
    ) {
      var startOfMonth = moment(endDate || undefined)
        .startOf('month')
        .format('YYYY-MM-DD');
      return $q(function(resolve, reject) {
        $http
          .post('/api/insights/campaign', {
            campaign: campaignId,
            companyUID: companyUID,
            endDate: startOfMonth,
            chart: chartType,
            period: 'month',
            type: 'campaign',
            variable: variable,
            focus: typeOfData === 'population' ? 'ua' : typeOfData
          })
          .then(
            function(result) {
              InsightsArea.roundData(result.data.insights, chartType).then(
                function(res) {
                  var data = formatDownloads(
                    res,
                    'Start_Date_of_Month',
                    startOfMonth,
                    chartType,
                    typeOfData
                  );
                  resolve(data);
                }
              );
            },
            function(reason) {
              $log.error('Error: campaign-gender-data-month');
              $log.error(reason);
              reject();
            }
          );
      });
    }

    /**
     * Format Download data
     * @param {Object[]} rawData
     * @param {string} dateHeading
     * @param {string} date
     * @param {string} chartType
     * @param {?string} typeOfData - ua, impressions, clicks or population.
     * @returns {Object[]}
     */
    function formatDownloads(
      rawData,
      dateHeading,
      date,
      chartType,
      typeOfData
    ) {
      var data = downloads.addHeading(rawData, dateHeading, date);
      data = downloads.renameHeading(data, 'impressions', 'Impressions');
      data = downloads.renameHeading(data, 'clicks', 'Clicks');
      if (chartType === 'state') {
        data = downloads.renameHeading(data, 'Area', 'State');
      }
      if (chartType === 'postcode') {
        data = downloads.renameHeading(data, 'Area', 'Postcode');

        if (typeOfData === 'ua') {
          data = data.map(function(d) {
            d.UA = Number(d.UA || 0);
            return d;
          });
        }
        if (typeOfData === 'impressions') {
          data = data.map(function(d) {
            d.Impressions = Number(d.Impressions || 0);
            return d;
          });
        }
        if (typeOfData === 'clicks') {
          data = data.map(function(d) {
            d.Clicks = Number(d.Clicks || 0);
            return d;
          });
        }
      }

      var headingOrder = [];
      if (chartType === 'area') {
        headingOrder = [
          dateHeading,
          'Area',
          'UA',
          '% of Area Reached',
          'Impressions',
          'Clicks'
        ];
      } else if (chartType === 'state') {
        if (typeOfData !== 'population') {
          headingOrder = [
            dateHeading,
            'State',
            'UA',
            '% of Area Reached',
            'Impressions',
            'Clicks'
          ];
        } else {
          headingOrder = ['State', '% of Area Reached'];
        }
      } else if (chartType === 'postcode') {
        headingOrder = ['Postcode'];
        if (typeOfData === 'ua') {
          headingOrder.push('UA');
        } else if (typeOfData === 'impressions') {
          headingOrder.push('Impressions');
        } else if (typeOfData === 'clicks') {
          headingOrder.push('Clicks');
        } else if (typeOfData === 'population') {
          headingOrder.push('% of Area Reached');
        }
      }
      data = downloads.orderFields(data, headingOrder);

      return data;
    }
  }
})();
