'use strict';

// https://github.com/danialfarid/ng-file-upload
angular.module('upload').directive('rmrs3directuploader', [
  'Upload',
  '$timeout',
  function(Upload, $timeout) {
    return {
      restrict: 'E',
      templateUrl:
        'modules/upload/templates/rmrs3directuploader.client.template.html',
      scope: {
        onSuccess: '&',
        onError: '&',
        onBefore: '&',
        onProgress: '&',
        getUrl: '&',
        mimeType: '@',
        minFileSize: '@'
      },
      link: function(scope, element, attrs) {
        scope.uploadbarvisible = false;
        scope.uploadfileitem = null;

        scope.IsVisible = function() {
          return scope.uploadbarvisible;
        };

        scope.uploadFiles = function(files) {
          console.log('we are here');
          if (files && files.length) {
            console.log(files.length);
            for (var i = 0; i < files.length; i++) {
              var file = files[i];
              if (file) {
                scope.uploadfileitem = file;
                if (file.size < scope.minFileSize * 1000000) {
                  if (
                    !confirm(
                      'File size is under ' +
                        scope.minFileSize +
                        'MB.  Please make sure it is a valid database and click OK to continue.'
                    )
                  ) {
                    return;
                  }
                }
                var filename = file.name;
                console.log('filename');
                var onBeforeUploadFileFunction = scope.onBefore({
                  fileItem: scope.uploadfileitem
                });
                onBeforeUploadFileFunction.then(
                  function(data) {
                    var signedUrlGetter = scope.getUrl({
                      fileItem: scope.uploadfileitem
                    });
                    signedUrlGetter.then(
                      function(data) {
                        var signedUrl = data;
                        console.log('signed Url: ' + signedUrl);
                        scope.uploadprogress = 0;
                        scope.uploadbarvisible = true;
                        var upload = Upload.http({
                          url: signedUrl,
                          data: file,
                          headers: {
                            'Content-Type': 'application/octet-stream'
                          },
                          method: 'PUT'
                        });
                        upload.then(
                          function(resp) {
                            scope.onSuccess({
                              fileItem: scope.uploadfileitem,
                              resp: resp
                            });
                            $timeout(function() {
                              scope.uploadbarvisible = false;
                            }, 1000);

                            // file is uploaded successfully
                            console.log(
                              'file ' +
                                resp.config.data +
                                'is uploaded successfully. Response: ' +
                                resp.data
                            );
                          },
                          function(resp) {
                            console.log('upload not onSuccess');
                            console.log(resp);
                            if (resp.data === null) {
                              resp.data = {
                                message:
                                  'something went wrong, please try again'
                              };
                            }
                            scope.onError({
                              fileItem: scope.uploadfileitem,
                              resp: resp
                            });
                            scope.uploadbarvisible = false;
                          },
                          function(evt) {
                            scope.onProgress({
                              fileItem: scope.uploadfileitem,
                              evt: evt
                            });
                            // progress notify
                            scope.uploadprogress = parseInt(
                              (100.0 * evt.loaded) / evt.total,
                              10
                            );
                            // console.log('progress: ' + parseInt(100.0 * evt.loaded / evt.total) + '% file :'+ evt.config.data.file.name);
                          }
                        );
                      },
                      function(err) {
                        console.log('error for getting signed url');
                        scope.onError({
                          fileItem: scope.uploadfileitem,
                          resp: err
                        });
                      }
                    );
                  },
                  function(err) {
                    console.log('error with before upload');
                    scope.onError({
                      fileItem: scope.uploadfileitem,
                      resp: err
                    });
                  }
                );
              }
            }
          }
        };
      },
      controller: function($scope, $element, $attrs, $transclude) {
        console.log($element);
        console.log($attrs);
      }
    };
  }
]);
