(function() {
  'use strict';

  // Campaigns controller
  angular
    .module('campaigns')
    .controller('CampaignsController', CampaignsController);

  CampaignsController.$inject = [
    '$location',
    'Campaigns',
    '$rootScope',
    'Authorization',
    'Settings',
    'store',
    'spinnerService',
    '$window',
    'listingTotalsCampaign',
    '$http'
  ];

  function CampaignsController(
    $location,
    Campaigns,
    $rootScope,
    Authorization,
    Settings,
    store,
    spinnerService,
    $window,
    listingTotalsCampaign,
    $http
  ) {
    var vm = this;
    Authorization.authorize().then(function() {
      vm.user = store.get('client');
      vm.permissions = Authorization.permissions();
      vm.permissionSet = Authorization.permissionSet();
      vm.currentCompany = store.get('currentCompany');
    });

    Settings.checkNotification();

    $rootScope.title = 'Campaign Evaluation';
    getCampaigns();
    vm.toggleArchiveFilter = toggleArchiveFilter;
    vm.showNewCampaign = false;
    vm.archive = 'Active';
    vm.orderByField = 'dateCreated';
    vm.reverseSort = true;
    vm.create = create;
    var today = new Date();
    vm.campaign = {
      startDate: today
    };
    vm.dateOptions = {
      formatYear: 'yy',
      startingDay: 1
    };
    vm.formats = [
      'dd-MMMM-yyyy',
      'yyyy/MM/dd',
      'dd.MM.yyyy',
      'shortDate',
      'dd MMM yyyy'
    ];
    vm.format = vm.formats[0];
    vm.toggleMin = toggleMin;
    vm.endopen = endopen;
    vm.startopen = startopen;
    vm.noToShowOptions = [
      {
        name: 10,
        value: 10
      },
      {
        name: 20,
        value: 20
      },
      {
        name: 'All',
        value: 0
      }
    ];
    vm.noToShow = vm.noToShowOptions[0].value;
    vm.currentPage = 1;

    // Create new Campaign
    function create() {
      vm.processing = true;
      var spinner = document.getElementById('create-spinner-button');
      spinner.classList.remove('hidden');

      // Create new Campaign object
      var campaign = new Campaigns({
        name: vm.campaign.name.toUpperCase(),
        advertiserName: vm.campaign.advertiserName.toUpperCase(),
        startDate: vm.campaign.startDate,
        endDate: vm.campaign.endDate
        //   team_id: vm.campaignTeam.teamId ToDo: Check if this is needed
      });

      // Redirect after save
      campaign.$save(
        function(response) {
          if (response.id) {
            //  log add new pixel events
            $http.post(
              '/api/pixels/logevents',
              JSON.stringify({
                action: 'Pixel created',
                dataString: response.id.toString()
              })
            );

            Campaigns.get({ id: response.id }).$promise.then(function(
              addedCampaign
            ) {
              angular.merge(addedCampaign, {
                ua: -1,
                imp: -1,
                clicks: -1,
                latestData: -1
              }); // remove spinning wheels
              vm.campaigns.push(addedCampaign);
            });

            // Clear form fields
            vm.campaign.name = '';
            vm.campaign.advertiserName = '';

            vm.showNewCampaign = false;
            spinner.className += ' hidden';
            vm.processing = false;
          }
        },
        function(errorResponse) {
          vm.processing = false;
          spinner.className += ' hidden';
          vm.error = errorResponse.data.errors[0].message;
        }
      );
    }

    function toggleMin() {
      vm.minDate = new Date();
    }

    vm.toggleMin();

    function endopen($event) {
      $event.preventDefault();
      $event.stopPropagation();

      vm.endOpened = true;
    }

    function startopen($event) {
      $event.preventDefault();
      $event.stopPropagation();

      vm.startOpened = true;
    }

    // Toggle Archived
    function toggleArchiveFilter() {
      if (vm.archive === '') {
        vm.archive = 'Active';
      } else {
        vm.archive = '';
      }
    }

    vm.hasWritePermission = function() {
      return Authorization.hasPermission(['Campaigns']);
    };
    vm.registerSpinner = function(spinnerName) {
      spinnerService.showGroup(spinnerName);
    };

    function getCampaigns() {
      vm.campaigns = [];
      Campaigns.query(function(campaigns) {
        vm.campaigns = campaigns;
        if (vm.campaigns && vm.campaigns.length > 0) {
          vm.campaigns.forEach(function(campaign) {
            campaign.ua = 0;
            campaign.imp = 0;
            campaign.clicks = 0;
            campaign.latestData = '';
            var startEnd = {
              start: moment(campaign.startDate).format('YYYY-MM-DD'),
              end: campaign.endDate
                ? moment(campaign.endDate).format('YYYY-MM-DD')
                : moment().format('YYYY-MM-DD')
            };
            listingTotalsCampaign
              .total(campaign.id, startEnd.start, startEnd.end)
              .then(function(result) {
                if (result) {
                  var campaignData = calculateTotals(campaign.id, result);
                  // check for NaN data
                  if (isNaN(campaignData.ua)) {
                    campaignData.ua = -1;
                  }
                  if (isNaN(campaignData.imp)) {
                    campaignData.imp = -1;
                  }
                  if (isNaN(campaignData.clicks)) {
                    campaignData.clicks = -1;
                  }
                  angular.merge(campaign, campaignData);
                } else {
                  // set to -1 if the data is not there as if we use ) it is bypassing the spinner condition and spinner keeps on working. AND if we are using undefined then sorting was not working
                  angular.merge(campaign, {
                    ua: -1,
                    imp: -1,
                    clicks: -1,
                    latestData: -1
                  });
                }
              })
              .catch(function(reason) {
                angular.merge(campaign, {
                  ua: -1,
                  imp: -1,
                  clicks: -1,
                  latestData: -1
                });
              });
          }); // end of foreach
        }
      });
    }

    /**
     * Calculate Totals for Information Panel
     * @param {string} campaignId
     * @param {Object} totals
     */
    function calculateTotals(campaignId, totals) {
      var ua = parseInt(totals.UA, 10);
      var impressions = parseInt(totals.impressions, 10);
      var clicks = parseInt(totals.clicks, 10);
      var endDate = totals.Period_End;

      // set to -1 if the data is not there as if we use ) it is bypassing the spinner condition and spinner keeps on working. AND if we are using undefined then sorting was not working
      if (ua === 0) {
        ua = -1;
      } else if (impressions === 0) {
        impressions = -1;
      } else if (clicks === 0) {
        clicks = -1;
      }

      if (!endDate) {
        endDate = -1;
      }

      return {
        id: campaignId,
        ua: ua,
        imp: impressions,
        clicks: clicks,
        latestData: endDate
      };
    }

    /**
     * Split string off numbers into 111,000 from 111000
     * @param {number} x - Number to split.
     * @param {string} [sep] - Separator.
     * @param {number} [grp] - Number to group by, must be an integer.
     * @return {string}
     */
    function localeString(x, sep, grp) {
      var sx = ('' + x).split('.');
      var s = '';
      var i;
      var j;

      var defaultSep = sep || (defaultSep = ','); // default separator
      var defaultGrp = grp || grp === 0 || (defaultGrp = 3); // default grouping
      i = sx[0].length;
      while (i > defaultGrp) {
        j = i - defaultGrp;
        s = defaultSep + sx[0].slice(j, i) + s;
        i = j;
      }

      s = sx[0].slice(0, i) + s;
      sx[0] = s;
      return sx.join('.');
    }
    vm.localeString = localeString;

    // redirect to campaign details
    vm.redirectToCampaign = function(loc) {
      $window.location.href = loc;
    };

    // get the current page
    vm.getCurrentPageNumber = function(newPageNumber) {
      vm.currentPage = newPageNumber;
    };
  }
})();
