'use strict';

// Websites service used to communicate Websites REST endpoints
angular.module('websites').factory('Websites', [
  'AudiencesApi',
  function(audiencesApi) {
    return audiencesApi.resource(
      '/websites/:id',
      {
        id: '@id'
      },
      {
        update: {
          method: 'PUT'
        },
        allTeams: {
          method: 'GET',
          isArray: true,
          params: { allTeams: true }
        }
      }
    );
  }
]);
