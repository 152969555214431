'use strict';

// Campaigns controller
angular.module('websites').controller('WebsitesController', WebsitesController);

WebsitesController.$inject = [
  '$location',
  'Authorization',
  'Websites',
  '$rootScope',
  'Settings',
  'Authentication',
  'store',
  'spinnerService',
  'commonWebsite',
  '$window',
  'listingTotalsWebsite',
  '$http'
];

function WebsitesController(
  $location,
  Authorization,
  Websites,
  $rootScope,
  Settings,
  Authentication,
  store,
  spinnerService,
  commonWebsite,
  $window,
  listingTotalsWebsite,
  $http
) {
  var vm = this;
  Authorization.authorize().then(function() {
    vm.user = store.get('client');
    vm.permissionSet = Authorization.permissionSet();
    vm.currentCompany = store.get('currentCompany');
  });

  Settings.checkNotification();

  $rootScope.title = 'Website Evaluation';
  getWebsites();
  vm.toggleArchiveFilter = toggleArchiveFilter;
  vm.auth = Authorization;

  // $scope.authentication = Authentication;
  vm.showNewWebsite = false;
  vm.archive = 'Active';
  vm.orderByField = 'dateCreated';
  vm.reverseSort = true;
  vm.create = create;
  vm.noToShowOptions = [
    { name: 10, value: 10 },
    { name: 20, value: 20 },
    { name: 'All', value: 0 }
  ];
  vm.noToShow = vm.noToShowOptions[0].value;

  // Create new Website
  function create() {
    vm.processing = true;
    var spinner = document.getElementById('create-spinner-button');
    spinner.classList.remove('hidden');

    // Create new Website object
    var website = new Websites({
      name: vm.website.name.toUpperCase(),
      websiteUrl: vm.website.websiteUrl.toUpperCase(),
      advertiserName: vm.website.advertiserName.toUpperCase()
      // team_id: vm.websiteTeam.teamId //ToDo: check if needed
    });

    // Redirect after save
    website.$save(
      function(response) {
        if (response.id) {
          //  log add new pixel events
          $http.post(
            '/api/pixels/logevents',
            JSON.stringify({
              action: 'Pixel created',
              dataString: response.id.toString()
            })
          );
          $location.path('/websites');

          Websites.get({ id: response.id }).$promise.then(function(
            addedWebsite
          ) {
            angular.merge(addedWebsite, {
              ua: -1,
              imp: -1,
              latestData: -1
            }); // remove spinning wheels
            vm.websites.push(addedWebsite);
          });

          // Clear form fields
          vm.website.name = '';
          vm.website.websiteUrl = '';
          vm.website.advertiserName = '';

          vm.showNewWebsite = false;
          spinner.className += ' hidden';
          vm.processing = false;
        }
      },
      function(errorResponse) {
        vm.processing = false;
        spinner.className += ' hidden';
        vm.error = errorResponse.data.errors[0].message;
      }
    );
  }

  // Toggle Archived
  function toggleArchiveFilter() {
    if (vm.archive === '') {
      vm.archive = 'Active';
    } else {
      vm.archive = '';
    }
  }

  vm.hasPermission = function(allowedPermissions) {
    return Authorization.hasPermission(allowedPermissions);
  };

  vm.registerSpinner = function(spinnerName) {
    spinnerService.showGroup(spinnerName);
  };

  function getWebsites() {
    vm.websites = [];
    Websites.query(function(websites) {
      vm.websites = websites;
      if (vm.websites && vm.websites.length > 0) {
        vm.websites.forEach(function(website) {
          website.ua = 0;
          website.imp = 0;
          website.latestData = '';
          var startDate = moment(website.dateCreated).format('YYYY-MM-DD');
          var endDate = moment(undefined).format('YYYY-MM-DD');
          listingTotalsWebsite
            .total(website.id, startDate, endDate)
            .then(function(result) {
              if (result) {
                var websiteData = calculateTotals(website.id, result);
                // check for NaN data
                if (isNaN(websiteData.ua)) {
                  websiteData.ua = -1;
                }
                if (isNaN(websiteData.imp)) {
                  websiteData.imp = -1;
                }
                angular.merge(website, websiteData);
              } else {
                // set to -1 if the data is not there as if we use ) it is bypassing the spinner condition and spinner keeps on working. AND if we are using undefined then sorting was not working
                angular.merge(website, {
                  ua: -1,
                  imp: -1,
                  latestData: -1
                });
              }
            })
            .catch(function(reason) {
              console.error(reason);
              angular.merge(website, {
                ua: -1,
                imp: -1,
                latestData: -1
              });
            });
        });
      }
    });
  }

  /**
   * Calculate Totals for Information Panel
   * @param {string} websiteId
   * @param {Object} totals
   */
  function calculateTotals(websiteId, totals) {
    var ua = parseInt(totals.UA, 10);
    var impressions = parseInt(totals.impressions, 10);
    var endDate = totals.Period_End;

    // set to -1 if the data is not there as if we use ) it is bypassing the spinner condition and spinner keeps on working. AND if we are using undefined then sorting was not working
    if (ua === 0) {
      ua = -1;
    } else if (impressions === 0) {
      impressions = -1;
    }

    if (!endDate) {
      endDate = -1;
    }

    return {
      id: websiteId,
      ua: ua,
      imp: impressions,
      latestData: endDate
    };
  }

  /**
   * Split string off numbers into 111,000 from 111000
   * @param {number} x - Number to split.
   * @param {string=} [sep] - Separator.
   * @param {number=} [grp] - Number to group by, must be an integer.
   * @return {string}
   */
  function localeString(x, sep, grp) {
    var sx = ('' + x).split('.');
    var s = '';
    var i;
    var j;

    var defaultSep = sep || (defaultSep = ','); // default separator
    var defaultGrp = grp || grp === 0 || (defaultGrp = 3); // default grouping
    i = sx[0].length;
    while (i > defaultGrp) {
      j = i - defaultGrp;
      s = defaultSep + sx[0].slice(j, i) + s;
      i = j;
    }

    s = sx[0].slice(0, i) + s;
    sx[0] = s;
    return sx.join('.');
  }
  vm.localeString = localeString;

  /**
   * Search website URL and Name for search text.
   * @param {Object} website
   * @return {boolean}
   */
  function searchWebsites(website) {
    return commonWebsite.searchWebsites(website, vm.searchText);
  }
  vm.searchWebsites = searchWebsites;

  // redirect to website details
  vm.redirectToWebsite = function(loc) {
    $window.location.href = loc;
  };
}
