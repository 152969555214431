'use strict';

angular.module('insights').factory('InsightsTopWebsitesConfig', [
  function() {
    return {
      blacklistedSites: ['N/A'],
      chartId: '#website-chart',
      barHeight: 30,
      margin: {
        top: 30,
        right: 10,
        bottom: 30,
        left: 10
      },
      width: function() {
        var width = d3
          .select(this.chartId)
          .node()
          .getBoundingClientRect().width;
        width = width - this.margin.left - this.margin.right;
        return width;
      },

      widthPercent: function() {
        return (
          (this.width() - 210 - this.margin.left - this.margin.right) /
          this.width() *
          100
        );
      },

      height: 200,
      spacing: 3,
      percent: d3.format('%')
    };
  }
]);
