(function() {
  'use strict';

  angular.module('insights').factory('InsightsCumulative', InsightsCumulative);

  InsightsCumulative.$inject = ['InsightsCumulativeConfig', 'commonCampaign'];

  function InsightsCumulative(InsightsCumulativeConfig, commonCampaign) {
    // TODO: Check what can be removed.
    var chartId = InsightsCumulativeConfig.chartId();
    var globalData = [];
    var parseDate = d3.time.format('%Y-%m-%d').parse;
    var typeOfData;
    var y;
    var x;
    var yAxis = [];
    var xAxis = [];

    // Define the line.
    var area = d3.svg
      .line()
      .x(function(d) {
        return x(new Date(d.Period_End));
      })
      .y(function(d) {
        return y(d[typeOfData]);
      });

    return {
      createChart: createChart,
      getEarliestDate: getEarliestDate
    };

    /**
     * StartEnd type
     * @typedef {Object} StartEnd
     * @property {string} start Campaign start date
     * @property {string=} end Campaign end date or selected date.
     */

    /**
     * Insight Data
     * @typedef {Object} InsightData
     * @property {string} Period_End
     */

    function transform(d) {
      return (
        'translate(' + x(new Date(d.Period_End)) + ',' + y(d[typeOfData]) + ')'
      );
    }

    function shortDateFormat(date) {
      date = new Date(date);
      return (
        date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear()
      );
    }

    function transformHoverBackground(d) {
      var yDiff = y(d[typeOfData]);
      if (yDiff > 75) yDiff = -100;
      else yDiff = 0;

      var xDiff = x(new Date(d.Period_End));

      if (
        d3
          .select(chartId)
          .node()
          .getBoundingClientRect().width <
        xDiff + 150
      ) {
        xDiff = -100;
      } else xDiff = 0;
      return 'translate(' + xDiff + ',' + yDiff + ')';
    }

    /**
     * Create cumulative chart.
     * @param {?InsightData[]} data - Array of objects that represent single day of data.
     * @param {string} dateRange - Date range e.g. 4 Weeks
     * @param {StartEnd} startEnd - Object containing start and end dates
     */
    function createChart(data, dateRange, startEnd) {
      if (data) {
        // Set globalData to unfiltered data.
        globalData = angular.copy(data);
      } else {
        if (globalData.length === 0) {
          return;
        }
        data = angular.copy(globalData);
      }

      data.forEach(function(datum) {
        datum.Period_End = parseDate(datum.Period_End);
      });

      // Remove chart.
      d3.select(chartId + ' rect.curtain').remove();
      var chart = d3.select(chartId);
      chart.selectAll('svg').remove();

      // Set start and end dates and filter data if needed.
      var startDate;
      var endDate;
      if (startEnd) {
        startDate = new Date(startEnd.start);
        endDate = new Date(startEnd.end);
        data = setDateFilteredData(data, startDate, endDate);
      }

      if (data.length === 0) {
        commonCampaign.hideSpinner('unique-area-chart', 'showNoDataMsg');
        commonCampaign.hideSpinner('cumulative-area-chart', 'showNoDataMsg');

        return;
      }
      chart.selectAll('.no-insights').remove();

      startDate = data[0].Period_End;
      endDate = data[data.length - 1].Period_End;

      // Get currently selected data type.
      typeOfData = angular
        .element(document.querySelector('.switch.active'))
        .attr('value');

      // Graph Styling
      var margin = {
        top: 20,
        right: 50,
        bottom: 30,
        left: 50
      };
      var width =
        d3
          .select(chartId)
          .node()
          .getBoundingClientRect().width -
        margin.left -
        margin.right;
      var height = 200 - margin.top - margin.bottom;

      // Calculate Axis
      var yMax = d3.max(data, function(d) {
        return d[typeOfData];
      });

      x = d3.time
        .scale()
        .domain([
          new Date(data[0].Period_End),
          new Date(data[data.length - 1].Period_End)
        ])
        .range([0, width]);

      y = d3.scale
        .linear()
        .domain([0, yMax])
        .range([height, 0])
        .nice();

      // Calculate Ticks
      // TODO: Clean up.
      var tickFormat;
      var dateRangeType = dateRange.split(' ')[1];
      if (
        dateRange === '52 Weeks' ||
        dateRangeType === 'Months' ||
        dateRange === '365 Days'
      ) {
        tickFormat = '%b';
      } else {
        tickFormat = '%b %e';
      }

      var ticks = dateRange.split(' ')[0];
      var tickValues = [];
      var noOfDays = moment(endDate).diff(startDate, 'days');
      if (ticks === 'All' && noOfDays >= 9) {
        ticks = 9;
      } else if (ticks === 'All' && noOfDays < 9) {
        ticks = noOfDays;
      } else if (dateRange === '90 Days') {
        ticks = 8;
      } else if (ticks > 12 || Number(ticks) !== parseInt(ticks)) {
        ticks = 11;
      } else {
        ticks -= 1;
      }

      if (dateRange === '15 Days') {
        ticks = 7;
      } else if (dateRange === '30 Days') {
        ticks = 5;
      } else if (dateRange === '12 Weeks') {
        ticks = 11;
      } else if (dateRange === '24 Weeks') {
        ticks = 10;
      } else if (dateRange === '52 Weeks' || dateRange === '365 Days') {
        ticks = moment(endDate).diff(startDate, 'months') + 1;
      }

      if (data.length < 5) {
        ticks = data.length;
      }

      // Tick Overrides
      if (dateRange === 'All') {
        if (data.length === 13) {
          ticks = 13;
        } else if (data.length === 17) {
          ticks = 8;
        } else if (data.length === 19) {
          ticks = 9;
        } else if (data.length === 23) {
          ticks = 11;
        } else if (data.length === 29) {
          ticks = 13;
        } else if (data.length === 31) {
          ticks = 14;
        } else if (data.length === 37) {
          ticks = 12;
        } else if (data.length === 41) {
          ticks = 13;
        } else if (data.length === 43) {
          ticks = 13;
        } else if (data.length === 47) {
          ticks = 14;
        }
      }

      var datesPerTick = Math.floor((noOfDays + 1) / ticks);

      if (dateRange === '30 Days') {
        datesPerTick = 6;
      }

      for (var i = 0; i < ticks; i++) {
        if (dateRange === '52 Weeks' || dateRange === '365 Days') {
          tickValues.push(
            moment(startDate)
              .add(i, 'month')
              .toDate()
          );
        } else {
          tickValues.push(
            moment(startDate)
              .add(i * datesPerTick, 'days')
              .toDate()
          );
        }
      }

      tickValues.push(data[data.length - 1].Period_End);

      xAxis = d3.svg
        .axis()
        .scale(x)
        .tickValues(tickValues)
        .tickFormat(d3.time.format(tickFormat))
        .orient('bottom');

      yAxis = d3.svg
        .axis()
        .scale(y)
        .ticks(5)
        .tickFormat(function(d) {
          var prefix = d3.formatPrefix(d);
          return prefix.scale(d) + prefix.symbol;
        })
        .orient('left');

      // Draw chart.
      var svg = d3
        .select(chartId)
        .append('svg')
        .attr('width', width + margin.left + margin.right)
        .attr('height', height + margin.top + margin.bottom)
        .append('g')
        .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

      svg
        .append('g')
        .attr('class', 'x axis')
        .attr('stroke', '#000')
        .attr('fill', 'none')
        .attr('transform', 'translate(0,' + height + ')')
        .call(xAxis);

      svg
        .append('g')
        .attr('class', 'y axis')
        .attr('stroke', '#000')
        .attr('fill', 'none')
        .call(yAxis)
        .append('text')
        .attr('transform', 'rotate(-90)')
        .attr('y', 6)
        .attr('stroke', InsightsCumulativeConfig.defaultColor)
        .attr('dy', '.71em')
        .style('text-anchor', 'end');

      svg
        .append('path')
        .datum(data)
        .transition()
        .attr('x', 320)
        .duration(1000) // this is 1s
        .delay(100)
        .attr('class', 'area')
        .attr('fill', 'none')
        .attr('stroke', '#0031bf')
        .attr('stroke-width', '2')
        .attr('d', area);
      var curtain = svg
        .append('rect')
        .attr('x', -1 * width)
        .attr('y', -1 * height)
        .attr('height', height)
        .attr('width', width)
        .attr('class', 'curtain')
        .attr('transform', 'rotate(180)')
        .style('fill', '#ffffff');

      var focus = svg
        .append('g')
        .attr('class', 'focus')
        .style('display', 'none');

      focus.append('circle').attr('r', 4.5);

      focus
        .append('rect')
        .attr('class', 'hover-background')
        .attr('height', '100')
        .attr('width', '100');

      focus
        .append('text')
        .attr('class', 'date')
        .attr('x', 10)
        .attr('dy', '1.60em')
        .text('Date:')
        .style('fill', '#fff');

      focus
        .append('text')
        .attr('class', 'dateVal')
        .attr('x', 10)
        .attr('dy', '2.85em')
        .style('fill', '#fff');

      focus
        .append('text')
        .attr('class', 'typeOfData')
        .attr('x', 10)
        .attr('dy', '4.85em')
        .text(typeOfData + ':')
        .style('fill', '#fff');

      focus
        .append('text')
        .attr('class', 'typeOfDataVal')
        .attr('x', 10)
        .attr('dy', '6.10em')
        .style('fill', '#fff');

      var t = svg
        .transition()
        .delay(0)
        .duration(1000)
        .ease('linear')
        .each('end', function() {
          d3.select('line.guide')
            .transition()
            .style('opacity', 0)
            .remove();
        });

      t.select(chartId + ' rect.curtain').attr('width', 0);
      t.select(chartId + ' line.guide').attr(
        'transform',
        'translate(' + width + ', 0)'
      );

      d3.select('#show_guideline').on('change', function() {
        guideline.attr('stroke-width', this.checked ? 1 : 0);
        curtain.attr('opacity', this.checked ? 0.75 : 1);
      });

      svg
        .append('rect')
        .attr('class', 'mouse-catcher')
        .attr('width', width + 20)
        .attr('height', height)
        .on('mouseover', function() {
          focus.style('display', null);
        })
        .on('mouseout', function() {
          focus.style('display', 'none');
        })
        .on('mousemove', mousemove);

      d3.selectAll('#cumulative-area-chart .spinner').style('opacity', '0');

      d3.selectAll('.switch').on('click.cumulative', switchDataType);

      function mousemove() {
        d3.selectAll('#cumulative-area-chart .spinner').style(
          'display',
          'none'
        );
        var x0 = new Date(x.invert(d3.mouse(this)[0])); // jshint ignore:line
        var dayAfterFinal = angular.copy(data[data.length - 1].Period_End);
        dayAfterFinal.setDate(dayAfterFinal.getDate() + 1);
        if (x0 < dayAfterFinal) {
          var i = data
            .map(function(e) {
              return shortDateFormat(e.Period_End);
            })
            .indexOf(shortDateFormat(x0));
          var d0 = data[i - 1];
          var d1 = data[i];
          if (i === 0) d0 = d1;
          if (d0 && d1) {
            var d = x0 - d0[typeOfData] > d1[typeOfData] - x0 ? d1 : d0;
            focus.attr('transform', transform(d));
            focus
              .select('#cumulative-area-chart text.typeOfDataVal')
              .text(getHoverValue(d, typeOfData));
            focus
              .select('#cumulative-area-chart text.dateVal')
              .text(shortDateFormat(d.Period_End));
            focus
              .selectAll('#cumulative-area-chart text')
              .attr('transform', transformHoverBackground(d));
            d3.selectAll('#cumulative-area-chart .hover-background').attr(
              'transform',
              transformHoverBackground(d)
            );
          }
        }
      }

      function switchDataType() {
        typeOfData = angular
          .element(document.querySelector('.switch.active'))
          .attr('value');

        var yMax = d3.max(data, function(d) {
          return d[typeOfData];
        });

        d3.selectAll('text.typeOfData').text(typeOfData + ':');
        // First transition the line & label to the new value.
        var t0 = svg.transition().duration(750);
        t0.selectAll('.area').attr('d', area);
        t0.selectAll('.label')
          .attr('transform', transform)
          .text(typeOfData);

        if (yMax > 0) {
          // Then transition the y-axis.
          y.domain([0, yMax]);
          var t1 = t0.transition();
          t1.selectAll('.area').attr('d', area(data));
          t1.selectAll('.label').attr('transform', transform);
          t1.selectAll('.y.axis').call(yAxis);
        }
      }

      commonCampaign.hideSpinner('cumulative-area-chart');
      return true;
    }

    function getHoverValue(d, typeOfData) {
      return d3.format(',d')(d[typeOfData]);
    }

    function setDateFilteredData(data, startDate, endDate) {
      if (startDate || endDate) {
        var tempDataArray = [];
        for (var j = 0; j < data.length; j++) {
          var currentDate = data[j].Period_End;
          if (moment(currentDate).isBetween(startDate, endDate, 'day', '[]')) {
            tempDataArray.push(data[j]);
          }
        }

        tempDataArray = addPaddingData(tempDataArray, startDate);
        return tempDataArray;
      }
    }

    /**
     * Add Extra empty data so fills out chart
     * @param {Array} data
     * @param {string} startDate
     * @returns {Array}
     */
    function addPaddingData(data, startDate) {
      if (data.length > 0) {
        var firstNonEmpty = data[0].Period_End;
        var emptyDays = moment(firstNonEmpty).diff(startDate, 'days');
        if (emptyDays > 0) {
          for (var i = 1; i <= emptyDays + 1; i++) {
            data.unshift({
              v: moment(firstNonEmpty)
                .subtract(i, 'day')
                .format('YYYY-MM-DD'),
              Period_End: moment(firstNonEmpty)
                .subtract(i, 'day')
                .format('YYYY-MM-DD'),
              UA: 0,
              impressions: 0,
              clicks: 0,
              Impressions: 0,
              Clicks: 0,
              ua: 0
            });
          }
        }
      }

      return data;
    }

    /**
     * Get the first Date
     * @returns {string}
     */
    function getEarliestDate() {
      return globalData[0].Period_End;
    }
  }
})();
