'use strict';

// Setting up route
angular.module('insights').config([
  '$stateProvider',
  function($stateProvider) {
    // Insights state routing
    $stateProvider.state('insights', {
      url: '/insights'
    });
  }
]);
