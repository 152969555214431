(function() {
  'use strict';

  angular.module('insights').factory('getLastDate', getLastDate);

  getLastDate.$inject = ['$http', '$q', '$log', 'Utility'];

  function getLastDate($http, $q, $log, Utility) {
    return {
      get: get
    };

    function get(insightId, period, startDate, endDate, type) {
      var companyUID = Utility.getUID();
      return $q(function(resolve, reject) {
        $http
          .post('/api/insights/getLastDate', {
            campaign: insightId,
            companyUID: companyUID,
            endDate: endDate,
            startDate: startDate,
            period: period,
            type: type
          })
          .then(function(result) {
            resolve(result.data);
          })
          .catch(function(reason) {
            $log.error('Error: ', reason);
            reject(reason);
          });
      });
    }
  }
})();
