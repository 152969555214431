'use strict';

angular.module('users').controller('AuthenticationController', [
  '$scope',
  '$http',
  '$location',
  'Authorization',
  '$window',
  function($scope, $http, $location, Authorization, $window) {
    Authorization.authorize().then(function(result) {
      $scope.user = result;
    });
  }
]);
