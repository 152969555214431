(function() {
  'use strict';

  angular
    .module('insights')
    .factory('InsightsDailyUnique', InsightsDailyUnique);

  InsightsDailyUnique.$inject = [
    'InsightsConfig',
    'commonCampaign',
    '$document',
    'usSpinnerService'
  ];

  function InsightsDailyUnique(
    InsightsConfig,
    commonCampaign,
    $document,
    usSpinnerService
  ) {
    var chartId = InsightsConfig.uniqueAreaChart;
    var globalData = [];
    var typeOfData;
    var parseDate = d3.time.format('%Y-%m-%d').parse;
    var y;
    var x;
    var yAxis;
    var xAxis;
    var periodType;

    // Define the line.
    var area = d3.svg
      .line()
      .x(function(d) {
        return x(new Date(d[periodType]));
      })
      .y(function(d) {
        return y(d[typeOfData]);
      });

    return {
      createChart: createChart,
      roundData: roundFinalData,
      getEarliestDate: getEarliestDate
    };

    /**
     * StartEnd type
     * @typedef {Object} StartEnd
     * @property {string} start Campaign start date
     * @property {string=} end Campaign end date or selected date.
     */

    /**
     * Check if Data is using Period_Start or Period_End
     * @param {Array} data
     * @returns {string}
     */
    function getDatePeriodType(data) {
      if (data[0].Period_Start) {
        return 'Period_Start';
      }
      return 'Period_End';
    }

    /**
     * Calculate data and draw graph
     * @param {object[]?} data
     * @param {string} dateRange - Date range e.g. 4 Weeks
     * @param {string} [datePeriod] - day, week, month
     * @param {StartEnd} startEnd
     * @return {boolean}
     */
    function createChart(data, dateRange, datePeriod, startEnd, selectedDate) {
      if (data) {
        globalData = angular.copy(data);
      } else if (globalData.length === 0) {
        return false;
      } else {
        data = angular.copy(globalData);
      }

      if (data.length > 0) {
        periodType = getDatePeriodType(data);

        data.forEach(function(datum) {
          datum[periodType] = parseDate(datum[periodType]);
        });
      }

      // Remove chart.
      d3.select(chartId + ' rect.curtain').remove();
      var chart = d3.select(chartId);
      chart.selectAll('svg').remove();

      // Set start and end dates and filter data if needed.
      var startDate;
      var endDate;
      var wrapper;
      if (startEnd) {
        var displayStartEnd = calculateDateRange(
          dateRange,
          startEnd,
          datePeriod,
          selectedDate
        );
        startDate = moment(displayStartEnd.start).toDate();
        endDate = moment(displayStartEnd.end).toDate();
        data = setDateFilteredData(data, startDate, endDate, dateRange);
      }

      if (data.length === 0) {
        commonCampaign.hideSpinner('unique-area-chart', 'showNoDataMsg');
        commonCampaign.hideSpinner('cumulative-area-chart', 'showNoDataMsg');
        return false;
      }
      chart.selectAll('.no-insights').remove();

      startDate = data[0][periodType];
      endDate = data[data.length - 1][periodType];

      data = addPaddingDateToMiddle(data, dateRange, startDate, endDate);

      // Get currently selected data type.
      typeOfData = angular
        .element($document[0].querySelector('.switch.active'))
        .attr('value');

      function transform(d) {
        return (
          'translate(' +
          x(new Date(d[periodType])) +
          ',' +
          y(d[typeOfData]) +
          ')'
        );
      }

      function transformHoverBackground(d) {
        var yDiff = 0;
        var xDiff = 0;
        if (angular.isDefined(d)) {
          if (y(d[typeOfData]) > 75) yDiff = -100;
        }

        if (angular.isDefined(d)) {
          if (
            d3
              .select(chartId)
              .node()
              // @ts-ignore
              .getBoundingClientRect().width <
            x(new Date(d[periodType])) + 150
          ) {
            xDiff = -100;
          }
        }

        return 'translate(' + xDiff + ',' + yDiff + ')';
      }

      // Graph Styling
      var margin = {
        top: 20,
        right: 50,
        bottom: 30,
        left: 50
      };

      var width =
        d3
          .select(chartId)
          .node()
          // @ts-ignore
          .getBoundingClientRect().width -
        margin.left -
        margin.right;

      var uniqueChartHeight = 0;

      // check if the dashboard is for campaigns or for websites
      if (
        $('#unique-area-chart')
          .parents('#campaign-listing')
          .hasClass('campaign-insights')
      ) {
        uniqueChartHeight = 200;
      } else {
        uniqueChartHeight = 445;
      }
      var height = uniqueChartHeight - margin.top - margin.bottom;

      var yMax = d3.max(data, function(d) {
        return d[typeOfData];
      });

      x = d3.time
        .scale()
        .domain([
          new Date(data[0][periodType]),
          new Date(data[data.length - 1][periodType])
        ])
        .range([0, width]);

      y = d3.scale
        .linear()
        .domain([0, yMax])
        .range([height, 0])
        .nice();

      // Calculate Ticks
      // TODO: Clean up.
      var tickFormat;
      var dateRangeType = dateRange.split(' ')[1];
      if (
        dateRange === '52 Weeks' ||
        dateRangeType === 'Months' ||
        dateRange === '365 Days' ||
        (datePeriod === 'month' && dateRange === 'All')
      ) {
        tickFormat = '%b';
      } else {
        tickFormat = '%b %e';
      }

      var dateRangeStart = dateRange.split(' ')[0];
      var ticks = 0;
      var tickValues = [];

      /**
       * MomentJS accepted time units for diff
       * @type {moment.unitOfTime.Diff}
       */
      var dateType;
      if (dateRangeType === 'Months') {
        dateType = 'months';
      } else {
        dateType = 'days';
      }

      var noOfDates = moment(endDate).diff(startDate, dateType);

      if (dateRangeStart === 'All') {
        ticks = 9;
      } else if (dateRange === '90 Days') {
        ticks = 8;
      } else if (
        Number(dateRangeStart) > 12 ||
        Number(dateRangeStart) !== parseInt(dateRangeStart, 10)
      ) {
        ticks = 11;
      } else {
        ticks = Number(dateRangeStart) - 1;
      }

      if (dateRange === '15 Days') {
        ticks = 7;
      } else if (dateRange === '30 Days') {
        ticks = 5;
      } else if (dateRange === '12 Weeks') {
        ticks = 11;
      } else if (dateRange === '24 Weeks') {
        ticks = 10;
      } else if (dateRange === '52 Weeks' || dateRange === '365 Days') {
        ticks = moment(endDate).diff(startDate, 'months') + 1;
      }

      if (data.length < 5) {
        ticks = data.length - 1;
      }

      // Tick Overrides
      if (dateRange === 'All' && datePeriod === 'day') {
        if (data.length === 13) {
          ticks = 13;
        } else if (data.length === 17) {
          ticks = 8;
        } else if (data.length === 19) {
          ticks = 9;
        } else if (data.length === 23) {
          ticks = 11;
        } else if (data.length === 29) {
          ticks = 13;
        } else if (data.length === 31) {
          ticks = 14;
        } else if (data.length === 37) {
          ticks = 12;
        } else if (data.length === 41) {
          ticks = 13;
        } else if (data.length === 43) {
          ticks = 13;
        } else if (data.length === 47) {
          ticks = 14;
        }
      }

      var datesPerTick = Math.floor(noOfDates / ticks);

      if (datesPerTick === 0) {
        datesPerTick = 1;
      }

      if (dateRange === '15 Days') {
        datesPerTick = 2;
      } else if (dateRange === '30 Days') {
        datesPerTick = 6;
      }

      if (dateRange === 'All' && noOfDates === 23) {
        ticks = 6;
        datesPerTick = 4;
      }

      for (var i = 0; i < ticks; i++) {
        if (dateRange === '52 Weeks' || dateRange === '365 Days') {
          tickValues.push(
            moment(startDate)
              .add(i, 'month')
              .toDate()
          );
        } else {
          tickValues.push(
            moment(startDate)
              .add(i * datesPerTick, dateType)
              .toDate()
          );
        }
      }

      tickValues.push(data[data.length - 1][periodType]);

      xAxis = d3.svg
        .axis()
        .scale(x)
        .tickValues(tickValues)
        .tickFormat(d3.time.format(tickFormat))
        .orient('bottom');

      yAxis = d3.svg
        .axis()
        .scale(y)
        .ticks(5)
        .tickFormat(function(d) {
          var prefix = d3.formatPrefix(d);
          return prefix.scale(d) + prefix.symbol;
        })
        .orient('left');

      // Set Graph Styling
      var svg = d3
        .select(chartId)
        .append('svg')
        .attr('width', width + margin.left + margin.right)
        .attr('height', height + margin.top + margin.bottom)
        .append('g')
        .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

      svg
        .append('g')
        .attr('class', 'x axis')
        .attr('stroke', '#000')
        .attr('fill', 'none')
        .attr('transform', 'translate(0,' + height + ')')
        .call(xAxis);

      svg
        .append('g')
        .attr('class', 'y axis')
        .attr('stroke', '#000')
        .attr('fill', 'none')
        .call(yAxis)
        .append('text')
        .attr('class', 'name')
        .attr('transform', 'rotate(-90)')
        .attr('y', 6)
        .attr('dy', '.71em')
        .style('text-anchor', 'end');

      svg
        .append('path')
        .datum(data)
        .transition()
        .attr('class', 'area')
        .attr('fill', 'none')
        .attr('stroke', '#0031bf')
        .attr('stroke-width', '2')
        .attr('d', area);
      var curtain = svg
        .append('rect')
        .attr('x', -1 * width)
        .attr('y', -1 * height)
        .attr('height', height)
        .attr('width', width)
        .attr('class', 'curtain')
        .attr('transform', 'rotate(180)')
        .style('fill', '#ffffff');
      var focus = svg
        .append('g')
        .attr('class', 'focus')
        .attr('width', '100')
        .attr('height', '100')
        .style('display', 'none');

      focus.append('circle').attr('r', 4.5);

      focus
        .append('rect')
        .attr('class', 'hover-background')
        .attr('height', '100')
        .attr('width', '100');

      focus
        .append('text')
        .attr('class', 'date')
        .attr('x', 10)
        .attr('dy', '1.60em')
        .text('Date:')
        .style('fill', '#fff');

      focus
        .append('text')
        .attr('class', 'dateVal')
        .attr('x', 10)
        .attr('dy', '2.85em')
        .style('fill', '#fff');

      focus
        .append('text')
        .attr('class', 'typeOfData')
        .attr('x', 10)
        .attr('dy', '4.85em')
        .text(typeOfData + ':')
        .style('fill', '#fff');

      focus
        .append('text')
        .attr('class', 'typeOfDataVal')
        .attr('x', 10)
        .attr('dy', '6.10em')
        .style('fill', '#fff');

      svg
        .append('rect')
        .attr('class', 'mouse-catcher')
        .attr('width', width + 20)
        .attr('height', height)
        .on('mouseover', function() {
          focus.style('display', null);
        })
        .on('mouseout', function() {
          focus.style('display', 'none');
        })
        .on('mousemove', mousemove);
      var t = svg
        .transition()
        .delay(0)
        .duration(1000)
        .ease('linear')
        .each('end', function() {
          d3
            .select('line.guide')
            .transition()
            .style('opacity', 0)
            .remove();
        });

      t.select(chartId + ' rect.curtain').attr('width', 0);
      t
        .select(chartId + ' line.guide')
        .attr('transform', 'translate(' + width + ', 0)');

      d3.select('#show_guideline').on('change', function() {
        guideline.attr('stroke-width', this.checked ? 1 : 0);
        curtain.attr('opacity', this.checked ? 0.75 : 1);
      });

      function shortDateFormat(date) {
        date = new Date(date);
        return (
          date.getDate() +
          '/' +
          (date.getMonth() + 1) +
          '/' +
          date.getFullYear()
        );
      }

      function mousemove() {
        commonCampaign.hideSpinner('unique-area-chart');
        var x0 = new Date(x.invert(d3.mouse(this)[0]));
        var dayAfterFinal = angular.copy(data[data.length - 1][periodType]);
        dayAfterFinal.setDate(dayAfterFinal.getDate() + 1);
        if (x0 < dayAfterFinal) {
          var i = data
            .map(function(e) {
              return shortDateFormat(e[periodType]);
            })
            .indexOf(shortDateFormat(x0));
          if (i >= 0) {
            var d0 = data[i - 1];
            var d1 = data[i];
            if (i === 0) d0 = d1;
            var d = x0 - d0[typeOfData] > d1[typeOfData] - x0 ? d1 : d0;
            focus.attr('transform', transform(d));
            focus
              .select(chartId + ' text.typeOfDataVal')
              .text(getHoverValue(d, typeOfData));
            focus.select(chartId + ' text.typeOfData').text(typeOfData);
            focus
              .select(chartId + ' text.dateVal')
              .text(shortDateFormat(d[periodType]));
            focus
              .selectAll(chartId + ' text')
              .attr('transform', transformHoverBackground(d));
            d3
              .selectAll(chartId + ' .hover-background')
              .attr('transform', transformHoverBackground(d));
          }
        }
      }

      d3.selectAll('.switch').on('click.unique', changeUnique);

      function changeUnique() {
        typeOfData = angular
          .element($document[0].querySelector('.switch.active'))
          .attr('value');

        var yMax = d3.max(data, function(d) {
          return d[typeOfData];
        });

        // First transition the line & label to the new value.
        var t0 = svg.transition().duration(750);
        t0.selectAll('#unique-area-chart .area').attr('d', area);
        t0
          .selectAll('#unique-area-chart .label')
          .attr('transform', transform)
          .text(typeOfData);

        if (yMax > 0) {
          // Then transition the y-axis.
          y.domain([0, yMax]);
          var t1 = t0.transition();
          t1.selectAll('#unique-area-chart .area').attr('d', area(data));
          t1
            .selectAll('#unique-area-chart .label')
            .attr('transform', transform);
          t1.selectAll('#unique-area-chart .y.axis').call(yAxis);
        }
      }

      commonCampaign.hideSpinner('unique-area-chart');
      return true;
    }

    function getHoverValue(d, typeOfData) {
      return d3.format(',d')(d[typeOfData]);
    }

    function roundFinalData(dataToRound) {
      angular.forEach(dataToRound, function(value) {
        value.UA = Math.round(value.UA);
        value.Impressions = Math.round(value.Impressions);
        if (value.clicks) {
          value.clicks = Math.round(value.clicks);
        } else {
          delete value.clicks;
        }
        // avoid duplicacy of data in downloaded CSV file
        if (value.ua || value.ua === '0' || value.ua === 0) {
          delete value.ua;
        }
        // avoid duplicacy of data in downloaded CSV file
        if (
          value.impressions ||
          value.impressions === '0' ||
          value.impressions === 0
        ) {
          delete value.impressions;
        }
      });

      return dataToRound;
    }

    /**
     * Return data between date range.
     * @param {*} data
     * @param {Date} startDate
     * @param {Date} endDate
     * @param {string} dateRange - Date range e.g. 4 Weeks
     * @return {object[]}
     */
    function setDateFilteredData(data, startDate, endDate, dateRange) {
      periodType = getDatePeriodType(data);
      if (startDate || endDate) {
        var tempDataArray = [];
        for (var j = 0; j < data.length; j++) {
          var currentDate = data[j][periodType];
          if (moment(currentDate).isBetween(startDate, endDate, 'day', '[]')) {
            tempDataArray.push(data[j]);
          }
        }

        tempDataArray = addPaddingDataToEnds(
          tempDataArray,
          moment(startDate).format('YYYY-MM-DD'),
          dateRange,
          moment(endDate).format('YYYY-MM-DD')
        );
        return tempDataArray;
      }
      return [];
    }

    /**
     * Get the first Date
     * @returns {string}
     */
    function getEarliestDate() {
      if (globalData.length) {
        var periodType = getDatePeriodType(globalData);
        return globalData[0][periodType];
      }
      return false;
    }

    /**
     * Add Extra empty data so fills out chart to correct start and end dates.
     * @param {Array} data
     * @param {string} startDate
     * @param {string} dateRange
     * @param {string} endDate - YYYY-MM-DD
     * @returns {Array}
     */
    function addPaddingDataToEnds(data, startDate, dateRange, endDate) {
      var datePeriodType = getDatePeriodType(globalData);
      var rangeType = dateRange.split(' ')[1];
      if (data.length > 0) {
        var firstNonEmpty = data[0][datePeriodType];

        if (dateRange !== 'All') {
          var emptyDates = moment(firstNonEmpty).diff(startDate, rangeType);

          if (emptyDates > 0) {
            for (var i = 1; i <= emptyDates; i++) {
              var tmpObj = {
                UA: 0,
                clicks: 0,
                impressions: 0,
                ua: 0,
                Clicks: 0,
                Impressions: 0,
                v: moment(firstNonEmpty)
                  .subtract(i, rangeType)
                  .toDate()
              };

              tmpObj[datePeriodType] = moment(firstNonEmpty)
                .subtract(i, rangeType)
                .toDate();
              data.unshift(tmpObj);
            }
          }
        }
      } else if (dateRange !== 'All') {
        var dateDiff = moment(endDate).diff(startDate, rangeType);
        if (dateDiff > 0) {
          for (var j = 0; j <= dateDiff; j++) {
            var tmpDate = moment(startDate)
              .add(j, rangeType)
              .toDate();
            if (rangeType.toLowerCase() === 'weeks') {
              tmpDate = moment(startDate)
                .add(j, rangeType)
                .startOf('isoWeek')
                .toDate();
            }
            var emptyData = {
              UA: 0,
              clicks: 0,
              impressions: 0,
              ua: 0,
              Clicks: 0,
              Impressions: 0,
              v: tmpDate
            };

            emptyData[datePeriodType] = tmpDate;
            data.push(emptyData);
          }
        }
      }

      return data;
    }

    /**
     * Add empty data objects to fill missing days.
     * @param {Object[]} data
     * @param {string} dateRange - All,
     * @param {string} startDate
     * @param {string} endDate
     * @returns {Object[]}
     */
    function addPaddingDateToMiddle(data, dateRange, startDate, endDate) {
      var paddedData = [];
      var wholeDateRange = moment.range(startDate, endDate);
      if (dateRange === 'All') {
        wholeDateRange.by('days', function(date) {
          var match = _.filter(data, function(datum) {
            return moment(datum[periodType]).isSame(date, 'day');
          });
          var tmpObj = {};
          if (match.length > 0) {
            angular.copy(match[0], tmpObj);
          } else {
            tmpObj[periodType] = date.toDate();
            tmpObj.Clicks = 0;
            tmpObj.Impressions = 0;
            tmpObj.UA = 0;
            tmpObj.clicks = 0;
            tmpObj.impressions = 0;
            tmpObj.ua = 0;
          }
          paddedData.push(tmpObj);
        });

        return paddedData;
      }
      return data;
    }

    /**
     * Calculate Date Ranges
     * @param {string} dateRange - Date range e.g. 4 Weeks
     * @param {StartEnd} insightStartEnd - Insight start and end dates
     * @param {string} [datePeriod] - day, week, month
     * @param {Date} [selectedDate] - Optional date
     * @return {StartEnd} startEnd
     */
    function calculateDateRange(
      dateRange,
      insightStartEnd,
      datePeriod,
      selectedDate
    ) {
      if (
        !datePeriod ||
        (dateRange.toLowerCase() === 'all' &&
          datePeriod.toLowerCase() === 'all' &&
          insightStartEnd)
      ) {
        return {
          start: moment(insightStartEnd.start).format('YYYY-MM-DD'),
          end: insightStartEnd.end || moment().format('YYYY-MM-DD')
        };
      }
      var startEnd = {};
      var dateRangeStart = dateRange.split(' ')[0];
      var newDate;

      // Check dateRangeStart is a number
      if (Number(dateRangeStart) === parseInt(dateRangeStart, 10)) {
        // if no selected date calculate date to use.
        if (!selectedDate) {
          // Check if insight has an enddate
          if (!insightStartEnd.end) {
            // use last periods date, etc. last month or week.
            if (datePeriod === 'week') {
              newDate = moment()
                .subtract(1, datePeriod)
                .endOf('isoWeek')
                .toDate();
            } else if (datePeriod === 'day') {
              newDate = moment()
                .subtract(1, 'day')
                .toDate();
            } else if (datePeriod === 'month') {
              newDate = moment()
                .subtract(1, datePeriod)
                .endOf('month')
                .toDate();
            }
          } else {
            newDate = moment(insightStartEnd.end).toDate();
          }
        }

        var dateRangeNumber = Number(dateRangeStart);
        if (datePeriod === 'day') {
          if (dateRange !== '365 Days') {
            dateRangeNumber -= 1;
          }
        } else if (datePeriod === 'week') {
          if (dateRange === '52 Weeks') {
            dateRangeNumber += 1;
          }

          // date = moment(date).toDate();
        } else if (datePeriod === 'month') {
          dateRangeNumber -= 1;
        }
        startEnd = commonCampaign.getLastDate(
          selectedDate || newDate,
          dateRangeNumber,
          datePeriod + 's'
        );
      } else {
        startEnd = {
          start: insightStartEnd.start,
          end: insightStartEnd.end || moment().format('YYYYY-MM-DD')
        };
      }

      return startEnd;
    }
  }
})();
