'use strict';

angular.module('websites').filter('websites', [
  function() {
    return function(input) {
      // Websites directive logic
      // ...

      return 'websites filter: ' + input;
    };
  }
]);
