'use strict';

// Config HTTP Error Handling
angular.module('users').config([
  '$httpProvider',
  function($httpProvider) {
    // Set the httpProvider "not authorized" interceptor
    $httpProvider.interceptors.push([
      '$q',
      '$location',
      'Authentication',
      '$window',
      function($q, $location, Authentication, $window) {
        return {
          responseError: function(rejection) {
            switch (rejection.status) {
              case 401:
                // Deauthenticate the global user
                Authentication.logout();
                $window.location = './';
                break;
              case 403:
                if (
                  rejection.data &&
                  rejection.data === 'Insight belongs to another team'
                ) {
                  // User trying to access insight from another team
                  $window.location = './';
                } else {
                  // NOTE: commented out below not authorised redirection
                  // Generic unauthorized behaviour
                  // $window.location = './not-authorised';
                }
                break;
            }

            return $q.reject(rejection);
          }
        };
      }
    ]);
  }
]);
