'use strict';

// Add token to all http requests
angular
  .module('users')
  .factory('helixVersionInterceptor', [
    'Authentication',
    'store',
    function(Authentication, store) {
      return {
        request: function(config) {
          var helixVersion = store.get('helixVersion');
          if (helixVersion) {
            config.headers.helixVersion = helixVersion;
          }
          return config;
        }
      };
    }
  ])
  .config(function($httpProvider) {
    $httpProvider.interceptors.push('helixVersionInterceptor');
  });
