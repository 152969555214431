'use strict';

angular
  .module('insights')
  .factory('InsightsLocalVsInternational', function(
    InsightsLocalVsInternationalConfig,
    commonWebsite
  ) {
    var path = [];
    var typeOfData = 'impressions';
    var width = 0;
    var globalData = [];

    /**
     * Check if Data is null
     * @param {Array} data
     * @returns {boolean}
     */
    function checkIfData(data) {
      var hasData = true;
      if (data.length === 0) {
        return false;
      }

      if (
        data[0].clicks_int === null &&
        data[0].clicks_local === null &&
        data[0].impressions_local === null &&
        data[0].impressions_int === null
      ) {
        hasData = false;
      }

      return hasData;
    }

    var readData = function(data, pushData) {
      var containsData = checkIfData(data);
      var wrapper = document.getElementById('local-vs-international-chart');
      if (!containsData) {
        var overlay = wrapper.getElementsByClassName('overlay');
        if (overlay) {
          angular.element(overlay).removeClass('active');
        }
        commonWebsite.hideSpinner(
          'local-vs-international-chart',
          'showNoDataMsg'
        );
        return;
      }

      typeOfData = 'impressions';
      if (pushData) {
        globalData = [];
        globalData.push({
          location: 'AU',
          impressions: parseInt(Number(data[0].impressions_local), 10),
          clicks: parseInt(Number(data[0].clicks_local), 10)
        });
        globalData.push({
          location: 'International',
          impressions: parseInt(Number(data[0].impressions_int), 10),
          clicks: parseInt(Number(data[0].clicks_int), 10)
        });
      }

      width = InsightsLocalVsInternationalConfig.width();
      var height = d3
        .select(InsightsLocalVsInternationalConfig.chartId)
        .node()
        .getBoundingClientRect().height;
      var radius = Math.min(width, height) / 2.3;
      var labelRadius = radius;
      var colour = InsightsLocalVsInternationalConfig.colors;
      var arc = d3.svg
        .arc()
        .outerRadius(radius - InsightsLocalVsInternationalConfig.outerRadius)
        .innerRadius(radius - InsightsLocalVsInternationalConfig.innerRadius);

      var pie = d3.layout
        .pie()
        .sort(null)
        .value(function(d) {
          return d[typeOfData];
        });

      var svg = d3
        .select(InsightsLocalVsInternationalConfig.chartId)
        .append('svg')
        .attr('width', '100%')
        .attr('height', '100%')
        .style('position', 'relative')
        .style('z-index', '10')
        .attr(
          'viewBox',
          '0 0 ' + Math.min(width, height) + ' ' + Math.min(width, height)
        )
        .attr('preserveAspectRatio', 'xMinYMin meet')
        .append('g')
        .attr(
          'transform',
          'translate(' + width / 2 + ',' + (height / 2 - 30) + ')'
        );

      path = svg
        .datum(globalData)
        .selectAll('path')
        .data(pie(globalData))
        .enter()
        .append('path');

      path
        .attr('fill', function(d, i) {
          return colour(i);
        })
        .transition()
        .delay(function(d, i) {
          return i * 500;
        })
        .duration(500)
        .attrTween('d', function(d) {
          var i = d3.interpolate(d.startAngle + 0.1, d.endAngle);
          return function(t) {
            d.endAngle = i(t);
            return arc(d);
          };
        });

      // Labels
      svg.append('g').attr('class', 'labels');
      var labels = d3.select(
        InsightsLocalVsInternationalConfig.chartId + ' .labels'
      );
      var enteringLabels = labels
        .selectAll('.label')
        .data(pie(globalData))
        .enter();
      var labelGroups = enteringLabels.append('g').attr('class', 'label');
      labelGroups.append('circle').attr({
        x: 0,
        y: 0,
        r: 2,
        fill: '#000',
        transform: function(d) {
          return 'translate(' + arc.centroid(d) + ')';
        },

        class: 'label-circle'
      });

      var textLines = labelGroups.append('line').attr({
        x1: function(d) {
          return arc.centroid(d)[0];
        },

        y1: function(d) {
          return arc.centroid(d)[1];
        },

        x2: function(d) {
          return arc.centroid(d)[0];
        },

        y2: function(d) {
          var centroid = arc.centroid(d);
          var midAngle = Math.atan2(centroid[1], centroid[0]);
          return Math.sin(midAngle) * labelRadius;
        },

        class: 'label-line'
      });

      var textLabels = labelGroups
        .append('text')
        .attr({
          x: function(d) {
            var centroid = arc.centroid(d);
            return centroid[0] + 3;
          },

          y: function(d) {
            var centroid = arc.centroid(d);
            var midAngle = Math.atan2(centroid[1], centroid[0]);
            return Math.sin(midAngle) * (labelRadius + 5);
          },

          'text-anchor': function(d) {
            var centroid = arc.centroid(d);
            var midAngle = Math.atan2(centroid[1], centroid[0]);
            var x = Math.cos(midAngle) * (labelRadius + 5);
            return x > 0 ? 'start' : 'end';
          },

          class: 'label-text'
        })
        .text(function(d) {
          var label = d.data.location;
          var total = 0;
          for (var i = 0; i < globalData.length; i++) {
            total += parseInt(Number(globalData[i][typeOfData]), 10);
          }

          var percentage = Math.round(d.value / total * 100 * 10) / 10;
          return label + ' ' + percentage + '%';
        });

      var alpha = 0.5;
      var spacing = 12;

      function relax() {
        var again = false;
        textLabels.each(function() {
          var a = this; // jscs:ignore safeContextKeyword
          var da = d3.select(a);
          var y1 = da.attr('y');
          textLabels.each(function() {
            var b = this; // jscs:ignore safeContextKeyword

            // a & b are the same element and don't collide
            if (a === b) return;
            var db = d3.select(b);

            // a & b are on opposite sides of the chart and
            // don't collide
            if (da.attr('text-anchor') !== db.attr('text-anchor')) return;

            // Now let's calculate the distance between
            // these elements
            var y2 = db.attr('y');
            var deltaY = y1 - y2;

            // If spacing is greater than our specified spacing,
            // they don't collide
            if (Math.abs(deltaY) > spacing) return;

            // If the labels collide, we'll push each
            // of the two labels up and down a little bit.
            again = true;
            var sign = deltaY > 0 ? 1 : -1;
            var adjust = sign * alpha;
            da.attr('y', +y1 + adjust);
            db.attr('y', +y2 - adjust);
          });
        });

        // Adjust our line leaders here
        // so that they follow the labels.
        if (again) {
          var labelElements = textLabels[0];
          textLines.attr('y2', function(d, i) {
            var labelForLine = d3.select(labelElements[i]);
            return labelForLine.attr('y');
          });

          setTimeout(relax, 20);
        }
      }

      function changeVs() {
        typeOfData = angular
          .element(document.querySelector('.switch.active'))
          .attr('value');
        var overlay = document.querySelector(
          InsightsLocalVsInternationalConfig.chartId + ' .overlay'
        );
        var variable = document
          .querySelector('.variable-dropdown .ng-scope')
          .innerHTML.trim();

        // check if the variable is not state as if it is state we are showing the age chart but not for other categories
        if (variable.length > 0 && variable != 'STATE') {
          return;
        }

        if (typeOfData === 'Impressions') {
          angular.element(overlay).removeClass('active');
          if (
            globalData.reduce(function(a, b) {
              return a + parseInt(Number(b.impressions), 10);
            }, 0) === 0
          ) {
            d3.selectAll('#no-vs-impressions').style('opacity', '0');
            d3.selectAll('#no-vs-clicks').style('opacity', '1');
          } else {
            d3.selectAll('#no-vs-clicks').style('opacity', '0');
            d3.selectAll('#no-vs-impressions').style('opacity', '0');

            // value = this.value;
            pie.value(function(d) {
              return d.impressions;
            });

            path.data(pie(globalData));
            path
              .transition()
              .duration(750)
              .attrTween('d', function(d) {
                var i = d3.interpolate(d.startAngle + 0.1, d.endAngle);
                return function(t) {
                  d.endAngle = i(t);
                  return arc(d);
                };
              });

            d3
              .select(InsightsLocalVsInternationalConfig.chartId)
              .selectAll('line')
              .data(pie(globalData))
              .attr({
                x1: function(d) {
                  return arc.centroid(d)[0];
                },

                y1: function(d) {
                  return arc.centroid(d)[1];
                },

                x2: function(d) {
                  return arc.centroid(d)[0];
                },

                y2: function(d) {
                  var centroid = arc.centroid(d);
                  var midAngle = Math.atan2(centroid[1], centroid[0]);
                  return Math.sin(midAngle) * labelRadius;
                },

                class: 'label-line'
              });

            d3
              .select(InsightsLocalVsInternationalConfig.chartId)
              .selectAll('text')
              .data(pie(globalData))
              .attr({
                x: function(d) {
                  var centroid = arc.centroid(d);
                  return centroid[0] + 3;
                },

                y: function(d) {
                  var centroid = arc.centroid(d);
                  var midAngle = Math.atan2(centroid[1], centroid[0]);
                  return Math.sin(midAngle) * (labelRadius + 5);
                },

                'text-anchor': function(d) {
                  var centroid = arc.centroid(d);
                  var midAngle = Math.atan2(centroid[1], centroid[0]);
                  var x = Math.cos(midAngle) * (labelRadius + 5);
                  return x > 0 ? 'start' : 'end';
                },

                class: 'label-text'
              })
              .text(function(d) {
                var label = d.data.location;
                var total = 0;
                for (var i = 0; i < globalData.length; i++) {
                  total += parseInt(
                    Number(globalData[i][typeOfData.toLowerCase()]),
                    10
                  );
                }

                var percentage = Math.round(d.value / total * 100 * 10) / 10;
                return label + ' ' + percentage + '%';
              });

            d3
              .select(InsightsLocalVsInternationalConfig.chartId)
              .selectAll('circle')
              .data(pie(globalData))
              .attr({
                x: 0,
                y: 0,
                r: 2,
                fill: '#000',
                transform: function(d) {
                  return 'translate(' + arc.centroid(d) + ')';
                },

                class: 'label-circle'
              });
            relax();
          }
        } else if (typeOfData === 'UA' || typeOfData === 'Clicks') {
          angular.element(overlay).addClass('active');
          d3.selectAll('#no-vs-impressions').style('opacity', '0');
          d3.selectAll('#no-vs-clicks').style('opacity', '0');
        }
      }

      commonWebsite.hideSpinner('local-vs-international-chart');

      // scales and axes
      d3.selectAll('.switch').on('click.vs', changeVs);
    };

    return {
      checkService: function(jsonData) {
        var chartId = InsightsLocalVsInternationalConfig.chartId;
        var chart = d3.select(chartId);
        chart.selectAll('svg').remove();
        return readData(jsonData, true);
      },

      getData: function() {
        return globalData;
      },

      resize: function() {
        var chartId = InsightsLocalVsInternationalConfig.chartId;
        var chart = d3.select(chartId);
        chart.selectAll('svg').remove();
        return readData(globalData);
      }
    };
  });
