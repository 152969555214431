(function() {
  'use strict';

  angular.module('insights').factory('HelixPersonas', HelixPersonas);

  HelixPersonas.$inject = [
    'HelixIndex',
    'HelixPeople',
    '$document',
    '$q',
    '$http',
    'store'
  ];

  function HelixPersonas(HelixIndex, HelixPeople, $document, $q, $http, store) {
    var activeSortOrder = 'ua';
    // Start descending
    var sortOrder = false;

    return {
      checkService: createOrChange,
      resize: resize,
      formatData: formatData,
      roundData: roundFinalData,
      sortData: sortData,
      indexOnClick: indexOnClick,
      ctrOnClick: ctrOnClick,
      freqOnClick: freqOnClick,
      peopleOnClick: peopleOnClick,
      personaOnClick: personaOnClick
    };

    /**
     * Create chart or change date
     * @param {Array} jsonData
     * @param {string} chartType
     */
    function createOrChange(jsonData, chartType) {
      var sortedData = jsonData.sort(sortByUA);
      HelixIndex.createOrChange(sortedData, chartType);
      HelixPeople.createOrChange(sortedData, chartType);
    }

    /**
     * Sort data by UA
     * @param  {Object} a
     * @param  {Object} b
     * @return {number}
     */
    function sortByUA(a, b) {
      if (a.ua > b.ua) return -1;
      if (a.ua < b.ua) return 1;
      return 0;
    }

    /**
     * Resize charts
     */
    function resize() {
      HelixPeople.resize();
      HelixIndex.resize();
    }

    /**
     * Calculate Total UA
     * @param {Array} data
     * @returns {*}
     */
    function calculateTotalUA(data) {
      // pass in date eventually
      return parseInt(
        data.reduce(function(a, b) {
          return a + Number(b.UA);
        }, 0),
        10
      );
    }

    /**
     * Read Helix Output JSON
     * @param {string} helixYear
     * @returns {promise}
     */
    function loadHelixOutput(helixYear) {
      return $q(function(resolve) {
        $http.get('/api/staticdata/helix-output').success(function(data) {
          // looping data and adding new key with the correct name
          if (data.length) {
            // for year 2014
            if (helixYear.toString() === '2014') {
              for (var i = 0; i < data.length; i++) {
                // replace helix with helix_2014 value when the year is 2014
                if (
                  data[i].Helix_Persona_2014 &&
                  data[i].hasOwnProperty('Helix_Persona_2014')
                ) {
                  data[i]['Helix Persona'] = data[i].Helix_Persona_2014;
                  delete data[i].Helix_Persona;
                }
                if (data[i].V_2014 && data[i].hasOwnProperty('V_2014')) {
                  data[i]['V%'] = data[i].V_2014;
                  delete data[i].V;
                }
                if (
                  data[i].Helix_Community_2014 &&
                  data[i].hasOwnProperty('Helix_Community_2014')
                ) {
                  data[i]['Helix community'] = data[i].Helix_Community_2014;
                  delete data[i].Helix_Community;
                }
                if (
                  data[i].Helix_2014 &&
                  data[i].hasOwnProperty('Helix_2014')
                ) {
                  data[i].Helix = parseInt(data[i].Helix_2014, 10);
                }
              }
            } else {
              for (var j = 0; j < data.length; j++) {
                if (data[j].hasOwnProperty('Helix_Persona')) {
                  data[j]['Helix Persona'] = data[j].Helix_Persona;
                  delete data[j].Helix_Persona;
                }
                if (data[j].hasOwnProperty('V')) {
                  data[j]['V%'] = data[j].V;
                  delete data[j].V;
                }
                if (data[j].hasOwnProperty('Helix_Community')) {
                  data[j]['Helix community'] = data[j].Helix_Community;
                  delete data[j].Helix_Community;
                }
                if (data[j].hasOwnProperty('Helix')) {
                  data[j].Helix = parseInt(data[j].Helix, 10);
                }
              }
            }
          }
          resolve(data);
        });
      });
    }

    /**
     * Load Helix Profiles from Static File
     * @param {string} helixYear
     * @returns {promise}
     */
    function loadHelixProfiles(helixYear) {
      return $q(function(resolve) {
        $http
          .get('/api/staticdata/helix-name-community')
          .success(function(data) {
            // looping data and casting numbers to string
            if (data.length) {
              // for year 2014
              if (helixYear.toString() === '2014') {
                for (var i = 0; i < data.length; i++) {
                  // replace helix with helix_2014 value when the year is 2014
                  if (
                    data[i].Helix_2014 &&
                    data[i].hasOwnProperty('Helix_2014')
                  ) {
                    data[i].Helix = data[i].Helix_2014.toString();
                  }

                  if (
                    data[i].Helix_Community_2014 &&
                    data[i].hasOwnProperty('Helix_Community_2014')
                  ) {
                    data[i].Helix_Community = data[
                      i
                    ].Helix_Community_2014.toString();
                  }

                  if (
                    data[i].Helix_Name_2014 &&
                    data[i].hasOwnProperty('Helix_Name_2014')
                  ) {
                    data[i].Helix_Name = data[i].Helix_Name_2014.toString();
                  }
                }
              } else {
                for (var j = 0; j < data.length; j++) {
                  if (data[j].Helix && data[j].hasOwnProperty('Helix')) {
                    data[j].Helix = data[j].Helix.toString();
                  }

                  if (
                    data[j].Helix_Community &&
                    data[j].hasOwnProperty('Helix_Community')
                  ) {
                    data[j].Helix_Community = data[
                      j
                    ].Helix_Community.toString();
                  }
                }
              }
            }
            resolve(data);
          });
      });
    }

    /**
     * Get description for Helix Persona
     * @returns {Promise<*>}
     */
    function getPersonaDescription() {
      return $q(function(resolve) {
        $http
          .get('/static-data/personas_australia.json')
          .success(function(data) {
            resolve(data);
          });
      });
    }

    /**
     * Format data for charts
     * @param {Object[]} data
     * @param {string} group
     * @param {string} helixYear
     * @returns {Promise<*>} Promise containing data
     */
    function formatData(data, group, helixYear) {
      var relevantData = data;
      var newData = [];
      var outputData;
      return $q(function(resolve) {
        loadHelixOutput(helixYear)
          .then(function(result) {
            outputData = result;
            return loadHelixProfiles(helixYear);
          })
          .then(function(areaData) {
            getPersonaDescription().then(function(descriptions) {
              if (group && group.toUpperCase() === 'COMMUNITIES') {
                newData = formatDataForCommunities(
                  relevantData,
                  areaData,
                  outputData,
                  descriptions,
                  helixYear
                );
              } else {
                newData = formatDataForPersonas(
                  relevantData,
                  areaData,
                  outputData,
                  descriptions,
                  helixYear
                );
              }
              resolve(newData);
            });
          });
      });
    }

    /**
     * Format data for Personas chart/downloads.
     * @param {Object[]} data
     * @param {Object[]} areaData
     * @param {Object[]} outputData
     * @param {Object[]} descriptions
     * @param {string} helixYear
     * @returns {Object[]} Promise containing data
     */
    function formatDataForPersonas(
      data,
      areaData,
      outputData,
      descriptions,
      helixYear
    ) {
      var newData = [];
      var totalUA = calculateTotalUA(data);
      angular.forEach(areaData, function(value) {
        // Other has a massive amount of data as it's not recorded &&
        // restricting communities values to add into personas
        if (
          parseInt(value.Helix, 10) < 800 &&
          (parseInt(value.Helix, 10) !== 100 &&
            parseInt(value.Helix, 10) !== 200 &&
            parseInt(value.Helix, 10) !== 300 &&
            parseInt(value.Helix, 10) !== 400 &&
            parseInt(value.Helix, 10) !== 500 &&
            parseInt(value.Helix, 10) !== 600 &&
            parseInt(value.Helix, 10) !== 700 &&
            value.Helix_Name !== 'Other')
        ) {
          var impressions = 0;
          var clicks = 0;
          var ua = 0;
          var ctr = 0;
          var freq = 0;
          var desc = null;

          for (var i = 0; i < data.length; i++) {
            // OR condition for year 2014 helix version
            if (
              (data[i].Helix === value.Helix &&
                data[i].Helix_Community === value.Helix_Community) ||
              (data[i].Helix_2014 === value.Helix &&
                data[i].Helix_Community_2014 === value.Helix_Community)
            ) {
              ua = parseInt(Number(data[i].UA ? data[i].UA : 0).toString(), 10);
              impressions = parseInt(
                Number(
                  data[i].impressions ? data[i].impressions : 0
                ).toString(),
                10
              );
              clicks = parseInt(
                Number(data[i].clicks ? data[i].clicks : 0).toString(),
                10
              );
              ctr =
                impressions > 0
                  ? Number((clicks / impressions) * 100)
                  : Number(clicks * 100);
              // just rounding off ctr up to 2 decimal, otherwise bars will work differently
              ctr = Math.round(ctr * 100) / 100;
              freq = ua > 0 ? Number(impressions / ua) : Number(impressions);
            }
          }

          var share = getPersonaShare(outputData, value.Helix);
          // for year 2014
          if (helixYear.toString() === '2014') {
            angular.forEach(descriptions, function(description) {
              // match description with the helix
              if (
                parseInt(value.Helix, 10) ===
                parseInt(description.Helix_2014, 10)
              ) {
                desc = description.Description_2014;
              }
            });
          } else {
            angular.forEach(descriptions, function(description) {
              if (
                parseInt(value.Helix, 10) === parseInt(description.Helix, 10)
              ) {
                desc = description.Description;
              }
            });
          }
          var helixCommunityName;
          if (helixYear.toString() === '2014') {
            for (var j = 0; j < outputData.length; j++) {
              if (outputData[j].Helix.toString() === value.Helix.toString()) {
                helixCommunityName = outputData[j].Helix_Community_2014;
                break;
              }
            }
          } else {
            for (var k = 0; k < outputData.length; k++) {
              if (outputData[k].Helix.toString() === value.Helix.toString()) {
                helixCommunityName = outputData[k]['Helix community'];
                break;
              }
            }
          }
          newData.push({
            Helix_Name: value.Helix + ' ' + value.Helix_Name,
            Helix_Code: value.Helix,
            Helix_Community_Name: helixCommunityName,
            ua: ua,
            impressions: impressions,
            clicks: clicks,
            index: calculateIndex(ua, totalUA, share),
            ctr: ctr,
            freq: freq,
            desc: desc
          });
        }
      });
      return newData;
    }

    /**
     * Format data for Communities chart/downloads.
     * @param {Object[]} data
     * @param {Object[]} _areaData
     * @param {Object[]} outputData
     * @param {Object[]} descriptions
     * @param {string} helixYear
     * @returns {Object[]} Promise containing data
     */
    function formatDataForCommunities(
      data,
      _areaData,
      outputData,
      descriptions,
      helixYear
    ) {
      var totalUA = calculateTotalUA(data);
      var communities = {};
      var defaultCommunity = {
        name: '',
        impressions: 0,
        clicks: 0,
        ua: 0,
        desc: null,
        'v%': 0
      };
      // loop through output data to set up communities
      angular.forEach(outputData, function(od) {
        if (parseInt(od.Helix, 10) < 800) {
          var key = String(od.Helix).charAt(0);
          communities[key] = communities[key] || angular.copy(defaultCommunity);
          communities[key].name = od['Helix community'];
          communities[key]['v%'] += od['V%'];
        }
      });

      // loop through data to sum the impressions, clicks, ua
      angular.forEach(data, function(d) {
        var community = d.Helix_Community;
        // for year 2014
        if (helixYear.toString() === '2014' && d.Helix_Community_2014) {
          community = d.Helix_Community_2014;
        }

        if (communities[community]) {
          var impressions = parseInt(Number(d.impressions).toString(), 10);
          var clicks = parseInt(Number(d.clicks).toString(), 10);
          var ua = parseInt(Number(d.UA).toString(), 10);

          // add to the community
          communities[community].helixCommunity = community;
          communities[community].impressions += impressions;
          communities[community].clicks += clicks;
          communities[community].ua += ua;
        }
      });

      var desc = null;
      // convert communities into array of data
      var newData = [];
      angular.forEach(communities, function(c) {
        var share = c['v%'];
        var communityNumber = getCommunityNumber(c.helixCommunity);

        // for year 2014
        if (helixYear.toString() === '2014') {
          angular.forEach(descriptions, function(description) {
            // match description with the helix
            if (
              parseInt(communityNumber, 10) ===
              parseInt(description.Community_2014, 10)
            ) {
              desc = description.Description_2014;
            }
          });
        } else {
          angular.forEach(descriptions, function(description) {
            if (
              parseInt(communityNumber, 10) ===
              parseInt(description.Community, 10)
            ) {
              desc = description.Description;
            }
          });
        }

        // we are not showing Other
        if (c.name !== '700 Other') {
          newData.push({
            Helix_Name: c.name || communityNumber,
            Helix_Code: communityNumber,
            Helix_Community: c.helixCommunity,
            ua: c.ua,
            impressions: c.impressions,
            clicks: c.clicks,
            index: calculateIndex(c.ua, totalUA, share),
            desc: desc,
            freq: Number(c.impressions / c.ua) || 0,
            ctr: Number((c.clicks / c.impressions) * 100) || 0
          });
        }
      });
      return newData;
    }

    /**
     * Get community number from first digit
     * @param {string} helixDigit
     * @returns {string} '700' or itself
     */
    function getCommunityNumber(helixDigit) {
      if (isNaN(Number(helixDigit))) {
        // if it's not a number, return it as is
        return helixDigit;
      }
      return helixDigit + '00';
    }

    /**
     * Round download data
     * @param {Array<Object>} dataToRound
     * @param {string} group
     * @param {string} helixYear
     * @returns {Promise<Array<Object>>} Promise
     */
    function roundFinalData(dataToRound, group, helixYear) {
      return $q(function(resolve) {
        formatData(dataToRound, group, helixYear).then(function(result) {
          dataToRound = result;
          angular.forEach(dataToRound, function(value) {
            value.UA = Math.round(value.ua);
            if (value.clicks) {
              value.clicks = Math.round(value.clicks);
            } else {
              value.clicks = 0;
            }

            value.impressions = Math.round(value.impressions);
            delete value.ua;
          });

          resolve(dataToRound);
        });
      });
    }

    /**
     * Calculate Helix Index
     * @param {number} sumUAPersona
     * @param {number} sumUACampaign
     * @param {number} personaShare
     * @returns {number|null}
     */
    function calculateIndex(sumUAPersona, sumUACampaign, personaShare) {
      if (personaShare) {
        var index = Math.round(
          (sumUAPersona / sumUACampaign / personaShare) * 10000
        );
        return index || null;
      }
      return null;
    }

    /**
     * Get Persona Share
     * @param {Array} helixOutput
     * @param {string} helixNo
     * @returns {number|null}
     */
    function getPersonaShare(helixOutput, helixNo) {
      for (var i = 0; i < helixOutput.length; i++) {
        if (helixOutput[i].Helix === parseInt(helixNo, 10)) {
          return helixOutput[i]['V%'];
        }
      }

      return null;
    }

    function peopleOnClick() {
      var typeOfData = getTypeOfData();
      var peopleCaret = $document[0].getElementById('people-caret');
      var helixCaret = $document[0].getElementById('persona-caret');
      var indexCaret = $document[0].getElementById('index-caret');

      helixCaret.className = 'fa';
      indexCaret.className = 'fa';
      sortData(typeOfData);

      if (sortOrder) {
        peopleCaret.className = 'fa fa-caret-up';
      } else {
        peopleCaret.className = 'fa fa-caret-down';
      }
    }

    function personaOnClick() {
      var peopleCaret = $document[0].getElementById('people-caret');
      var helixCaret = $document[0].getElementById('persona-caret');
      var indexCaret = $document[0].getElementById('index-caret');
      peopleCaret.className = 'fa';
      indexCaret.className = 'fa';
      sortData('Helix_Code');

      if (sortOrder) {
        helixCaret.className = 'fa fa-caret-up';
      } else {
        helixCaret.className = 'fa fa-caret-down';
      }
    }

    function indexOnClick() {
      var typeOfData = getTypeOfData();
      var peopleCaret = $document[0].getElementById('people-caret');
      var helixCaret = $document[0].getElementById('persona-caret');
      var indexCaret = $document[0].getElementById('index-caret');
      if (typeOfData === 'ua') {
        sortData('index');
        helixCaret.className = 'fa';
        peopleCaret.className = 'fa';
        if (sortOrder) {
          indexCaret.className = 'fa fa-caret-up';
        } else {
          indexCaret.className = 'fa fa-caret-down';
        }
      }
    }

    function freqOnClick() {
      var typeOfData = getTypeOfData();
      var peopleCaret = $document[0].getElementById('people-caret');
      var helixCaret = $document[0].getElementById('persona-caret');
      var indexCaret = $document[0].getElementById('index-caret');
      if (typeOfData === 'impressions') {
        sortData('freq');
        helixCaret.className = 'fa';
        peopleCaret.className = 'fa';
        if (sortOrder) {
          indexCaret.className = 'fa fa-caret-up';
        } else {
          indexCaret.className = 'fa fa-caret-down';
        }
      }
    }

    function ctrOnClick() {
      var typeOfData = getTypeOfData();
      var peopleCaret = $document[0].getElementById('people-caret');
      var helixCaret = $document[0].getElementById('persona-caret');
      var indexCaret = $document[0].getElementById('index-caret');
      if (typeOfData === 'clicks') {
        sortData('ctr');
        helixCaret.className = 'fa';
        peopleCaret.className = 'fa';
        if (sortOrder) {
          indexCaret.className = 'fa fa-caret-up';
        } else {
          indexCaret.className = 'fa fa-caret-down';
        }
      }
    }

    function sortData(sortable) {
      if (sortable === activeSortOrder) {
        // change sort direction, keep same data
        sortOrder = !sortOrder;
      } else {
        // keep same sort direction, change data
        activeSortOrder = sortable;
      }

      HelixPeople.sortData(sortable, sortOrder);
      HelixIndex.sortData(sortable, sortOrder);
    }

    /**
     * Gets the currently showed data type. E.g. UA, Impressions or Clicks.
     * @returns {string} - Type of data shown
     */
    function getTypeOfData() {
      var data = angular
        .element($document[0].querySelector('.switch.active'))
        .attr('value');
      if (data === 'UA') {
        data = 'ua';
      } else if (data === 'Impressions') {
        data = 'impressions';
      } else if (data === 'Clicks') {
        data = 'clicks';
      }

      return data;
    }
  }
})();
