'use strict';

// Pixels service used to communicate Pixels REST endpoints
angular.module('pixels').factory('Pixels', [
  'AudiencesApi',
  function(audiencesApi) {
    return audiencesApi.resource(
      '/campaigns/:campaignId/pixels/:pixelId',
      {
        pixelId: '@pixelId',
        campaignId: '@campaignId'
      },
      {
        update: {
          method: 'PUT'
        }
      }
    );
  }
]);
